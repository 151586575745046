import React, { useContext } from "react";
import { Hand, Water, EasyInstallation } from "../../assets/svg";
import { ContactModalContext } from "../../config/contexts";

const PoshCard = ({ svg, label, text }) => {
  return (
    <div className=" grid grid-cols-1 pt-[10px] lg:pt-[20px] gap-[10px]">
      <div className=" flex justify-center items-center">{svg}</div>
      <p className="font-inter font-semibold text-[16px] leading-[22.4px] lg:text-[20px] lg:leading-[28px] text-[#000000] tracking-[-0.03em] text-center">
        {label}
      </p>
      <p className="font-inter font-normal text-[12px] leading-[18px] lg:text-[16px] lg:leading-[24px] tracking-[0em] text-center text-[#000000]">
        {text}
      </p>
    </div>
  );
};

const PoshArray = [
  {
    svg: <Hand />,
    label: "Feather-Touch Controls",
    text: "CapSense technology ensures smooth, effortless interaction",
  },
  {
    svg: <Water />,
    label: "Splash-Proof & Durable",
    text: "Resists water splashes while maintaining a sleek look",
  },
  {
    svg: <EasyInstallation />,
    label: "Modular & Easy Installation",
    text: "Fits seamlessly into any home with hassle-free retrofitting",
  },
];

function PoshSeries() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div
      id="PoshSeries"
      className="poshseries-section h-[910px] lg:h-[994px]  items-center px-[20px] py-[40px] lg:px-[140px] lg:py-[60px] "
    >
      <div className="grid grid-cols-1  bg-[#FFFFFF] gap-[0px] lg:gap-[38px] h-[868px] ">
        <div className=" flex justify-center items-center">
          <img
            className="w-[382px] h-[225px] lg:h-[430px] lg:w-[730px]"
            src="/images/posh.png"
            alt="PoshSeries"
            loading="lazy"
          />
        </div>
        <div className="">
          <div className=" flex justify-center items-center pb-[20px]">
            <p className="font-inter font-semibold text-[16px] leading-[22.4px] tracking-[-0.03em] lg:text-[24px] lg:leading-[33.6px] lg:tracking-[-3%] text-center text-[#1E1E1E]">
              Introducing Posh Series—where style meets smart living. Luxury in
              every touch. Smart in every way. Designed to impress, built to
              last.
            </p>
          </div>
          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-[20px] lg:gap-[40px]">
            {PoshArray?.map((item, index) => (
              <div className="" key={index}>
                {" "}
                <PoshCard svg={item.svg} label={item.label} text={item.text} />
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <button
            className=" w-full lg:w-[400px]  h-[56px] px-[32px] py-[14px] rounded-[8px] bg-[#1E1E1E] text-white font-inter font-semibold text-[16px] leading-[22.4px] tracking-[-3%] lg:text-[20px] lg:leading-[28px]"
            onClick={() => openModal()}
          >
            Enquire About the Posh Series
          </button>
        </div>
      </div>
    </div>
  );
}

export default PoshSeries;

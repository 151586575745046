import React, { useContext, useEffect, useState } from "react";
import {
  WhitelionIcon,
  WhitelionWhite,
  ToggleMenu,
  ToggleClose,
} from "../../assets/svg";
import { Link } from "react-router-dom";
import {
  useDotClass,
  useActiveLink,
  useActiveScreenLink,
  usGoToRoute,
  useDotProductClass,
  useActiveLinkProduct,
} from "../../config/customHooks";
import { navLinks, productsLink } from "../../config/constant/NavLink";
import { NavContext, ContactModalContext } from "../../config/contexts";
import Header from "../header";

function Navbar() {
  const { isWhiteColor } = useContext(NavContext);
  const { openModal } = useContext(ContactModalContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [productDropdown, setProductDropdown] = useState(false); // State for product dropdown

  const toggleProductDropdown = () => {
    setProductDropdown(!productDropdown);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const ToggleCloseMenu = () => {
    setIsMobileMenuOpen(false);
    usGoToRoute();
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.getElementById("navbar-main");

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (navbar) {
        if (scrollTop > lastScrollTop) {
          navbar.style.top = "-80px"; // Hide navbar on scroll down
          setIsMobileMenuOpen(false);
        } else {
          navbar.style.top = "0"; // Show navbar on scroll up
        }
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="navbar-main"
      className={`${
        isWhiteColor ? "bg-custom-bg-white" : "bg-[#000000]"
      } mt-[0px] h-[80px] lg:h-[80px] sticky top-0 z-50 transition-all duration-100 lg:px-[160px] relative`}
    >
      <div className="flex flex-wrap items-center justify-between mx-auto lg:p-0 ">
        {/* <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse"> */}
        <div className="pl-4">
          {isWhiteColor ? <WhitelionIcon /> : <WhitelionWhite />}
        </div>
        {/* </a> */}
        <div className="pr-4 lg:hidden block">
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden"
            aria-controls="navbar-default"
            aria-expanded={isMobileMenuOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            {!isMobileMenuOpen ? (
              <ToggleMenu isWhiteColor={isWhiteColor} />
            ) : (
              <ToggleClose isWhiteColor={isWhiteColor} />
            )}
          </button>
        </div>
        <div
          className={`lg:hidden w-full h-screen bg-white transition-all duration-500 ease-in-out transform origin-top absolute top-[80px] left-0 ${
            isMobileMenuOpen
              ? "opacity-100 scale-y-100 pointer-events-auto"
              : "opacity-0 scale-y-0 pointer-events-none"
          }`}
        >
          <div className="grid grid-cols-1 w-full h-screen">
            <div className="h-[240px] grid grid-cols-1 pt-[20px] ">
              <div className=" flex justify-between items-center w-full px-[16px]">
                <Link
                  to={navLinks.home.route}
                  className={useActiveScreenLink(navLinks.home.route)}
                  onClick={() => ToggleCloseMenu()}
                >
                  {navLinks.home.name}
                </Link>
                <div className={useDotClass(navLinks.home.route)}></div>
              </div>
              <div className=" flex justify-between items-center w-full px-[16px]">
                <Link
                  to={navLinks.about.route}
                  className={useActiveScreenLink(navLinks.about.route)}
                  onClick={() => ToggleCloseMenu()}
                >
                  {navLinks.about.name}
                </Link>
                <div className={useDotClass(navLinks.about.route)}></div>
              </div>

              <div className="flex flex-col justify-center items-center w-full px-[16px] cursor-pointer">
                <div
                  className="flex justify-between items-center w-full"
                  onClick={toggleProductDropdown}
                >
                  <Link
                    className={useActiveScreenLink(navLinks.products.route)}
                  >
                    {navLinks.products.name}
                  </Link>
                  <div className={useDotClass(navLinks.products.route)}></div>
                </div>
                {/* Products Dropdown */}
                <div
                  className={` w-full grid grid-cols-2 overflow-hidden transition-all duration-300 ease-in-out ${
                    productDropdown ? "h-[230px] opacity-100" : "h-0 opacity-0"
                  }`}
                >
                  <Link
                    to={productsLink?.posh?.route}
                    className={useActiveScreenLink(productsLink?.posh?.route)}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(productsLink.posh.route)}
                    ></div>

                    {productsLink?.posh?.name}
                  </Link>
                  <Link
                    to={productsLink?.quartz?.route}
                    className={useActiveScreenLink(productsLink?.quartz?.route)}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(productsLink.quartz.route)}
                    ></div>

                    {productsLink?.quartz?.name}
                  </Link>
                  {/* <Link
                    to={productsLink?.mocha?.route}
                    className={useActiveScreenLink(productsLink?.mocha?.route)}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(productsLink.mocha.route)}
                    ></div>

                    {productsLink?.mocha?.name}
                  </Link> */}
                  <Link
                    to={productsLink?.airtouch?.route}
                    className={useActiveScreenLink(
                      productsLink?.airtouch?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(
                        productsLink.airtouch.route
                      )}
                    ></div>

                    {productsLink?.airtouch?.name}
                  </Link>
                  <Link
                    to={productsLink?.airglass?.route}
                    className={useActiveScreenLink(
                      productsLink?.airglass?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(
                        productsLink.airglass.route
                      )}
                    ></div>

                    {productsLink?.airglass?.name}
                  </Link>
                  <Link
                    to={productsLink?.airsensor?.route}
                    className={useActiveScreenLink(
                      productsLink?.airsensor?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(
                        productsLink.airsensor.route
                      )}
                    ></div>

                    {productsLink?.airsensor?.name}
                  </Link>
                  {/* <Link
                    to={productsLink?.airblaster?.route}
                    className={useActiveScreenLink(
                      productsLink?.airblaster?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(
                        productsLink.airblaster.route
                      )}
                    ></div>

                    {productsLink?.airblaster?.name}
                  </Link> */}
                  <Link
                    to={productsLink?.airhome?.route}
                    className={useActiveScreenLink(
                      productsLink?.airhome?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(productsLink.airhome.route)}
                    ></div>

                    {productsLink?.airhome?.name}
                  </Link>
                  <Link
                    to={productsLink?.accessories?.route}
                    className={useActiveScreenLink(
                      productsLink?.accessories?.route
                    )}
                    onClick={() => ToggleCloseMenu()}
                  >
                    <div
                      className={useDotProductClass(
                        productsLink.accessories.route
                      )}
                    ></div>

                    {productsLink?.accessories?.name}
                  </Link>
                </div>
              </div>

              {/* temp hide */}
              {/* <div className=" flex justify-between items-center w-full px-[16px]">
                <Link
                  to={navLinks.solution.route}
                  className={useActiveScreenLink(navLinks.solution.route)}
                  onClick={() => ToggleCloseMenu()}
                >
                  {navLinks.solution.name}
                </Link>
                <div className={useDotClass(navLinks.solution.route)}></div>
              </div>
              <div className=" flex justify-between items-center w-full px-[16px]">
                <Link
                  to={navLinks.partner.route}
                  className={useActiveScreenLink(navLinks.partner.route)}
                  onClick={() => ToggleCloseMenu()}
                >
                  {navLinks.partner.name}
                </Link>
                <div className={useDotClass(navLinks.partner.route)}></div>
              </div> */}
              <div className=" flex justify-between items-center w-full px-[16px]">
                <Link
                  to={navLinks.career.route}
                  className={useActiveScreenLink(navLinks.career.route)}
                  onClick={() => ToggleCloseMenu()}
                >
                  {navLinks.career.name}
                </Link>
                <div className={useDotClass(navLinks.career.route)}></div>
              </div>
            </div>
            <div className=" mt-auto mb-[120px] px-4 ">
              <div className="border rounded-lg bg-[linear-gradient(145.42deg,_#00F0FF_-19.1%,_#0066FF_105.1%)] text-center hover:cursor-pointer">
                <Link
                  // to={navLinks.contact.route}
                  onClick={() => {
                    ToggleCloseMenu();
                    openModal();
                  }}
                  className="block py-2 px-3 text-white rounded-sm  block py-2 px-4 text-[#1E1E1E80] rounded-lg hover:text-white text-white"
                >
                  {navLinks.contact.name}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden w-full lg:flex lg:w-auto h-[80px] ">
          <ul className="font-medium flex flex-col lg:p-0 rounded-lg lg:flex-row lg:gap-[20px] rtl:space-x-reverse items-center lg:items-center">
            <li>
              <Link
                to={navLinks.home.route}
                className={useActiveLink(navLinks.home.route)}
                onClick={() => usGoToRoute()}
              >
                {navLinks.home.name}
              </Link>
            </li>
            <li>
              <Link
                to={navLinks.about.route}
                className={useActiveLink(navLinks.about.route)}
                onClick={() => usGoToRoute()}
              >
                {navLinks.about.name}
              </Link>
            </li>
            <li className="group">
              <Link
                className={useActiveLinkProduct("/products/")}
                onClick={() => usGoToRoute()}
              >
                {navLinks.products.name}
              </Link>
              {/* Dropdown Menu */}
              <div
                className={`${
                  isWhiteColor ? "bg-custom-bg-white" : "bg-[#000000]"
                } absolute left-0 w-full shadow-lg py-2 opacity-0 translate-y-[-10px] group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-600 ease-out invisible group-hover:visible`}
              >
                <Header />
              </div>
            </li>
            {/* temp hide */}
            {/* <li>
              <Link
                to={navLinks.solution.route}
                className={useActiveLink(navLinks.solution.route)}
                onClick={() => usGoToRoute()}
              >
                {navLinks.solution.name}
              </Link>
            </li>
            <li>
              <Link
                to={navLinks.partner.route}
                className={useActiveLink(navLinks.partner.route)}
                onClick={() => usGoToRoute()}
              >
                {navLinks.partner.name}
              </Link>
            </li> */}
            <li>
              <Link
                to={navLinks.career.route}
                className={useActiveLink(navLinks.career.route)}
                onClick={() => usGoToRoute()}
              >
                {navLinks.career.name}
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden lg:flex rounded-lg bg-[linear-gradient(145.42deg,_#00F0FF_-19.1%,_#0066FF_105.1%)] hover:cursor-pointer">
          <Link
            // to={navLinks.contact.route}
            onClick={() => {
              openModal();
            }}
            className="block py-2 px-3 text-white rounded-sm  block py-2 px-4 text-[#1E1E1E80] rounded-lg hover:text-white text-white"
          >
            {navLinks.contact.name}
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React from "react";

function RoundClock() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2464_3857)">
        <path
          d="M50 32C50 29 47.6 26.4 44.6 26C42.4 21.2 37.6 18 32 18C31.6 18 31 18 30.6 18.2C29.4 16.8 27.8 16 26 16C22.6 16 20 18.6 20 22C20 22.8 20.2 23.4 20.4 24.2C18.8 26.4 18 29 18 32C18 36.5 18.8 37.6 20.4 39.8C20.2 40.6 20 41.2 20 42C20 45.4 22.6 48 26 48C27.8 48 29.4 47.2 30.6 45.8C31 45.8 31.6 46 32 46C37.6 46 42.4 42.6 44.6 38C47.6 37.6 50 35 50 32ZM26 20C27.2 20 28 20.8 28 22C28 23.2 27.2 24 26 24C24.8 24 24 23.2 24 22C24 20.8 24.8 20 26 20ZM26 44C24.8 44 24 43.2 24 42C24 40.8 24.8 40 26 40C27.2 40 28 40.8 28 42C28 43.2 27.2 44 26 44ZM32 42C32 38.6 29.4 36 26 36C25 36 24 36.2 23.2 36.8C22.4 35.4 22 33.8 22 32C22 29.3 22.4 28.6 23.2 27.2C24 27.8 25 28 26 28C29.4 28 32 25.4 32 22C35.8 22 38.8 24 40.6 27C39 28.2 38 30 38 32C38 34 39 35.8 40.6 37C38.8 40 35.8 42 32 42ZM44 34C42.8 34 42 33.2 42 32C42 30.8 42.8 30 44 30C45.2 30 46 30.8 46 32C46 33.2 45.2 34 44 34Z"
          fill="white"
        />
        <path
          d="M32 61.9984C31.6 61.9984 31.4 61.9984 31 61.7984L7 47.7984C6.4 47.3984 6 46.7984 6 45.9984V17.9984C6 17.1984 6.4 16.5984 7 16.1984L31 2.19844C31.6 1.79844 32.4 1.79844 33 2.19844L57 16.1984L55 19.5984L32 6.39844L10 19.1984V44.9984L32 57.7984L54 44.9984V29.9984H58V45.9984C58 46.7984 57.6 47.3984 57 47.7984L33 61.7984C32.6 61.9984 32.4 61.9984 32 61.9984Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2464_3857">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RoundClock;

import { LeafIcon, ModulerIcon, RemoteIcon } from "../../../assets/svg";

export const TimelessArray = {
  label: "Master Your Home, Effortlessly",
  text: "A smart IR remote that wirelessly controls TVs, ACs, set-top boxes and other appliances via the AirHome App. With Wi-Fi connectivity and remote learning, it eliminates clutter and enhances your smart home.",
  firstCard: { img: "/images/airblaster/cardone.png" },
  secondCard: {
    label: "Why Use AirBlaster?",
    text: "Say goodbye to multiple remotes. Control appliances from anywhere, automate routines, and master unsupported brands with ease.",
  },
  thirdCard: {
    label: "What is AirBlaster?",
    text: "A smart IR remote that wirelessly controls TVs, ACs, and set-top boxes via the AirHome App. With Wi-Fi connectivity and remote learning, it eliminates clutter and enhances your smart home",
  },
  fourthCard: {
    img: "/images/airblaster/cardfour.png",
  },
  fiveCard: {
    img: "/images/airblaster/cardfive.png",
    label: "How to Use AirBlaster?",
    text: "Connect via AirHome App’s Device Manager, configure IR appliances with remote learning, and command from anywhere with ease.",
  },
};

export const EXcellenceArray = [
  {
    icon: <LeafIcon />,
    label: "Wireless Efficiency",
    text: "Low-energy design ensures smooth, eco-friendly operation",
  },
  {
    icon: <RemoteIcon />,
    label: "Precision Reach",
    text: "IR range up to 10m for reliable appliance control",
  },
  {
    icon: <ModulerIcon />,
    label: "Modular & Easy Installation",
    text: "Engineered for long-lasting performance and reliability",
  },
];

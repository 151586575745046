import { motion } from "framer-motion";
import { useContext } from "react";
import { ContactModalContext } from "../../config/contexts";

const ScrollToFullScreen = () => {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="flex items-center justify-center relative bg-custom-bg-white">
      <motion.div className="w-full h-screen overflow-hidden relative transition-all duration-300 ">
        {/* Background Image */}
        <img
          src="/images/home/herohome.jpg"
          alt="Expandable"
          className="w-full h-full object-cover"
          fetchPriority="high"
        />

        <motion.div
          initial={{ opacity: 0, y: 50 }} // Start off-screen and invisible
          animate={{ opacity: 1, y: 0 }} // Animate to visible and normal position
          transition={{ duration: 1, ease: "easeOut" }} // Smooth transition
          className="absolute inset-0 flex flex-col items-center justify-center text-[#000000] text-center px-[48px] lg:px-[165px] gap-[18px]"
        >
          <motion.h1
            initial={{ opacity: 0, y: 20 }} // Slightly lower
            animate={{ opacity: 1, y: 0 }} // Slide up
            transition={{ duration: 1, delay: 0.2, ease: "easeOut" }} // Delay for staggered effect
            className="font-bold text-[32px] lg:text-[64px] leading-[120%] tracking-[0%] text-center align-middle"
          >
            Welcome to the Future of Smart Living
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
            className="font-sfpro font-medium text-[18px] lg:text-[32px] leading-[120%] tracking-[1%] text-center align-middle opacity-70"
          >
            Whitelion turns your space into a seamless smart experience
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.6, ease: "easeOut" }}
          >
            <button
              onClick={() => openModal()}
              className="h-[56px] w-[180px] px-[32px] py-[14px]  rounded-[8px]  font-semibold text-[#000000] text-[18px] leading-[140%] tracking-[-1%] text-center align-middle border border-black backdrop-blur-sm"
            >
              Get a Demo
            </button>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ScrollToFullScreen;

import React from "react";
import { motion } from "framer-motion";
function index() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 via-gray-900 to-black flex items-center justify-center px-6 text-white">
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="bg-white rounded-3xl shadow-2xl p-10 max-w-2xl w-full text-center"
      >
        <h1 className="text-4xl md:text-5xl font-extrabold text-yellow-700 mb-4">
          We're Launching Soon
        </h1>
        <p className="text-lg text-gray-600">
          We’re preparing something special just for you. Our{" "}
          <span className="font-semibold text-yellow-800">
            ₹999 limited-time offers
          </span>{" "}
          will be live shortly.
        </p>

        <p className="mt-6 text-sm text-gray-400">
          This page will be available soon. Stay tuned!
        </p>
      </motion.div>
    </div>
  );
}

export default index;

import React from "react";

function RemoteIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0918 21.7983C17.2473 21.7983 18.1841 20.8616 18.1841 19.7061C18.1841 18.5505 17.2473 17.6138 16.0918 17.6138C14.9363 17.6138 13.9995 18.5505 13.9995 19.7061C13.9995 20.8616 14.9363 21.7983 16.0918 21.7983Z"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.56934 4.42552C10.2039 2.30323 12.9847 1.01786 15.9731 1.00017C18.9623 0.983246 21.763 2.23862 23.4299 4.34167M12.6154 7.52163C13.3554 6.56011 14.6154 5.97781 15.9693 5.97011C17.3231 5.96242 18.5908 6.53165 19.3461 7.48394"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0201 11.4028H13.0525C12.0494 11.4028 11.2363 12.2159 11.2363 13.219V29.1842C11.2363 30.1872 12.0494 31.0003 13.0525 31.0003H19.0201C20.0231 31.0003 20.8362 30.1872 20.8362 29.1842V13.219C20.8362 12.2159 20.0231 11.4028 19.0201 11.4028Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RemoteIcon;

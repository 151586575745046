import React from "react";
import {
  AdvantagePoshQuartz,
  CompatibilityPoshQuartz,
  ElevatePoshQuartz,
  // InnovationsPoshQuartz,
  SectionPoshQuartz,
  SwitchPoshQuartz,
  WelcomePoshQuartz,
} from "../common";
import {
  SmartControl,
  Warranty,
  // Insights,
  ResourceHub,
  EsteemedClients,
  ProductComparison,
} from "../../common";
import {
  head,
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  swipperList,
  elevateList,
  // innovations,
  switchData,
  masterButton,
  twoWay,
  productComparisonArray,
} from "./Constants";

function index() {
  return (
    <div className="w-full">
      <WelcomePoshQuartz
        img={"/images/quartz/quartzhome.png"}
        alt={"Quartz Home"}
        head={"QUARTZ"}
        text={"Pinnacle of Beauty"}
      />
      <SectionPoshQuartz
        head={head}
        firstCard={firstCard}
        secondCard={secondCard}
        thirdCard={thirdCard}
        fourthCard={fourthCard}
        swipperList={swipperList}
        masterButton={masterButton}
        twoWay={twoWay}
      />
      {/* <InnovationsPoshQuartz innovations={innovations} /> */}
      <AdvantagePoshQuartz />
      <SwitchPoshQuartz switchData={switchData} />

      <Warranty />
      <SmartControl series="quartz" />
      <ProductComparison productComparisonArray={productComparisonArray} />
      <CompatibilityPoshQuartz mode={false} />
      <ResourceHub />
      <EsteemedClients />
      <ElevatePoshQuartz elevateList={elevateList} />
      {/* <Insights /> */}
    </div>
  );
}

export default index;

import React from "react";

function ExceedIcon() {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9297 0.648438C18.9333 0.648438 17.9531 1.74844 17.9531 3.3125C17.9531 4.87656 18.9333 5.97656 19.9297 5.97656C20.926 5.97656 21.9062 4.87656 21.9062 3.3125C21.9062 1.74844 20.926 0.648438 19.9297 0.648438ZM10.9964 0.904102L9.61298 1.5959C10.9099 4.18992 14.0276 6.47801 16.676 7.99326L17.8959 23.8516H19.1562V15H20.7031V23.8516H21.9635L23.1834 7.99326C25.8318 6.47801 28.9494 4.18992 30.2464 1.5959L28.863 0.904102C27.152 3.47363 25.0052 5.12776 22.7327 5.86871C22.1049 6.86 21.1113 7.52344 19.9297 7.52344C18.748 7.52344 17.7546 6.86 17.1267 5.86863C14.8542 5.12784 12.7074 3.47363 10.9964 0.904102ZM12.4531 25.3984V33.6484H0.078125V40.3516H39.7812V37.7734H27.4062V25.3984H12.4531Z"
        fill="#F4F4F4"
      />
    </svg>
  );
}

export default ExceedIcon;

import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { OpenIcon } from "../../assets/svg";

function WarrantyRegModel() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <div>
      <button onClick={handleOpen} className="cursor-pointer">
        <OpenIcon />
      </button>

      <Modal
        open={isOpen}
        onClose={handleClose}
        center
        focusTrapped={false}
        showCloseIcon={false}
        styles={{
          modal: {
            width: "90%",
          },
        }}
      >
        <div className="lg:w-full  bg-[#FFFFFF] rounded-[8px]  max-h-[90vh] h-[90vh]   ">
          {/* Modal Header with Title */}
          <div className="flex justify-between items-center border-b pb-2 mb-4">
            <h2 className="text-xl font-semibold">Warranty Registration</h2>
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-black text-2xl"
            >
              ✖
            </button>
          </div>

          {/* Modal Content */}
          <div className="flex justify-center items-center h-[85vh] ">
            <iframe
              src="https://forms.monday.com/forms/embed/aa7f4b4f18b22a75a9b422e9a7ff45c7?r=use1%22"
              className="w-full h-full border-none shadow-xl"
              title="Monday Form"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default WarrantyRegModel;

import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import ControlRight from "./ControlRight";

function Control() {
  const containerRef = useRef(null);

  const isMobile = window?.innerWidth <= 768;

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 20 });
  };

  const text1Opacity = useSpringTransform(
    scrollYProgress,
    [0.1, 0.2, 0.3, 0.4],
    [0.2, 1, 1, 0]
  );
  const text2Opacity = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7, 0.8, 0.9],
    [0, 1, 1, 1]
  );
  const slideUp = useSpringTransform(scrollYProgress, [0.1, 0.2], [200, 0]);
  const slideUp2 = useSpringTransform(scrollYProgress, [0.6, 0.7], [200, 0]);

  const text1LineHeight = useSpringTransform(
    scrollYProgress,
    [0.1, 0.2],
    ["180%", "140%"]
  );
  const text2LineHeight = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7],
    ["180%", "140%"]
  );

  const xMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [0, -50]);
  const yMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [0, -100]);
  const opacityMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [1, 0]);

  const opacityText = useSpringTransform(
    scrollYProgress,
    [0.7, 0.9, 1],
    [0, 1, 0]
  );

  const backgroundPosition = useSpringTransform(
    scrollYProgress,
    [0.7, 1],
    ["-100% 0", "100% 0"]
  );

  const textScale = useSpringTransform(scrollYProgress, [0.7, 1], [0.6, 1]);
  return (
    <div ref={containerRef} className="relative h-[500vh] bg-black text-white">
      <div className="sticky top-0 w-full h-screen flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 h-[50vh] md:h-screen flex items-center justify-center order-2 md:order-1">
          <motion.div
            className="relative w-full h-full "
            style={{
              x: !isMobile ? xMove : undefined,
              y: !isMobile ? yMove : undefined,
              opacity: opacityMove,
            }}
          >
            <motion.div
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center md:items-start justify-center p-6 md:pl-[100px] w-full gap-[24px] md:gap-[48px]"
              style={{ opacity: text1Opacity }}
            >
              <motion.h2
                className="text-[#CCCCCC] font-medium text-[16px] md:text-[24px] lg:text-[32px] tracking-[0%] align-middle"
                style={{ y: slideUp, lineHeight: text1LineHeight }}
              >
                Stay in control
              </motion.h2>
              <motion.p
                className="font-semibold text-[24px] md:text-[40px] lg:text-[56px] tracking-[0%] align-middle text-center md:text-start"
                style={{ y: slideUp, lineHeight: text1LineHeight }}
              >
                Manage security, connectivity, and accessibility settings
                effortlessly.
              </motion.p>
            </motion.div>

            <motion.div
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center md:items-start justify-center p-6 md:pl-[100px] w-full gap-[24px] md:gap-[48px]"
              style={{ opacity: text2Opacity }}
            >
              <motion.h2
                className="text-[#CCCCCC] font-medium text-[16px] md:text-[24px] lg:text-[32px] leading-[140%] tracking-[0%] align-middle"
                style={{ y: slideUp2, lineHeight: text2LineHeight }}
              >
                Personalize your experience
              </motion.h2>
              <motion.p
                className="font-semibold text-[24px] md:text-[40px] lg:text-[56px] leading-[140%] tracking-[0%] align-middle text-center md:text-start"
                style={{ y: slideUp2, lineHeight: text2LineHeight }}
              >
                Adjust app preferences, themes, and notifications to suit your
                needs.
              </motion.p>
            </motion.div>
          </motion.div>
        </div>

        <div className="w-full md:w-1/2 h-[50vh] md:h-screen flex items-center justify-center order-1 md:order-2">
          <ControlRight scrollYProgress={scrollYProgress} />
        </div>
      </div>
      <div className="absolute bottom-0 h-[200vh] flex justify-center items-center w-full">
        <motion.div className="sticky top-1/2 -translate-y-1/2 text-center  flex justify-center items-center overflow-hidden">
          <motion.h1
            className="text-[40px] md:text-[48px] lg:text-[62px] font-semibold leading-[140%] text-center font-[SF Pro Display]"
            style={{
              scale: textScale,
              opacity: opacityText,
              background: "linear-gradient(90deg, black, white, black)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "40%",
              backgroundPosition,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            All Your Smart Devices, One App
          </motion.h1>
        </motion.div>
      </div>
    </div>
  );
}

export default Control;

import React from "react";

function TvIconWhite() {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.707031H16.8114V2.38817H15.9709V14.1562H10.4349L13.7971 17.5185L12.6086 18.707L8.40572 14.5042L4.20286 18.707L3.01429 17.5185L6.37658 14.1562H0.840572V2.38817H0V0.707031ZM2.52171 2.38817V12.475H14.2897V2.38817H2.52171ZM6.72457 4.4896L10.6475 7.4316L6.72457 10.3736V4.4896Z"
        fill="white"
      />
    </svg>
  );
}

export default TvIconWhite;

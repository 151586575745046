import React from "react";
import ContactForm from "./ContactForm";
import { CallIcon, EmailIcon } from "../../assets/svg";

const LogoWithText = ({ icon, text }) => {
  return (
    <div className="flex items-center pb-[10px] space-x-3">
      {icon}
      <p className="font-inter font-medium text-left text-[16px] leading-[19.36px] lg:text-[20px] lg:leading-[24.2px] tracking-normal text-[#F4F4F4] w-[400px]">
        {text}
      </p>
    </div>
  );
};

function ConatctMain() {
  return (
    <div
      id="ContactUs"
      className="contact-section grid grid-cols-1 lg:grid-cols-2 gap-[64px] lg:py-[100px] lg:px-[108px]"
    >
      <div
        className=" bg-cover bg-center bg-no-repeat lg:rounded-[12px] py-[20px]"
        style={{ backgroundImage: `url('/images/contactusbackground.png')` }}
      >
        <div className="flex flex-col py-[20px] px-[20px] gap-y-[20px] lg:gap-y-[40px] w-full h-full">
          <div className="mb-[6px] lg:mb-[32px] gap-[2px] text-left">
            <p className="font-inter font-semibold text-[40px] leading-[48px] lg:text-[64px] lg:leading-[76.8px] tracking-[-0.41%] text-[#F4F4F4]">
              Contact Us
            </p>
            <p className="font-inter font-medium text-[16px] leading-[19.36px] lg:text-[20px] lg:leading-[24.2px] tracking-normal text-[#CCCCCC]">
              Book 100% Free Demo at Your Place.
            </p>
          </div>

          <div className="lg:hidden block">
            <ContactForm />
          </div>

          <div className="grid grid-cols-1 pt-[20px] ">
            <LogoWithText icon={<CallIcon />} text={"+91-74050 29863"} />
            <LogoWithText icon={<EmailIcon />} text={"info@whitelion.in"} />
          </div>

          <div className=" mt-auto">
            <img
              src={"/images/whiteliontext.png"}
              alt={"Warranty"}
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="lg:block hidden">
        <ContactForm />
      </div>
    </div>
  );
}

export default ConatctMain;

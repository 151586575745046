import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { DownArrow, RigthArrow, OpenIcon } from "../../assets/svg";

const faqs = [
  {
    question:
      "Are Whitelion smart panels compatible with all types of lights and appliances?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "Can I control Whitelion touch panel via a mobile app?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "Do these touch panel support voice assistants?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "What technology is used in Whitelion smart panels?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
];

const FaqCard = ({ question, answer, openIndex, index, toggleFAQ }) => {
  return (
    <div className="flex justify-between p-[14px] lg:px-[32px] lg:py-[10px] rounded-[8px] bg-[#FFFFFF]">
      <div className="flex flex-col justify-center gap-[10px]">
        <p className="font-inter font-medium  text-left text-[#1E1E1E] text-[13px] leading-[20.8px] lg:text-[24px] lg:leading-[33.6px] tracking-[0] ">
          {question}
        </p>
        {openIndex === index && (
          <p className="font-inter font-normal text-[#808080] text-left text-[12px] leading-[22.4px] lg:text-[20px] lg:leading-[28px] tracking-[-0.01em]">
            {answer}
          </p>
        )}
      </div>

      {openIndex === index ? (
        <div className=" ">
          <div className="hidden md:block " onClick={() => toggleFAQ(index)}>
            <DownArrow size={"desktop"} />
          </div>
          <div className="block md:hidden" onClick={() => toggleFAQ(index)}>
            <DownArrow size={"mobile"} />
          </div>
        </div>
      ) : (
        <div className=" ">
          <div className="hidden md:block " onClick={() => toggleFAQ(index)}>
            <RigthArrow size={"desktop"} />
          </div>
          <div className="block md:hidden" onClick={() => toggleFAQ(index)}>
            <RigthArrow size={"mobile"} />
          </div>
        </div>
      )}
    </div>
  );
};

function FaqModel() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div>
      <button onClick={handleOpen} className="cursor-pointer">
        <OpenIcon />
      </button>

      <Modal
        open={isOpen}
        onClose={handleClose}
        center
        focusTrapped={false}
        showCloseIcon={false}
        styles={{
          modal: {
            // width: '100%',
          },
        }}
      >
        <div className="lg:w-full bg-[#FFFFFF] rounded-[8px]  max-h-[90vh] overflow-y-auto">
          {/* Modal Header with Title */}
          <div className="flex justify-between items-center border-b pb-2 mb-4">
            <h2 className="text-xl font-semibold">
              Frequently Asked Questions (FAQs)
            </h2>
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-black text-2xl"
            >
              ✖
            </button>
          </div>

          {/* Modal Content */}
          <div className="frequently-section bg-custom-bg-white">
            <div className="grid grid-cols-1 px-[12px] py-[12px] lg:px-[24px] lg:py-[24px] gap-[16px] lg:gap-[36px]">
              <div className="grid grid-cols-1 ">
                <div className="grid grid-cols-1 gap-[10px] ">
                  {faqs.map((faq, index) => (
                    <FaqCard
                      key={index}
                      index={index}
                      question={faq.question}
                      answer={faq.answer}
                      openIndex={openIndex}
                      toggleFAQ={toggleFAQ}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FaqModel;

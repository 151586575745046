import React from "react";

function AppleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8389 12.6925C17.8496 11.8578 18.072 11.0394 18.4851 10.3135C18.8983 9.58753 19.4889 8.97759 20.202 8.54042C19.749 7.89526 19.1514 7.36432 18.4566 6.98977C17.7619 6.61521 16.9891 6.40737 16.1997 6.38274C14.5157 6.20648 12.8832 7.38757 12.0251 7.38757C11.1504 7.38757 9.82916 6.40024 8.40645 6.42943C7.4862 6.45908 6.58934 6.72593 5.80326 7.20399C5.01718 7.68205 4.36869 8.35501 3.92097 9.1573C1.98154 12.5057 3.42818 17.4268 5.28599 20.1334C6.21549 21.4587 7.30182 22.9392 8.72324 22.8867C10.1142 22.8291 10.6337 22.0022 12.3127 22.0022C13.9761 22.0022 14.4635 22.8867 15.9138 22.8533C17.4063 22.8291 18.3467 21.5221 19.2436 20.1842C19.9115 19.2398 20.4254 18.1961 20.7663 17.0916C19.8992 16.7259 19.1592 16.1137 18.6386 15.3314C18.118 14.5492 17.8399 13.6313 17.8389 12.6925Z"
        fill="#1E1E1E"
      />
      <path
        d="M15.0995 4.60262C15.9133 3.62841 16.3143 2.37623 16.2172 1.112C14.9739 1.24222 13.8254 1.83478 13.0006 2.77161C12.5973 3.22928 12.2885 3.76171 12.0917 4.33848C11.8949 4.91525 11.814 5.52504 11.8537 6.133C12.4756 6.13939 13.0908 6.00497 13.653 5.73988C14.2153 5.4748 14.7099 5.08595 15.0995 4.60262Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default AppleIcon;

import React, { useRef, useEffect, useState } from "react";
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
  useInView,
  useSpring,
} from "framer-motion";

function SwitchPoshQuartz({ switchData }) {
  const containerRef = useRef(null);
  const dragX = useMotionValue(0);
  const isInView = useInView(containerRef, { once: true }); // Trigger only once when visible
  const [dragLimits, setDragLimits] = useState({ left: 0, right: 0 });

  // Update constraints when the container resizes
  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setDragLimits({
        left: width * 0.07, // Prevent moving fully left (10% of width)
        right: width * 0.93, // Prevent moving fully right (90% of width)
      });
    }
  }, []);

  // Smooth delayed movement for the image transition
  const smoothDragX = useSpring(dragX, {
    stiffness: 100, // Controls how fast the movement follows
    damping: 15, // Controls smoothness
  });

  const clipPath = useTransform(smoothDragX, (x) => {
    const width = containerRef.current?.offsetWidth || 1; // Prevent division by zero
    return `inset(0 ${100 - (x / width) * 100}% 0 0)`;
  });

  // Auto animate when the section becomes visible
  useEffect(() => {
    if (isInView && containerRef.current) {
      const width = containerRef.current.offsetWidth;
      animate(dragX, width * 0.5, { duration: 1.5, ease: "easeInOut" }); // Move to 50% initially
    }
  }, [isInView, dragX]);

  return (
    <div
      ref={containerRef}
      className="relative w-full h-[470px] lg:h-[1008px] overflow-hidden"
    >
      {/* After Image (Full Background) */}
      <img
        src={switchData?.img?.after}
        alt="After"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div
        className={`absolute lg:w-[800px] inset-0 top-[40px] lg:top-[60px] ${
          switchData?.isPosh ? "lg:left-1/2" : "lg:left-1/3"
        }`}
      >
        <p
          className=" font-semibold text-[32px] leading-[32px] lg:text-[64px] lg:leading-[64px] tracking-[0%] text-center lg:text-right "
          style={{ color: switchData?.isPosh ? "#000000" : "#ffffff" }}
        >
          {switchData?.head}
        </p>
        <p
          className="font-normal text-[12px] leading-[16.8px] lg:text-[20px] lg:leading-[28px] tracking-[1%] text-center lg:text-right pt-[10px] lg:pt-[20px]  opacity-80"
          style={{ color: switchData?.isPosh ? "#1E1E1ECC" : "#ffffff" }}
        >
          {switchData.text}
        </p>
      </div>

      {/* Before Image (Clipped to Divider) */}
      <motion.div className="absolute inset-0" style={{ clipPath }}>
        <img
          src={switchData?.img?.before}
          alt="Before"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div
          className={`absolute lg:w-[800px] inset-0 top-[40px] lg:top-[60px] ${
            switchData?.isPosh ? "lg:left-1/2" : "lg:left-1/3"
          }`}
        >
          <p
            className=" font-semibold text-[32px] leading-[32px] lg:text-[64px] lg:leading-[64px] tracking-[0%] text-center lg:text-right "
            style={{ color: switchData?.isPosh ? "#000000" : "#ffffff" }}
          >
            {switchData?.head}
          </p>
          <p
            className="font-normal text-[12px] leading-[16.8px] lg:text-[20px] lg:leading-[28px] tracking-[1%] text-center lg:text-right pt-[10px] lg:pt-[20px]  opacity-80"
            style={{ color: switchData?.isPosh ? "#1E1E1ECC" : "#ffffff" }}
          >
            {switchData.text}
          </p>
        </div>
      </motion.div>

      {/* Draggable Divider */}
      <motion.div
        className="absolute top-0 bottom-0 w-1.5 bg-white/40 backdrop-blur-3xl shadow-lg cursor-pointer"
        drag="x"
        dragConstraints={{ left: dragLimits.left, right: dragLimits.right }} // Set new drag constraints
        dragElastic={0.1}
        style={{ x: dragX }}
      >
        {/* Handle with Glassmorphism Effect */}
        <div className="absolute -left-[29px] top-1/2 -translate-y-1/2 w-16 h-16 bg-white/40 backdrop-blur-md rounded-full border border-white/100 shadow-lg flex items-center justify-center">
          <div className="w-1.5 h-8 bg-gray-700 rounded-full"></div>
        </div>
      </motion.div>
    </div>
  );
}

export default SwitchPoshQuartz;

import React from 'react'

function Hand() {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0825 18.405L13.096 18.4175L15.5014 20.5748C15.7217 20.7724 16.0376 20.822 16.3079 20.7014C16.5781 20.5807 16.7521 20.3124 16.7521 20.0165V9.00049C16.7521 8.66897 16.8838 8.35102 17.1183 8.1166C17.3527 7.88218 17.6706 7.75049 18.0021 7.75049C18.3337 7.75049 18.6516 7.88218 18.886 8.1166C19.1205 8.35102 19.2521 8.66897 19.2521 9.00049V14.9765C19.2521 15.3405 19.5136 15.652 19.8721 15.7151L22.2574 16.1351L22.2574 16.1352L22.2656 16.1365C23.2595 16.3003 23.9658 16.4173 24.5116 16.5479C25.0515 16.6771 25.3739 16.8067 25.6153 16.9662L25.6158 16.9665C26.595 17.6125 27.2521 18.5307 27.2521 19.8832C27.2521 20.8473 27.0592 21.4833 26.4908 23.357C26.4604 23.4572 26.4289 23.5609 26.3964 23.6684L26.3962 23.6688C25.9843 25.0315 25.8148 25.5715 25.5552 25.9874L25.5552 25.9874L25.5512 25.9939C25.1335 26.6786 24.486 27.1927 23.7244 27.4443C23.311 27.5753 22.8209 27.5838 21.5115 27.5838H20.4408C18.6976 27.5838 18.0532 27.5683 17.4886 27.3124C17.38 27.263 17.2741 27.2075 17.1716 27.1464C16.6219 26.8167 16.1898 26.2558 15.0937 24.7699L15.0937 24.7699L11.6723 20.1322C11.508 19.9031 11.4191 19.6285 11.418 19.3465C11.4169 19.0647 11.5036 18.7896 11.666 18.5593C12.0358 18.0538 12.6602 18.0071 13.0825 18.405Z" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.0006 12.3337C28.0006 7.30566 28.0006 4.79099 26.438 3.22966C24.8753 1.66833 22.362 1.66699 17.334 1.66699H12.0007C6.97265 1.66699 4.45798 1.66699 2.89665 3.22966C1.33532 4.79233 1.33398 7.30566 1.33398 12.3337V15.0003C1.33398 17.4857 1.33398 18.7283 1.73932 19.707C2.00734 20.3541 2.40023 20.9422 2.89552 21.4375C3.39082 21.9328 3.97883 22.3256 4.62598 22.5937C5.41265 22.9203 6.36865 22.9843 8.00065 22.9977" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Hand
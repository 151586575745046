import React from 'react'

function BuiltSocket() {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.998 12.6665V7.33317M19.6647 12.6665V7.33317M16.3314 23.3332H20.998V22.6772C20.9979 19.6496 22.0282 16.7121 23.9194 14.3478L24.998 12.9998V12.6665H7.66471V12.9998L8.74338 14.3465C10.6352 16.7115 11.6655 19.65 11.6647 22.6785V23.3345L16.3314 23.3332ZM16.3314 23.3332V31.3332C24.7994 31.3332 31.6647 24.4678 31.6647 15.9998C31.6647 7.53184 24.7994 0.666504 16.3314 0.666504C7.86338 0.666504 0.998047 7.53184 0.998047 15.9998C0.998047 23.3238 6.13138 29.4478 12.998 30.9705" stroke="white" strokeWidth="1.33333" />
        </svg>

    )
}

export default BuiltSocket
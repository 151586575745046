import React from "react";

function CallIconBlack() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62 11.497C8.06 14.327 10.38 16.647 13.21 18.087L15.41 15.887C15.69 15.607 16.08 15.527 16.43 15.637C17.55 16.007 18.75 16.207 20 16.207C20.2652 16.207 20.5196 16.3124 20.7071 16.4999C20.8946 16.6875 21 16.9418 21 17.207V20.707C21 20.9722 20.8946 21.2266 20.7071 21.4141C20.5196 21.6017 20.2652 21.707 20 21.707C15.4913 21.707 11.1673 19.916 7.97918 16.7278C4.79107 13.5397 3 9.21571 3 4.70703C3 4.44181 3.10536 4.18746 3.29289 3.99992C3.48043 3.81239 3.73478 3.70703 4 3.70703H7.5C7.76522 3.70703 8.01957 3.81239 8.20711 3.99992C8.39464 4.18746 8.5 4.44181 8.5 4.70703C8.5 5.95703 8.7 7.15703 9.07 8.27703C9.18 8.62703 9.1 9.01703 8.82 9.29703L6.62 11.497Z"
        fill="black"
      />
    </svg>
  );
}

export default CallIconBlack;

import React from "react";

function EveningIcon({ active }) {
  return active ? (
    <svg
      width="40"
      height="35"
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2049 19.7797C28.2049 22.1708 27.2353 24.3374 25.6677 25.905C24.1001 27.4717 21.9345 28.4412 19.5444 28.4412C17.1552 28.4412 14.9915 27.4717 13.4229 25.9031C11.8534 24.3374 10.8848 22.1708 10.8848 19.7797C10.8848 17.3925 11.8534 15.2279 13.421 13.6602C14.9886 12.0936 17.1562 11.1201 19.5444 11.1201C21.9335 11.1201 24.0991 12.0907 25.6658 13.6573C27.2343 15.2259 28.2049 17.3905 28.2049 19.7797ZM24.6658 24.9031C25.9765 23.5914 26.7883 21.7806 26.7883 19.7797C26.7883 17.7788 25.9775 15.9671 24.6697 14.6583C23.358 13.3485 21.5463 12.5377 19.5444 12.5377C17.5424 12.5377 15.7317 13.3465 14.4229 14.6563C13.1112 15.9671 12.3014 17.7788 12.3014 19.7797C12.3014 21.7797 13.1122 23.5923 14.4229 24.9021C15.7317 26.2138 17.5434 27.0246 19.5444 27.0246C21.5443 27.0246 23.355 26.2129 24.6658 24.9031Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M1.50375 28.444C1.1116 28.444 0.794922 28.1273 0.794922 27.7371C0.794922 27.345 1.1116 27.0273 1.50375 27.0273H38.0861C38.4773 27.0273 38.7949 27.345 38.7949 27.7371C38.7949 28.1273 38.4773 28.444 38.0861 28.444H1.50375Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M8.48809 34.2604C8.09791 34.2604 7.78125 33.9427 7.78125 33.5526C7.78125 33.1614 8.09791 32.8438 8.48809 32.8438H30.6248C31.0169 32.8438 31.3336 33.1614 31.3336 33.5526C31.3336 33.9427 31.0169 34.2604 30.6248 34.2604H8.48809Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M20.2477 7.83693C20.2477 8.2281 19.93 8.54475 19.5389 8.54475C19.1467 8.54475 18.8301 8.2281 18.8301 7.83693V1.87141C18.8301 1.48024 19.1467 1.16357 19.5389 1.16357C19.93 1.16357 20.2477 1.48024 20.2477 1.87141V7.83693Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M31.4891 20.4884C31.0969 20.4884 30.7793 20.1718 30.7793 19.7796C30.7793 19.3884 31.0969 19.0708 31.4891 19.0708H37.4517C37.8428 19.0708 38.1605 19.3884 38.1605 19.7796C38.1605 20.1718 37.8428 20.4884 37.4517 20.4884H31.4891Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M1.63751 20.4884C1.24635 20.4884 0.927734 20.1718 0.927734 19.7796C0.927734 19.3884 1.24635 19.0708 1.63751 19.0708H7.60207C7.99323 19.0708 8.31087 19.3884 8.31087 19.7796C8.31087 20.1718 7.99323 20.4884 7.60207 20.4884H1.63751Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M11.6023 10.8358C11.8778 11.1113 11.8778 11.5603 11.6023 11.8367C11.3249 12.1132 10.8759 12.1132 10.6004 11.8367L6.38386 7.62017C6.10642 7.34371 6.10642 6.89372 6.38386 6.61824C6.65935 6.3408 7.10835 6.3408 7.38579 6.61824L11.6023 10.8358Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
      <path
        d="M28.1896 11.6339C27.8991 11.9245 27.4267 11.9235 27.1362 11.6308C26.8468 11.3403 26.8478 10.8678 27.1393 10.5772L31.5874 6.13006C31.8789 5.84055 32.3513 5.84159 32.6407 6.13213C32.9312 6.42475 32.9291 6.89726 32.6376 7.18781L28.1896 11.6339Z"
        fill="#36E061"
        stroke="#36E061"
        strokeWidth="1.1875"
      />
    </svg>
  ) : (
    <svg
      width="40"
      height="35"
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2049 19.7016C28.2049 22.0927 27.2353 24.2593 25.6677 25.8269C24.1001 27.3935 21.9345 28.3631 19.5444 28.3631C17.1552 28.3631 14.9915 27.3935 13.4229 25.8249C11.8534 24.2593 10.8848 22.0927 10.8848 19.7016C10.8848 17.3144 11.8534 15.1497 13.421 13.5821C14.9886 12.0155 17.1562 11.042 19.5444 11.042C21.9335 11.042 24.0991 12.0126 25.6658 13.5792C27.2343 15.1478 28.2049 17.3124 28.2049 19.7016ZM24.6658 24.825C25.9765 23.5132 26.7883 21.7025 26.7883 19.7016C26.7883 17.7006 25.9775 15.8889 24.6697 14.5801C23.358 13.2704 21.5463 12.4596 19.5444 12.4596C17.5424 12.4596 15.7317 13.2684 14.4229 14.5782C13.1112 15.8889 12.3014 17.7006 12.3014 19.7016C12.3014 21.7015 13.1122 23.5142 14.4229 24.824C15.7317 26.1357 17.5434 26.9465 19.5444 26.9465C21.5443 26.9465 23.355 26.1347 24.6658 24.825Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M1.50375 28.3659C1.1116 28.3659 0.794922 28.0492 0.794922 27.659C0.794922 27.2669 1.1116 26.9492 1.50375 26.9492H38.0861C38.4773 26.9492 38.7949 27.2669 38.7949 27.659C38.7949 28.0492 38.4773 28.3659 38.0861 28.3659H1.50375Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M8.48809 34.1822C8.09791 34.1822 7.78125 33.8646 7.78125 33.4744C7.78125 33.0833 8.09791 32.7656 8.48809 32.7656H30.6248C31.0169 32.7656 31.3336 33.0833 31.3336 33.4744C31.3336 33.8646 31.0169 34.1822 30.6248 34.1822H8.48809Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M20.2477 7.75881C20.2477 8.14997 19.93 8.46662 19.5389 8.46662C19.1467 8.46662 18.8301 8.14997 18.8301 7.75881V1.79328C18.8301 1.40212 19.1467 1.08545 19.5389 1.08545C19.93 1.08545 20.2477 1.40212 20.2477 1.79328V7.75881Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M31.4891 20.4103C31.0969 20.4103 30.7793 20.0936 30.7793 19.7015C30.7793 19.3103 31.0969 18.9927 31.4891 18.9927H37.4517C37.8428 18.9927 38.1605 19.3103 38.1605 19.7015C38.1605 20.0936 37.8428 20.4103 37.4517 20.4103H31.4891Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M1.63751 20.4103C1.24635 20.4103 0.927734 20.0936 0.927734 19.7015C0.927734 19.3103 1.24635 18.9927 1.63751 18.9927H7.60207C7.99323 18.9927 8.31087 19.3103 8.31087 19.7015C8.31087 20.0936 7.99323 20.4103 7.60207 20.4103H1.63751Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M11.6023 10.7577C11.8778 11.0331 11.8778 11.4821 11.6023 11.7586C11.3249 12.0351 10.8759 12.0351 10.6004 11.7586L6.38386 7.54204C6.10642 7.26558 6.10642 6.8156 6.38386 6.54011C6.65935 6.26267 7.10835 6.26267 7.38579 6.54011L11.6023 10.7577Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
      <path
        d="M28.1896 11.5558C27.8991 11.8464 27.4267 11.8453 27.1362 11.5527C26.8468 11.2622 26.8478 10.7896 27.1393 10.4991L31.5874 6.05194C31.8789 5.76242 32.3513 5.76346 32.6407 6.05401C32.9312 6.34663 32.9291 6.81914 32.6376 7.10969L28.1896 11.5558Z"
        fill="#FEFEFE"
        stroke="white"
        strokeWidth="1.1875"
      />
    </svg>
  );
}

export default EveningIcon;

import React, { useRef } from "react";
import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { NextIcon, PrevIcon } from "../../../assets/svg";

const Card = ({ item }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className="h-[500px] w-[380px] lg:h-[604px] lg:w-[450px] text-black bg-cover bg-center bg-no-repeat rounded-[24px]"
        style={{ backgroundImage: `url(${item?.bgImage})` }}
      >
        <motion.div
          className="p-[24px] text-white flex flex-col gap-[4px]"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          <h1 className="font-medium text-[12px] leading-[100%] tracking-[0px] lg:text-[16px] lg:leading-[38px] lg:tracking-[-0.41px] align-middle text-white opacity-80">
            {item?.label}
          </h1>

          {item?.text?.map((line, index) => (
            <div className="flex" key={index}>
              <h1 className="font-semibold text-[16px] leading-[130%] tracking-[-0.35px] lg:text-[22px] lg:leading-[100%] lg:tracking-[-0.41px]  text-left">
                {line}
              </h1>

              {index === item?.text?.length - 1 && (
                <img
                  src={item?.img}
                  alt="Decoration"
                  className="w-[20px] h-[20px] lg:w-[22px] lg:h-[22px]"
                />
              )}
              <br />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};
function AdvantageTouchGlass({ swiperData }) {
  const swiperRef = useRef(null);

  return (
    <div className="border p-[24px] lg:py-[105px] lg:px-[165px] bg-custom-bg-white">
      <div className="flex flex-col gap-[24px] lg:gap-[48px]">
        <h1 className="font-semibold text-[40px] lg:text-[64px] leading-[130%] tracking-[0%] align-middle text-custom-black">
          Safe, Secure, and Reliable
        </h1>

        <Swiper
          slidesPerView={1} // Default for mobile
          spaceBetween={16}
          loop={true}
          pagination={{ clickable: true }}
          navigation={false} // Disable default navigation
          modules={[Navigation]}
          allowTouchMove={false} // Prevent touch-based slide changing
          simulateTouch={false} // Disable mouse drag to prevent unwanted slide changes
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Store swiper instance
          direction="horizontal"
          style={{ width: "100%", touchAction: "auto" }}
          className="mySwiper"
          breakpoints={{
            1024: {
              slidesPerView: 3, // Show 3 slides on large screens (desktop)
              spaceBetween: 48,
            },
            768: {
              slidesPerView: 2, // Show 3 slides on large screens (desktop)
              spaceBetween: 48,
            },
          }}
        >
          {swiperData?.map((item, index) => {
            return (
              <SwiperSlide className="" key={index}>
                <Card item={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* Custom Navigation Buttons */}

        <div className=" grid grid-cols-1 pt-[30px]">
          <div className="flex justify-center items-center gap-4 ">
            <button
              className="custom-prev h-[36px] w-[36px] rounded-full bg-[#FFFFFF] cursor-pointer"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <PrevIcon />
            </button>
            <button
              className="custom-next h-[36px] w-[36px] rounded-full bg-[#FFFFFF] cursor-pointer"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <NextIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvantageTouchGlass;

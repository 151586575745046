import {
  BagIconWhite,
  CallIconWhite,
  CartIconWhite,
  ChatIconWhite,
  HandShakeIconWhite,
  MoneyIconWhite,
  TrendIconWhite,
  TvIconWhite,
  VideoIconWhite,
} from "../../../assets/svg";

export const head = {
  label: "Smart Design, Stunning Aesthetics",
  text: `The Glass Smart touch panel are a perfect marriage of futuristic design and advanced technology. The toughened glass surface is not only visually stunning but also ensures that these touch panel remain as pristine as the day they were installed.`,
};
export const firstCard = {
  firstLabel: "Frameless Glass Design",
  secondLabel: "Sleek and Modern.",
  icon: "/images/quartz/multistar.png",
  img: "/images/quartz/quartzsection.png",
};
export const secondCard = {
  label: ["Durable", "and", "Resilient,", "Built to", " Withstand"],
  img: "/images/quartz/shield.png",
};
export const thirdCard = {
  video: "/images/quartz/splash_proof_quartz.mp4",
  text: ["The touch panel", "that really", "resists water", "and ", "daily wear"],
  img: "/images/posh/droplet.png",
};
export const fourthCard = {
  video: "/images/quartz/fast_installation_quartz.mp4",
  label: "Hassle-Free Installation",
  imgEnd: "/images/quartz/lightbulb.png",
  text: "Get started effortlessly—quick, simple, and no technical expertise required.",
};
export const masterButton = {
  label: "Master button",
  text: "It can be tedious to turn off all the touch panel when you are moving out. One touch of the master button can turn off all the touch panel at once. It's hassle free and convenient.",
  video: "/images/quartz/master_button_quartz.mp4",
};
export const twoWay = {
  label: "Two Way",
  text: "Our smart touch panel enable two-way usage. You can easily turn them on at one end and turn them off at the other - or vice versa.",
  video: "/images/quartz/two_way_quartz.mp4",
};
export const swipperList = {
  head: "Complete Control at Your Fingertips",
  list: [
    {
      name: "LIGHTS",
      text: "Effortlessly control your home lighting to create the perfect ambiance.",
      bg: "#000000",
      textColor: "#ffffff",
      img: "/images/posh/light.png",
    },
    {
      name: "FAN",
      text: "Adjust your fan settings for optimal comfort and energy efficiency.",
      bg: "#FCFEFE",
      textColor: "#000000",
      img: "/images/posh/fan.png",
    },
    {
      name: "CURTAINS",
      text: "Manage your TV with ease, from changing channels to adjusting the volume.",
      bg: "#E0E0E2",
      textColor: "#000000",
      img: "/images/posh/curtains.png",
    },
    {
      name: "TELEVISION",
      text: "Manage your TV with ease, from changing channels to adjusting the volume.",
      bg: "#E0E0E2",
      textColor: "#000000",
      img: "/images/posh/television.png",
    },
  ],
};

export const elevateList = {
  head: "Ready to Elevate Your Home?",
  text: "Simple steps to purchase the Posh Series.",
  card: [
    {
      headIcon: ["08", "09", "10", "11", "12"],
      cardIcon: <CallIconWhite />,
      steps: {
        head: "Step 1: Schedule a Call or Fill Form",
        text: "Let’s get started by scheduling a consultation. Either book a call or fill out our quick form, and one of our experts will reach out to guide you through the process.",
      },
    },
    {
      headIcon: [<VideoIconWhite />, <HandShakeIconWhite />, <ChatIconWhite />],
      cardIcon: <TvIconWhite />,
      steps: {
        head: "Step 2: Personalized Demo",
        text: "Experience the Posh Series in action. Our team will provide a personalized demo, answering all your questions and helping you find the perfect fit for your smart home.",
      },
    },
    {
      headIcon: [<MoneyIconWhite />, <BagIconWhite />, <TrendIconWhite />],
      cardIcon: <CartIconWhite />,
      steps: {
        head: "Step 3: Purchase & Upgrade",
        text: "Once you're satisfied, proceed with the purchase and get ready to elevate your home with elegance and innovation.",
      },
    },
  ],
};
export const innovations = {
  head: "Advanced Technology for Seamless Living",
  text: "Cutting-edge technology that enhances your home experience",
  img: "/images/posh/poshhome.png",
};

export const switchData = {
  head: "Sleek colors that blend with comfort",
  text: "Designed to match cozy corners and tech-savvy homes alike.",
  img: {
    after: "/images/quartz/whitequartz.png",
    before: "/images/quartz/blackquartz.png",
  },
  isPosh: false,
};

export const productComparisonArray = {
  label: "Crafted for Every Style",
  text: "Choose between two uniquely designed series, each crafted for distinct tastes.",
  productA: "Quartz Series",
  productB: "Posh Series",
  imgProductA: "/images/quartz/quartzproduct.png",
  imgProductAClass: "w-full",
  imgProductB: "/images/posh/poshproduct.png",
  imgProductBClass: "w-full",
  productList: [
    {
      category: "Surface Aesthetic",
      productA: {
        title: "Frameless Glass",
        description:
          "Sleek and modern, for those who appreciate elegance in simplicity.",
      },
      productB: {
        title: "Structured Frame",
        description:
          "Acrylic with a structured frame—classic and dependable, offering a refined touch.",
      },
    },
    {
      category: "Visual and Tactile Experience",
      productA: {
        title: "Luxurious Look",
        description:
          "Designed to blend seamlessly into any sophisticated setting.",
      },
      productB: {
        title: "Timeless Feel",
        description:
          "Acrylic with a structured frame—classic and dependable, offering a refined touch.",
      },
    },
    {
      category: "Installation Methodology",
      productA: {
        title: "Concealed Box",
        description: "Clean, effortless, and designed for a flawless finish.",
      },
      productB: {
        title: "Modular Fit",
        description: "Versatile and easy to integrate into existing setups.",
      },
    },
    {
      category: "Power Access Solution",
      productA: {
        title: "In-built Socket",
        description:
          "Streamlined for a clutter-free experience with everything in one place.",
      },
      productB: {
        title: "External Socket",
        description: "Offering flexibility and convenience when needed.",
      },
    },
  ],
};

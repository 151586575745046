import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { ContactModalProvider, NavProvider } from "./config/contexts";

const App = () => {
  return (
    <Router>
      <ContactModalProvider>
        <NavProvider>
          <AppRoutes />
        </NavProvider>
      </ContactModalProvider>
    </Router>
  );
};

export default App;

import React from "react";

function career() {
  return (
    <div className="flex justify-center items-center w-full h-screen ">
      <iframe
        src="https://forms.monday.com/forms/embed/aa7f4b4f18b22a75a9b422e9a7ff45c7?r=use1%22"
        className="w-full h-full border-none shadow-xl"
        title="Monday Form"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default career;

import React from "react";

function SeamlessControl() {
  return (
    <svg
      width="44"
      height="48"
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2106_2937)">
        <path
          d="M24.8804 13.7142C23.2644 13.7161 21.7152 14.3588 20.5725 15.5015C19.4298 16.6442 18.787 18.1934 18.7852 19.8094H20.8169C20.8169 18.7317 21.245 17.6981 22.0071 16.9361C22.7691 16.174 23.8027 15.7459 24.8804 15.7459C25.9581 15.7459 26.9917 16.174 27.7537 16.9361C28.5158 17.6981 28.9439 18.7317 28.9439 19.8094H30.9756C30.9738 18.1934 30.331 16.6442 29.1883 15.5015C28.0456 14.3588 26.4964 13.7161 24.8804 13.7142Z"
          fill="#1E1E1E"
        />
        <path
          d="M33.0073 42.1586H28.4968C27.4776 42.1583 26.4957 41.7751 25.7458 41.0848L16.3998 32.4844C16.1816 32.285 16.0095 32.0403 15.8955 31.7675C15.7815 31.4947 15.7284 31.2003 15.7398 30.9049C15.7512 30.6095 15.8269 30.3201 15.9616 30.0569C16.0963 29.7937 16.2867 29.563 16.5197 29.381C16.9188 29.0886 17.408 28.9459 17.9017 28.9781C18.3954 29.0102 18.862 29.215 19.2199 29.5567L22.8486 32.8705V19.8094C22.8486 19.2705 23.0626 18.7538 23.4437 18.3727C23.8247 17.9917 24.3415 17.7776 24.8803 17.7776C25.4192 17.7776 25.9359 17.9917 26.317 18.3727C26.698 18.7538 26.9121 19.2705 26.9121 19.8094V26.9205C26.9121 26.3817 27.1261 25.8649 27.5071 25.4838C27.8882 25.1028 28.405 24.8888 28.9438 24.8888C29.4827 24.8888 29.9994 25.1028 30.3805 25.4838C30.7615 25.8649 30.9756 26.3817 30.9756 26.9205V27.9364C30.9756 27.3975 31.1896 26.8807 31.5706 26.4997C31.9517 26.1187 32.4684 25.9046 33.0073 25.9046C33.5462 25.9046 34.0629 26.1187 34.444 26.4997C34.825 26.8807 35.039 27.3975 35.039 27.9364V28.9523C35.039 28.4134 35.2531 27.8966 35.6341 27.5156C36.0152 27.1346 36.5319 26.9205 37.0708 26.9205C37.6096 26.9205 38.1264 27.1346 38.5075 27.5156C38.8885 27.8966 39.1025 28.4134 39.1025 28.9523V36.0634C39.1025 37.6799 38.4604 39.2303 37.3173 40.3734C36.1742 41.5164 34.6239 42.1586 33.0073 42.1586Z"
          fill="#1E1E1E"
        />
        <path
          d="M39.6102 21.3331C39.6102 14.6291 39.6102 11.2762 37.5267 9.1944C35.4431 7.11262 32.092 7.11084 25.388 7.11084H18.2769C11.5729 7.11084 8.22002 7.11084 6.13824 9.1944C4.05647 11.278 4.05469 14.6291 4.05469 21.3331V24.8886C4.05469 28.2024 4.05469 29.8593 4.59513 31.1642C4.9525 32.027 5.47634 32.8111 6.13674 33.4715C6.79714 34.1319 7.58116 34.6557 8.44402 35.0131C9.49291 35.4486 10.7676 35.5339 12.9436 35.5517"
          stroke="#1E1E1E"
          strokeWidth="2.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2106_2937">
          <rect
            width="42.6667"
            height="48"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SeamlessControl;

export const head = {
  label: "The AirSensor",
  text: "Smart Sensors. Precise Control.",
  button: "Explore More",
  imgWeb: "/images/airsensor/airsensorhome.png",
};
export const TimelessArray = {
  label: "Timeless Design, Enduring Beauty",
  text: "Experience the AirSensor’s fusion of smart technology and stunning design. Transform your home with precision—Contact Us for a demo and discover flawless living today.",
  firstCard: { img: "/images/airsensor/cardone.png" },
  secondCard: {
    label: "Wide Radar Field",
    text: "The AirSensor’s 360° detection delivers seamless, flawless precision, keeping your home smart and responsive.",
  },
  thirdCard: {
    label: "Elegant Design",
    text: "The AirSensor fuses cutting-edge intelligence with stunning elegance. Its pristine, sleek design captivates and keeps your space effortlessly sophisticated.",
  },
  fourthCard: {
    img: "/images/airsensor/cardfour.png",
  },
  fiveCard: {
    img: "/images/airsensor/cardfive.png",
    label: "Lux Setting",
    text: "With adjustable lux settings from 3 to 2000, the AirSensor delivers stunning light control. This fusion of technology and elegance ensures your space shines with tailored brilliance.",
  },
};

export const MotionSensorArray = {
  label: "Our Motion Sensor Trio",
  text: "Whitelion’s motion sensors: Basic for reliable detection, Smart for customizable alerts, and Pro for AI-powered performance and wide coverage.",
  firstCard: {
    img: "/images/airsensor/motioncardone.png",
    label: "PIR.S 102",
    text: "With adjustable lux settings from 3 to 2000, the AirSensor delivers stunning light control. This fusion of technology and elegance ensures your space shines with tailored brilliance.",
  },
  secondCard: {
    img: "/images/airsensor/motioncardtwo.png",
    label: "MW.C 101",
    text: "With adjustable lux settings from 3 to 2000, the AirSensor delivers stunning light control. This fusion of technology and elegance ensures your space shines with tailored brilliance.",
  },
  thirdCard: {
    img: "/images/airsensor/motioncardthree.png",
    label: "PIR.C 101",
    text: "With adjustable lux settings from 3 to 2000, the AirSensor delivers stunning light control. This fusion of technology and elegance ensures your space shines with tailored brilliance.",
  },
};

export const PrecisionArray = {
  label: "AirSensors: Precision in Every Space",
  firstCard: {
    img: "/images/airsensor/precisionone.png",
    label: "Bathroom LuxurY",
    text: "Trigger lights with flawless precision and elegant design",
  },
  secondCard: {
    img: "/images/airsensor/precisiontwo.png",
    label: "Parking Sophistication",
    text: "Power lights and enhance security with refined accuracy.",
  },
  thirdCard: {
    img: "/images/airsensor/precisionthree.png",
    label: "Garage Innovation",
    text: "Activate lights and secure spaces with smart style",
  },
  fourthCard: {
    img: "/images/airsensor/precisionfour.png",
    label: "Passage Elegance",
    text: "AirSensors guide safely with smart precision and stylish design.",
  },
  fifthCard: {
    img: "/images/airsensor/precisionfive.png",
    label: "Godown Efficiency",
    text: "AirSensors optimize security with advanced detection and efficiency.",
  },
};

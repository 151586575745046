import React from "react";

function index() {
  return (
    <div className=" w-full">
      <div className="w-full flex justify-center items-center text-center h-[500px] text-xl">
        mocha{" "}
      </div>
    </div>
  );
}

export default index;

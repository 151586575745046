import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { ContactModalContext } from "../../config/contexts";

function ContactModel() {
  const { isOpen, closeModal } = useContext(ContactModalContext);
  const [formData, setFormData] = useState({
    SingleLine: "",
    SingleLine4: "",
    Email: "",
    Dropdown: "",
    Number: "",
    Radio1: "",
    Radio4: "",
    Radio: "",
    Radio2: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleClose = () => {
    setFormData({
      SingleLine: "",
      SingleLine4: "",
      Email: "",
      Dropdown: "",
      Number: "",
      Radio1: "",
      Radio4: "",
      Radio: "",
      Radio2: "",
    });
    setErrors({});
    setTouched({});
    closeModal(); // Close the modal
  };
  const [modalWidth, setModalWidth] = useState("50vw");

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const params = {
      zf_redirect_url: urlParams.get("zf_redirect_url") || "",
      zc_gad: urlParams.get("zc_gad") || "",
      utm_source: urlParams.get("utm_source") || "",
      utm_medium: urlParams.get("utm_medium") || "",
      utm_campaign: urlParams.get("utm_campaign") || "",
      utm_term: urlParams.get("utm_term") || "",
      utm_content: urlParams.get("utm_content") || "",
    };

    setQueryParams(params);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setModalWidth("90vw");
      } else if (window.innerWidth < 768) {
        setModalWidth("90vw");
      } else if (window.innerWidth < 1024) {
        setModalWidth("70vw");
      } else {
        setModalWidth("50vw");
      }
    };

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const mobileRegex = /^[0-9]{0,10}$/; // Only allows numbers,max 10 digits
    const pinRegex = /^[0-9]{0,6}$/; // Allow only numbers, max 6 digits

    if (name === "SingleLine4") {
      if (!mobileRegex.test(value)) return;
    }
    if (name === "Number") {
      if (!pinRegex.test(value)) return; // Reject invalid input
    }

    if (name.startsWith("Radio")) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    setFormData((prevData) => {
      // Mobile number validation
      if (name === "SingleLine4") {
        if (!mobileRegex.test(value)) {
          setErrors((prev) => ({
            ...prev,
            SingleLine4: "Only numbers are allowed, max 10 digits.",
          }));
          return prevData; // Prevent updating state
        } else {
          setErrors((prev) => ({
            ...prev,
            SingleLine4:
              value.length === 10 ? "" : "Enter a valid 10-digit number.",
          }));
        }
      }

      return {
        ...prevData,
        [name]: value,
        ...(name === "Radio1" && value === "No"
          ? { Radio2: "", Radio4: "", Radio: "" }
          : {}),
        ...(name === "Radio" && (value === "Office" || value === "Other")
          ? { Radio2: "" }
          : {}),
      };
    });
  };

  // 👉 Function to validate the input field
  const validateField = (name, value) => {
    let error = "";

    if (name === "SingleLine") {
      if (value.length === 0) {
        error = "Name is required.";
      }
    }
    if (name === "SingleLine4") {
      if (value.length !== 10) {
        error = "Enter a valid 10-digit mobile number.";
      }
    }

    if (name === "Email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        error = "Enter a valid email address.";
      }
    }

    if (name === "Dropdown") {
      if (value.length === 0) {
        error = "City is required.";
      }
    }
    if (name === "Number") {
      if (value.length !== 6) {
        error = "Pin code must be exactly 6 digits.";
      }
    }
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  // 👉 Function to handle when user clicks outside (onBlur)
  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true })); // Mark field as touched
    validateField(name, value); // Run validation
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.SingleLine) newErrors.SingleLine = "Name is required.";
    if (!formData.Email) {
      //  newErrors.Email = "Email is required.";
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(formData.Email)) {
        newErrors.email = "Enter a valid email address.";
      }
    }

    if (!formData.SingleLine4) {
      newErrors.SingleLine4 = "Mobile number is required.";
    } else if (formData.SingleLine4.length !== 10) {
      newErrors.SingleLine4 = "Enter a valid 10-digit number.";
    }
    if (!formData.Dropdown) newErrors.Dropdown = "City is required.";

    if (!formData.Number) {
      newErrors.Number = "Pin code is required.";
    } else if (formData.Number.length !== 6) {
      newErrors.Number = "Pin code must be 6 digits.";
    }

    if (!formData.Radio1) {
      newErrors.Radio1 = "Please select an option.";
    }
    if (formData.Radio1 === "Yes") {
      if (!formData.Radio4) {
        newErrors.Radio4 = "Please select an option.";
      }
      if (!formData.Radio) {
        newErrors.Radio = "Please select an option.";
      }
    }
    if (
      formData?.Radio1 === "Yes" &&
      (formData?.Radio === "Apartment Flat" ||
        formData?.Radio === "Independant House")
    ) {
      if (!formData.Radio2) {
        newErrors.Radio2 = "Please select an option.";
      }
    }

    setErrors(newErrors);
    setTouched((prev) => ({
      ...prev,
      SingleLine: true,
      Email: true,
      SingleLine4: true,
      Dropdown: true,
      Number: true,
      Radio1: true,
      Radio4: true,
      Radio: true,
      Radio2: true,
    }));

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault(); //

    if (validateForm()) {
      event.target.submit(); // Manually submit the form if valid
    }
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        center
        focusTrapped={false}
        showCloseIcon={false}
        styles={{
          modal: {
            width: modalWidth,
            borderRadius: "12px", 
          },
        }}
      >
        <div className="lg:w-full bg-[#FFFFFF] rounded-[8px] p-[20px] max-h-[90vh] overflow-y-auto">
          {/* Modal Header with Title */}
          <div className="flex justify-between items-center border-b pb-2 mb-4">
            <h2 className="text-xl font-semibold">Connect With Us</h2>
            <button
              onClick={() => handleClose()}
              className="text-gray-500 hover:text-black text-2xl"
            >
              ✖
            </button>
          </div>

          <form
            className="mx-auto w-full"
            action="https://forms.zohopublic.in/whtielionsystemspvtltd/form/Websiteinquiryform/formperma/P41YSJDCAkjhZvLKoS_Nlkg8eNdiMauTT2i3St2ZPGA/htmlRecords/submit"
            name="form"
            method="POST"
            // onSubmit='javascript:document.charset="UTF-8"; return zf_ValidateAndSubmit();'
            acceptCharset="UTF-8"
            encType="multipart/form-data"
            id="form"
            onSubmit={handleSubmit}
          >
            <input
              type="hidden"
              name="zf_redirect_url"
              value={queryParams.zf_redirect_url || ""}
            />
            <input
              type="hidden"
              name="zc_gad"
              value={queryParams.zc_gad || ""}
            />

            <input
              type="hidden"
              name="utm_source"
              value={queryParams.utm_source || ""}
            />
            <input
              type="hidden"
              name="utm_medium"
              value={queryParams.utm_medium || ""}
            />
            <input
              type="hidden"
              name="utm_campaign"
              value={queryParams.utm_campaign || ""}
            />
            <input
              type="hidden"
              name="utm_term"
              value={queryParams.utm_term || ""}
            />
            <input
              type="hidden"
              name="utm_content"
              value={queryParams.utm_content || ""}
            />

            <div className="mb-1 lg:mb-4">
              <label
                htmlFor="SingleLine"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Name <span className="text-red-500">*</span>
              </label>
              <input
                id="SingleLine"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-black focus:ring-black"
                type="text"
                checktype="c1"
                name="SingleLine"
                value={formData.SingleLine}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="John doe"
              />
              {errors?.SingleLine && touched?.SingleLine && (
                <p className="text-red-500 text-xs mt-1 text-left">
                  {errors.SingleLine}
                </p>
              )}
            </div>
            <div className="mb-1 lg:mb-4">
              <label
                htmlFor="SingleLine4"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Contact Number <span className="text-red-500">*</span>
              </label>
              <input
                id="SingleLine4"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-black focus:ring-black"
                type="text "
                name="SingleLine4"
                value={formData.SingleLine4}
                onChange={handleChange}
                onBlur={handleBlur}
                checktype="c1"
                placeholder="9876543210"
              />
              {errors?.SingleLine4 && touched?.SingleLine4 && (
                <p className="text-red-500 text-xs mt-1 text-left">
                  {errors.SingleLine4}
                </p>
              )}
            </div>
            <div className="mb-1 lg:mb-4">
              <label
                htmlFor="Email"
                className="block mb-2 text-sm font-medium text-gray-900 text-left "
              >
                Email
              </label>
              <input
                id="Email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-black focus:ring-black"
                type="text"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                checktype="c5"
                onBlur={handleBlur}
                placeholder="john@gmail.com"
                autoComplete="email"
              />
              {errors?.Email && touched?.Email && (
                <p className="text-red-500 text-xs mt-1 text-left">
                  {errors?.Email}
                </p>
              )}
            </div>
            <div className="mb-1 lg:mb-4">
              <label
                htmlFor="cityDropdown"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                City <span className="text-red-500">*</span>
              </label>
              <select
                id="cityDropdown"
                name="Dropdown"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-black focus:ring-black"
                value={formData.Dropdown}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">-Select City-</option>
                <option value="Surat">Surat</option>
                <option value="Ahmedabad">Ahmedabad</option>
                <option value="Rajkot">Rajkot</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Pune">Pune</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Jaipur">Jaipur</option>
              </select>
              {errors?.Dropdown && touched?.Dropdown && (
                <p className="text-red-500 text-xs mt-1 text-left">
                  {errors.Dropdown}
                </p>
              )}
            </div>

            <div className="mb-1 lg:mb-4">
              <label
                htmlFor="Number"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Pin Code <span className="text-red-500">*</span>
              </label>
              <input
                id="Number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-black focus:ring-black"
                type="text"
                checktype="c2"
                name="Number"
                value={formData.Number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="395007"
              />
              {errors?.Number && touched?.Number && (
                <p className="text-red-500 text-xs mt-1 text-left">
                  {errors?.Number}
                </p>
              )}
            </div>

            <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
              Want to give more details <span className="text-red-500">*</span>
            </label>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white  rounded-lg flex gap-[20px] mb-1 lg:mb-4">
              <li className="">
                <div className="flex items-center">
                  <input
                    id="horizontal-list-radio-Yes"
                    type="radio"
                    value="Yes"
                    name="Radio1"
                    onChange={handleChange}
                    checked={formData?.Radio1 === "Yes"}
                    onBlur={handleBlur}
                    className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                  />
                  <label
                    htmlFor="horizontal-list-radio-Yes"
                    className=" py-1 ms-2 text-sm font-medium text-gray-900"
                  >
                    Yes
                  </label>
                </div>
              </li>
              <li className="">
                <div className="flex items-center">
                  <input
                    id="horizontal-list-radio-No"
                    type="radio"
                    value="No"
                    name="Radio1"
                    onChange={handleChange}
                    checked={formData?.Radio1 === "No"}
                    onBlur={handleBlur}
                    className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                  />
                  <label
                    htmlFor="horizontal-list-radio-No"
                    className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                  >
                    No
                  </label>
                </div>
              </li>
            </ul>
            {errors?.Radio1 && touched?.Radio1 && (
              <p className="text-red-500 text-xs mt-1 text-left">
                {errors?.Radio1}
              </p>
            )}
            <label className="block mb-2 text-[12px] font-medium text-left text-[#808080]">
              It will help us to serve you better
            </label>
            {formData?.Radio1 && formData?.Radio1 === "Yes" && (
              <>
                <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                  Project type{" "}
                </label>
                <ul className="items-center  text-sm font-medium text-gray-900 bg-white  rounded-lg sm:flex gap-[20px] mb-1 lg:mb-4">
                  <li className="">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Property"
                        type="radio"
                        value="New Property"
                        name="Radio4"
                        onChange={handleChange}
                        checked={formData?.Radio4 === "New Property"}
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Property"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900"
                      >
                        New Property
                      </label>
                    </div>
                  </li>
                  <li className="">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Renovation"
                        type="radio"
                        value="Renovation in Existing Property"
                        name="Radio4"
                        onChange={handleChange}
                        checked={
                          formData?.Radio4 === "Renovation in Existing Property"
                        }
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Renovation"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                      >
                        Renovation in Existing Property
                      </label>
                    </div>
                  </li>
                </ul>
                {errors?.Radio4 && touched?.Radio4 && (
                  <p className="text-red-500 text-xs mt-1 text-left">
                    {errors?.Radio4}
                  </p>
                )}

                <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                  Type of Property
                </label>
                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white  rounded-lg sm:flex gap-[20px] mb-1 lg:mb-4">
                  <li className="">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Apartment"
                        type="radio"
                        value="Apartment Flat"
                        name="Radio"
                        onChange={handleChange}
                        checked={formData?.Radio === "Apartment Flat"}
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Apartment"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900"
                      >
                        Apartment Flat{" "}
                      </label>
                    </div>
                  </li>
                  <li className="">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Independant"
                        type="radio"
                        value="Independant House"
                        name="Radio"
                        onChange={handleChange}
                        checked={formData?.Radio === "Independant House"}
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Independant"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                      >
                        Independant House
                      </label>
                    </div>
                  </li>
                  <li className="">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Office"
                        type="radio"
                        value="Office"
                        name="Radio"
                        onChange={handleChange}
                        checked={formData?.Radio === "Office"}
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Office"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                      >
                        Office
                      </label>
                    </div>
                  </li>
                  <li className=" ">
                    <div className="flex items-center">
                      <input
                        id="horizontal-list-radio-Other"
                        type="radio"
                        value="Other"
                        name="Radio"
                        onChange={handleChange}
                        checked={formData?.Radio === "Other"}
                        onBlur={handleBlur}
                        className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                      />
                      <label
                        htmlFor="horizontal-list-radio-Other"
                        className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                      >
                        Other
                      </label>
                    </div>
                  </li>
                </ul>
                {errors?.Radio && touched?.Radio && (
                  <p className="text-red-500 text-xs mt-1 text-left">
                    {errors?.Radio}
                  </p>
                )}
              </>
            )}

            {formData?.Radio &&
              formData?.Radio1 === "Yes" &&
              (formData?.Radio === "Apartment Flat" ||
                formData?.Radio === "Independant House") && (
                <>
                  <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                    BHK
                  </label>
                  <ul className="items-center w-full text-sm font-medium text-gray-900  grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6  lg:gap-[10px] gap-x-[10px] gap-y-[0px] mb-1 lg:mb-4">
                    <li className="">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-license-5moreBHK"
                          type="radio"
                          value="5+ BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "5+ BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500"
                        />
                        <label
                          htmlFor="horizontal-list-radio-license-5moreBHK"
                          className=" py-1 ms-2 text-sm font-medium text-gray-900"
                        >
                          5+ BHK
                        </label>
                      </div>
                    </li>
                    <li className="">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-id-5BHK"
                          type="radio"
                          value="5 BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "5 BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                        />
                        <label
                          htmlFor="horizontal-list-radio-id-5BHK"
                          className="py-1 ms-2 text-sm font-medium text-gray-900 "
                        >
                          5 BHK
                        </label>
                      </div>
                    </li>
                    <li className="">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-4BHK"
                          type="radio"
                          value="4 BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "4 BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                        />
                        <label
                          htmlFor="horizontal-list-radio-4BHK"
                          className="py-1 ms-2 text-sm font-medium text-gray-900 "
                        >
                          4 BHK
                        </label>
                      </div>
                    </li>
                    <li className=" ">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-3BHK"
                          type="radio"
                          value="3 BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "3 BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                        />
                        <label
                          htmlFor="horizontal-list-radio-3BHK"
                          className="py-1 ms-2 text-sm font-medium text-gray-900 "
                        >
                          3 BHK
                        </label>
                      </div>
                    </li>
                    <li className="">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-2BHK"
                          type="radio"
                          value="2 BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "2 BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                        />
                        <label
                          htmlFor="horizontal-list-radio-2BHK"
                          className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                        >
                          2 BHK
                        </label>
                      </div>
                    </li>
                    <li className=" ">
                      <div className="flex items-center">
                        <input
                          id="horizontal-list-radio-1BHK"
                          type="radio"
                          value="1 BHK"
                          name="Radio2"
                          onChange={handleChange}
                          checked={formData?.Radio2 === "1 BHK"}
                          onBlur={handleBlur}
                          className="w-4 h-4 text-blue-600 bg-gray-100  focus:ring-blue-500 "
                        />
                        <label
                          htmlFor="horizontal-list-radio-1BHK"
                          className=" py-1 ms-2 text-sm font-medium text-gray-900 "
                        >
                          1 BHK
                        </label>
                      </div>
                    </li>
                  </ul>
                  {errors?.Radio2 && touched?.Radio2 && (
                    <p className="text-red-500 text-xs mt-1 text-left">
                      {errors?.Radio2}
                    </p>
                  )}
                </>
              )}
            <div className="mb-2 pt-4 lg:mb-4 w-full ">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default ContactModel;

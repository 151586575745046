export const navLinks = {
  home: { name: "Home", route: "/" },
  about: { name: "About", route: "/about" },
  products: { name: "Products", route: "" },
  solution: { name: "Solution", route: "/solution" },
  partner: { name: "Partner", route: "/partner" },
  career: { name: "Career", route: "/career" },
  contact: { name: "Contact Us", route: "/lp" },
  scenePanel: { name: "Scene Panel", route: "/scene-panel" },
  thankYou: { name: "Thanks you", route: "/thank-you" },
};

export const productsLink = {
  posh: { name: "Posh", route: "/products/posh" },
  quartz: { name: "Quartz", route: "/products/quartz" },
  // mocha: { name: "Mocha", route: "/products/mocha" },
  airtouch: { name: "AirTouch", route: "/products/airtouch" },
  airglass: { name: "AirGlass", route: "/products/airglass" },
  airsensor: { name: "AirSensor", route: "/products/airsensor" },
  // airblaster: { name: "AirBlaster", route: "/products/airblaster" },
  airhome: { name: "AirHome", route: "/products/airhome" },
  accessories: { name: "Accessories", route: "/products/accessories" },
};

export const lpLink = {
  getInTouch: {
    name: "get-in-touch",
    route: "/lp/get-in-touch",
    head: "Get in Touch",
    text: "Connect with us for business inquiries, partnerships, or feedback.",
  },
  offers: {
    name: "999-offers",
    route: "/lp/999-offers",
    head: "Special ₹999 Offers",
    text: "Explore our limited-time deals starting at just ₹999!",
  },
};

export const routeColorMap = {
  "/": true,
  "/about": false,
  // "/products": false,
  "/solution": true,
  "/partner": true,
  "/career": true,
  "/lp": true,
  "/thank-you": true,
  "/products/posh": false,
  "/products/quartz": false,
  "/products/mocha": true,
  "/products/airtouch": true,
  "/products/airglass": true,
  "/products/airsensor": true,
  "/products/airblaster": false,
  "/products/airhome": false,
  "/products/accessories": true,
};

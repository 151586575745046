import React from "react";

function ChatIconWhite() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50033 14.707C2.21699 14.707 1.16699 13.657 1.16699 12.3737V6.54036C1.16699 5.25703 2.21699 4.20703 3.50033 4.20703H12.8337C14.117 4.20703 15.167 5.25703 15.167 6.54036V12.3737C15.167 13.657 14.117 14.707 12.8337 14.707H10.5003V18.207L7.00032 14.707H3.50033ZM24.5003 21.707C25.7837 21.707 26.8337 20.657 26.8337 19.3737V13.5404C26.8337 12.257 25.7837 11.207 24.5003 11.207H17.5003V12.3737C17.5003 14.9404 15.4003 17.0404 12.8337 17.0404V19.3737C12.8337 20.657 13.8837 21.707 15.167 21.707H17.5003V25.207L21.0003 21.707H24.5003Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
}

export default ChatIconWhite;

import React from "react";

function ThumbIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7078 0.473633C2.55533 0.473633 0 3.02945 0 6.18143C0 9.33389 2.55533 11.8892 5.7078 11.8892C8.85978 11.8892 11.4156 9.33341 11.4156 6.18143C11.4156 3.02945 8.85978 0.473633 5.7078 0.473633ZM4.3095 7.71415C4.3095 8.02262 4.05952 8.27211 3.75153 8.27211C3.44305 8.27211 3.19356 8.02262 3.19356 7.71415V5.33529C3.19356 5.02682 3.44305 4.77684 3.75153 4.77684C4.05952 4.77684 4.3095 5.02682 4.3095 5.33529V7.71415ZM8.25104 7.67401C8.25104 8.11014 7.99623 8.26631 7.56011 8.26631H5.55356C5.11743 8.26631 4.76399 7.91287 4.76399 7.47674V5.50307C4.76399 5.50307 4.72483 5.17477 5.08842 4.86629C5.29392 4.69175 5.59901 4.35861 5.81223 3.9544C6.23579 3.15129 6.48914 2.91728 6.68206 2.98013C7.39669 3.21173 7.03937 4.27109 6.80874 4.7135H7.46148C7.89712 4.7135 8.25104 5.06695 8.25104 5.50307V7.67401Z"
        fill="#282828"
      />
    </svg>
  );
}

export default ThumbIcon;

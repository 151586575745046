import React, { useState } from "react";
import { DownArrow, RigthArrow } from "../../assets/svg";

const faqs = [
  {
    question:
      "Are Whitelion smart panels compatible with all types of lights and appliances?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "Can I control Whitelion touch panel via a mobile app?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "Do these touch panel support voice assistants?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
  {
    question: "What technology is used in Whitelion smart panels?",
    answer:
      "Yes, our smart panels support lights (on/off, dimming, tuning), fans, curtains, and scene controls, ensuring seamless automation.",
  },
];

const FaqCard = ({ question, answer, openIndex, index, toggleFAQ }) => {
  return (
    <div className="flex justify-between p-[14px] lg:px-[32px] lg:py-[10px] rounded-[8px] bg-[#FFFFFF]">
      <div className="flex flex-col justify-center gap-[10px]">
        <p className="font-inter font-medium  text-left text-[#1E1E1E] text-[13px] leading-[20.8px] lg:text-[24px] lg:leading-[33.6px] tracking-[0] ">
          {question}
        </p>
        {openIndex === index && (
          <p className="font-inter font-normal text-[#808080] text-left text-[12px] leading-[22.4px] lg:text-[20px] lg:leading-[28px] tracking-[-0.01em]">
            {answer}
          </p>
        )}
      </div>

      {openIndex === index ? (
        <div className=" ">
          <div className="hidden md:block " onClick={() => toggleFAQ(index)}>
            <DownArrow size={"desktop"} />
          </div>
          <div className="block md:hidden" onClick={() => toggleFAQ(index)}>
            <DownArrow size={"mobile"} />
          </div>
        </div>
      ) : (
        <div className=" ">
          <div className="hidden md:block " onClick={() => toggleFAQ(index)}>
            <RigthArrow size={"desktop"} />
          </div>
          <div className="block md:hidden" onClick={() => toggleFAQ(index)}>
            <RigthArrow size={"mobile"} />
          </div>
        </div>
      )}
    </div>
  );
};

function Frequently() {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="frequently-section bg-custom-bg-white">
      <div className="grid grid-cols-1 px-[24px] py-[50px] lg:px-[188px] lg:py-[100px] gap-[16px] lg:gap-[36px]">
        <div className="grid grid-cols-1 ">
          <h1 className="font-inter font-semibold text-[32px] leading-[35.2px] lg:text-[64px] lg:leading-[70.4px] tracking-[0%] text-center text-[#1E1E1E]">
            Frequently Asked Questions
          </h1>
        </div>

        <div className="grid grid-cols-1 ">
          <div className="grid grid-cols-1 gap-[10px] ">
            {faqs.map((faq, index) => (
              <FaqCard
                key={index}
                index={index}
                question={faq.question}
                answer={faq.answer}
                openIndex={openIndex}
                toggleFAQ={toggleFAQ}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frequently;

import React, { useContext } from "react";
import { CallIconBlack } from "../../../assets/svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ContactModalContext } from "../../../config/contexts";

const ElevateCard = ({ data }) => {
  return (
    <div
      className="border border-[#FFFFFF33] grid rounded-[8px] px-[20px] py-[24px] lg:py-[48px] lg:px-[28px] gap-[16px] "
      style={{
        background: `linear-gradient(141.52deg, rgba(255, 255, 255, 0.072) -6.72%, rgba(255, 255, 255, 0.12) 112.79%)`,
      }}
    >
      <div className="flex gap-[10px] justify-center items-center">
        {data?.headIcon?.map((item, index) => {
          return (
            <div
              key={index}
              className="w-[46px] h-[46px] gap-[10px] rounded-[40px] border border-[#FFFFFF33] p-[12px] text-white flex justify-center items-center"
              style={{
                background: `linear-gradient(141.52deg, rgba(255, 255, 255, 0.072) -6.72%, rgba(255, 255, 255, 0.12) 112.79%)`,
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className=" h-[2px] bg-[radial-gradient(70.54%_66.17%_at_50%_50%,rgba(255,255,255,0.47)_0%,rgba(255,255,255,0)_100%)]"></div>

      <div className=" h-[240px] lg:h-[254px] text-left text-[#F3F5F0] opacity-80">
        <div
          className="w-[58px] h-[58px] gap-[10px] rounded-[16px] border border-[#FFFFFF33] p-[20px]"
          style={{
            background: `linear-gradient(141.52deg, rgba(255, 255, 255, 0.072) -6.72%, rgba(255, 255, 255, 0.12) 112.79%)`,
          }}
        >
          {data?.cardIcon}
        </div>

        <p className="font-semibold text-[16px] lg:text-[19.53px] leading-[29.3px] pt-2">
          {data?.steps?.head}
        </p>
        <p className="font-normal text-[16px] lg:text-[18px] leading-[27px] pt-2">
          {data?.steps?.text}
        </p>
      </div>
    </div>
  );
};

function ElevatePoshQuartz({ elevateList }) {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="px-[28px] py-[50px] lg:px-[137px] lg:py-[150px] bg-custom-bg-black">
      <div className=" grid grid-cols-1 gap-[40px] gap-[64px]">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="text-center font-semibold text-[40px] leading-[44px] lg:text-[64px] lg:leading-[70.4px] tracking-[0%] text-white opacity-80"
        >
          {elevateList?.head}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
          className="text-center font-semibold text-[14px] leading-[21px] lg:text-[20px] lg:leading-[30px] text-white opacity-80"
        >
          {elevateList?.text}
        </motion.p>

        <div className=" grid grid-cols-1 lg:grid-cols-3 gap-[16px]">
          {elevateList?.card?.map((item, index) => (
            <ElevateCard key={index} data={item} />
          ))}
        </div>
        <div className="flex justify-center items-center">
          <Link
            // to={navLinks.contact.route}
            onClick={() => {
              openModal();
            }}
            className="w-[218px] h-[54px] gap-[8px] px-[28px] py-[12px]  font-semibold rounded-[8px] bg-[#ffffff] flex justify-center items-center"
          >
            Schedule a call
            <CallIconBlack />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ElevatePoshQuartz;

import React, { useState } from "react";
import Welcome from "./Welcome";
import IconRotate from "./IconRotate";
import Control from "./Control";
import Feature from "./Feature";
import Benefits from "./Benefits";
import Enhance from "./Enhance";

function Index() {
  const [bgColor, setBgColor] = useState("#000000");
  return (
    <div className="relative w-full" style={{ backgroundColor: bgColor }}>
      <Welcome />
      <IconRotate />
      <Control />
      <Feature setBgColor={setBgColor} />
      <Benefits setBgColor={setBgColor} />
      <Enhance />
    </div>
  );
}

export default Index;

import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routeColorMap } from "../constant/NavLink";

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const location = useLocation();

  const [isWhiteColor, setIsWhiteColor] = useState(
    routeColorMap[location.pathname] ?? true
  );

  // Update state when route changes
  useEffect(() => {
    setIsWhiteColor(routeColorMap[location.pathname] ?? true);
  }, [location.pathname]);

  return (
    <NavContext.Provider value={{ isWhiteColor, setIsWhiteColor }}>
      {children}
    </NavContext.Provider>
  );
};

import React from "react";

function CallIconWhite() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.62 8.49703C5.06 11.327 7.38 13.647 10.21 15.087L12.41 12.887C12.69 12.607 13.08 12.527 13.43 12.637C14.55 13.007 15.75 13.207 17 13.207C17.2652 13.207 17.5196 13.3124 17.7071 13.4999C17.8946 13.6875 18 13.9418 18 14.207V17.707C18 17.9722 17.8946 18.2266 17.7071 18.4141C17.5196 18.6017 17.2652 18.707 17 18.707C12.4913 18.707 8.1673 16.916 4.97918 13.7278C1.79107 10.5397 0 6.21571 0 1.70703C0 1.44181 0.105357 1.18746 0.292893 0.999924C0.48043 0.812388 0.734784 0.707031 1 0.707031H4.5C4.76522 0.707031 5.01957 0.812388 5.20711 0.999924C5.39464 1.18746 5.5 1.44181 5.5 1.70703C5.5 2.95703 5.7 4.15703 6.07 5.27703C6.18 5.62703 6.1 6.01703 5.82 6.29703L3.62 8.49703Z"
        fill="white"
      />
    </svg>
  );
}

export default CallIconWhite;

import React from "react";
import { motion } from "framer-motion";
import { MissionIcon, VisionIcon } from "../../assets/svg";

function Welcome() {
  return (
    <div className="bg-[#000000] flex flex-col justify-center items-center">
      <div className="grid grid-cols-1 gap-[32px] lg:gap-[64px] px-[24px] py-[24px] lg:py-[64px] lg:px-[164px] max-w-[1300px]">
        <div className="flex items-center justify-center gap-[20px] lg:gap-[60px] ">
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="font-inter text-[#ffffff] lg:font-semibold font-medium text-[32px] leading-[38.19px] lg:text-[72px] lg:leading-[85.92px] tracking-[-0.41px]"
          >
            ABOUT US
          </motion.h1>
        </div>
        <div>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
            className="font-inter text-[#CCCCCC] font-medium text-[14px] leading-[21px] lg:text-[26px] lg:leading-[39px] tracking-[0px] text-center"
          >
            At Whitelion, we craft the future of smart living through
            intelligent panels that blend seamlessly with Alexa, Google Home,
            and intuitive sensors. Our latest innovation, the Air Series, takes
            automation to the next level—bringing effortless control, elegance,
            and modern convenience into every home.
          </motion.p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-[24px] lg:gap-[32px] px-[24px] py-[24px] lg:py-[90px] lg:px-[164px]">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className=" h-full flex flex-col justify-center items-center gap-[12px] lg:gap-[24px] p-[12px] lg:p-[24px] w-full lg:w-[546px] border border-[1.5px] rounded-[18px] bg-[#262626]"
        >
          <VisionIcon />
          <p className="font-inter font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] text-center text-[#ffffff]">
            Vision
          </p>

          <p className="font-inter font-semibold text-[14px] lg:text-[20px] leading-[140%] tracking-[-3%] text-center text-[#ffffff] opacity-80">
            To create and provide affordable Smart Home Automation Solutions to
            empower every City in India, By 2025.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="h-full flex flex-col justify-center items-center gap-[12px] lg:gap-[24px] p-[12px] lg:p-[24px] w-full lg:w-[546px] border border-[1.5px] rounded-[18px] bg-[#262626]"
        >
          <MissionIcon />
          <p className="font-inter font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] text-center text-[#ffffff]">
            Mission
          </p>

          <p className="font-inter font-semibold text-[14px] lg:text-[20px] leading-[140%] tracking-[-3%] text-center text-[#ffffff] opacity-80">
            To spread power of technology for mankind. Providing world class
            quality products and value in the application of Home Automation.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Welcome;

import React from "react";
import { motion } from "framer-motion";

function ProductComparison({ productComparisonArray }) {
  return (
    <div className=" px-[24px] py-[60px] lg:px-[165px] lg:py-[150px] bg-custom-bg-black text-white">
      <div className="flex flex-col gap-[40px] lg:gap-[60px]">
        <div className=" flex justify-center items-center">
          <div className=" flex-col flex justify-center items-center w-[800px]">
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              className="bg-custom-gradient-text bg-clip-text text-transparent font-semibold text-[40px] lg:text-[64px] leading-[110%] tracking-[-0.41%] text-center align-middle"
            >
              {productComparisonArray?.label}
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              className="font-inter font-semibold text-[16px] lg:text-[24px]  leading-[150%] tracking-[0%] text-center opacity-80"
            >
              {productComparisonArray?.text}
            </motion.p>
          </div>
        </div>
        <div className=" grid grid-cols-1 gap-[32px]">
          <motion.div
            className=" flex flex-row justify-between gap-[24px] lg:gap-[94px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <div className=" flex flex-col items-center justify-center gap-[24px] w-[185px] lg:w-[400px] ">
              <img
                src={productComparisonArray?.imgProductA}
                alt="productA"
                loading="lazy"
                className={productComparisonArray?.imgProductAClass}
              />
              <h1 className=" font-bold text-[24px] tracking-[0%] lg:text-[48px] leading-[100%] lg:tracking-[2%] text-center">
                {productComparisonArray?.productA}
              </h1>
            </div>
            <div className=" flex flex-col items-center justify-center gap-[24px] w-[185px] lg:w-[400px] ">
              <img
                src={productComparisonArray?.imgProductB}
                alt="productA"
                loading="lazy"
                className={productComparisonArray?.imgProductBClass}
              />
              <h1 className=" font-bold text-[24px] tracking-[0%] lg:text-[48px] leading-[100%] lg:tracking-[2%] text-center">
                {productComparisonArray?.productB}
              </h1>
            </div>
          </motion.div>

          {productComparisonArray?.productList?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  index={index}
                  className=" flex justify-center items-center py-[16px] border-t border-b border-white border-opacity-30"
                >
                  <motion.h1
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                    className="font-semibold text-[12px] lg:text-[16px] leading-[100%] tracking-[2%] text-center opacity-80"
                  >
                    {item?.category}
                  </motion.h1>
                </div>
                <div className=" flex flex-row justify-between gap-[24px] lg:gap-[94px]  items-start">
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                    className=" flex flex-col items-center justify-center gap-[12px] w-[185px] lg:w-[450px]  "
                  >
                    <p className=" font-semibold text-[20px] lg:text-[40px] leading-[100%] tracking-[2%] text-center">
                      {item.productA.title}
                    </p>
                    <p className="font-sfpro font-light italic text-[14px] leading-[120%] lg:text-[24px] lg:leading-[100%] tracking-[2%] text-center">
                      {item.productA.description}
                    </p>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                    className=" flex flex-col items-center justify-center gap-[12px] w-[185px] lg:w-[450px] "
                  >
                    <p className=" font-semibold text-[20px] lg:text-[40px] leading-[100%] tracking-[2%] text-center">
                      {item.productB.title}
                    </p>
                    <p className="font-sfpro font-light italic text-[14px] leading-[120%] lg:text-[24px] lg:leading-[100%] tracking-[2%] text-center">
                      {item.productB.description}
                    </p>
                  </motion.div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductComparison;

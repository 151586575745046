import React from 'react'

function Security() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 27.7783C8 19.2503 8 14.9863 9.008 13.5517C10.0133 12.1197 14.0213 10.7463 22.04 8.00234L23.568 7.47967C27.7467 6.04767 29.8347 5.33301 32 5.33301C34.1653 5.33301 36.2533 6.04767 40.432 7.47967L41.96 8.00234C49.9787 10.7463 53.9867 12.1197 54.992 13.5517C56 14.9863 56 19.253 56 27.7783V31.9757C56 47.0103 44.696 54.309 37.6027 57.405C35.68 58.245 34.72 58.6663 32 58.6663C29.28 58.6663 28.32 58.245 26.3973 57.405C19.304 54.3063 8 47.013 8 31.9757V27.7783Z" stroke="white" strokeWidth="4" />
            <path d="M25.332 33.066L29.1427 37.3327L38.6654 26.666" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Security
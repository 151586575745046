import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  WhitelionIcon,
  Instagram,
  Whatsapp,
  YouTube,
  LinkedIn,
  Facebook,
} from "../../assets/svg";
import { navLinks } from "../../config/constant/NavLink";
import { usGoToRoute } from "../../config/customHooks";
import { ContactModalContext } from "../../config/contexts";

function Index() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="text-center px-[24px] py-[24px]  lg:px-[40px] bg-gradient-to-br from-[#1E1E1E] to-[#000000] ">
      <div className="grid grid-cols-1 gap-[16px] lg:gap-[40px] ">
        <div className="grid grid-cols-1 gap-[16px] lg:gap-[40px] bg-[#FAFAFA] rounded-[16px] gap-[16px] px-[12px] lg:px-[40px] py-[32px]">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[16px] ">
            <div className="flex justify-center lg:justify-start">
              <WhitelionIcon />
            </div>

            <div className="grid grid-cols-2">
              <div className="grid grid-cols-1 gap-[4px] lg:gap-[20px] text-left">
                <p className="font-inter font-medium text-[#1B1819] text-[10px] leading-[14px] tracking-[0.04em] text-opacity-40 uppercase">
                  Quick Links
                </p>
                <div className="grid grid-cols-1 gap-[4px]  ">
                  <Link
                    to={navLinks.about.route}
                    className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer "
                    onClick={() => {
                      usGoToRoute();
                    }}
                  >
                    {navLinks.about.name}
                  </Link>
                  <Link
                    to={navLinks.solution.route}
                    className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer "
                    onClick={() => {
                      usGoToRoute();
                    }}
                  >
                    {navLinks.solution.name}
                  </Link>
                  <Link
                    // to={navLinks.products.route}
                    className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer "
                    onClick={() => {
                      usGoToRoute();
                    }}
                  >
                    {navLinks.products.name}
                  </Link>

                  <Link
                    to={navLinks.career.route}
                    className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer "
                    onClick={() => {
                      usGoToRoute();
                    }}
                  >
                    {navLinks.career.name}
                  </Link>
                  <Link
                    // to={navLinks.contact.route}
                    className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer "
                    onClick={() => {
                      openModal();
                    }}
                  >
                    {navLinks.contact.name}
                  </Link>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-[4px] lg:gap-[20px] text-left">
                <p className="font-inter font-medium text-[#1B1819] text-[10px] leading-[14px] tracking-[0.04em] text-opacity-40 uppercase">
                  other
                </p>
                <div className="grid grid-cols-1 gap-[4px]">
                  <p className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer ">
                    Return Policy
                  </p>
                  <p className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer ">
                    Downloads
                  </p>
                  <p className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer ">
                    Warranty Registration
                  </p>
                  <p className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer ">
                    Blogs
                  </p>
                  <p className="font-inter font-normal text-[14px] leading-[19.6px] tracking-[0]  text-[#1B1819]   lg:text-[16px] hover:cursor-pointer ">
                    Gallery
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col item-start lg:items-end gap-[10px]">
              <Link
                // to={navLinks.contact.route}
                onClick={() => {
                  openModal();
                }}
                className="self-start lg:self-end font-inter w-[130px] h-[30px] lg:w-[150px] lg:h-[36px] px-[12px] py-[6px] lg:px-[16px] lg:py-[8px] rounded-[8px] bg-[#1E1E1E] text-white font-semibold text-[12px] leading-[16px] lg:text-[14px] lg:leading-[19.6px] tracking-[0.01em]"
              >
                Call us for Demo
              </Link>

              <div className="flex flex-col items-start lg:items-end gap-[10px] text-left lg:text-right">
                <p className="font-inter text-[#1E1E1E] font-medium text-[14px] leading-[18.2px] tracking-[0]">
                  +91-74050 29863
                </p>
                <p className="font-inter text-[#1E1E1E] font-medium text-[14px] leading-[18.2px] tracking-[0]">
                  info@whitelion.in
                </p>
                <p className="font-inter text-[#1E1E1E] font-medium text-[14px] leading-[18.2px] tracking-[0]">
                  Union Heights, Opp. Rahulraj Mall,
                  <br /> Piplod, Surat 395 007
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center  gap-[16px]">
            <div className="flex justify-center gap-[8px]  ">
              <a
                href="https://www.facebook.com/whiteliongrp"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <Facebook />
              </a>

              {/* <a href="#" className=""> */}
              {/* WhatsApp */}
              <Whatsapp />
              {/* </a> */}
              <a
                href="https://in.linkedin.com/company/whitelion-in"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                {/* LinkedIn */}
                <LinkedIn />
              </a>
              <a
                href="https://www.youtube.com/@whitelion-in"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <YouTube />
              </a>
              <a
                href="https://www.instagram.com/whitelion.in/"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <Instagram />
              </a>
            </div>

            <div className="grid grid-cols-2 gap-x-[14px] gap-y-[10px] sm:flex sm:flex-wrap lg:flex-row justify-between text-start items-start lg:items-center">
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Embedded Software Services
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                FAQs
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Terms of Use
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Privacy Policy
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Embedded Systems
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Sitemap
              </p>
              <p className="font-inter text-[12px] leading-[14.32px] tracking-[0px] font-normal">
                Warranty Policy
              </p>
            </div>
          </div>
        </div>

        <p className="font-inter  text-[#FFFFFF] font-bold lg:font-extrabold text-[17px] leading-[20px] tracking-[0] lg:text-[40px] lg:leading-[40px] lg:tracking-[-0.02em] text-left">
          Have questions or need assistance?
          <br />
          Reach out to us!
        </p>
        <p className="font-inter text-[#FFFFFF] font-normal text-[10px] leading-[14px] tracking-[0] text-left">
          Copyright © 2024 Whitelion Systems Private Limited. All rights
          reserved
        </p>
      </div>
    </div>
  );
}

export default Index;

import React from "react";

function GooglePlayIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69406 3.27352H3.69423L3.69389 3.2672C3.6749 2.91747 3.78424 2.57343 4.00039 2.29926L13.5085 11.7997V11.9362L4.00339 21.4413C3.78135 21.1689 3.67035 20.8219 3.6938 20.4701L3.69406 20.4701V20.4624V3.27352Z"
        fill="#1E1E1E"
        stroke="white"
        strokeWidth="0.233122"
      />
      <path
        d="M20.639 10.8503L20.6391 10.8504C21.1618 11.1453 21.3958 11.5189 21.3958 11.8688C21.3958 12.2189 21.1615 12.5946 20.6386 12.8934L16.8435 15.0509L16.8366 15.0541L13.7416 11.9364V11.7993L16.8266 8.69177L20.639 10.8503Z"
        fill="#1E1E1E"
        stroke="white"
        strokeWidth="0.233122"
      />
      <mask id="path-3-inside-1_2541_4664" fill="white">
        <path d="M16.9043 15.1472L13.625 11.8679L3.94269 21.5502C4.16227 21.7457 4.44333 21.8581 4.73713 21.8681C5.03093 21.878 5.31895 21.7848 5.55123 21.6046L16.9043 15.1472Z" />
      </mask>
      <path
        d="M16.9043 15.1472L13.625 11.8679L3.94269 21.5502C4.16227 21.7457 4.44333 21.8581 4.73713 21.8681C5.03093 21.878 5.31895 21.7848 5.55123 21.6046L16.9043 15.1472Z"
        fill="#1E1E1E"
      />
      <path
        d="M13.625 11.8679L13.7899 11.7031L13.625 11.5382L13.4602 11.7031L13.625 11.8679ZM3.94269 21.5502L3.77785 21.3854L3.60314 21.5601L3.78769 21.7244L3.94269 21.5502ZM5.55123 21.6046L5.43597 21.402L5.4215 21.4102L5.40835 21.4204L5.55123 21.6046ZM17.0691 14.9823L13.7899 11.7031L13.4602 12.0328L16.7394 15.312L17.0691 14.9823ZM13.4602 11.7031L3.77785 21.3854L4.10753 21.7151L13.7899 12.0328L13.4602 11.7031ZM3.78769 21.7244C4.04793 21.956 4.38104 22.0893 4.72925 22.1011L4.74501 21.6351C4.50562 21.627 4.2766 21.5354 4.09769 21.3761L3.78769 21.7244ZM4.72925 22.1011C5.07746 22.1128 5.41881 22.0024 5.69411 21.7888L5.40835 21.4204C5.21908 21.5672 4.9844 21.6432 4.74501 21.6351L4.72925 22.1011ZM5.66649 21.8073L17.0195 15.3498L16.789 14.9445L5.43597 21.402L5.66649 21.8073Z"
        fill="white"
        mask="url(#path-3-inside-1_2541_4664)"
      />
      <path
        d="M5.47888 2.23031L5.48589 2.23586L5.49365 2.24027L16.7136 8.61439L13.625 11.703L4.11725 2.19526C4.29939 2.06574 4.5166 1.99234 4.74184 1.98581C5.00864 1.97808 5.26961 2.06465 5.47888 2.23031Z"
        fill="#1E1E1E"
        stroke="white"
        strokeWidth="0.233122"
      />
      <path
        opacity="0.2"
        d="M16.811 15.0773L5.55123 21.4881C5.32739 21.6571 5.05459 21.7484 4.77416 21.7484C4.49372 21.7484 4.22092 21.6571 3.99708 21.4881L3.94269 21.5425L3.99708 21.6047C4.22092 21.7736 4.49372 21.865 4.77416 21.865C5.05459 21.865 5.32739 21.7736 5.55123 21.6047L16.9043 15.1472L16.811 15.0773Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M3.94275 21.4415C3.69314 21.1333 3.57082 20.7413 3.60083 20.3458V20.4624C3.57439 20.859 3.70565 21.25 3.96606 21.5503L4.02045 21.4959L3.94275 21.4415Z"
        fill="black"
      />
      <path
        opacity="0.12"
        d="M20.6965 12.8781L16.8111 15.0772L16.881 15.1472L20.6965 12.9869C20.9217 12.8917 21.1171 12.7375 21.262 12.5406C21.407 12.3436 21.4961 12.1113 21.5202 11.8679C21.4716 12.089 21.3717 12.2957 21.2286 12.4711C21.0856 12.6466 20.9033 12.786 20.6965 12.8781Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M5.55128 2.24776L20.6964 10.8577C20.9033 10.9498 21.0855 11.0892 21.2286 11.2647C21.3717 11.4402 21.4715 11.6468 21.5201 11.8679C21.4961 11.6246 21.4069 11.3922 21.262 11.1953C21.117 10.9984 20.9216 10.8442 20.6964 10.7489L5.55128 2.13897C4.47115 1.51731 3.57751 2.03018 3.57751 3.2735V3.39006C3.60083 2.14674 4.47115 1.63388 5.55128 2.24776Z"
        fill="white"
      />
    </svg>
  );
}

export default GooglePlayIcon;

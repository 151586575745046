import React from "react";

function TrendIconWhite() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2505 6.83203V13.832C26.2505 14.0641 26.1583 14.2867 25.9942 14.4507C25.8301 14.6148 25.6076 14.707 25.3755 14.707C25.1434 14.707 24.9209 14.6148 24.7568 14.4507C24.5927 14.2867 24.5005 14.0641 24.5005 13.832V8.94406L15.4946 17.9511C15.4133 18.0324 15.3168 18.097 15.2106 18.141C15.1043 18.1851 14.9905 18.2077 14.8755 18.2077C14.7605 18.2077 14.6466 18.1851 14.5404 18.141C14.4342 18.097 14.3377 18.0324 14.2564 17.9511L10.5005 14.1941L3.24455 21.4511C3.08036 21.6153 2.85768 21.7075 2.62549 21.7075C2.39329 21.7075 2.17061 21.6153 2.00642 21.4511C1.84224 21.2869 1.75 21.0642 1.75 20.832C1.75 20.5998 1.84224 20.3772 2.00642 20.213L9.88142 12.338C9.96269 12.2566 10.0592 12.1921 10.1654 12.148C10.2716 12.104 10.3855 12.0813 10.5005 12.0813C10.6155 12.0813 10.7293 12.104 10.8356 12.148C10.9418 12.1921 11.0383 12.2566 11.1195 12.338L14.8755 16.095L23.2635 7.70703H18.3755C18.1434 7.70703 17.9209 7.61484 17.7568 7.45075C17.5927 7.28666 17.5005 7.0641 17.5005 6.83203C17.5005 6.59997 17.5927 6.37741 17.7568 6.21331C17.9209 6.04922 18.1434 5.95703 18.3755 5.95703H25.3755C25.6076 5.95703 25.8301 6.04922 25.9942 6.21331C26.1583 6.37741 26.2505 6.59997 26.2505 6.83203Z"
        fill="white"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default TrendIconWhite;

import React from "react";
import { motion } from "framer-motion";

function WelcomePoshQuartz({ img, alt, head, text }) {
  return (
    <div
      className={
        `bg-custom-bg-black text-white h-screen overflow`
        // head === "POSH" ? "" : "pt-[50px] lg:pt-0 "
      }
    >
      <div className="flex flex-col justify-around lg:justify-center itmes-center gap-[100px] px-[24px] lg:px-[165px] py-[100px] h-full">
        <motion.div
          className="flex justify-center items-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          <motion.img
            animate={{
              y: [0, -10, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            src={img}
            alt={alt}
            loading="lazy"
            className={` ${
              head === "POSH"
                ? " lg:w-[40vw] lg:h-[40vh] "
                : "px-[24px] lg:w-[30vw] lg:h-[40vh]"
            }`}
          />
        </motion.div>

        <motion.div
          className="flex flex-col lg:flex-row justify-between w-full gap-[24px]"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          <div
            className={`flex flex-col  ${
              head === "POSH"
                ? "w-[155px] lg:w-[280px]"
                : "w-[230px] lg:w-[405px]"
            }`}
          >
            <p className=" text-left font-medium text-[20px] leading-[20px] tracking-[0.68px] lg:text-[16px] lg:leading-[16px] lg:tracking-[0.45px] opacity-90">
              The
            </p>

            <p className="font-bold text-[56px] leading-[56px] track lg:text-[100px] lg:leading-[100px] lg:tracking-[0.68px] text-center ">
              {head}
            </p>

            <p className="text-right font-medium text-[20px] leading-[20px] tracking-[0.68px] lg:text-[16px] lg:leading-[16px] lg:tracking-[0.45px] opacity-90">
              Series
            </p>
          </div>
          <div className="flex items-center lg:justify-center">
            <p className="text-center font-normal text-[30px] leading-[42px] lg:text-[64px] lg:leading-[64px] tracking-[0] opacity-90">
              {text}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default WelcomePoshQuartz;

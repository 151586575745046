import React, { useContext } from "react";
import { NavContext } from "../../config/contexts/NavContext";
import { productsLink } from "../../config/constant/NavLink";
import { Link, useLocation } from "react-router-dom";
import "swiper/css";
import {
  AccessoriesIcon,
  // AirBlasterIcon,
  AirGlassIcon,
  AirHomeIcon,
  AirSensorIcon,
  AirTouchIcon,
  // MochaIcon,
  PoshIcon,
  QuartzIcon,
} from "../../assets/svg";
import { usGoToRoute } from "../../config/customHooks";

const ProductIcons = {
  Posh: PoshIcon,
  Quartz: QuartzIcon,
  // Mocha: MochaIcon,
  AirTouch: AirTouchIcon,
  AirGlass: AirGlassIcon,
  // AirBlaster: AirBlasterIcon,
  AirHome: AirHomeIcon,
  AirSensor: AirSensorIcon,
  Accessories: AccessoriesIcon,
};

function Index() {
  const { isWhiteColor } = useContext(NavContext);
  const location = useLocation();
  return (
    <div className="w-full flex justify-center items-center md:h-[97px] h-[120px] ">
      {/* Show normal flex layout on large screens */}
      <div className="hidden lg:flex gap-[30px]">
        {Object.values(productsLink)?.map((section, index) => {
          const IconComponent = ProductIcons?.[section?.name];

          return (
            <Link
              to={section?.route}
              key={index}
              onClick={() => usGoToRoute()}
              className="flex flex-col justify-center items-center gap-[10px] cursor-pointer text-[13.29px] leading-[13.5px] tracking-[0.68px] text-center font-inter transition duration-300"
            >
              {/* Render the Icon dynamically */}
              {IconComponent && <IconComponent position={location.pathname === section.route} />}

              <p
                className={` ${
                  isWhiteColor ? "text-custom-black" : "text-custom-white"
                } ${
                  location.pathname === section.route
                    ? "font-semibold opacity-100"
                    : "opacity-60 font-normal"
                }`}
              >
                {section.name}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Index;

import React from "react";

function NextIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5585 16.916C24.1445 17.5 24.1465 18.446 23.5645 19.034L16.6075 26.056C16.3145 26.352 15.9285 26.5 15.5425 26.5C15.1605 26.5 14.7785 26.355 14.4865 26.065C13.8975 25.482 13.8945 24.533 14.4775 23.944L20.3816 17.984L14.4406 12.062C13.8546 11.478 13.8526 10.5279 14.4376 9.941C15.0216 9.354 15.9736 9.353 16.5586 9.938L23.5585 16.916Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default NextIcon;

import React from "react";
import {
  Leadership,
  OurStory,
  SuccessStories,
  Welcome,
} from "../../components/about";

function index() {
  return (
    <div className="">
      <Welcome />
      {/* Leadership */}
      <Leadership />
      {/* Our Story */}
      <OurStory />
      {/* SuccessStories */}
      <SuccessStories />
    </div>
  );
}

export default index;

import React from "react";

function RigthArrow({ size }) {
  return (
    <>
      {size === "desktop" && (
        <svg
          width="80"
          height="79"
          viewBox="0 0 80 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2502_4147)">
            <circle cx="40" cy="35" r="24" fill="white" />
            <circle
              cx="40"
              cy="35"
              r="23.5263"
              stroke="#1E1E1E"
              strokeWidth="0.947461"
            />
          </g>
          <path
            d="M37.0527 42.8232L44.8402 35.0004L37.0527 27.1777"
            stroke="#1E1E1E"
            strokeWidth="1.89492"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_2502_4147"
              x="0.840624"
              y="0.577929"
              width="78.3188"
              height="78.3188"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.73731" />
              <feGaussianBlur stdDeviation="7.57969" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2502_4147"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2502_4147"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
      {size === "mobile" && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2502_4153)">
            <circle cx="20" cy="18" r="12" fill="white" />
            <circle
              cx="20"
              cy="18"
              r="11.7631"
              stroke="#1E1E1E"
              strokeWidth="0.473731"
            />
          </g>
          <path
            d="M18.5273 21.9121L22.4211 18.0007L18.5273 14.0893"
            stroke="#1E1E1E"
            strokeWidth="0.947461"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_2502_4153"
              x="0.420312"
              y="0.788965"
              width="39.1594"
              height="39.1594"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.36865" />
              <feGaussianBlur stdDeviation="3.78984" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2502_4153"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2502_4153"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </>
  );
}

export default RigthArrow;

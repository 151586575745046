import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { MobileIcon } from "../../../assets/svg";

function IconRotate() {
  const sectionRef = useRef(null);

  // Track scroll progress specifically for this section
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"], // Start when section enters viewport, end when it leaves
  });
  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 20 });
  };
  const rotation = useSpringTransform(scrollYProgress, [0, 0.4], [0, 360]);
  const iconScale = useSpringTransform(scrollYProgress, [0, 0.4], [0.5, 1]);
  const iconOpacity = useSpringTransform(scrollYProgress, [0, 0.4], [0, 1]);

  const textOpacity = useSpringTransform(scrollYProgress, [0, 0.4], [0, 1]);
  const textScale = useSpringTransform(scrollYProgress, [0, 0.4], [0.8, 1]);

  return (
    <div
      ref={sectionRef}
      className="flex flex-col justify-center items-center gap-[48px] p-[24px] md:px-[100px] md:py-[40px] bg-[#000000]"
    >
      <motion.div
        style={{
          rotate: rotation,
          scale: iconScale,
          opacity: iconOpacity,
        }}
      >
        <MobileIcon />
      </motion.div>

      <motion.div
        className="flex flex-col justify-center items-center text-center gap-[16px] text-white"
        style={{
          opacity: textOpacity,
          scale: textScale,
        }}
      >
        <h1 className="font-semibold text-[24px] md:text-[48px] leading-[140%] tracking-[1%] text-center">
          Your Home, Smarter & Safer – All in One App
        </h1>
        <p className="font-medium text-[16px] md:text-[28px] leading-[140%] tracking-[1%] text-center text-[#CCCCCC]">
          Seamless Smart Home Control at Your Fingertips
        </p>
      </motion.div>
    </div>
  );
}

export default IconRotate;

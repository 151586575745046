import React, { useContext } from "react";
import { ContactModalContext } from "../../config/contexts";

const WhyChooseUsArray = [
  { img: "/images/innovation.png", text: "Innovation-Driven" },
  {
    img: "/images/user-friendlyexperience.png",
    text: "User-Friendly Experience",
  },
  { img: "/images/reliable&secure.png", text: "Reliable & Secure" },
  { img: "/images/scalable&future-ready.png", text: "Scalable & Future-Ready" },
  { img: "/images/exceptionalsupport.png", text: "Exceptional Support" },
];
const LogoWithText = ({ img, text }) => {
  return (
    <div className=" pb[2px] md:pb-[10px]">
      <div className="flex items-center pb-[10px] space-x-3">
        <img
          className="w-[24px] h-[24px] lg:h-[32px] lg:w-[32px]"
          src={img}
          alt={text}
          loading="lazy"
        />
        <p className="text-[#1E1E1E] font-inter font-semibold text-[16px] leading-[22.4px] lg:text-[24px] leading-[33.6px] tracking-[-0.03em]">
          {text}
        </p>
      </div>
    </div>
  );
};

function WhyChooseUs() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div
      id="WhyChooseUs"
      className="whychooseus-section grid grid-cols-1 md:grid-cols-2 bg-[#FFFFFF] md:gap-4 "
    >
      <div className="">
        <div className="grid grid-cols-1  bg-[#FFFFFF] gap-4 px-[40px] md:px-[70px] py-[50px] md:py-[200px]">
          <div className=" text-left">
            {/* <Innovation/> */}
            <h1 className="font-inter font-semibold text-[26px] md:text-[40px]  leading-[6  7.2px] tracking-[-0.0041em]">
              Why Choose{" "}
              <span className="bg-gradient-to-r from-[#00F0FF] to-[#0066FF] inline-block text-transparent bg-clip-text">
                {" "}
                Whitelion?{" "}
              </span>
            </h1>
          </div>
          <div className=" text-left">
            {WhyChooseUsArray?.map((item, index) => (
              <LogoWithText key={index} img={item?.img} text={item?.text} />
            ))}
          </div>
          <div className="md:text-left w-full">
            <button
              className=" w-full md:w-[289px] font-inter font-semibold text-[20px] leading-[28px] tracking-[-3%] text-[#1E1E1E] hover:text-white border border-[#1E1E1E] hover:bg-[#1E1E1E] font-medium rounded-[8px] text-sm px-5 py-2.5 text-center"
              onClick={() => openModal()}
            >
              Why Whitelion? Find Out
            </button>
          </div>
        </div>
      </div>
      <div className=" md:py-[100px] flex justify-end">
        <img
          className=""
          src="/images/choosehome.png"
          alt="ChooseHome"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default WhyChooseUs;

import React from "react";

function BagIconWhite() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62493 8.88886V7.70703C9.62493 6.54671 10.0859 5.43391 10.9063 4.61344C11.7268 3.79297 12.8396 3.33203 13.9999 3.33203C15.1603 3.33203 16.2731 3.79297 17.0935 4.61344C17.914 5.43391 18.3749 6.54671 18.3749 7.70703V8.88886C19.8881 8.93203 20.8098 9.0872 21.5156 9.66703C22.4839 10.4604 22.7523 11.8067 23.2924 14.5005L23.9924 18.0005C24.7671 21.8739 25.1544 23.8105 24.1044 25.0927C23.0544 26.3749 21.0781 26.3737 17.1266 26.3737H10.8733C6.92293 26.3737 4.9466 26.3737 3.8966 25.0927C2.8466 23.8117 3.23394 21.875 4.0086 18.0005L4.7086 14.5005C5.2476 11.8067 5.5171 10.4604 6.48427 9.66703C7.19127 9.08836 8.11294 8.93203 9.6261 8.88886M11.3749 7.70703C11.3749 7.01084 11.6515 6.34316 12.1438 5.85088C12.6361 5.35859 13.3037 5.08203 13.9999 5.08203C14.6961 5.08203 15.3638 5.35859 15.8561 5.85088C16.3484 6.34316 16.6249 7.01084 16.6249 7.70703V8.8737H11.3749V7.70703ZM17.4999 13.5404C17.8094 13.5404 18.1061 13.4174 18.3249 13.1987C18.5437 12.9799 18.6666 12.6831 18.6666 12.3737C18.6666 12.0643 18.5437 11.7675 18.3249 11.5487C18.1061 11.3299 17.8094 11.207 17.4999 11.207C17.1905 11.207 16.8938 11.3299 16.675 11.5487C16.4562 11.7675 16.3333 12.0643 16.3333 12.3737C16.3333 12.6831 16.4562 12.9799 16.675 13.1987C16.8938 13.4174 17.1905 13.5404 17.4999 13.5404ZM11.6666 12.3737C11.6666 12.6831 11.5437 12.9799 11.3249 13.1987C11.1061 13.4174 10.8094 13.5404 10.4999 13.5404C10.1905 13.5404 9.89377 13.4174 9.67498 13.1987C9.45618 12.9799 9.33327 12.6831 9.33327 12.3737C9.33327 12.0643 9.45618 11.7675 9.67498 11.5487C9.89377 11.3299 10.1905 11.207 10.4999 11.207C10.8094 11.207 11.1061 11.3299 11.3249 11.5487C11.5437 11.7675 11.6666 12.0643 11.6666 12.3737Z"
        fill="white"
      />
    </svg>
  );
}

export default BagIconWhite;

import React, { useRef } from "react";
import { AirHomeWhiteIcon, PowerButtonIcon } from "../../../assets/svg";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

function Enhance() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 10 });
  };

  const imgOpacity1 = useSpringTransform(scrollYProgress, [0, 0.05], [0, 1]);
  const y1 = useSpringTransform(scrollYProgress, [0.1, 0.2], [20, 0]);
  const opacity1 = useSpringTransform(scrollYProgress, [0.1, 0.15], [0, 1]);
  const y2 = useSpringTransform(scrollYProgress, [0.2, 0.3], [20, 0]);
  const opacity2 = useSpringTransform(scrollYProgress, [0.2, 0.25], [0, 1]);
  const y3 = useSpringTransform(scrollYProgress, [0.3, 0.4], [20, 0]);
  const opacity3 = useSpringTransform(scrollYProgress, [0.3, 0.35], [0, 1]);
  const y4 = useSpringTransform(scrollYProgress, [0.4, 0.5], [20, 0]);
  const opacity4 = useSpringTransform(scrollYProgress, [0.4, 0.45], [0, 1]);
  const y5 = useSpringTransform(scrollYProgress, [0.5, 0.6], [20, 0]);
  const opacity5 = useSpringTransform(scrollYProgress, [0.5, 0.55], [0, 1]);
  const y6 = useSpringTransform(scrollYProgress, [0.6, 0.7], [20, 0]);
  const opacity6 = useSpringTransform(scrollYProgress, [0.6, 0.65], [0, 1]);
  const y7 = useSpringTransform(scrollYProgress, [0.7, 0.8], [20, 0]);
  const opacity7 = useSpringTransform(scrollYProgress, [0.7, 0.75], [0, 1]);
  const y8 = useSpringTransform(scrollYProgress, [0.8, 0.9], [20, 0]);
  const opacity8 = useSpringTransform(scrollYProgress, [0.8, 0.85], [0, 1]);
  const y9 = useSpringTransform(scrollYProgress, [0.9, 0.1], [20, 0]);
  const opacity9 = useSpringTransform(scrollYProgress, [0.9, 0.95], [0, 1]);
  return (
    <div className="hidden sm:block lg:h-[570vh]">
      <motion.div ref={containerRef} className="h-[600vh] relative  px-[160px]">
        <div className="relative sticky top-0 top-1/2 -translate-y-1/2 flex justify-center items-center">
          <div className="h-[850px]  flex flex-col gap-[30px] w-full ">
            <div className="h-[405px] w-full flex gap-[30px] justify-center">
              <motion.div className="h-[405px] w-[262px] rounded-[24px] p-[24px] flex flex-col justify-between bg-custom-gradient-card">
                <motion.div
                  className=" flex flex-col items-start gap-[20px]"
                  style={{ y: y1, opacity: opacity1 }}
                >
                  <div className="flex justify-center items-center bg-white w-[48px] h-[48px] rounded-[8px]">
                    <PowerButtonIcon />
                  </div>
                  <h1 className="font-semibold text-[36px] leading-[40px] tracking-[-0.01em] bg-custom-gradient-air-text bg-clip-text text-transparent">
                    Instant Home Reset
                  </h1>
                </motion.div>
                <motion.div
                  className="flex flex-col gap-[4px]"
                  style={{ y: y1, opacity: opacity1 }}
                >
                  <p className="font-[SF Pro Display] font-bold text-[18px] leading-[28px] tracking-[0%] align-bottom text-[#E3EEFF]">
                    Master Button
                  </p>
                  <p className="font-[SF Pro Display] font-normal text-[16px] leading-[24px] tracking-[0%] align-bottom text-[#A0BBE4]">
                    Turns off all devices, recalls states with one tap.
                  </p>
                </motion.div>
              </motion.div>
              <div
                className="h-[405px] w-[556px] rounded-[24px] p-[24px]"
                style={{
                  backgroundImage: `url('/images/airhome/card-middle-bg.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <motion.div
                  className="flex flex-col items-center gap-[16px]"
                  style={{ y: y2, opacity: opacity2 }}
                >
                  <div className="flex  items-center gap-[16px]">
                    <AirHomeWhiteIcon />
                    <p className="font-bold text-[20px] leading-[28px] tracking-[0%]  align-bottom text-white">
                      AirHome
                    </p>
                  </div>

                  <p className="font-semibold text-[62px] leading-[62px] tracking-[-2%] text-center text-white">
                    Effortless Smart Home Perfection
                  </p>
                </motion.div>
              </div>

              <div className=" h-[405px] w-[262px] flex flex-col gap-[30px] ">
                <div className=" h-[160px] rounded-[24px] flex justify-center items-center bg-custom-gradient-card">
                  <motion.img
                    className="h-[72px] w-[132px]"
                    src="/images/airhome/buttoncloud.png"
                    alt="icon"
                    style={{ y: y3, opacity: opacity3 }}
                  />
                </div>
                <div className=" h-[215px] rounded-[24px] bg-custom-gradient-card flex flex-col justify-center items-center gap-[12px] p-[24px]">
                  <motion.div style={{ y: y4, opacity: opacity4 }}>
                    <p className=" font-semibold text-[32px] leading-[130%] tracking-[-2%] text-center bg-custom-gradient-air-text bg-clip-text text-transparent">
                      Active Sessions
                    </p>
                  </motion.div>
                  <motion.div className="" style={{ y: y4, opacity: opacity4 }}>
                    <p className=" font-normal text-[18px] leading-[28px] tracking-[0%] text-center align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent">
                      Manage Logins
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
            <div className=" h-[405px] w-full flex gap-[30px] justify-center">
              <div className=" h-[405px] w-[262px] flex flex-col gap-[30px] ">
                <div className=" h-[215px] rounded-[24px] bg-custom-gradient-card flex flex-col justify-center items-center gap-[12px] px-[24px]">
                  <motion.p
                    className=" font-bold text-[18px] leading-[28px] tracking-[0%] text-center align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent"
                    style={{ y: y5, opacity: opacity5 }}
                  >
                    Real-Time Insights
                  </motion.p>
                  <motion.p
                    className=" font-normal text-[18px] leading-[28px] tracking-[0%] text-center align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent"
                    style={{ y: y5, opacity: opacity5 }}
                  >
                    Get alerts and review activity logs instantly.
                  </motion.p>

                  <motion.img
                    className="h-[58px] w-[144px]"
                    src="/images/airhome/multiple.png"
                    alt="icon"
                    style={{ y: y5, opacity: opacity5 }}
                  />
                </div>
                <div className=" h-[160px] rounded-[24px] flex justify-center items-center bg-custom-gradient-card">
                  <motion.img
                    className="h-[72px] w-[132px]"
                    src="/images/airhome/buttoncloud.png"
                    alt="icon"
                    style={{ y: y6, opacity: opacity6 }}
                  />
                </div>
              </div>
              <div className=" h-[405px] w-[556px] flex flex-row justify-center items-center gap-[24px]">
                <div
                  className=" w-full h-full rounded-[24px]"
                  style={{
                    backgroundImage: `url('/images/airhome/card-middle-left-bg.png')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <motion.div
                    className="flex flex-col justify-end w-full h-full p-[24px]"
                    style={{ y: y7, opacity: opacity7 }}
                  >
                    <div className="flex flex-col justify-center items-start gap-[8px]">
                      <img
                        className="h-[52px] w-[52px] rounded-full"
                        src="/images/airhome/nature.png"
                        alt="icon"
                      />
                      <p className=" font-bold text-[18px] leading-[28px] tracking-[0%] align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent">
                        Peaceful Getaway Mode
                      </p>
                      <p className="font-normal text-[16px] leading-[24px] tracking-[0%] align-bottom text-[#A0BBE4]">
                        Pauses all automations while away.
                      </p>
                    </div>
                  </motion.div>
                </div>
                <div
                  className=" w-full h-full rounded-[24px]"
                  style={{
                    backgroundImage: `url('/images/airhome/card-middle-right-bg.png')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <motion.div
                    className="flex flex-col justify-end w-full h-full p-[24px]"
                    style={{ y: y8, opacity: opacity8 }}
                  >
                    <div className="flex flex-col justify-center items-start gap-[8px]">
                      <img
                        className="h-[52px] w-[52px] rounded-full"
                        src="/images/airhome/second.png"
                        alt="icon"
                      />
                      <p className=" font-bold text-[18px] leading-[28px] tracking-[0%] align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent">
                        Peaceful Getaway Mode
                      </p>
                      <p className="font-normal text-[16px] leading-[24px] tracking-[0%] align-bottom text-[#A0BBE4]">
                        Pauses all automations while away.
                      </p>
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className=" h-[405px] w-[262px] bg-custom-gradient-card p-[24px] rounded-[24px] flex flex-col justify-start items-center ">
                <motion.div
                  className="flex flex-col justify-center items-center gap-[12px]"
                  style={{ y: y9, opacity: opacity9 }}
                >
                  <p className=" font-bold text-[18px] leading-[28px] tracking-[0%] text-center align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent">
                    Real-Time Insights
                  </p>
                  <p className=" font-normal text-[18px] leading-[28px] tracking-[0%] text-center align-bottom bg-custom-gradient-air-text bg-clip-text text-transparent">
                    Get alerts and review activity logs instantly.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
          <motion.div
            className="absolute h-[368px] w-[368px]"
            style={{
              backgroundImage: `url('/images/airhome/card-center.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: imgOpacity1,
            }}
          ></motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default Enhance;

import React from "react";
import {
  // Insights,
  Warranty,
  ResourceHub,
  EsteemedClients,
  ProductComparison,
} from "../../common";
import {
  AdvantageTouchGlass,
  EffortlessTouchGlass,
  ElevatePoshQuartz,
  // InnovationAirTouchGlass,
  SectionAirGlass,
  SmartControlTouchGlass,
  SwitchPoshQuartz,
  WelcomeTouchGlass,
} from "../common";
import {
  elevateList,
  fourthCard,
  head,
  // innovations,
  productComparisonArray,
  secondCard,
  section,
  smartControlList,
  swiperData,
  switchData,
  thirdCard,
} from "./Constants";
function index() {
  return (
    <div className=" w-full">
      <WelcomeTouchGlass head={head} />
      <SectionAirGlass
        section={section}
        secondCard={secondCard}
        thirdCard={thirdCard}
        fourthCard={fourthCard}
      />
      {/* <InnovationAirTouchGlass innovations={innovations} /> */}
      <AdvantageTouchGlass swiperData={swiperData} />
      <SwitchPoshQuartz switchData={switchData} />

      <Warranty />
      <SmartControlTouchGlass smartControlList={smartControlList} />
      <ProductComparison productComparisonArray={productComparisonArray} />
      <EffortlessTouchGlass />

      <ResourceHub />
      <EsteemedClients />
      <ElevatePoshQuartz elevateList={elevateList} />
      {/* <Insights /> */}
    </div>
  );
}

export default index;

import React from "react";

function ClockGreenIcon() {
  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.00114V0.334473H16V3.00114H8ZM10.6667 17.6678H13.3333V9.66781H10.6667V17.6678ZM12 28.3345C10.3556 28.3345 8.80534 28.0176 7.34934 27.3838C5.89334 26.75 4.62134 25.8891 3.53334 24.8011C2.44445 23.7123 1.58311 22.4398 0.949335 20.9838C0.315557 19.5278 -0.000887021 17.978 1.86741e-06 16.3345C1.86741e-06 14.69 0.316891 13.1398 0.950669 11.6838C1.58445 10.2278 2.44534 8.95581 3.53334 7.86781C4.62222 6.77892 5.89467 5.91758 7.35067 5.28381C8.80667 4.65003 10.3564 4.33358 12 4.33447C13.3778 4.33447 14.7 4.55669 15.9667 5.00114C17.2333 5.44558 18.4222 6.09003 19.5333 6.93447L21.4 5.06781L23.2667 6.93447L21.4 8.80114C22.2444 9.91225 22.8889 11.1011 23.3333 12.3678C23.7778 13.6345 24 14.9567 24 16.3345C24 17.9789 23.6831 19.5291 23.0493 20.9851C22.4156 22.4411 21.5547 23.7131 20.4667 24.8011C19.3778 25.89 18.1053 26.7514 16.6493 27.3851C15.1933 28.0189 13.6436 28.3354 12 28.3345Z"
        fill="#34C759"
      />
    </svg>
  );
}

export default ClockGreenIcon;

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { NavContext } from "../../contexts";

export function useDotClass(path) {
  const location = useLocation();
  return location?.pathname === path
    ? "w-[9px] h-[9px] bg-[#1E1E1E] rounded-full"
    : "hidden";
}
export function useActiveLink(path) {
  const { isWhiteColor } = useContext(NavContext);
  const location = useLocation();
  return location?.pathname === path
    ? `${
        isWhiteColor ? "[#1E1E1E]" : "text-[#ffffff]"
      } font-semibold  block  text-[16px] leading-[19.09px] tracking-[0%]  hover:cursor-pointer md:p-0 !h-[80px] flex justify-center items-center`
    : `${
        isWhiteColor
          ? "text-[#1E1E1E] opacity-60 hover:opacity-100 "
          : "text-[#FFFFFF] opacity-60 hover:opacity-100"
      } block  font-medium  text-[16px] leading-[19.09px] tracking-[0%] hover:cursor-pointer md:p-0 h-full !h-[80px] flex justify-center items-center`;
}
export function useActiveLinkProduct(path) {
  const { isWhiteColor } = useContext(NavContext);
  const location = useLocation();
  return location?.pathname?.includes(path)
    ? `${
        isWhiteColor ? "[#1E1E1E]" : "text-[#ffffff]"
      } font-semibold  block  text-[16px] leading-[19.09px] tracking-[0%]  hover:cursor-pointer md:p-0 !h-[80px] flex justify-center items-center`
    : `${
        isWhiteColor
          ? "text-[#1E1E1E] opacity-60 hover:opacity-100 "
          : "text-[#FFFFFF] opacity-60 hover:opacity-100"
      } block  font-medium  text-[16px] leading-[19.09px] tracking-[0%] hover:cursor-pointer md:p-0 h-full !h-[80px] flex justify-center items-center`;
}
export function useActiveScreenLink(path) {
  const location = useLocation();
  return location?.pathname === path
    ? "font-semibold text-[#1E1E1E] block  text-[16px] leading-[19.09px] tracking-[0%] text-[#1E1E1E80] hover:cursor-pointer md:p-0 py-[12px] flex flex-row justify-start items-center gap-[10px]"
    : "block  font-medium hover:text-[#1E1E1E] text-[16px] leading-[19.09px] tracking-[0%] text-[#1E1E1E80] hover:cursor-pointer md:p-0 py-[12px] flex flex-row justify-start items-center gap-[10px]";
}
export function useDotProductClass(path) {
  const location = useLocation();
  return location?.pathname === path
    ? "w-[9px] h-[9px] bg-[#1E1E1E] rounded-full"
    : "w-[9px] h-[9px] bg-[#1E1E1E80] rounded-full";
}

export const usGoToRoute = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0 });
  }, 100);
};

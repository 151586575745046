import React from 'react'

function FramelessGlass() {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.166 5V9C21.166 9.13261 21.1133 9.25979 21.0196 9.35355C20.9258 9.44732 20.7986 9.5 20.666 9.5C20.5334 9.5 20.4062 9.44732 20.3125 9.35355C20.2187 9.25979 20.166 9.13261 20.166 9V5.5H16.666C16.5334 5.5 16.4062 5.44732 16.3125 5.35355C16.2187 5.25979 16.166 5.13261 16.166 5C16.166 4.86739 16.2187 4.74021 16.3125 4.64645C16.4062 4.55268 16.5334 4.5 16.666 4.5H20.666C20.7986 4.5 20.9258 4.55268 21.0196 4.64645C21.1133 4.74021 21.166 4.86739 21.166 5ZM8.66602 16.5H5.16602V13C5.16602 12.8674 5.11334 12.7402 5.01957 12.6464C4.9258 12.5527 4.79862 12.5 4.66602 12.5C4.53341 12.5 4.40623 12.5527 4.31246 12.6464C4.21869 12.7402 4.16602 12.8674 4.16602 13V17C4.16602 17.1326 4.21869 17.2598 4.31246 17.3536C4.40623 17.4473 4.53341 17.5 4.66602 17.5H8.66602C8.79862 17.5 8.9258 17.4473 9.01957 17.3536C9.11334 17.2598 9.16602 17.1326 9.16602 17C9.16602 16.8674 9.11334 16.7402 9.01957 16.6464C8.9258 16.5527 8.79862 16.5 8.66602 16.5ZM25.166 2V20C25.166 20.3978 25.008 20.7794 24.7267 21.0607C24.4454 21.342 24.0638 21.5 23.666 21.5H1.66602C1.26819 21.5 0.88666 21.342 0.605356 21.0607C0.324051 20.7794 0.166016 20.3978 0.166016 20V2C0.166016 1.60218 0.324051 1.22064 0.605356 0.93934C0.88666 0.658035 1.26819 0.5 1.66602 0.5H23.666C24.0638 0.5 24.4454 0.658035 24.7267 0.93934C25.008 1.22064 25.166 1.60218 25.166 2ZM24.166 2C24.166 1.86739 24.1133 1.74021 24.0196 1.64645C23.9258 1.55268 23.7986 1.5 23.666 1.5H1.66602C1.53341 1.5 1.40623 1.55268 1.31246 1.64645C1.21869 1.74021 1.16602 1.86739 1.16602 2V20C1.16602 20.1326 1.21869 20.2598 1.31246 20.3536C1.40623 20.4473 1.53341 20.5 1.66602 20.5H23.666C23.7986 20.5 23.9258 20.4473 24.0196 20.3536C24.1133 20.2598 24.166 20.1326 24.166 20V2Z" fill="white" />
        </svg>


    )
}

export default FramelessGlass
import React from "react";

function SmartIntegration() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1675 35.9996H24.1875M18.5115 30.3436C20.0117 28.8438 22.0462 28.0013 24.1675 28.0013C26.2888 28.0013 28.3233 28.8438 29.8235 30.3436M12.8535 24.6856C14.3393 23.1998 16.1031 22.0212 18.0444 21.217C19.9857 20.4129 22.0663 19.999 24.1675 19.999C26.2687 19.999 28.3494 20.4129 30.2906 21.217C32.2319 22.0212 33.9958 23.1998 35.4815 24.6856"
        stroke="#1E1E1E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19727 19.0295C16.5693 9.65552 31.7653 9.65552 41.1973 19.0295"
        stroke="#1E1E1E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SmartIntegration;

import { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Lottie from "lottie-react";
import arrowClickData from "../../assets/js/arrowclick.json";
import handClickData from "../../assets/js/handclick.json";
import {
  EveningIcon,
  MoringIcon,
  NightIcon,
  TheaterIcon,
} from "../../assets/svg";

const VideoPlayer = () => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [arrowVisible, setArrowVisible] = useState(false);
  const [isHeading, setISHeading] = useState(true);
  const hasTriggered = useRef(false);

  const timeRanges = {
    night: { start: 0, end: 3.9 },
    theater: { start: 4, end: 5.5 },
    morning: { start: 5.5, end: 9.5 },
    evening: { start: 9.5, end: 12 },
  };

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"], // Start animation when div enters & stops when it leaves
  });

  const textOpacity = useTransform(scrollYProgress, [0.1, 0.4, 0.5], [1, 1, 0]);

  const playSegmentWithFastForward = (targetLabel) => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    const target = timeRanges[targetLabel];

    let fastForwardEnd = target.start;
    let playEnd = target.end;

    if (targetLabel === "morning") {
      fastForwardEnd = 5.9; // Special case for morning button
      playEnd = 9.5;
    }

    const startFastForward = () => {
      video.playbackRate = 4; // Speed up
      video.play();

      const fastForwardCheck = () => {
        if (video.currentTime >= fastForwardEnd) {
          video.playbackRate = 1; // Reset to normal speed
          video.removeEventListener("timeupdate", fastForwardCheck);
          startNormalPlay();
        }
      };

      video.addEventListener("timeupdate", fastForwardCheck);
    };

    const startNormalPlay = () => {
      const normalPlayCheck = () => {
        if (video.currentTime >= playEnd) {
          video.pause();
          video.removeEventListener("timeupdate", normalPlayCheck);
        }
      };
      video.addEventListener("timeupdate", normalPlayCheck);
    };

    // If already before fastForwardEnd → fast forward
    if (video.currentTime < fastForwardEnd) {
      startFastForward(); // ✅ Removed unnecessary self-assignment
    } else {
      // If already past fastForwardEnd → start normal
      video.currentTime = fastForwardEnd;
      startNormalPlay();
      video.play();
    }
  };

  useEffect(() => {
    let timeoutId;

    const unsubscribe = scrollYProgress.on("change", (latest) => {
      if (latest >= 0.3 && !hasTriggered.current) {
        setArrowVisible(true);

        if (isActive === false) {
          timeoutId = setTimeout(() => {
            playSegmentWithFastForward("night");
            setIsActive("night");
            setISHeading(false);
          }, 3000);
        }

        hasTriggered.current = true;
      }
    });

    return () => {
      clearTimeout(timeoutId);
      unsubscribe();
    };
  }, [scrollYProgress, isActive]);

  // Unlock video playback on iOS (if needed)
  useEffect(() => {
    const unlockVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().then(() => {
          videoRef.current.pause();
          window.removeEventListener("touchstart", unlockVideo);
        });
      }
    };
    window.addEventListener("touchstart", unlockVideo);
    return () => window.removeEventListener("touchstart", unlockVideo);
  }, []);

  const handleClick = (label) => {
    setIsActive(label);
    setArrowVisible(false);
    setISHeading(false);
    playSegmentWithFastForward(label);
  };
  return (
    <div ref={containerRef} style={{ height: "200vh" }}>
      <motion.div className="sticky top-0 flex justify-center items-center relative w-full h-screen">
        <video
          ref={videoRef}
          className="absolute inset-0 w-full h-full object-cover pointer-events-none"
          muted
          playsInline
          preload="auto"
          controlsList="nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
        >
          <source
            src="/images/home/living_room_animation.mp4"
            type="video/mp4"
          />
        </video>

        <div
          className="grid grid-cols-2 gap-2 lg:gap-4 absolute bottom-[50px] lg:bottom-[100px] right-[5%] lg:right-[10%]  rounded-lg p-[24px]"
          style={{
            background:
              "linear-gradient(134.06deg, #505050 0%, #000000 102.74%)",
          }}
        >
          <button
            onClick={() => handleClick(Object?.keys(timeRanges)[0])}
            className="px-[24px] py-[12px] rounded-lg text-white relative"
          >
            <NightIcon active={isActive === Object?.keys(timeRanges)[0]} />
            {arrowVisible && (
              <div className="cursor-pointer w-[60px] absolute  top-0 left-1/2 -translate-x-1/2 ">
                <Lottie animationData={handClickData} loop={true} />
              </div>
            )}
          </button>
          <button
            onClick={() => handleClick(Object.keys(timeRanges)[1])}
            className="px-[24px] py-[12px] rounded-lg text-white"
          >
            <TheaterIcon active={isActive === Object?.keys(timeRanges)[1]} />
          </button>{" "}
          <button
            onClick={() => handleClick(Object.keys(timeRanges)[2])}
            className="px-[24px] py-[12px] rounded-lg text-white"
          >
            <MoringIcon active={isActive === Object?.keys(timeRanges)[2]} />
          </button>{" "}
          <button
            onClick={() => handleClick(Object.keys(timeRanges)[3])}
            className="px-[24px] py-[12px] rounded-lg text-white"
          >
            <EveningIcon active={isActive === Object?.keys(timeRanges)[3]} />
          </button>
        </div>

        {/* text content */}
        {isHeading && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            style={{ opacity: textOpacity }}
            className="absolute top-[10%] lg:left-[10%]  max-w-[1000px] p-[24px] "
          >
            <motion.h1
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
              className="font-semibold text-[24px] lg:text-[56px] leading-[120%] tracking-normal text-start text-[#ffffff]"
            >
              Experience Custom Scenes - Control with a Tap!
            </motion.h1>
          </motion.div>
        )}

        {arrowVisible && (
          <motion.div className="cursor-pointer w-[30vh] h-[60vw] lg:w-[20vw] lg:h-[20vh] absolute bottom-[220px] lg:right-[20%]  rotate-[90deg] lg:rotate-[20deg] ">
            <Lottie
              animationData={arrowClickData}
              loop={false}
              onComplete={() => {
                setTimeout(() => {
                  setArrowVisible(false);
                }, 1000);
              }}
            />
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default VideoPlayer;

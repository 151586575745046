import React from "react";

function EnhancedSecurity() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 39.8C27.7333 38.8 30.4333 36.8253 32.6 33.876C34.7667 30.9266 36.0333 27.6346 36.4 24H24.5V8.28798L13.308 12.442C13.0507 12.5446 12.852 12.6986 12.712 12.904C12.5707 13.1093 12.5 13.34 12.5 13.596V23.062C12.5 23.3206 12.5333 23.6333 12.6 24H24.5V39.8ZM24.5 41.684C24.3187 41.684 24.1273 41.6673 23.926 41.634C23.7247 41.6006 23.538 41.5506 23.366 41.484C19.4287 39.984 16.3 37.44 13.98 33.852C11.66 30.264 10.5 26.38 10.5 22.2V13.634C10.5 12.954 10.6967 12.3386 11.09 11.788C11.4833 11.2373 11.9867 10.8373 12.6 10.588L23.37 6.58798C23.7593 6.44664 24.136 6.37598 24.5 6.37598C24.864 6.37598 25.2413 6.44664 25.632 6.58798L36.4 10.588C37.0133 10.8373 37.5167 11.2373 37.91 11.788C38.3033 12.3386 38.5 12.954 38.5 13.634V22.2C38.5 26.38 37.34 30.264 35.02 33.852C32.7 37.44 29.5713 39.984 25.634 41.484C25.4607 41.5506 25.274 41.6006 25.074 41.634C24.874 41.6673 24.6827 41.684 24.5 41.684Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default EnhancedSecurity;

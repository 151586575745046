import React from "react";

function SuccessStories() {
  return (
    <div className="items-center px-[20px] py-[40px] lg:px-[75px] lg:py-[80px] bg-[#0E0E0E] ">
      <div className="grid grid-cols-1 gap-[30px] lg:gap-[68px]">
        <div className=" grid grid-cols-1">
          <h1 className="font-inter font-semibold text-left text-[40px] leading-[40px] lg:text-[64px] lg:leading-[64px] tracking-[-0%] text-[#F4F4F4]">
            Success Stories
          </h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[16px]">
          <div
            style={{
              background:
                "linear-gradient(145.42deg, #00F0FF -19.1%, #0066FF 105.1%)",
            }}
            className="rounded-[4px] flex"
          >
            <div className="flex flex-col justify-center items-center px-[40px] h-[142px] lg:h-[202px] ">
              <img
                className="h-[100px] w-[100px] lg:h-[125px] lg:w-[125px]"
                src="/images/rocket.png"
                alt="rocket"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col justify-center items-center px-[10px] gap-[20px]">
              <p className="w-full font-inter text-left font-bold text-[24px] leading-[36px] lg:text-[48px] lg:leading-[72px] tracking-[0] text-[#F4F4F4]">
                3L+
              </p>
              <p className="w-full font-semibold text-left text-[14px] leading-[28px] lg:text-[24px] lg:leading-[36px] tracking-[0] text-[#F4F4F4]">
                Smart Products in Action
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-[16px] h-[142px] lg:h-[202px]">
            <div className="w-full flex flex-col justify-center items-center bg-[#2D2D2D] rounded-[4px] text-white p-[10px] lg:p-[20px] h-[140px] lg:h-[200px] gap-[20px]">
              <p className="w-full font-inter text-left font-bold text-[24px] leading-[36px] lg:text-[48px] lg:leading-[72px] tracking-[0]">
                25K+
              </p>
              <p className="w-full font-semibold text-left text-[14px] leading-[28px] lg:text-[24px] lg:leading-[36px] tracking-[0] text-[#CCCCCC]">
                Happy Customers
              </p>
            </div>

            <div className="w-full flex flex-col justify-center items-center bg-[#2D2D2D] rounded-[4px] text-white p-[10px] lg:p-[20px] h-[140px] lg:h-[200px] gap-[20px]">
              <p className="w-full font-inter text-left font-bold text-[24px] leading-[36px] lg:text-[48px] lg:leading-[72px] tracking-[0]">
                1000+
              </p>
              <p className="w-full font-semibold text-left text-[14px] leading-[28px] lg:text-[24px] lg:leading-[36px] tracking-[0] text-[#CCCCCC]">
                Allies Empowered
              </p>
            </div>
          </div>

          <div className="rounded-[4px] bg-[#F4F4F4] flex justify-between ">
            <div className="flex flex-col justify-center items-center px-[40px] w-full h-[142px] lg:h-[202px]  gap-[20px]">
              <div className="flex justify-between items-center w-full">
                <p className="w-full font-inter text-left font-bold text-[24px] leading-[36px] lg:text-[48px] lg:leading-[72px] tracking-[0]">
                  2200+ Reviews
                </p>
                <img
                  className="h-[54px] w-[54px] lg:h-[90px] lg:w-[90px]"
                  src="/images/google.png"
                  alt="start"
                  loading="lazy"
                />
              </div>
              <div className="flex items-center text-left w-full gap-[10px]">
                <p className=" font-bold text-[14px] leading-[28px] lg:text-[24px] lg:leading-[36px] tracking-[16%] text-left text-[#585858]">
                  4.9
                </p>

                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <img
                      key={index}
                      className=""
                      src="/images/ratings.png"
                      alt="star"
                      loading="lazy"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[4px] bg-[#F4F4F4] flex justify-between ">
            <div className="flex flex-col justify-center items-center px-[40px] w-full h-[142px] lg:h-[202px]  gap-[20px]">
              <div className="flex justify-between items-center w-full">
                <p className="w-full font-inter text-left font-bold text-[24px] leading-[36px] lg:text-[48px] lg:leading-[72px] tracking-[0]">
                  125+ cities
                </p>
                <img
                  className="h-[64px] w-[64px] lg:h-[100px] lg:w-[100px]"
                  src="/images/star.png"
                  alt="start"
                  loading="lazy"
                />
              </div>
              <p className="w-full font-normal text-[14px] leading-[28px] lg:text-[24px] lg:leading-[36px] tracking-[16%] text-left text-[#585858]">
                Smart Automation Everywhere
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessStories;

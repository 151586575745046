import React from "react";

const InsightsCard = ({ item }) => {
  return (
    <div className="">
      <div className=" flex justify-center items-center">
        <img
          src={item?.img}
          alt="Insights one"
          className="rounded-[8px] w-full h-full"
          loading="lazy"
        />
      </div>
      <div className=" text-left pt-[30px] px-[10px]">
        <div className="flex items-center gap-[20px] ">
          {" "}
          <p className="w-[150px] h-[27px] gap-[10px] px-[12px] py-[4px] rounded-[4px] text-[12px] leading-[18.5px] tracking-[0px] bg-[#1E1E1E0A]">
            Product Management
          </p>
          <div className="h-[2px] w-[30px] bg-[#1E1E1E66]"></div>
          <p className="font-medium text-[11.56px] leading-[18.5px] tracking-[0px]">
            {" "}
            Oct 17, 2023
          </p>
        </div>
        <p className="pt-[20px] font-semibold text-[24px] leading-[39.2px] tracking-[0px]">
          {item?.head}
        </p>

        <p className=" pt-[10px] font-light italic text-[16px] leading-[24px] tracking-[0px] text-[#3F4654]">
          {item?.text}
        </p>
      </div>
    </div>
  );
};
const insightsArray = [
  {
    img: "/images/insightstwo.png",
    head: "Smart Lighting for Every Room",
    text: "Learn how the Posh Series’ smart lighting options bring versatility and convenience to any room. Control lights remotely or automate them with ease, creating the perfect ambiance for every occasion.",
  },
  {
    img: "/images/insightsthree.png",
    head: "AirHome Integration",
    text: "Explore the seamless integration of the Posh Series with AirHome. With features like geofencing, automation, and user-specific functions.",
  },
  {
    img: "/images/insightsfour.png",
    head: "Safety & Style with Posh",
    text: "Combine safety and aesthetics with the Posh Series. With overcurrent protection, water resistance, and durable design, this system ensures reliable operation without sacrificing style.",
  },
];
function Insights() {
  return (
    <div className="grid grid-cols-1  p-[24px] lg:py-[90px] lg:px-[164px]  gap-[24px] lg:gap-[64px]">
      <div className="lg:px-[56px]">
        <h1 className="text-[#1E1E1E] font-semibold text-[36px] leading-[44px] lg:text-[64px] lg:leading-[70.4px] tracking-[0%] text-center">
          Insights & Inspiration: The Quartz Series in Action
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-[30px] py-[24px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] lg:gap-[30px]">
          <div className=" flex justify-center items-center">
            <img
              src="/images/insightsone.png"
              alt="Insights one"
              className="rounded-[8px] w-full h-full"
              loading="lazy"
            />
          </div>
          <div className=" text-left">
            <div className="flex items-center gap-[20px] ">
              {" "}
              <p className="w-[150px] h-[27px] gap-[10px] px-[12px] py-[4px] rounded-[4px] text-[12px] leading-[18.5px] tracking-[0px] bg-[#1E1E1E0A]">
                Product Management
              </p>
              <div className="h-[2px] w-[30px] bg-[#1E1E1E66]"></div>
              <p className="font-medium text-[11.56px] leading-[18.5px] tracking-[0px]">
                {" "}
                Oct 17, 2023
              </p>
            </div>
            <p className="pt-[20px] font-semibold text-[24px] leading-[39.2px] tracking-[0px]">
              Transform Your Home with Posh Series
            </p>

            <p className=" pt-[10px] font-light italic text-[16px] leading-[24px] tracking-[0px] text-[#3F4654]">
              Discover how the Posh Series can elevate the look and
              functionality of your home with its advanced technology and sleek
              design. Perfect for modern living spaces, it offers both elegance
              and smart control.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3  gap-[30px]">
          {insightsArray?.map((item, index) => {
            return <InsightsCard key={index} item={item} />;
          })}
        </div>
        <div className="lg:pt-2 flex justify-center items-center">
          <button className="bg-transparent hover:bg-[#1E1E1E] text-[#1E1E1E] font-semibold hover:text-white py-[12px] px-[24px] border border-[#1E1E1E] hover:border-transparent rounded-[8px]">
            Show more...
          </button>
        </div>
      </div>
    </div>
  );
}

export default Insights;

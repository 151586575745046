import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { NextIcon, PrevIcon } from "../../../assets/svg";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Card = ({ item }) => {
  return (
    <div
      className="grid grid-cols-1 px-[33px] pt-[45px] gap-2 h-[450px] lg:h-[500px] overflow-hidden rounded-[8px]"
      style={{ backgroundColor: item.bg }}
    >
      <motion.p
        className="font-medium text-[12px] leading-[14.4px] lg:text-[14px] lg:leading-[16.8px] tracking-[1px] text-center text-[#96969B]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        {item.name}
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        style={{ color: item.textColor }}
        className="font-bold text-[20px] leading-[22.6px] tracking-[0.19px] lg:text-[20px] lg:leading-[27.12px] lg:tracking-[0.2px] text-center"
      >
        {item.text}
      </motion.p>

      <div className="flex justify-center items-center ">
        <img src={item?.img} alt={item?.img} />
      </div>
    </div>
  );
};

function SectionPoshQuartz({
  head,
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  swipperList,
  masterButton,
  twoWay,
}) {
  const swiperRef = useRef(null);

  return (
    <div className="grid grid-cols-1 p-[24px] lg:px-[165px] lg:py-[150px] gap-[64px] bg-custom-bg-white">
      <motion.div
        className="grid grid-cols-1 gap-[16px] lg:gap-[24px] px-[0px] lg:px-[80px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <h1 className="custom-black text-[36px] leading-[36px]  lg:text-[64px] lg:leading-[64px] font-semibold text-center">
          {head.label}
        </h1>
        <p className="custom-black font-semibold text-[18px] leading-[23.4px] tracking-[0] lg:text-[24px] lg:leading-[33.6px] lg:tracking-[1%] text-center opacity-80">
          {head.text}
        </p>
      </motion.div>
      <div className="grid grid-cols-1 gap-[18px] lg:gap-[30px]">
        <div className="grid grid-cols-1 gap-[18px] lg:gap-[30px] rounded-[32px] shadow-custom-box-shadow bg-white px-[10px] lg:px-[84px] pb-[16px] pt-[42px] pt-[140px]">
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <div className="flex flex-row items-center gap-1 lg:gap-4">
              <h1 className="font-inter font-bold text-[23px] leading-[36.4px] tracking-[-0.1px] lg:text-[56px] lg:leading-[72.8px] lg:tracking-[-0.28px] text-center">
                {firstCard.firstLabel}
              </h1>
              <img
                src={firstCard?.icon}
                alt={firstCard?.icon}
                loading="lazy"
                className="w-[24px] h-[24px] lg:w-auto lg:h-auto"
              />
            </div>
            <h1 className="font-inter font-bold text-[23px] leading-[36.4px] tracking-[-0.1px] lg:text-[56px] lg:leading-[72.8px] lg:tracking-[-0.28px] text-center">
              {firstCard?.secondLabel}
            </h1>
          </motion.div>

          <motion.div
            className="flex justify-center items-center px-[60px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <motion.img
              animate={{
                y: [0, -10, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              src={firstCard?.img}
              alt={firstCard?.img}
              loading="lazy"
            />
          </motion.div>
        </div>

        <div className="relative grid grid-cols-1 gap-[18px] lg:gap-[30px] rounded-[32px] shadow-custom-box-shadow bg-[#252525] px-[10px] lg:px-[84px] pt-[140px]">
          <motion.div
            className=" absolute top-[30px] lg:top-[50px] left-0 right-0 flex flex-col items-center px-[10px] lg:px-[84px] "
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <h1 className="font-inter font-bold text-[23px] leading-[36.4px] tracking-[-0.1px] lg:text-[56px] lg:leading-[72.8px] lg:tracking-[-0.28px] text-center text-[#FFFFFF]">
              {masterButton?.label}
            </h1>
            <p className="font-medium text-[12px] lg:text-[20px] leading-[130%] tracking-[0%] text-center align-middle text-[#CCCCCC]">
              {masterButton?.text}
            </p>
          </motion.div>

          <div className="md:[30px] lg:pt-[60px]">
            <video
              src={masterButton?.video} // Make sure `thirdCard.video` holds the video URL
              className=" w-full h-full object-cover rounded-[32px]"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[18px] lg:gap-[30px]">
          <div className="rounded-[32px] shadow-custom-box-shadow bg-custom-bg-black text-white p-[30px] lg:px-[48px]  ">
            <div className="flex items-center  h-full">
              <motion.div
                className=""
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                {" "}
                {secondCard?.label?.map((line, index) => (
                  <div className="flex" key={index}>
                    <h1 className="font-inter font-bold text-[36px] leading-[50.6px] lg:text-[72px] lg:leading-[79.2px] tracking-[-0.28px] text-left">
                      {line}
                    </h1>
                    {index === secondCard?.label?.length - 1 && (
                      <img
                        src={secondCard?.img}
                        alt="Decoration"
                        className="w-[56px] h-[56px] lg:w-[80px] lg:h-[80px]"
                      />
                    )}
                    <br />
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
          <div className="rounded-[32px] shadow-custom-box-shadow">
            <video
              src={thirdCard.video} // Make sure `thirdCard.video` holds the video URL
              className="w-full rounded-t-[32px]"
              autoPlay
              loop
              muted
              playsInline
            />
            <div className="lg:pr-[80px] px-[20px] lg:px-[60px]  py-[26px] lg:py-[40px]">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                {thirdCard?.text?.map((line, index) => (
                  <div className="flex" key={index}>
                    <p className="font-inter font-bold text-[32px] leading-[35.2px] tracking-[0px] lg:text-[48px] lg:leading-[52.8px]  text-left">
                      {line}
                    </p>
                    {index === thirdCard?.text?.length - 1 && (
                      <img
                        src={thirdCard?.img}
                        alt="Decoration"
                        className="w-[38px] h-[38px] lg:w-[56px] lg:h-[56px]"
                      />
                    )}
                    <br />
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </div>

        <div className="relative grid  grid-cols-1 gap-[18px] lg:gap-[30px] rounded-[32px] shadow-custom-box-shadow  ">
          <motion.div
            className=" absolute w-[160px] lg:w-[700px] top-[10px] lg:top-[50px] right-0 flex flex-col justify-end px-[10px] lg:px-[84px] "
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <h1 className="font-inter font-bold text-[18px] leading-[36.4px] tracking-[-0.1px] lg:text-[56px] lg:leading-[72.8px] lg:tracking-[-0.28px] text-right text-[#FFFFFF]">
              {twoWay?.label}
            </h1>
            <p className="font-medium text-[10px] lg:text-[20px] leading-[130%] tracking-[0%] text-right align-middle text-[#FFFFFF]">
              {twoWay?.text}
            </p>
          </motion.div>

          <div className="">
            <video
              src={twoWay?.video} // Make sure `thirdCard.video` holds the video URL
              className=" w-full h-full object-cover rounded-[32px] "
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>
       

        <div className="relative rounded-[32px] grid grid-cols-1 shadow-custom-box-shadow bg-black bg-black text-white gap-4  w-full h-[40vh] lg:h-full">
          <video
            className=" w-full h-full object-cover rounded-[32px] z-0"
            src={fourthCard.video}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="absolute  left-0 bottom-4 flex flex-col h-full justify-end gap-[6px]">
            <div className="">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                className="flex items-center justify-center lg:justify-start px-[20px] lg:px-[80px] text-center lg:text-left"
              >
                <p className=" font-inter font-bold text-[18px] leading-[40px] tracking-[0px] lg:text-[64px] lg:leading-[100px] lg:tracking-[-2.16px]  ">
                  {fourthCard?.label}
                </p>
                <img
                  src={fourthCard?.imgEnd}
                  alt="Decoration"
                  className="w-[18px] h-[18px] lg:w-[60px] lg:h-[60px]"
                />
                <br />
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
            >
              {" "}
              <p className="  bg-custom-gradient-text bg-clip-text text-transparent font-inter font-medium text-[14px] lg:text-[40px] leading-[120%] tracking-[-0.41%] align-middle px-[20px] lg:px-[80px] text-center lg:text-left">
                {fourthCard.text}
              </p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-[24px]">
        <motion.p
          className="font-semibold text-[36px] leading-[40px] lg:text-[64px] lg:leading-[64px] tracking-[0px] text-center px-[20px] lg:px-[100px]"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          {swipperList?.head}
        </motion.p>

        <div className="relative hidden lg:block">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            pagination={{ clickable: true }}
            navigation={false} // Disable default navigation
            modules={[Navigation]}
            allowTouchMove={false} // Prevent touch-based slide changing
            simulateTouch={false} // Disable mouse drag to prevent unwanted slide changes
            onSwiper={(swiper) => (swiperRef.current = swiper)} // Store swiper instance
            direction="horizontal"
            style={{ width: "100%", touchAction: "auto" }}
            className="mySwiper "
          >
            {swipperList?.list?.map((item, index) => (
              <SwiperSlide key={index} className="">
                <Card item={item} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}

          <div className=" grid grid-cols-1 pt-[30px]">
            <div className="flex justify-center items-center gap-4 ">
              <button
                className="custom-prev h-[36px] w-[36px] rounded-full bg-[#FFFFFF] cursor-pointer"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <PrevIcon />
              </button>
              <button
                className="custom-next h-[36px] w-[36px] rounded-full bg-[#FFFFFF] cursor-pointer"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <NextIcon />
              </button>
            </div>
          </div>
        </div>
        {/* mobile view */}
        <div className="relative lg:hidden block">
          <div className="grid grid-cols-1 gap-[18px]">
            {swipperList?.list?.map((item, index) => (
              <div key={index} className="">
                <Card item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionPoshQuartz;

import React from 'react'

function Water() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 19.9998C25 25.5229 21.5231 28.9998 16 28.9998C10.4769 28.9998 7 25.5229 7 19.9998C7 14.0729 13.4519 6.07168 15.4306 3.75731C15.501 3.67508 15.5884 3.60907 15.6867 3.56381C15.7851 3.51855 15.8921 3.49512 16.0003 3.49512C16.1086 3.49512 16.2155 3.51855 16.3139 3.56381C16.4122 3.60907 16.4996 3.67508 16.57 3.75731C18.5481 6.07168 25 14.0729 25 19.9998Z" stroke="#1E1E1E" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M21.5 20.5C21.5 21.6935 21.0259 22.8381 20.182 23.682C19.3381 24.5259 18.1935 25 17 25" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Water
import React, { useContext } from "react";
import { ContactModalContext } from "../../config/contexts";

const horizontalCardArray = [
  {
    img: "/images/airhomeapp.png",
    label: "AirHome Mobile App",
    text: "On-the-Go Management.",
    subText:
      " Control your home from anywhere with the AirHome Mobile App. Manage lights, fan, TV, bell, curtains, and more.",
  },
  {
    img: "/images/irremote.png",
    label: "IR Remotes",
    text: "Traditional Control.",
    subText:
      " Control your smart home from anywhere with remote access. Monitor and adjust settings on the go.",
  },
];

const verticalCardArray = [
  {
    img: "/images/voicecontrol.png",
    label: "Voice Control",
    text: "Hands-Free Convenience.",
    subText:
      " Use voice commands with Alexa or Google Home to adjust your home settings. Manage lights, TV, thermostat, and more.",
  },
  {
    img: "/images/scenepanel.png",
    label: "Scene Panel",
    text: "Simplified Control, Multiple Devices.",
    subText:
      " Easily group multiple devices and control them with a single tap. Simplify your home automation with effortless grouping and management.",
  },
];
const verticalCardPoshArray = [
  {
    img: "/images/voicecontrol.png",
    label: "Voice Control",
    text: "Hands-Free Convenience.",
    subText:
      " Use voice commands with Alexa or Google Home to adjust your home settings. Manage lights, TV, thermostat, and more.",
  },
  {
    img: "/images/posh/poshscenepanel.png",
    label: "Scene Panel",
    text: "Simplified Control, Multiple Devices.",
    subText:
      " Easily group multiple devices and control them with a single tap. Simplify your home automation with effortless grouping and management.",
  },
];
const verticalCardQuartzArray = [
  {
    img: "/images/voicecontrol.png",
    label: "Voice Control",
    text: "Hands-Free Convenience.",
    subText:
      " Use voice commands with Alexa or Google Home to adjust your home settings. Manage lights, TV, thermostat, and more.",
  },
  {
    img: "/images/quartz/quartzscenepanel.png",
    label: "Scene Panel",
    text: "Simplified Control, Multiple Devices.",
    subText:
      " Easily group multiple devices and control them with a single tap. Simplify your home automation with effortless grouping and management.",
  },
];

const HorizontalCard = ({ img, label, text, subText }) => {
  return (
    <div className=" px-[24px] pt-[24px] lg:px-[65px] lg:pt-[52px]  grid grid-cols-1 bg-[#ffffff] rounded-[8px] gap-[10px]">
      <div className=" text-left">
        <h1 className="font-inter font-semibold text-[16px] leading-[24px] lg:text-[22px] lg:leading-[33px] tracking-[0em]  bg-gradient-to-r from-[#00F0FF] to-[#0066FF] inline-block text-transparent bg-clip-text">
          {label}
        </h1>
        <p className="font-inter font-medium lg:text-[20px] lg:leading-[26px] text-[12px] leading-[15.6px] tracking-[0em] text-[#6E6E73]">
          <span className="text-[#1e1e1e]">{text}</span>
          {subText}
        </p>
      </div>
      <div className="">
        <img
          className="w-[300px] h-[310px] lg:h-[400px] lg:w-[400px]"
          src={img}
          alt={text}
          loading="lazy"
        />
      </div>
    </div>
  );
};

const VerticalCard = ({ img, label, text, subText }) => {
  return (
    <div className=" px-[24px] py-[24px] lg:px-[65px]  lg:py-[65px] grid grid-cols-1 lg:grid-cols-2 bg-[#ffffff] rounded-[8px] gap-[10px] place-items-center">
      <div className=" text-left lg:order-last">
        <h1 className="font-inter font-semibold text-[16px] leading-[24px] lg:text-[22px] lg:leading-[33px] tracking-[0em]  bg-gradient-to-r from-[#00F0FF] to-[#0066FF] inline-block text-transparent bg-clip-text">
          {label}
        </h1>
        <p className="font-inter font-medium lg:text-[20px] lg:leading-[26px] text-[12px] leading-[15.6px] tracking-[0em] text-[#6E6E73]">
          <span className="text-[#1e1e1e]">{text}</span>
          {subText}
        </p>
      </div>
      <div className="lg:order-first">
        <img
          className="w-[490px] h-[240px] lg:h-[280px] lg:w-[530px]"
          src={img}
          alt={text}
          loading="lazy"
        />
      </div>
    </div>
  );
};

function SmartControl({ isContact, series }) {
  const { openModal } = useContext(ContactModalContext);

  const vertical =
    series === "posh"
      ? verticalCardPoshArray
      : series === "quartz"
      ? verticalCardQuartzArray
      : verticalCardArray;
  return (
    <div className=" items-center px-[20px] py-[40px] lg:px-[140px] lg:py-[60px] bg-custom-bg-white">
      <div className="grid grid-cols-1 gap-[30px] lg:gap-[60px] bg-custom-bg-white ">
        <div className=" grid grid-cols-1 h-[122px]">
          <h1 className="font-inter font-semibold text-[32px] leading-[32px] lg:text-[64px] lg:leading-[70.4px] tracking-[-0.0041em] text-center">
            <span className="bg-gradient-to-r from-[#00F0FF] to-[#0066FF] inline-block text-transparent bg-clip-text">
              Smart Control
            </span>
            , Wherever You Are
          </h1>

          <p className="font-inter font-medium text-[16px] leading-[22.4px] lg:text-[24px] lg:leading-[36px] tracking-[0em] text-center text-[#808080]">
            Seamless integration with your existing smart home setup.
          </p>
        </div>
        <div className=" grid  grid-cols-1 lg:grid-cols-2 h-[600] gap-[30px] lg:gap[60px]">
          {horizontalCardArray?.map((item, index) => (
            <HorizontalCard
              key={index}
              img={item?.img}
              label={item?.label}
              text={item?.text}
              subText={item?.subText}
            />
          ))}
        </div>
        {vertical?.map((item, index) => (
          <VerticalCard
            key={index}
            img={item?.img}
            label={item?.label}
            text={item?.text}
            subText={item?.subText}
          />
        ))}

        <div
          className=" grid  grid-cols-1"
          style={isContact ? {} : { display: "none" }}
        >
          <div className="w-full flex justify-center items-center">
            <button
              className=" w-full lg:w-[450px]  h-[56px] px-[0px] lg:px-[32px] py-[14px] rounded-[8px] bg-[#1E1E1E] text-white font-inter font-semibold text-[16px] leading-[22.4px] tracking-[-3%] lg:text-[20px] lg:leading-[28px]"
              onClick={() => openModal()}
            >
              Try It Yourself – Schedule a Live Demo!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartControl;

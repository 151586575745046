import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const SuccessCard = ({ name, count, text, img }) => {
  const countRef = useRef(null);

  // useEffect(() => {
  //   // Extract numeric part and suffix
  //   const match = count.match(/^(\d+)(.*)$/);
  //   const numericValue = match ? parseInt(match[1], 10) : 0;
  //   const suffix = match ? match[2] : "";

  //   gsap.fromTo(
  //     countRef.current,
  //     { innerText: 0 },
  //     {
  //       innerText: numericValue,
  //       duration: 2,
  //       ease: "power2.out",
  //       snap: { innerText: 1 }, // Ensures whole numbers
  //       scrollTrigger: {
  //         trigger: countRef.current,
  //         start: "top 80%",
  //         once: true,
  //       },
  //       onUpdate: function () {
  //         countRef.current.innerText = `${Math.round(
  //           this.targets()[0].innerText
  //         )}${suffix}`;
  //       },
  //     }
  //   );
  // }, [count]);
  useEffect(() => {
    // Extract numeric part and suffix
    const match = count.match(/^(\d+)(.*)$/);
    const numericValue = match ? parseInt(match[1], 10) : 0;
    const suffix = match ? match[2] : "";

    if (!countRef.current) return; // Prevent animation if ref is not set

    gsap.fromTo(
      countRef.current,
      { innerText: 0 },
      {
        innerText: numericValue,
        duration: 2,
        ease: "power2.out",
        snap: { innerText: 1 }, // Ensures whole numbers
        scrollTrigger: {
          trigger: countRef.current,
          start: "top 80%",
          once: true,
        },
        onUpdate: function () {
          if (countRef.current) {
            countRef.current.innerText = `${Math.round(
              this.targets()[0].innerText
            )}${suffix}`;
          }
        },
      }
    );
  }, [count]);

  return (
    <motion.div
      className="grid grid-cols-1 rounded-[8px] bg-[#FFFFFF] gap-[24px] px-[14px] py-[18px] lg:py-[32px] lg:px-[24px]"
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
    >
      <h1 className="font-inter font-medium text-[#1E1E1E] text-[12px] leading-[18px] lg:text-[20px] lg:leading-[30px] tracking-[0] text-center ">
        {name}
      </h1>
      <div className="flex flex-wrap justify-between items-center">
        <p
          ref={countRef}
          className="font-inter font-semibold text-[#1E1E1E] text-[24px] leading-[48px] lg:text-[50px] lg:leading-[75px] tracking-[0]"
        >
          0{count.replace(/\d+/g, "")}
        </p>
        {text ? (
          <p className="font-inter font-normal text-[#808080] text-[10px] leading-[18px] lg:text-[20px] lg:leading-[30px] tracking-[0]">
            {text}
          </p>
        ) : (
          <img
            src={img}
            alt={img}
            loading="lazy"
            className="h-[25px] w-[50px] lg:h-[40px] lg:w-[80px]"
          />
        )}
      </div>
    </motion.div>
  );
};

const SuccessArray = [
  { name: "Connected in", count: "125+", text: "Cities" },
  {
    name: "Happy Customers",
    count: "25k+",
    text: "",
    img: "images/customers.png",
  },
  { name: "Empowered by", count: "1000+", text: "Allies" },
  { name: "Automating with", count: "10L+", text: "touch panel" },
];

function SuccessStories() {
  return (
    <div className="grid grid-cols-1 bg-custom-bg-white gap-[24px] lg:gap-[48px] px-[24px] py-[24px] lg:py-[150px] lg:px-[164px]">
      <motion.p
        className="font-inter font-semibold text-left text-[#6E6E73] text-[24px] leading-[28.8px] lg:text-[64px] lg:leading-[64px] tracking-[0]"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
      >
        Success Stories
      </motion.p>
      <div className="grid grid-cols-2 lg:grid-cols-4 bg-custom-bg-white gap-[24px] lg:gap-[48px]">
        {SuccessArray.map((item, index) => (
          <SuccessCard
            key={index}
            name={item.name}
            count={item.count}
            text={item.text}
            img={item.img}
          />
        ))}
      </div>
    </div>
  );
}

export default SuccessStories;

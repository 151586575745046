import React, { useContext, useEffect, useState } from "react";
import { WhitelionIcon, ToggleMenu, ToggleClose } from "../../assets/svg";
import { ContactModalContext } from "../../config/contexts";

function ContactUSNavbar() {
  const { openModal } = useContext(ContactModalContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [active, setActive] = useState("");

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleLinkClick = (link) => {
    setActive(link);
    setIsMobileMenuOpen(false);

    const element = document.querySelector(link);
    if (element) {
      const navbarHeight =
        document.querySelector(".your-navbar-class")?.offsetHeight || 80; // Adjust if needed
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - navbarHeight + 1, // Offset by navbar height + some padding
        behavior: "smooth",
      });
    }
  };

  function useActiveLink(path) {
    return active === path
      ? "font-semibold text-[#1E1E1E] block  text-[16px] leading-[19.09px] tracking-[0%] text-[#1E1E1E80] hover:cursor-pointer md:p-0"
      : "block  font-medium hover:text-[#1E1E1E] text-[16px] leading-[19.09px] tracking-[0%] text-[#1E1E1E80] hover:cursor-pointer md:p-0";
  }

  function useDot(path) {
    return active === path
      ? "w-[9px] h-[9px] bg-[#1E1E1E] rounded-full"
      : "hidden";
  }

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.getElementById("navbar");

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (navbar) {
        if (scrollTop > lastScrollTop) {
          navbar.style.top = "-80px"; // Hide navbar on scroll down
          setIsMobileMenuOpen(false);
        } else {
          navbar.style.top = "0"; // Show navbar on scroll up
        }
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="navbar"
      className="bg-white mt-[0px] h-[80px] lg:h-[80px] sticky top-0 z-50 transition-all duration-1000 lg:px-[80px]"
    >
      <div className=" flex flex-wrap items-center justify-between mx-auto ">
        {/* <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse"> */}
        <div className="pl-4">
          <WhitelionIcon />
        </div>
        {/* </a> */}
        <div className="pr-4">
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden"
            aria-controls="navbar-default"
            aria-expanded={isMobileMenuOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            {!isMobileMenuOpen ? (
              <ToggleMenu isWhiteColor={true} />
            ) : (
              <ToggleClose isWhiteColor={true} />
            )}
          </button>
        </div>
        <div
          className={
            isMobileMenuOpen
              ? "lg:hidden w-full h-screen flex bg-white"
              : "hidden"
          }
        >
          <div className="grid grid-cols-1 gap-4 w-full h-screen">
            <div className="h-[240px] grid grid-cols-1 gap-4 pt-[20px] ">
              <div
                className=" flex justify-between items-center w-full px-[16px]"
                onClick={() => handleLinkClick(".whychooseus-section")}
              >
                <button
                  className={useActiveLink(".whychooseus-section")}
                  aria-current="page"
                >
                  Why Choose Us
                </button>
                <div className={useDot(".whychooseus-section")}></div>
              </div>
              <div
                className=" flex justify-between items-center w-full px-[16px]"
                onClick={() => handleLinkClick(".poshseries-section")}
              >
                <button
                  className={useActiveLink(".poshseries-section")}
                  aria-current="page"
                >
                  Posh
                </button>
                <div className={useDot(".poshseries-section")}></div>
              </div>
              <div
                className=" flex justify-between items-center w-full px-[16px]"
                onClick={() => handleLinkClick(".quartzseries-section")}
              >
                <button
                  className={useActiveLink(".quartzseries-section")}
                  aria-current="page"
                >
                  Quartz
                </button>
                <div className={useDot(".quartzseries-section")}></div>
              </div>
              <div
                className=" flex justify-between items-center w-full px-[16px]"
                onClick={() => handleLinkClick(".testimonials-section")}
              >
                <button
                  className={useActiveLink(".testimonials-section")}
                  aria-current="page"
                >
                  Testimonials
                </button>
                <div className={useDot(".testimonials-section")}></div>
              </div>
              <div className=" flex justify-between items-center w-full px-[16px]">
                {" "}
                <button
                  onClick={() => handleLinkClick(".frequently-section")}
                  className={useActiveLink(".frequently-section")}
                  aria-current="page"
                >
                  FAQs
                </button>
                <div className={useDot(".frequently-section")}></div>
              </div>
            </div>
            <div className=" mt-auto mb-[120px] px-4 ">
              <div
                className="border rounded-lg bg-[linear-gradient(145.42deg,_#00F0FF_-19.1%,_#0066FF_105.1%)] text-center hover:cursor-pointer"
                onClick={() => openModal()}
              >
                <button
                  className="block py-2 px-3 text-white rounded-sm  block py-2 px-4 text-[#1E1E1E80] rounded-lg hover:text-white text-white"
                  aria-current="page"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden w-full lg:flex md:w-auto h-[52px]">
          <ul className="font-medium flex flex-col md:p-0 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse items-center md:items-center">
            <li>
              <button
                onClick={() => handleLinkClick(".whychooseus-section")}
                className={useActiveLink(".whychooseus-section")}
                aria-current="page"
              >
                Why Choose Us
              </button>
            </li>
            <li>
              <button
                onClick={() => handleLinkClick(".poshseries-section")}
                className={useActiveLink(".poshseries-section")}
                aria-current="page"
              >
                Posh
              </button>
            </li>
            <li>
              <button
                onClick={() => handleLinkClick(".quartzseries-section")}
                className={useActiveLink(".quartzseries-section")}
                aria-current="page"
              >
                Quartz
              </button>
            </li>
            <li>
              <button
                onClick={() => handleLinkClick(".testimonials-section")}
                className={useActiveLink(".testimonials-section")}
                aria-current="page"
              >
                Testimonials
              </button>
            </li>
            <li>
              <button
                onClick={() => handleLinkClick(".frequently-section")}
                className={useActiveLink(".frequently-section")}
                aria-current="page"
              >
                FAQs
              </button>
            </li>
            <li className="border rounded-lg bg-[linear-gradient(145.42deg,_#00F0FF_-19.1%,_#0066FF_105.1%)] hover:cursor-pointer">
              <button
                onClick={() => openModal()}
                className="block py-2 px-3 text-white rounded-sm  block py-2 px-4 text-[#1E1E1E80] rounded-lg hover:text-white text-white"
                aria-current="page"
              >
                Contact Us
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default ContactUSNavbar;

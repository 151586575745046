import React, { useContext } from "react";
import AirGlassFilledDark from "./airglass-filled-dark.svg";
import AirGlassFilledLight from "./airglass-filled-light.svg";
import AirGlassOutlineDark from "./airglass-outline-dark.svg";
import AirGlassOutlineLight from "./airglass-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function AirGlassIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? AirGlassFilledLight
      : AirGlassOutlineLight
    : isDefaultHeader
    ? AirGlassFilledDark
    : AirGlassOutlineDark;

  return <img src={iconSrc} alt="airglass Icon" />;
}

export default AirGlassIcon;

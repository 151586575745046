import React from "react";

function PowerButtonIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1.6665V11.3732M17.3466 3.9065C17.3466 3.9065 20.3333 5.84485 20.3333 10.9998C20.3333 13.4752 19.35 15.8492 17.5996 17.5995C15.8493 19.3498 13.4753 20.3332 11 20.3332C8.52461 20.3332 6.15063 19.3498 4.4003 17.5995C2.64996 15.8492 1.66663 13.4752 1.66663 10.9998C1.66663 5.84485 4.65329 3.9065 4.65329 3.9065"
        stroke="#323232"
        strokeWidth="1.63333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PowerButtonIcon;

import React from "react";

function EnergyEfficiency() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8797 4.41999L9.15969 22.98C7.87969 24.14 8.59969 26.28 10.3197 26.44L26.4997 28L16.7997 41.52C16.3597 42.14 16.4197 43 16.9597 43.54C17.5597 44.14 18.4997 44.16 19.1197 43.58L39.8397 25.02C41.1197 23.86 40.3997 21.72 38.6797 21.56L22.4997 20L32.1997 6.47999C32.6397 5.85999 32.5797 4.99999 32.0397 4.45999C31.7569 4.17122 31.372 4.00518 30.9679 3.9977C30.5638 3.99022 30.173 4.14189 29.8797 4.41999Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default EnergyEfficiency;

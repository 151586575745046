import React from "react";
import { motion } from "framer-motion";

const LeadershipArray = [
  {
    img: "images/vishalkukadiya.png",
    alt: "Vishal Kukadiya",
    name: "Vishal Kukadiya",
    degination: "Co-Founder, Director",
    description:
      "As the founder of a smart touch panel company, I am confident in our innovative and high-quality products, and believe that customer satisfaction will drive our success. I am excited about the growth potential of our brand and its positive impact on the market.",
  },
  {
    img: "images/niravdholiya.png",
    alt: "Nirav Dholiya",
    name: "Nirav Dholiya",
    degination: "Co-Founder, Director",
    description:
      "We are excited to embark on the new technology and potential growth. Whitelion has innovative products and a commitment to customer satisfaction. We see growth and expansion in the homes of India.",
  },
  {
    img: "images/jenishkumbhani.png",
    alt: "Jenish Kumbhani",
    name: "Jenish Kumbhani",
    degination: "Co-Founder, Director",
    description:
      "We are excited about the potential for growth in this industry and are committed to bringing innovative and high-quality products to our customers. We believe that our focus on customer satisfaction will be key to our success in this market.",
  },
  {
    img: "images/sajagsheta.png",
    alt: "Sajag Sheta",
    name: "Sajag Sheta",
    degination: "Co-Founder, Director",
    description:
      "We hope that we bring new designs and convenience to Indian homes. By believing in our success and growth, we aim to motivate our team to push forward to change people's lives.",
  },
];

const LeadershipCard = ({ img, name, degination, description, alt }) => {
  return (
    <motion.div
      className="flex flex-col gap-[16px] w-[255px]"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
    >
      <img
        src={img}
        alt={alt}
        className="w-[255px] h-[360px] lg:w-[255px] lg:h-[360px]"
        loading="lazy"
      />
      <div className="flex flex-col gap-[8px] text-left">
        <p className="font-inter font-medium text-[#1E1E1E] text-[24px] leading-[27.12px] tracking-[0.2px]">
          {name}
        </p>

        <p className="font-inter font-medium text-[#1E1E1E] text-[14px] leading-[16.8px] tracking-[0.14px]">
          {degination}
        </p>
        <p className="font-inter font-normal text-[#8C8C91] text-[14px] leading-[18.2px] tracking-[0.14px]">
          {description}
        </p>
      </div>
    </motion.div>
  );
};
function Leadership() {
  return (
    <div className="grid grid-cols-1 bg-custom-bg-white gap-[24px] lg:gap-[48px] px-[24px] py-[24px] lg:py-[90px] lg:px-[164px] justify-center items-center ">
      <motion.p
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        className="font-inter font-semibold text-center text-[#808080] text-[24px] leading-[28.8px] lg:text-[52px] lg:leading-[62.4px] tracking-[-0.41px]"
      >
        The Leadership
      </motion.p>
      <div className="flex flex-col lg:flex-row justify- lg:justify-center items-center lg:items-start bg-custom-bg-white gap-[24px] lg:gap-[48px]">
        {LeadershipArray.map((leader, index) => (
          <LeadershipCard
            key={index} // Always use a unique key
            img={leader.img}
            alt={leader.alt}
            name={leader.name}
            degination={leader.degination}
            description={leader.description}
          />
        ))}
      </div>
    </div>
  );
}

export default Leadership;

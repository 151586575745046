import React, { useContext } from "react";
import { ContactModalContext } from "../../config/contexts";

const SectionMain = () => {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="flex items-center justify-center h-full px-[20px] lg:px-[100px] ">
      <div className="h-[292px] ">
        <h1 className="font-inter font-bold text-[32px] leading-[38.4px] tracking-[-0.41%] md:text-[64px] md:leading-[76.8px] text-center text-[#FFFFFF]">
          Welcome to the Future of Smart Living
        </h1>
        <p className="font-inter font-medium text-[20px] leading-[24px] tracking-[-1%] md:text-[32px] md:leading-[38.4px] text-center text-[#EBEBEB]">
          Whitelion turns your space into a seamless smart experience
        </p>
        <div className="flex items-center justify-center">
          <button
            className="mt-8 w-full md:w-[288px] font-inter font-semibold text-[16px] leading-[22.4px] tracking-[-3%] md:text-[20px] md:leading-[28px] md:tracking-[-1%] text-center h-[56px] px-[32px] py-[14px] rounded-[8px] bg-[#FFFFFF] text-[#1E1E1E] "
            onClick={() => openModal()}
          >
            Get 100% Free Demo
          </button>
        </div>
      </div>
    </div>
  );
};

function Welcome() {
  return (
    <>
      <div
        className=" h-[716px] bg-cover bg-center bg-no-repeat  lg:block hidden"
        style={{ backgroundImage: `url('/images/heroconatct.png')` }}
      >
        <SectionMain />
      </div>
      <div
        className=" h-[868px] bg-cover bg-center bg-no-repeat lg:hidden block"
        style={{ backgroundImage: `url('/images/herocontactmobile.png')` }}
      >
        <SectionMain />
      </div>
    </>
  );
}

export default Welcome;

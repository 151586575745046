import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { NextIcon, PrevIcon } from "../../assets/svg";
import { Modal } from "react-responsive-modal";
import { motion } from "framer-motion";

const ModelView = ({ id, onClose }) => {
  return (
    <Modal
      open={!!id} // ✅ Open modal when videoId is set
      onClose={onClose} // ✅ Close modal when requested
      center
      classNames={{
        modal: "lg:h-full lg:w-full flex items-center justify-center",
        overlay: "bg-black bg-opacity-80",
        closeButton: "hidden",
      }}
    >
      <div className="video-container relative w-full h-full flex items-center justify-center">
        <iframe
          className="pt-[30px] md:pt-[40px] w-full h-full"
          src={`https://www.youtube.com/embed/${id}?autoplay=1&mute=0`}
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
};
const reviewArray = [
  "XSsOupuwxCM",
  "nHAzDtPgCRI",
  "wJIkPBUb1Rk",
  "ennG8tj3oRY",
  "3SlYBopn6tQ",
  "AmtopRKmIgk",
];
function CustomersReview() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        setActiveIndex(swiperRef.current.realIndex);
      });
    }
  }, []);

  const clickModel = (id) => {
    setVideoId(id); // ✅ Store the video ID in state
  };

  return (
    <div className=" py-[30px] lg:py-[100px] bg-custom-bg-white ">
      <motion.h1
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
        className="font-semibold text-[28px] lg:text-[72px] leading-[88px] tracking-[-0.41%] text-center align-middle  pb-[32px] lg:pb-[64px]"
      >
        Hear from Our Customers
      </motion.h1>
      {/* Modal - Render only when videoId is set */}
      {videoId && <ModelView id={videoId} onClose={() => setVideoId(null)} />}

      <Swiper
        slidesPerView={1.4} // Active slide takes 80% of the space
        spaceBetween={30}
        loop={true}
        centeredSlides={true} // Centers the active slide
        // navigation={true}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        modules={[Pagination, Navigation]}
        allowTouchMove={false}
        simulateTouch={false}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        className="mySwiper h-[300px] lg:h-[450px]"
        style={{ touchAction: "auto" }}
        breakpoints={{
          640: {
            slidesPerView: 4, // Slightly more visible on tablets
          },
          1024: {
            slidesPerView: 4, // 80% visibility on desktops
          },
        }}
      >
        {reviewArray?.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className="relative flex justify-center items-center h-[300px] lg:h-[450px] transition-all duration-500 cursor-pointer"
              onClick={
                activeIndex === index ? () => clickModel(item) : undefined
              }
            >
              {/* YouTube Thumbnail */}
              <img
                src={`https://img.youtube.com/vi/${item}/maxresdefault.jpg`}
                alt="YouTube Thumbnail"
                className={`w-full object-cover transition-all duration-500 ${
                  activeIndex === index ? "h-full" : "h-[90%] opacity-50"
                }`}
              />

              {/* Play Button - Centered */}
              {activeIndex === index && (
                <div className="absolute inset-0 flex justify-center items-center ">
                  <div className="w-16 h-16 bg-black bg-opacity-50 rounded-full flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      width="40"
                      height="40"
                    >
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <div className=" grid grid-cols-1 pt-[30px]">
        <div className="flex justify-center items-center gap-4 ">
          <button className="custom-prev h-[36px] w-[36px] lg:h-[50px] lg:w-[50px] rounded-full bg-[#FFFFFF] cursor-pointer flex justify-center items-center">
            <PrevIcon />
          </button>
          <button className="custom-next h-[36px] w-[36px] lg:h-[50px] lg:w-[50px] rounded-full bg-[#FFFFFF] cursor-pointer flex justify-center items-center">
            <NextIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomersReview;

import React from "react";

function LeafIcon() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49369 25.4896C6.39148 24.3763 5.53364 23.1031 4.92018 21.6703C4.30673 20.2374 4 18.7561 4 17.2263C4 15.6866 4.29671 14.1518 4.89012 12.6221C5.48354 11.0923 6.43545 9.67279 7.74587 8.36349C8.80466 7.30247 10.1474 6.46189 11.774 5.84175C13.3995 5.2205 15.1669 4.76681 17.0763 4.48068C18.9857 4.19455 20.9641 4.03701 23.0116 4.00806C25.059 3.97912 27.0313 4.0281 28.9285 4.15503C29.0654 5.99206 29.1189 7.92928 29.0888 9.96672C29.0587 12.0064 28.8934 13.9837 28.5928 15.8986C28.2922 17.8136 27.8329 19.5905 27.215 21.2294C26.5971 22.8682 25.766 24.2098 24.7217 25.2541C23.4391 26.559 22.0552 27.5098 20.57 28.1065C19.0859 28.7022 17.5957 29 16.0993 29C14.5317 29 12.9814 28.6933 11.4483 28.0798C9.91523 27.4664 8.59702 26.603 7.49369 25.4896ZM10.3077 25.6082C11.1794 26.1893 12.1252 26.6213 13.1451 26.9041C14.1649 27.188 15.1524 27.33 16.1077 27.33C17.4025 27.33 18.6829 27.0778 19.9487 26.5735C21.2169 26.0691 22.4104 25.2358 23.5293 24.0734C24.0269 23.5724 24.5307 22.8693 25.0407 21.9642C25.5506 21.059 25.9926 19.8766 26.3667 18.417C26.7407 16.9574 27.0241 15.19 27.2167 13.1147C27.4093 11.0394 27.4572 8.5689 27.3603 5.70314C25.9965 5.64747 24.4484 5.63745 22.716 5.67308C20.9836 5.70871 19.2523 5.85678 17.5222 6.11731C15.7909 6.37894 14.1654 6.77752 12.6457 7.31305C11.1249 7.84745 9.90966 8.57002 9.00006 9.48074C7.76869 10.711 6.90584 11.9869 6.41152 13.3084C5.91719 14.63 5.67003 15.8614 5.67003 17.0025C5.67003 18.5156 5.9723 19.929 6.57685 21.2427C7.1814 22.5565 7.91343 23.5813 8.77293 24.3172C9.471 22.3477 10.5498 20.4578 12.0094 18.6475C13.4702 16.8372 15.5132 15.1494 18.1384 13.584C16.219 15.2518 14.6058 17.0265 13.2987 18.908C11.9916 20.7896 10.9946 23.023 10.3077 25.6082Z"
        fill="black"
      />
    </svg>
  );
}

export default LeafIcon;

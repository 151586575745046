import React from "react";

function InnovationsPoshQuartz({ innovations }) {
  return (
    <div className="grid grid-cols-1  gap-[18px] lg:gap-[30px] px-[10px] lg:px-[84px] pb-[40px] pt-[42px] lg:pt-[100px] bg-custom-bg-black">
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center gap-4 lg:w-[800px]">
          <h1 className="font-inter font-semibold text-[36px] leading-[44px] lg:text-[64px] lg:leading-[70.4px] tracking-[0%] text-center text-[#F4F4F4]">
            {innovations?.head}
          </h1>

          <p className="text-[18px] leading-[23.4px] lg:text-[20px] lg:leading-[30px] font-semibold text-center text-[#F4F4F4] opacity-80">
            {innovations?.text}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center px-[60px] lg:px-[300px] py-[48px]">
        <img src={innovations?.img} alt="posh home" loading="lazy" />
      </div>
    </div>
  );
}

export default InnovationsPoshQuartz;

import React, { useContext } from "react";
import AirHomeFilledDark from "./airhome-filled-dark.svg";
import AirHomeFilledLight from "./airhome-filled-light.svg";
import AirHomeOutlineDark from "./airhome-outline-dark.svg";
import AirHomeOutlineLight from "./airhome-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function AirHomeIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? AirHomeFilledLight
      : AirHomeOutlineLight
    : isDefaultHeader
    ? AirHomeFilledDark
    : AirHomeOutlineDark;

  return <img src={iconSrc} alt="AirHome Icon" />;
}

export default AirHomeIcon;

import React from "react";

function EmailIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 25.3337V6.66699H28V25.3337H4ZM16 16.155L26.6667 9.18033V8.00033L16 14.667L5.33333 8.00033V9.18033L16 16.155Z"
        fill="white"
      />
    </svg>
  );
}

export default EmailIcon;

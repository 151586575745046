import React, { useState } from "react";
import { motion } from "framer-motion";
import { PlayIcon, RightIcon } from "../../assets/svg";
import { routinesList } from "./Constants";
function Routines() {
  const [videoSrc, setVideoSrc] = useState(routinesList[0]?.video);
  return (
    <div className="p-[24px] lg:px-[132px] lg:py-[150px] ">
      <div className="flex flex-col gap-[24px] lg:gap-[64px]">
        <div className="flex flex-col gap-[6px]">
          <h1 className="font-semibold text-[24px] lg:text-[52px] leading-[120%] tracking-[-0.41%] align-middle">
            <span className="bg-custom-gradient-text bg-clip-text text-transparent">
              Automate{" "}
            </span>
            Your Daily Routines
          </h1>
          <p className=" font-normal text-[14px] lg:text-[21px] leading-[130%] tracking-[0.34px] align-middle">
            Tailor your home with automations that match your lifestyle for a
            truly personalized experience.
          </p>
        </div>

        <div className="grid grid-cols-12 gap-3 lg:gap-4">
          {/* Video Player Section */}
          <div className="col-span-12 lg:col-span-8 lg:pr-[150px]">
            {videoSrc && (
              <motion.video
                key={videoSrc}
                width="600"
                autoPlay
                muted
                loop
                playsInline
                initial={{ opacity: 0, scale: 0.9 }} // Fade-in and scale effect
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
                className="w-full h-full pointer-events-none"
              >
                <source src={videoSrc} type="video/mp4" />
              </motion.video>
            )}
          </div>

          {/* Cards Section */}
          <div className="col-span-12 lg:col-span-4">
            <div className="flex flex-col gap-3">
              {routinesList?.map((item, index) => {
                const isActive = videoSrc === item?.video; // Check if video is playing

                return (
                  <div
                    key={index}
                    className={`relative flex flex-row justify-between items-center cursor-pointer p-[8px] lg:p-[16px] shadow-[0px_1px_4px_rgba(0,0,0,0.1)] transition-all duration-500 ${
                      isActive
                        ? "bg-blue-100 shadow-lg scale-105"
                        : "bg-transparent"
                    }`}
                    onClick={() => setVideoSrc(item?.video)}
                  >
                    {isActive && (
                      <motion.div
                        className="absolute top-0 left-0 h-full bg-blue-500"
                        style={{
                          background:
                            "linear-gradient(120.27deg, rgba(0, 102, 255, 0.15) -5.03%, rgba(0, 240, 255, 0.15) 105.2%)",
                        }}
                        initial={{ width: "0%" }}
                        animate={{ width: "100%" }}
                        transition={{
                          duration: item?.sec,
                          ease: "linear",
                          repeat: Infinity,
                        }}
                      />
                    )}
                    <div className="flex flex-col justify-center items-start gap-[8px] lg:gap-[16px]">
                      <p className="font-medium text-[#1E1E1E] text-[12px] lg:text-[16px] leading-[20px] tracking-[0.1%]">
                        {item?.text}
                      </p>
                      <div className="flex flex-row items-center gap-[4px]">
                        {item?.iconOne}
                        <RightIcon />
                        {item?.IconTwo}
                      </div>
                    </div>

                    {/* Play Button */}
                    <button
                      onClick={() => setVideoSrc(item?.video)}
                      className="transition-transform duration-200 hover:scale-110"
                    >
                      <PlayIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Routines;

import {
  ClockGreenIcon,
  HouseIcon,
  LocationColorIcon,
  ReadIcon,
  SunriseIcon,
} from "../../assets/svg";
import { productsLink } from "../../config/constant/NavLink";

export const sliderImages = [
  {
    name: "Posh",
    img: "/images/home/posh.png",
    route: productsLink?.posh?.route,
  },
  {
    name: "Quartz",
    img: "/images/home/quartz.png",
    route: productsLink?.quartz?.route,
  },
  {
    name: "AirTouch",
    img: "/images/home/airtouch.png",
    route: productsLink?.airtouch?.route,
  },
  {
    name: "AirGlass",
    img: "/images/home/airglass.png",
    route: productsLink?.airglass?.route,
  },
  {
    name: "AirHome",
    img: "/images/home/airhome.png",
    route: productsLink?.airhome?.route,
  },
  {
    name: "AirSensor",
    img: "/images/home/airsensor.png",
    route: productsLink?.airsensor?.route,
  },
  // {
  //   name: "AirBlaster",
  //   img: "/images/home/airblaster.png",
  //   route: productsLink?.airblaster?.route,
  // },
  {
    name: "Accessories",
    img: "/images/home/accessories.png",
    route: productsLink?.accessories?.route,
  },
];

export const routinesList = [
  {
    text: " Reading Mode",
    iconOne: <ClockGreenIcon />,
    IconTwo: <ReadIcon />,
    video: "/images/home/readingmood.mp4",
    sec: "8",
  },
  {
    text: " Arrive (Location)",
    iconOne: <LocationColorIcon />,
    IconTwo: <HouseIcon />,
    video: "/images/home/location.mp4",
    sec: "6.1",
  },
  {
    text: "Morning (Schedule)",
    iconOne: <ClockGreenIcon />,
    IconTwo: <SunriseIcon />,
    video: "/images/home/Schedule.mp4",
    sec: "5",
  },
  {
    text: "Stairs (Motion)",
    iconOne: <LocationColorIcon />,
    IconTwo: <HouseIcon />,
    video: "/images/home/motion.mp4",
    sec: "5.2",
  },
];
export const clockNumbers = [
  { num: 12, top: "0%", left: "50%", translate: "-translate-x-1/2" },
  {
    num: 1,
    top: "15%",
    left: "75%",
    translate: "-translate-x-1/2 -translate-y-1/2",
  },
  { num: 2, top: "30%", left: "90%", translate: "-translate-y-1/2" },
  { num: 3, top: "50%", right: "0%", translate: "-translate-y-1/2" },
  { num: 4, top: "70%", left: "90%", translate: "-translate-y-1/2" },
  {
    num: 5,
    top: "85%",
    left: "75%",
    translate: "-translate-x-1/2 -translate-y-1/2",
  },
  { num: 6, bottom: "0%", left: "50%", translate: "-translate-x-1/2" },
  {
    num: 7,
    top: "85%",
    left: "25%",
    translate: "-translate-x-1/2 -translate-y-1/2",
  },
  { num: 8, top: "70%", left: "10%", translate: "-translate-y-1/2" },
  { num: 9, top: "50%", left: "5%", translate: "-translate-y-1/2" },
  { num: 10, top: "30%", left: "10%", translate: "-translate-y-1/2" },
  {
    num: 11,
    top: "15%",
    left: "25%",
    translate: "-translate-x-1/2 -translate-y-1/2",
  },
];

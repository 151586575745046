import React from 'react'

function Whatsapp() {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.802734" width="40" height="40" rx="20" fill="#1B1819" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.6 15.1027C24.1 13.6027 22.1 12.8027 20 12.8027C15.6 12.8027 12 16.4027 12 20.8027C12 22.2027 12.4 23.6027 13.1 24.8027L12 28.8027L16.2 27.7027C17.4 28.3027 18.7 28.7027 20 28.7027C24.4 28.7027 28 25.1027 28 20.7027C28 18.6027 27.1 16.6027 25.6 15.1027ZM20 27.4027C18.8 27.4027 17.6 27.1027 16.6 26.5027L16.4 26.4027L13.9 27.1027L14.6 24.7027L14.4 24.4027C13.7 23.3027 13.4 22.1027 13.4 20.9027C13.4 17.3027 16.4 14.3027 20 14.3027C21.8 14.3027 23.4 15.0027 24.7 16.2027C26 17.5027 26.6 19.1027 26.6 20.9027C26.6 24.4027 23.7 27.4027 20 27.4027ZM23.6 22.4027C23.4 22.3027 22.4 21.8027 22.2 21.8027C22 21.7027 21.9 21.7027 21.8 21.9027C21.7 22.1027 21.3 22.5027 21.2 22.7027C21.1 22.8027 21 22.8027 20.8 22.8027C20.6 22.7027 20 22.5027 19.2 21.8027C18.6 21.3027 18.2 20.6027 18.1 20.4027C18 20.2027 18.1 20.1027 18.2 20.0027C18.3 19.9027 18.4 19.8027 18.5 19.7027C18.6 19.6027 18.6 19.5027 18.7 19.4027C18.8 19.3027 18.7 19.2027 18.7 19.1027C18.7 19.0027 18.3 18.0027 18.1 17.6027C18 17.3027 17.8 17.3027 17.7 17.3027C17.6 17.3027 17.5 17.3027 17.3 17.3027C17.2 17.3027 17 17.3027 16.8 17.5027C16.6 17.7027 16.1 18.2027 16.1 19.2027C16.1 20.2027 16.8 21.1027 16.9 21.3027C17 21.4027 18.3 23.5027 20.3 24.3027C22 25.0027 22.3 24.8027 22.7 24.8027C23.1 24.8027 23.9 24.3027 24 23.9027C24.2 23.4027 24.2 23.0027 24.1 23.0027C24 22.5027 23.8 22.5027 23.6 22.4027Z" fill="white" />
        </svg>
    )
}

export default Whatsapp
import React from "react";

function Guarantee() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 26C35.6 26 28 33.6 28 43C28 52.4 35.6 60 45 60C54.4 60 62 52.4 62 43C62 33.6 54.4 26 45 26ZM58 42H52C52 38 51.4 34 50.2 31C54.4 33 57.6 37 58 42ZM45 56C44.2 55.6 42.4 52.4 42 46H47.8C47.4 52.4 45.8 55.6 45 56ZM42 42C42.2 34.4 44.2 30.4 44.8 30C45.6 30.4 47.6 34.4 47.8 42H42ZM39.8 31C38.6 34 38.2 38 38 42H32C32.4 37 35.6 33 39.8 31ZM32.4 46H38C38.2 49.2 38.8 52.4 39.8 55C36.2 53.4 33.4 50 32.4 46ZM50.2 55C51.2 52.4 51.8 49.4 52 46H57.8C56.6 50 53.8 53.4 50.2 55Z"
        fill="white"
      />
      <path
        d="M52.6 23C50.8 13.8 41.6 4 32 4C22.4 4 14.2 10.8 12.4 20.2C5.4 21.6 0 27.6 0 35C0 43.2 6.8 50 15 50H25V46H15C9 46 4 41 4 35C4 29.2 8.4 24.4 14.2 24H16V22.2C17 14.2 23.8 8 32 8C39.4 8 46.6 16.2 48.4 23H52.6Z"
        fill="white"
      />
    </svg>
  );
}

export default Guarantee;

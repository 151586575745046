import React from "react";
import {
  WhitelionIcon,
  Whatsapp,
  YouTube,
  LinkedIn,
  Facebook,
  Instagram,
} from "../../assets/svg";

function ContactUSFooter() {
  return (
    <div className="h-[264px] md:h-[336px] text-center py-[24px] md:py-[47px] md:px-[104px]">
      {/* Logo */}
      <div className="flex justify-center items-center mb-[24px] md:mb-[46px] ">
        <WhitelionIcon />
      </div>
      <p className="font-inter font-normal  text-[10px] leading-[14px]  md:text-[14px] md:leading-[19.6px] tracking-[0] text-center text-[#1E1E1E] ">
        Connect with us
      </p>

      <div className="flex justify-center gap-[8px] mt-3 ">
        <a
          href="https://www.facebook.com/whiteliongrp"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <Facebook />
        </a>
        {/* <a href="#" className=""> */}
        {/* WhatsApp */}
        <Whatsapp />
        {/* </a> */}
        <a
          href="https://in.linkedin.com/company/whitelion-in"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          {/* LinkedIn */}
          <LinkedIn />
        </a>
        <a
          href="https://www.youtube.com/@whitelion-in"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <YouTube />
        </a>
        <a
          href="https://www.instagram.com/whitelion.in/"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          <Instagram />
        </a>
      </div>
      <div className="border-t border-[#80808080] w-full mx-auto my-4 md:my-6"></div>
      {/* Copyright */}
      <p className="font-inter font-normal text-[10px] leading-[14px] md:text-[14px] md:leading-[19.6px] tracking-[0] text-center text-[#1e1e1e]">
        Copyright © 2025 Whitelion Systems Private Limited. All rights reserved.
      </p>
    </div>
  );
}

export default ContactUSFooter;

import React, { useContext } from "react";
import AccessoriesFilledDark from "./accessories-filled-dark.svg";
import AccessoriesFilledLight from "./accessories-filled-light.svg";
import AccessoriesOutlineDark from "./accessories-outline-dark.svg";
import AccessoriesOutlineLight from "./accessories-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function AccessoriesIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? AccessoriesFilledLight
      : AccessoriesOutlineLight
    : isDefaultHeader
    ? AccessoriesFilledDark
    : AccessoriesOutlineDark;

  return <img src={iconSrc} alt="Accessories Icon" />;
}

export default AccessoriesIcon;

import React from "react";
import Welcome from "./Welcome";
import ElegantTech from "./ElegantTech";

function index() {
  return (
    <div className="w-full">
      <Welcome />
      <ElegantTech />
    </div>
  );
}

export default index;

import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { AppleIcon, GooglePlayIcon } from "../../../assets/svg";
import { AppleAppLink, GoogleAppLink } from "../../../config/constant";

function Welcome() {
  const containerRef = useRef(null);

  const isMobile = window?.innerWidth <= 768;

  const { scrollY } = useScroll({
    smooth: 20,
  });
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });
  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 20 });
  };
  const scale = useSpringTransform(scrollY, [0, 500], [1, 0.85]);

  const appScale = useSpringTransform(
    scrollYProgress,
    [0, 0.6, 1],
    [0.7, 0.7, 0.9]
  );

  const imageY = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    isMobile
      ? [40, 50, 60, 60, 70, 80, 90, 100, 110, 120, 130]
      : [240, 200, 140, 0, 0, 0, 0, 0, 0, 0, 0]
  );

  const leftImageY = useSpringTransform(scrollYProgress, [0, 0.3], [0, -40]);
  const rightImageY = useSpringTransform(scrollYProgress, [0, 0.3], [0, -70]);
  const sideImagesOpacity = useSpringTransform(
    scrollYProgress,
    [0.3, 0.5],
    [1, 0]
  );

  const welcomeTextOpacity = useSpringTransform(
    scrollYProgress,
    [0, 0.1],
    [1, 0]
  );
  const welcomeTextY = useSpringTransform(scrollYProgress, [0, 0.2], [0, -50]);

  // The first circle grows from 50px to 200px
  const circlescale1 = useSpringTransform(
    scrollYProgress,
    [0.4, 0.8],
    [0.1, 1]
  );
  const circlescale2 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.8],
    [0.1, 0.8]
  );
  const circlescale3 = useSpringTransform(
    scrollYProgress,
    [0.6, 0.8],
    [0.1, 0.6]
  );
  const circlescale4 = useSpringTransform(
    scrollYProgress,
    [0.7, 0.8],
    [0.1, 0.4]
  );

  const circleOpacity1 = useSpringTransform(
    scrollYProgress,
    [0.39, 0.4, 0.8, 1],
    [0, 1, 1, 0]
  );
  const circleOpacity2 = useSpringTransform(
    scrollYProgress,
    [0.39, 0.4, 0.8, 1],
    [0, 1, 1, 0]
  );
  const circleOpacity3 = useSpringTransform(
    scrollYProgress,
    [0.39, 0.4, 0.8, 1],
    [0, 1, 1, 0]
  );
  const circleOpacity4 = useSpringTransform(
    scrollYProgress,
    [0.39, 0.4, 0.8, 1],
    [0, 1, 1, 0]
  );

  return (
    <div
      ref={containerRef}
      className="relative px-[24px] lg:px-[164px] bg-[#000000] pt-[20px] lg:pt-[40px] h-[300vh] lg:h-[400vh]"
    >
      <div className="relative">
        <div className="absolute inset-0">
          <motion.div className="sticky top-0 z-0">
            <motion.img
              src="/images/airhome/airhomehero.png"
              alt="Full screen image"
              className="w-full h-[100vh] object-cover rounded-[32px]"
              initial={{ scale: 1.1, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                duration: 1.2,
                ease: "easeOut",
                opacity: { duration: 1 },
              }}
              style={{
                scale,
                transformOrigin: "center top",
              }}
            />
          </motion.div>

          <div className="absolute top-0 left-0 right-0 z-30  flex items-center justify-center text-black">
            <motion.div
              className="text-center pt-[48px] flex flex-col items-center justify-center gap-[16px]"
              style={{
                opacity: welcomeTextOpacity,
                y: welcomeTextY,
              }}
            >
              <motion.h1
                className="font-bold text-[24px] md:text-[40px] lg:text-[60px] leading-[110%] tracking-[3%] text-center align-middle"
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.2,
                }}
              >
                Welcome to AirHome
              </motion.h1>

              <motion.div
                className="flex flex-row justify-center items-center gap-[8px] md:gap-[16px] lg:gap-[16px]"
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.4,
                }}
              >
                <a
                  href={AppleAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row justify-center items-center gap-[8px] px-[10px] py-[6px] lg:px-[20px] lg:py-[10px] border border-black border-[0.5px] rounded-[8px]"
                >
                  <AppleIcon />
                  <p className="flex justify-center items-center font-normal text-[14px] md:text-[16px] lg:text-[20px] leading-[140%] tracking-[0%] text-center align-middle">
                    App Store
                  </p>
                </a>

                <a
                  href={GoogleAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row justify-center items-center gap-[8px] px-[10px] py-[6px] lg:px-[20px] lg:py-[10px] border border-black border-[0.5px] rounded-[8px]"
                >
                  <GooglePlayIcon />
                  <p className="flex justify-center items-center font-normal text-[14px] md:text-[16px] lg:text-[20px] leading-[140%] tracking-[0%] text-center align-middle">
                    Google Play
                  </p>
                </a>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="sticky top-0 h-screen flex items-center justify-center z-10">
        <motion.img
          src="/images/airhome/airhomeapp.png"
          alt="AirHome App"
          className="h-[540px] lg:h-[100vh] object-contain"
          initial={{
            y: isMobile ? 40 : 240,
            opacity: 0,
          }}
          animate={{
            y: isMobile ? 40 : 240,
            opacity: 1,
          }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.6,
          }}
          style={{
            y: imageY,
            scale: appScale,
          }}
        />
      </div>
      <div className="sticky top-0 h-screen flex items-center justify-center z-0 hidden lg:flex">
        <div className="relative flex flex-col items-center justify-center  text-white w-full ">
          <div className="relative w-[80vw] h-[80vw]">
            <motion.div
              className="absolute inset-0 rounded-full border-[6px] border-[#4e7abf] w-[80vw] h-[80vw]"
              initial={{ opacity: 0 }}
              style={{ scale: circlescale1, opacity: circleOpacity1 }}
            />

            <motion.div
              className="absolute inset-0 rounded-full border-[6px] border-[#4e7abf] w-[80vw] h-[80vw]"
              initial={{ opacity: 0 }}
              style={{ scale: circlescale2, opacity: circleOpacity2 }}
            />
            <motion.div
              className="absolute inset-0 rounded-full border-[6px] border-[#4e7abf] w-[80vw] h-[80vw]"
              initial={{ opacity: 0 }}
              style={{ scale: circlescale3, opacity: circleOpacity3 }}
            />
            <motion.div
              className="absolute inset-0 rounded-full border-[6px] border-[#4e7abf] w-[80vw] h-[80vw]"
              initial={{ opacity: 0 }}
              style={{ scale: circlescale4, opacity: circleOpacity4 }}
            />
          </div>
        </div>
      </div>

      <div className="absolute inset-0 w-full h-screen flex items-center justify-between px-10 z-20">
        {/* Left Image with Parallax */}
        <motion.div
          className="absolute left-[10%] top-[60%] md:left-[34%] md:top-[78%]"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.8,
          }}
          style={{
            y: leftImageY,
            opacity: sideImagesOpacity,
          }}
        >
          <img
            src="/images/airhome/heroleft.png"
            alt="Left hero element"
            className="h-[84px] w-[140px] md:h-[104px] md:w-[236px] object-contain"
          />
        </motion.div>

        {/* Right Image with Parallax */}
        <motion.div
          className="absolute right-[15%] top-[40%] md:right-[36%] md:top-[60%] "
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.8,
          }}
          style={{
            y: rightImageY,
            opacity: sideImagesOpacity,
          }}
        >
          <img
            src="/images/airhome/heroright.png"
            alt="Right hero element"
            className="h-[90px] w-[105px] md:h-[129px] md:w-[155px] object-contain"
          />
        </motion.div>
      </div>
    </div>
  );
}

export default Welcome;

import React from "react";

function OpenIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2507 14H26.0007V23.75H24.5007V16.5605L15.2817 25.781L14.2197 24.719L23.4402 15.5H16.2507V14Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
}

export default OpenIcon;

import React from "react";
import { motion } from "framer-motion";
import { PrecisionArray } from "./Constants";

const Card = ({ img, lable, text, color }) => {
  return (
    <div
      className="w-full lg:w-[416px] h-[400px] lg:h-[500px] rounded-[8px] p-[20px] lg:p-[40px]"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: color,
      }}
    >
      <motion.div
        className="flex flex-col justify-center items-center gap-[8px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        <p className="font-semibold text-[12px] lg:text-[16px] leading-[120%] tracking-[1%] text-center align-middle uppercase">
          {lable}
        </p>

        <p className="font-bold text-[24px] leading-[113%] tracking-[0.2px] text-center">
          {text}
        </p>
      </motion.div>
    </div>
  );
};
function Precision() {
  return (
    <div className="p-[24px] lg:px-[64px] lg:py-[150px] flex flex-col justify-center items-center gap-[24px] lg:gap-[64px] bg-custom-bg-white ">
      <motion.div
        className=" w-full  flex flex-col justify-center items-center gap-[12px] lg:gap-[24px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <h1 className="font-semibold text-[22px] lg:text-[64px] leading-[100%] tracking-[0%] align-middle text-center text-[#000000]">
          {PrecisionArray?.label}
        </h1>
      </motion.div>
      <div className="grid grid-cols-1 w-full gap-[24px] lg:gap-[32px]">
        <div className=" flex  flex-col lg:flex-row gap-[24px] lg:gap-[32px] justify-center items-center">
          <Card
            img={PrecisionArray?.firstCard?.img}
            lable={PrecisionArray?.firstCard?.label}
            text={PrecisionArray?.firstCard?.text}
            color={"#FFFFFF"}
          />
          <div className="w-full lg:w-[416px] h-[400px] lg:h-[500px]">
            <Card
              img={PrecisionArray?.secondCard?.img}
              lable={PrecisionArray?.secondCard?.label}
              text={PrecisionArray?.secondCard?.text}
              color={"#FFFFFF"}
            />
          </div>
          <div className="w-full lg:w-[416px] h-[400px] lg:h-[500px]">
            <Card
              img={PrecisionArray?.thirdCard?.img}
              lable={PrecisionArray?.thirdCard?.label}
              text={PrecisionArray?.thirdCard?.text}
              color={"#FFFFFF"}
            />
          </div>
        </div>
        <div className="flex  flex-col lg:flex-row gap-[24px] lg:gap-[32px] justify-center items-center">
          <div className="w-full lg:w-[416px] h-[400px] lg:h-[500px]">
            <Card
              img={PrecisionArray?.fourthCard?.img}
              lable={PrecisionArray?.fourthCard?.label}
              text={PrecisionArray?.fourthCard?.text}
              color={"#000000"}
            />
          </div>
          <div className="w-full lg:w-[416px] h-[400px] lg:h-[500px]">
            <Card
              img={PrecisionArray?.fifthCard?.img}
              lable={PrecisionArray?.fifthCard?.label}
              text={PrecisionArray?.fifthCard?.text}
              color={"#000000"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Precision;

import React from "react";
import { DownloadIcon } from "../../assets/svg";
import FaqModel from "./FaqModel";
import WarrantyRegModel from "./WarrantyRegModel";
const ResourceData = [
  {
    icon: "/images/resource/openfile.png",
    name: "Product Catalogue",
    isPdf: true,
    pdfLink: "/images/pdf/product_catalogue.pdf",
    pdfName: "product Catalogue.pdf",
  },
  {
    icon: "/images/resource/servicepolicy.png",
    name: "Service Policy",
    isPdf: true,
    pdfLink: "/images/pdf/service_policy.pdf",
    pdfName: "Service Policy.pdf",
  },
  // {
  //   icon: "/images/resource/pageuser.png",
  //   name: "User Manual",
  //   isPdf: true,
  //   pdfLink: "/images/pdf/product_catalogue.pdf",
  //   pdfName: "User Manual.pdf",
  // },
  {
    icon: "/images/resource/questionmark.png",
    name: "Frequently Asked Questions (FAQs)",
    isPdf: false,
    model: <FaqModel />,
  },
  {
    icon: "/images/resource/registration.png",
    name: "Warranty Registration",
    isPdf: false,
    model: <WarrantyRegModel />,
  },
];

const ResourceCard = ({ item, index }) => {
  return (
    <div
      key={index}
      className={`grid grid-cols-1 border-b  ${
        index === 0 ? "border-t " : ""
      } ${index === 1 ? "lg:border-t " : ""}`}
    >
      <div className="p-[12px] lg:p-[24px] flex flex-row justify-between items-center  ">
        <div className=" flex flex-row justify-between items-center gap-[6px]">
          <img
            src={item?.icon}
            alt={item?.img}
            loading="lazy"
            className="w-[30px] h-[30px] lg:w-[40px] lg:h-full"
          />
          <p className=" text-left font-medium text-[14px] lg:text-[20px] leading-[23.87px] tracking-[1%]">
            {item?.name}
          </p>
        </div>
        <div className="">
          {item?.isPdf ? (
            <a href={item?.pdfLink} download={item?.pdfName}>
              <DownloadIcon />
            </a>
          ) : (
            item?.model
          )}
        </div>
      </div>
    </div>
  );
};

function ResourceHub() {
  return (
    <div className="grid grid-cols-1 p-[24px] lg:px-[165px] lg:py-[200px] gap-[16px] lg:gap-[24px]">
      <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left gap-[10px]">
        <p className="font-semibold text-[#1E1E1E] text-[32px] leading-[30px] lg:text-[52px] lg:leading-[62.4px] tracking-[-0.41%] ">
          Resource Hub
        </p>
        <p className="font-sf font-normal text-[#1E1E1E] opacity-80 text-[20px] leading-[23.87px] tracking-[5%] ">
          Find the Downloadable here!
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[24px]">
        {ResourceData?.map((item, index) => {
          return <ResourceCard key={index} item={item} index={index} />;
        })}
      </div>
    </div>
  );
}

export default ResourceHub;

import {
  BagIconWhite,
  CallIconWhite,
  CartIconWhite,
  ChatIconWhite,
  HandShakeIconWhite,
  MoneyIconWhite,
  TrendIconWhite,
  TvIconWhite,
  VideoIconWhite,
} from "../../../assets/svg";

export const head = {
  label: "The Essence of Automation, Built to Endure",
  text: `Wake up in a home where automation and sophistication work hand in hand, with every command executed flawlessly. The Posh Series, designed with sleek, splash-proof smart touch panel and a flexible modular system, goes beyond functionality—it's the centerpiece of your automated lifestyle, offering enduring control and refined elegance.`,
};
export const firstCard = {
  firstLabel: "Gentle control ",
  secondLabel: "exact precision at your command.",
  icon: "/images/posh/control.png",
  img: "/images/posh/poshsection.png",
};
export const secondCard = {
  label: [
    "Aristocratic",
    "White,",
    "Commanding",
    "Black:",
    "Your",
    "Preference",
  ],
  img: "/images/posh/glowingstar.png",
};
export const thirdCard = {
  video: "/images/posh/splash-proof.mp4",
  text: [
    "The touch panel",
    "designed to resist",
    " Water, and thrive",
    "in everyday",
    "conditions",
  ],
  img: "/images/posh/droplet.png",
};
export const fourthCard = {
  video: "/images/posh/fast_installation.mp4",
  label: "Zero Trouble Installation",
  imgEnd: "/images/posh/hammer.png",
  text: "Seamless and straightforward—enjoy a quick setup with minimal effort. ",
};
export const masterButton = {
  label: "Master button",
  text: "It can be tedious to turn off all the touch panel when you are moving out. One touch of the master button can turn off all the touch panel at once. It's hassle free and convenient.",
  video: "/images/posh/master_button_posh.mp4",
};
export const twoWay = {
  label: "Two Way",
  text: "Our smart touch panel enable two-way usage. You can easily turn them on at one end and turn them off at the other - or vice versa.",
  video: "/images/posh/two_way_posh.mp4",
};

export const swipperList = {
  head: "Complete Control at Your Command",
  list: [
    {
      name: "LIGHTS",
      text: "Instantly control your home lighting to create the desired mood.",
      bg: "#000000",
      textColor: "#ffffff",
      img: "/images/posh/light.png",
    },
    {
      name: "FAN",
      text: "Use automation to optimize your fan settings for comfort and efficiency.",
      bg: "#FCFEFE",
      textColor: "#000000",
      img: "/images/posh/fan.png",
    },
    {
      name: "CURTAINS",
      text: "Automate your curtains for optimal natural illumination and ambiance.",
      bg: "#E0E0E2",
      textColor: "#000000",
      img: "/images/posh/curtains.png",
    },
    {
      name: "TELEVISION",
      text: "Manage your TV with ease, from changing channels to adjusting the volume.",
      bg: "#E0E0E2",
      textColor: "#000000",
      img: "/images/posh/television.png",
    },
  ],
};

export const elevateList = {
  head: "Looking to Enhance Your Lifestyle with Automation?",
  text: "A few simple steps to bring home the Posh Series.",
  card: [
    {
      headIcon: ["08", "09", "10", "11", "12"],
      cardIcon: <CallIconWhite />,
      steps: {
        head: "Step 1: Schedule a Call or Fill Form",
        text: "Let’s get started by scheduling a consultation. Either book a call or fill out our quick form, and one of our experts will reach out to guide you through the process.",
      },
    },
    {
      headIcon: [<VideoIconWhite />, <HandShakeIconWhite />, <ChatIconWhite />],
      cardIcon: <TvIconWhite />,
      steps: {
        head: "Step 2: Personalized Demo",
        text: "Experience the Posh Series in action. Our team will provide a personalized demo, answering all your questions and helping you find the perfect fit for your smart home.",
      },
    },
    {
      headIcon: [<MoneyIconWhite />, <BagIconWhite />, <TrendIconWhite />],
      cardIcon: <CartIconWhite />,
      steps: {
        head: "Step 3: Purchase & Upgrade",
        text: "Once you're satisfied, proceed with the purchase and get ready to elevate your home with elegance and innovation.",
      },
    },
  ],
};
export const innovations = {
  head: "Smart Innovations for Simplified Lifestyles",
  text: "State-of-the-art technology that transforms your home experience.",
  img: "/images/posh/poshhome.png",
};
export const switchData = {
  head: "Touch that enhances every elegant space",
  text: "Select from our refined color palette to complement your interior.",
  img: {
    after: "/images/posh/whiteposh.png",
    before: "/images/posh/blackposh.png",
  },
  isPosh: true,
};

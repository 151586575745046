import React, { useContext } from "react";
import { ContactModalContext } from "../../config/contexts";

function FooterButton() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="sticky bottom-0 left-0 z-10  mx-auto  grid grid-cols-1  px-[8px] py-[0px] lg:px-[50px]  bg-[#F4F4F4] h-[80px] lg:h-[122px]">
      <div className="flex justify-between items-center  gap-[16px]">
        <p className="font-inter font-semibold text-[12px] leading-[19.36px] lg:text-[28px] lg:leading-[33.89px]">
          Transform Your Space with{" "}
          <span className="font-inter font-semibold text-[18px] leading-[24.2px] lg:text-[40px] lg:leading-[48px] tracking-[-0.41%] bg-gradient-to-r from-[#00F0FF] to-[#0066FF] inline-block text-transparent bg-clip-text">
            Smart Automation!
          </span>
        </p>
        <div className=" ">
          <button
            className=" w-[150px] md:w-[400px] h-[40px] lg:h-[56px] p-[6px] ig:px-[32px] ig:py-[14px] rounded-[8px] bg-[#1E1E1E] text-white font-inter font-semibold text-[13px] leading-[19.6px] lg:text-[16px] lg:leading-[22.4px] tracking-[-3%] md:text-[20px] md:leading-[28px]"
            onClick={() => openModal()}
          >
            Get 100% Free Demo
          </button>
        </div>
      </div>
    </div>
  );
}

export default FooterButton;

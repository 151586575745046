import React, { useContext } from "react";
import AirSensorFilledDark from "./airsensor-filled-dark.svg";
import AirSensorFilledLight from "./airsensor-filled-light.svg";
import AirSensorOutlineDark from "./airsensor-outline-dark.svg";
import AirSensorOutlineLight from "./airsensor-outline-light.svg";
import { NavContext } from "../../../config/contexts";
function AirSensorIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? AirSensorFilledLight
      : AirSensorOutlineLight
    : isDefaultHeader
    ? AirSensorFilledDark
    : AirSensorOutlineDark;

  return <img src={iconSrc} alt="AirSensor Icon" />;
}
export default AirSensorIcon;

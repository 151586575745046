import React from "react";
import { Helmet } from "react-helmet-async";

function index() {
  return (
    <>
      <Helmet>
        {/* Example: Google Tag Manager Script */}

        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WLJT625F');`}
        </script>
        <noscript>
          {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLJT625F"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
      </Helmet>
      <div className="   flex items-center justify-center">
        <div className="text-[22px] py-[40px] lg:text-[48px] font-semibold flex items-center justify-center ">
          <p> Thank You for contacting Us.</p>
        </div>
      </div>
    </>
  );
}

export default index;

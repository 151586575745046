import React from "react";
import Welcome from "../../components/scene-panel";
function index() {
  return (
    <div className="w-full">
      <Welcome />
    </div>
  );
}

export default index;

import React from 'react'

function LinkedIn() {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.802734" width="40" height="40" rx="20" fill="#1B1819" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.1188 16.574H13.0934C11.8234 16.574 11 15.7431 11 14.6899C11 13.6162 11.8477 12.8027 13.1431 12.8027C14.4374 12.8027 15.2333 13.6142 15.2587 14.6868C15.2587 15.74 14.4374 16.574 13.1188 16.574ZM29 28.8027H25.0218V23.1781C25.0218 21.7059 24.3995 20.7008 23.031 20.7008C21.9844 20.7008 21.4023 21.379 21.1313 22.0327C21.0419 22.2392 21.0435 22.5173 21.0452 22.8039V22.8039C21.0454 22.8428 21.0456 22.8819 21.0456 22.9209V28.8027H17.1044C17.1044 28.8027 17.1552 18.8392 17.1044 17.9336H21.0456V19.6394C21.2785 18.8935 22.5379 17.8291 24.5476 17.8291C27.041 17.8291 29 19.3925 29 22.7591V28.8027ZM14.9624 17.9336H11.454V28.8027H14.9624V17.9336Z" fill="white" />
        </svg>
    )
}

export default LinkedIn
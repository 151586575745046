import React from "react";
import { lpLink } from "../../config/constant/NavLink";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();
  return (
    <div>
      <div className=" bg-gray-50 flex flex-col items-center justify-center p-[100px] ">
        <h1 className="text-4xl font-bold text-gray-800 mb-10 text-center">
          Our Landing Pages
        </h1>
        <div className="w-full max-w-3xl space-y-6">
          {Object.values(lpLink)?.map((page, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-md p-6 flex items-center justify-between hover:shadow-lg transition"
            >
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {page?.head}
                </h2>
                <p className="text-gray-600">{page?.text}</p>
              </div>
              <button
                className="bg-indigo-600 text-white font-medium px-4 py-2 rounded-full hover:bg-indigo-700 transition"
                onClick={() => navigate(page?.route)}
              >
                Click Here
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Index;

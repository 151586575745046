export const head = {
  label: "Smart Home Add-Ons",
  text: "Enhance your smart home with Whitelion’s premium accessories, designed for seamless integration, elegant control, and innovative functionality across all devices, transforming your living space with unmatched style and convenience.",
  imgWeb: "/images/accessories/accessorieshome.png",
};

export const ElegantTechArray = {
  label: "Elegant Tech Upgrades",
  text: "Experience WhiteLion’s High Load, AirRemote, IR Remote, and AirChip, engineered for heavy-duty support, modern IR control, remote consolidation, and app-driven touch panel functionality",
  first: {
    cardLabel: "High Load",
    cardText:
      "Supports high-voltage appliances with safe, efficient control, ensuring durability and seamless integration into your smart home setup.",
    img: "/images/accessories/highload.png",
  },
  second: {
    cardLabel: "AirRemote",
    cardText:
      "Sleek, compact design controls IR devices via app with clean, modern precision, enhancing your smart home effortlessly.",
    img: "/images/airremote.png",
  },
  third: {
    cardLabel: "IR Remote",
    cardText:
      "Replaces multiple remotes with one smart, elegant controller, simplifying operation of all your IR-based devices seamlessly.",
    img: "/images/irremote.png",
  },
  fourth: {
    cardLabel: "AirChip",
    cardText:
      "Integrates with touch panels, enabling app-based control via AirHome, boosting smart home functionality with ease.",
    img: "/images/accessories/airchip.png",
  },
};

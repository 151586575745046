import React from 'react'

function index() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
            <path d="M48.1701 40.2457H47.9675L47.7943 40.3507L40.5891 44.7202L40.2402 44.9317V45.3398V50.2879V51.0126H40.9648H41.0227H41.2241L41.3966 50.9086L48.6019 46.5681L48.9526 46.3569V45.9474V40.9703V40.2457H48.2279H48.1701Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M32.2813 40.3507L32.1081 40.2457H31.9055H31.8477H31.123V40.9703V45.9474V46.3569L31.4737 46.5681L38.679 50.9086L38.8515 51.0126H39.0529H39.1108H39.8354V50.2879V45.3398V44.9317L39.4865 44.7202L32.2813 40.3507Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M40.3959 37.9173L40.0252 37.6967L39.6546 37.9173L35.3285 40.4927L34.2942 41.1084L35.3224 41.7343L39.6485 44.3676L40.0252 44.5969L40.402 44.3676L44.728 41.7343L45.7563 41.1084L44.7219 40.4927L40.3959 37.9173Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M55.2301 29.3378V28.8779L54.814 28.6821L50.3867 26.5987L49.3535 26.1125V27.2543V44.8189V46.093L50.4486 45.4417L54.8759 42.8084L55.2301 42.5978V42.1856V29.3378Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M25.2344 28.6821L24.8183 28.8779V29.3378V42.1856V42.5978L25.1725 42.8084L29.5999 45.4417L30.695 46.093V44.8189V27.2543V26.1125L29.6618 26.5987L25.2344 28.6821Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M48.9526 27.0217V26.1013L48.0579 26.3173L43.8621 27.33L43.3075 27.4639V28.0344V32.4802L40.599 34.0644L40.2402 34.2742V34.6899V36.9759V37.3921L40.5997 37.6018L45.8083 40.6401L46.176 40.8546L46.5424 40.6379L48.5969 39.4226L48.9526 39.2121V38.7989V27.0217Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M32.0177 26.3173L31.123 26.1013V27.0217V38.7989V39.2121L31.4787 39.4226L33.5332 40.6379L33.8996 40.8546L34.2673 40.6401L39.4759 37.6018L39.8354 37.3921V36.9759V34.6899V34.2742L39.4766 34.0644L36.7681 32.4802V28.0344V27.4639L36.2135 27.33L32.0177 26.3173Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M40.336 21.8254L40.0243 21.6769L39.7127 21.8254L33.2743 24.8927L31.4298 25.7714L33.4158 26.2513L37.1729 27.159V31.8551V32.2707L37.5316 32.4805L39.6584 33.7248L40.0219 33.9375L40.3866 33.727L42.5424 32.4827L42.9048 32.2735V31.8551V27.1329L46.6295 26.2521L48.6332 25.7782L46.7744 24.8927L40.336 21.8254Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M24.8183 27.311V28.4621L25.8562 27.9645C29.0252 26.4451 35.9258 23.1381 38.182 22.062L37.8452 20.6837C35.7002 20.7574 30.2216 21.2513 25.3432 22.6501L24.8183 22.8007V23.3467V27.311Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M54.1934 27.9644L55.2313 28.4621V27.311V23.3467V22.8007L54.7064 22.6501C49.8282 21.2513 44.3493 20.7574 42.2045 20.6837L41.8678 22.062C44.124 23.1381 51.0245 26.4451 54.1934 27.9644Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M55.218 13.9131V12.3107L54.0148 13.369L48.2181 18.4671L40.3982 13.3358L39.9967 13.0724L39.5977 13.3394L31.8895 18.496L25.9799 13.4206L24.7832 12.3927V13.9703V21.4451V22.4087L25.7089 22.1413C30.3226 20.8085 42.5938 18.9359 54.3016 22.1481L55.218 22.3995V21.4493V13.9131Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M24.782 13.9131V12.3107L25.9852 13.369L31.7819 18.4671L39.6018 13.3358L40.0033 13.0724L40.4023 13.3394L48.1105 18.496L54.0201 13.4206L55.2168 12.3927V13.9703V21.4451V22.4087L54.2911 22.1413C49.6774 20.8085 37.4062 18.9359 25.6984 22.1481L24.782 22.3995V21.4493V13.9131Z" stroke="#1E1E1E" strokeWidth="1.44928" />
            <path d="M17.5627 58.8157H18.5382C18.3567 59.02 18.1903 59.3224 18.0391 59.7231L15.5549 66.1774H14.6021L12.9914 62.0601L11.4147 66.1774H10.4618L7.955 59.7231C7.88694 59.5417 7.80754 59.3753 7.7168 59.2243C7.62605 59.0729 7.5542 58.967 7.50128 58.9065L7.42188 58.8157H9.42961C9.33132 59.1259 9.33887 59.4283 9.4523 59.7231L11.5508 65.1227L12.8552 61.7082L12.6851 61.2773L12.6511 61.1752L12.0953 59.7231C12.0272 59.5417 11.9478 59.3753 11.857 59.2243C11.7739 59.0729 11.702 58.967 11.6415 58.9065L11.5621 58.8157H13.5699C13.464 59.1108 13.4716 59.4132 13.5926 59.7231L15.691 65.1227L17.7555 59.7231C17.8009 59.5721 17.8084 59.4283 17.7782 59.2923C17.7479 59.156 17.7063 59.0425 17.6534 58.952L17.5627 58.8157Z" fill="#1E1E1E" />
            <path d="M26.4562 65.202C26.4562 65.3229 26.4825 65.4592 26.5355 65.6103C26.5884 65.7616 26.6414 65.8826 26.6943 65.9734L26.7736 66.1094H24.6752C24.8869 65.7767 24.9928 65.4743 24.9928 65.202V62.6498H21.6012V65.202C21.6012 65.3229 21.6277 65.4592 21.6806 65.6103C21.7335 65.7616 21.7865 65.8826 21.8394 65.9734L21.9188 66.1094H19.8203C20.032 65.7767 20.1379 65.4743 20.1379 65.202V59.7231C20.1379 59.6022 20.1114 59.4662 20.0585 59.3148C20.0056 59.1638 19.9526 59.0425 19.8997 58.952L19.8203 58.8157H21.9188C21.7071 59.1484 21.6012 59.4511 21.6012 59.7231V62.3888H24.9928V59.7231C24.9928 59.6022 24.9663 59.4662 24.9134 59.3148C24.8605 59.1638 24.8075 59.0425 24.7546 58.952L24.6752 58.8157H26.7736C26.5618 59.1484 26.4562 59.4511 26.4562 59.7231V65.202Z" fill="#1E1E1E" />
            <path d="M28.9501 59.7231C28.9501 59.6022 28.92 59.4662 28.8595 59.3148C28.8066 59.1638 28.7536 59.0425 28.7007 58.952L28.6211 58.8157H30.7312C30.5193 59.1484 30.4134 59.4511 30.4134 59.7231V65.202C30.4134 65.3229 30.44 65.4592 30.4927 65.6103C30.5457 65.7616 30.5986 65.8826 30.6516 65.9734L30.7312 66.1094H28.6211C28.8404 65.7767 28.9501 65.4743 28.9501 65.202V59.7231Z" fill="#1E1E1E" />
            <path d="M38.3587 59.7314C38.3358 59.6859 38.2982 59.6255 38.2452 59.5497C38.1998 59.4741 38.0901 59.3757 37.9162 59.2548C37.7498 59.1338 37.5684 59.0734 37.3716 59.0734H36.0105V65.1987C36.0105 65.3196 36.0371 65.4559 36.09 65.607C36.143 65.7583 36.1957 65.8793 36.2486 65.9701L36.3282 66.1061H34.2297C34.4415 65.7733 34.5471 65.471 34.5471 65.1987V59.0734H33.186C32.9895 59.0734 32.808 59.1301 32.6417 59.2435C32.4753 59.3494 32.3581 59.4591 32.2901 59.5725L32.1992 59.7314L32.2785 58.7444C32.5358 58.7898 32.8381 58.8124 33.186 58.8124H37.3716C37.523 58.8124 37.6743 58.8086 37.8254 58.8011C37.9767 58.786 38.0901 58.771 38.1657 58.7556L38.2791 58.7444L38.3587 59.7314Z" fill="#1E1E1E" />
            <path d="M40.1962 59.7198C40.1962 59.5989 40.1699 59.4629 40.1169 59.3115C40.064 59.1605 40.0072 59.0392 39.9468 58.9487L39.8672 58.8124H43.9394C44.0908 58.8124 44.2421 58.8086 44.3932 58.8011C44.5445 58.7861 44.6579 58.771 44.7335 58.7557L44.8469 58.7444L44.9265 59.7314C44.9036 59.686 44.866 59.6255 44.813 59.5497C44.7676 59.4742 44.6579 59.3758 44.484 59.2548C44.3176 59.1339 44.1362 59.0734 43.9394 59.0734H41.6482V61.6369H43.2134C43.3648 61.6369 43.5161 61.6106 43.6671 61.5576C43.8185 61.4971 43.9319 61.4404 44.0074 61.3875L44.1209 61.2966V62.2608C43.8639 62.0189 43.5615 61.8979 43.2134 61.8979H41.6482V65.1987C41.6482 65.3651 41.6821 65.5164 41.7504 65.6524C41.8259 65.7809 41.9356 65.8451 42.0791 65.8451H43.9056C44.1096 65.8451 44.2988 65.8188 44.4727 65.7659C44.6542 65.7054 44.7939 65.6374 44.8923 65.5616C44.9982 65.486 45.085 65.4105 45.1533 65.3347C45.2289 65.2517 45.278 65.1837 45.3006 65.1307L45.346 65.0398L45.2439 66.1062H39.8672C40.0865 65.7734 40.1962 65.471 40.1962 65.1987V59.7198Z" fill="#1E1E1E" />
            <path d="M47.0755 59.7231C47.0755 59.6022 47.0489 59.4662 46.9963 59.3148C46.9433 59.1638 46.8903 59.0425 46.8374 58.952L46.7578 58.8157H48.8563C48.6448 59.1484 48.5389 59.4511 48.5389 59.7231V65.202C48.5389 65.3684 48.5727 65.5197 48.641 65.6557C48.7165 65.7842 48.8224 65.8484 48.9584 65.8484H50.3083C50.5126 65.8484 50.7016 65.8221 50.8755 65.7691C51.0569 65.7087 51.197 65.6407 51.2951 65.5649C51.401 65.4893 51.4881 65.4138 51.5561 65.338C51.6316 65.2549 51.6846 65.1869 51.7149 65.134L51.7488 65.0431L51.6469 66.1094H46.7578C46.9696 65.7767 47.0755 65.4743 47.0755 65.202V59.7231Z" fill="#1E1E1E" />
            <path d="M53.8912 59.7231C53.8912 59.6022 53.8611 59.4662 53.8006 59.3148C53.7477 59.1638 53.6947 59.0425 53.6418 58.952L53.5625 58.8157H55.6723C55.4605 59.1484 55.3545 59.4511 55.3545 59.7231V65.202C55.3545 65.3229 55.3812 65.4592 55.4341 65.6103C55.4871 65.7616 55.5397 65.8826 55.5927 65.9734L55.6723 66.1094H53.5625C53.7816 65.7767 53.8912 65.4743 53.8912 65.202V59.7231Z" fill="#1E1E1E" />
            <path d="M58.3319 59.7777C59.0504 59.0442 59.9541 58.6776 61.043 58.6776C62.1319 58.6776 63.0318 59.0442 63.7428 59.7777C64.461 60.5113 64.8201 61.4074 64.8201 62.4662C64.8201 63.5247 64.461 64.4209 63.7428 65.1544C63.0318 65.8805 62.1319 66.2433 61.043 66.2433C59.9541 66.2433 59.0504 65.8805 58.3319 65.1544C57.6213 64.4209 57.2656 63.5247 57.2656 62.4662C57.2656 61.4074 57.6213 60.5113 58.3319 59.7777ZM61.3946 65.8917C62.1357 65.8917 62.6574 65.5818 62.9601 64.9617C63.27 64.3341 63.3759 63.5022 63.2775 62.4662C63.1794 61.4303 62.9146 60.6021 62.4835 59.982C62.0526 59.3544 61.4588 59.0404 60.7027 59.0404C59.9541 59.0404 59.4246 59.3544 59.1147 59.982C58.8123 60.6021 58.7101 61.4303 58.8082 62.4662C58.8991 63.4796 59.1714 64.3037 59.6251 64.9389C60.0864 65.5743 60.6761 65.8917 61.3946 65.8917Z" fill="#1E1E1E" />
            <path d="M71.777 58.8157H72.6278C72.4313 59.1484 72.3329 59.4511 72.3329 59.7231V66.1094H71.5389L66.8428 59.5871C66.8352 59.6175 66.8315 59.6627 66.8315 59.7231V65.202C66.8315 65.3229 66.8543 65.4592 66.8995 65.6103C66.9524 65.7616 67.0054 65.8826 67.0583 65.9734L67.1263 66.1094H66.2756C66.4724 65.7767 66.5708 65.4743 66.5708 65.202V59.7231C66.5708 59.6022 66.5404 59.4662 66.4799 59.3148C66.427 59.1638 66.3702 59.0425 66.3098 58.952L66.2305 58.8157H67.8069L72.0722 64.7483V59.7231C72.0722 59.6022 72.0456 59.4662 71.9926 59.3148C71.9472 59.1638 71.8983 59.0425 71.8453 58.952L71.777 58.8157Z" fill="#1E1E1E" />
        </svg>
    )
}

export default index
import React from "react";

function CartIconWhite() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 15.107C13.401 15.107 12.6 15.908 12.6 16.907C12.6 17.3844 12.7896 17.8423 13.1272 18.1798C13.4648 18.5174 13.9226 18.707 14.4 18.707C14.8774 18.707 15.3352 18.5174 15.6728 18.1798C16.0104 17.8423 16.2 17.3844 16.2 16.907C16.2 16.4296 16.0104 15.9718 15.6728 15.6342C15.3352 15.2967 14.8774 15.107 14.4 15.107ZM0 0.707031V2.50703H1.8L5.04 9.33803L3.816 11.543C3.681 11.795 3.6 12.092 3.6 12.407C3.6 12.8844 3.78964 13.3423 4.12721 13.6798C4.46477 14.0174 4.92261 14.207 5.4 14.207H16.2V12.407H5.778C5.71833 12.407 5.6611 12.3833 5.6189 12.3411C5.5767 12.2989 5.553 12.2417 5.553 12.182C5.553 12.137 5.562 12.101 5.58 12.074L6.39 10.607H13.095C13.77 10.607 14.364 10.229 14.67 9.68003L17.892 3.85703C17.955 3.71303 18 3.56003 18 3.40703C18 3.16834 17.9052 2.93942 17.7364 2.77064C17.5676 2.60185 17.3387 2.50703 17.1 2.50703H3.789L2.943 0.707031M5.4 15.107C4.401 15.107 3.6 15.908 3.6 16.907C3.6 17.3844 3.78964 17.8423 4.12721 18.1798C4.46477 18.5174 4.92261 18.707 5.4 18.707C5.87739 18.707 6.33523 18.5174 6.67279 18.1798C7.01036 17.8423 7.2 17.3844 7.2 16.907C7.2 16.4296 7.01036 15.9718 6.67279 15.6342C6.33523 15.2967 5.87739 15.107 5.4 15.107Z"
        fill="white"
      />
    </svg>
  );
}

export default CartIconWhite;

import React, { useContext } from "react";
import AirTouchFilledDark from "./airtouch-filled-dark.svg";
import AirTouchFilledLight from "./airtouch-filled-light.svg";
import AirTouchOutlineDark from "./airtouch-outline-dark.svg";
import AirTouchOutlineLight from "./airtouch-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function AirTouchIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? AirTouchFilledLight
      : AirTouchOutlineLight
    : isDefaultHeader
    ? AirTouchFilledDark
    : AirTouchOutlineDark;

  return <img src={iconSrc} alt="AirTouch Icon" />;
}

export default AirTouchIcon;

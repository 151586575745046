import React from "react";

function NightIcon({ active }) {
  return active ? (
    <svg
      width="41"
      height="34"
      viewBox="0 0 41 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6379 24.1855C35.3603 23.5093 39.7948 18.7426 39.7948 12.9626C39.7948 12.7119 39.7847 12.4645 39.768 12.2183H39.7197C38.3968 14.7215 35.7276 16.433 32.6482 16.433C28.2533 16.433 24.6907 12.9478 24.6907 8.6485C24.6907 5.63506 26.4412 3.02355 29.0005 1.72933V1.68254C28.7479 1.66587 28.4939 1.65625 28.2372 1.65625C21.854 1.65625 16.6797 6.71839 16.6797 12.9626C16.6797 13.5504 16.7253 14.1279 16.8137 14.6914"
        stroke="#36E061"
        strokeWidth="2.30767"
        strokeMiterlimit="22.9256"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4075 32.507C4.30802 32.507 1.79492 30.0487 1.79492 27.0167C1.79492 24.2244 3.92671 21.9206 6.68507 21.5731C6.98664 17.5533 10.4165 14.3828 14.603 14.3828C17.9404 14.3828 20.7959 16.3969 21.9707 19.2501C22.3185 19.1962 22.675 19.1687 23.0382 19.1687C26.2349 19.1687 28.9169 21.3199 29.6547 24.2231C31.956 24.2654 33.8083 26.1026 33.8083 28.3641C33.8083 30.6519 31.9118 32.507 29.5729 32.507H7.4075Z"
        stroke="#36E061"
        strokeWidth="2.30767"
        strokeMiterlimit="22.9256"
      />
    </svg>
  ) : (
    <svg
      width="41"
      height="34"
      viewBox="0 0 41 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6379 24.1855C35.3603 23.5093 39.7948 18.7426 39.7948 12.9626C39.7948 12.7119 39.7847 12.4645 39.768 12.2183H39.7197C38.3968 14.7215 35.7276 16.433 32.6482 16.433C28.2533 16.433 24.6907 12.9478 24.6907 8.6485C24.6907 5.63506 26.4412 3.02355 29.0005 1.72933V1.68254C28.7479 1.66587 28.4939 1.65625 28.2372 1.65625C21.854 1.65625 16.6797 6.71839 16.6797 12.9626C16.6797 13.5504 16.7253 14.1279 16.8137 14.6914"
        stroke="#FEFEFE"
        strokeWidth="2.30767"
        strokeMiterlimit="22.9256"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4075 32.507C4.30802 32.507 1.79492 30.0487 1.79492 27.0167C1.79492 24.2244 3.92671 21.9206 6.68507 21.5731C6.98664 17.5533 10.4165 14.3828 14.603 14.3828C17.9404 14.3828 20.7959 16.3969 21.9707 19.2501C22.3185 19.1962 22.675 19.1687 23.0382 19.1687C26.2349 19.1687 28.9169 21.3199 29.6547 24.2231C31.956 24.2654 33.8083 26.1026 33.8083 28.3641C33.8083 30.6519 31.9118 32.507 29.5729 32.507H7.4075Z"
        stroke="#FEFEFE"
        strokeWidth="2.30767"
        strokeMiterlimit="22.9256"
      />
    </svg>
  );
}

export default NightIcon;

import React from "react";

function MoneyIconWhite() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 7.70703H24.5V21.707H3.5V7.70703ZM14 11.207C14.9283 11.207 15.8185 11.5758 16.4749 12.2322C17.1313 12.8885 17.5 13.7788 17.5 14.707C17.5 15.6353 17.1313 16.5255 16.4749 17.1819C15.8185 17.8383 14.9283 18.207 14 18.207C13.0717 18.207 12.1815 17.8383 11.5251 17.1819C10.8687 16.5255 10.5 15.6353 10.5 14.707C10.5 13.7788 10.8687 12.8885 11.5251 12.2322C12.1815 11.5758 13.0717 11.207 14 11.207ZM8.16667 10.0404C8.16667 10.6592 7.92083 11.2527 7.48325 11.6903C7.04566 12.1279 6.45217 12.3737 5.83333 12.3737V17.0404C6.45217 17.0404 7.04566 17.2862 7.48325 17.7238C7.92083 18.1614 8.16667 18.7549 8.16667 19.3737H19.8333C19.8333 18.7549 20.0792 18.1614 20.5168 17.7238C20.9543 17.2862 21.5478 17.0404 22.1667 17.0404V12.3737C21.5478 12.3737 20.9543 12.1279 20.5168 11.6903C20.0792 11.2527 19.8333 10.6592 19.8333 10.0404H8.16667Z"
        fill="white"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default MoneyIconWhite;

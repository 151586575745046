import React from "react";

function PlayIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9994 2.66699C13.3623 2.66699 10.7844 3.44898 8.59175 4.91406C6.3991 6.37915 4.69013 8.46153 3.68096 10.8979C2.67179 13.3342 2.40775 16.0151 2.92222 18.6015C3.43669 21.1879 4.70656 23.5637 6.57126 25.4284C8.43597 27.2931 10.8117 28.563 13.3982 29.0775C15.9846 29.5919 18.6655 29.3279 21.1018 28.3187C23.5382 27.3096 25.6205 25.6006 27.0856 23.4079C28.5507 21.2153 29.3327 18.6374 29.3327 16.0003C29.3327 14.2494 28.9878 12.5156 28.3177 10.8979C27.6477 9.2802 26.6656 7.81035 25.4274 6.57224C24.1893 5.33412 22.7195 4.35199 21.1018 3.68193C19.4841 3.01187 17.7503 2.66699 15.9994 2.66699ZM14.1327 21.4003C13.8031 21.6475 13.3327 21.4123 13.3327 21.0003V11.0003C13.3327 10.5883 13.8031 10.3531 14.1327 10.6003L20.7994 15.6003C21.066 15.8003 21.066 16.2003 20.7994 16.4003L14.1327 21.4003Z"
        fill="url(#paint0_linear_2276_2366)"
      />
      <path
        d="M14.1327 21.4003C13.8031 21.6475 13.3327 21.4123 13.3327 21.0003V11.0003C13.3327 10.5883 13.8031 10.3531 14.1327 10.6003L20.7994 15.6003C21.066 15.8003 21.066 16.2003 20.7994 16.4003L14.1327 21.4003Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2276_2366"
          x1="-0.757409"
          y1="-0.103571"
          x2="19.0131"
          y2="10.9959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PlayIcon;

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const SmartAutomation = () => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const [headText, setIsHeadText] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  // Use a ref to track executed steps
  const executedStepsRef = useRef({
    step50: false,
    step60: false,
    step70: false,
    step80: false,
    step90: false,
  });
  // const { scrollYProgress } = useScroll({
  //   target: containerRef,
  //   offset: ["start start", "end end"],
  // });

  // // Disable/Enable scroll using body style for better mobile compatibility
  // const disableScroll = () => {
  //   // document.body.style.overflow = "hidden";
  //   document.documentElement.style.overflow = "hidden";
  // };

  // const enableScroll = () => {
  //   // document.body.style.overflow = "auto";
  //   document.documentElement.style.overflow = "auto";
  // };

  const disableScroll = () => {
    const scrollY = window.scrollY;

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    // Get the stored scroll position
    const scrollY = Math.abs(parseInt(document.body.style.top || "0"));

    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    document.body.style.overflow = "";

    window.scrollTo(0, scrollY);
  };

  const scrollToPosition = (percentage) => {
    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    const position = (percentage / 100) * scrollHeight;
    window.scrollTo({ top: position, behavior: "smooth" });
  };

  // Unlock video playback on iOS (if needed)
  useEffect(() => {
    const unlockVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().then(() => {
          videoRef.current.pause();
          window.removeEventListener("touchstart", unlockVideo);
        });
      }
    };
    window.addEventListener("touchstart", unlockVideo);
    return () => window.removeEventListener("touchstart", unlockVideo);
  }, []);

  useEffect(() => {
    const timeouts = [];
    const handleScroll = () => {
      // const progress = window.scrollY;
      const progress =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
        100;

      // Check thresholds and executed steps from the ref
      if (progress > 10 && !executedStepsRef.current.step50) {
        executedStepsRef.current.step50 = true;
        disableScroll();
        scrollToPosition(12);
        videoRef.current.play();
        const timeout = setTimeout(() => {
          videoRef.current.pause();
          enableScroll();
          setIsHeadText(false);
          setActiveStep(1);
        }, 2000);
        timeouts.push(timeout);
      }

      if (progress > 12 && !executedStepsRef.current.step60) {
        executedStepsRef.current.step60 = true;
        disableScroll();
        scrollToPosition(14);
        videoRef.current.play();
        const timeout = setTimeout(() => {
          videoRef.current.pause();
          enableScroll();
          setActiveStep(2);
        }, 2000);
        timeouts.push(timeout);
      }

      if (progress > 14 && !executedStepsRef.current.step70) {
        executedStepsRef.current.step70 = true;
        disableScroll();
        scrollToPosition(16);
        videoRef.current.play();
        const timeout = setTimeout(() => {
          videoRef.current.pause();
          enableScroll();
          setActiveStep(3);
        }, 2000);
        timeouts.push(timeout);
      }

      if (progress > 16 && !executedStepsRef.current.step80) {
        executedStepsRef.current.step80 = true;
        disableScroll();
        scrollToPosition(18);
        videoRef.current.play();
        const timeout = setTimeout(() => {
          videoRef.current.pause();
          enableScroll();
          setActiveStep(4);
        }, 2300);
        timeouts.push(timeout);
      }

      if (progress > 18 && !executedStepsRef.current.step90) {
        executedStepsRef.current.step90 = true;
        disableScroll();
        scrollToPosition(21);
        videoRef.current.play();
        const timeout = setTimeout(() => {
          videoRef.current.pause();
          enableScroll();
        }, 4000);
        timeouts.push(timeout);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      timeouts.forEach(clearTimeout); // Cleanup all timeouts
      enableScroll();
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height: "300vh" }}>
      <motion.div className="sticky top-0 w-full h-screen flex justify-center items-center relative">
        <video
          ref={videoRef}
          src="/images/home/bedroom_animation.mp4"
          muted
          playsInline
          preload="auto"
          controlsList="nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
          className="w-full h-full object-cover"
        />
        {/* Animated text overlay that fades out based on scroll progress */}
        <motion.div className="absolute inset-0 flex flex-col justify-center items-center gap-[15vh]  pointer-events-none h-screen">
          <motion.div className="h-[55vh] flex justify-center items-end">
            {headText && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                viewport={{ once: true }}
                className="lg:w-[1300px] p-[24px]"
              >
                <h1 className=" font-medium text-[18px] lg:text-[32px] leading-[120%] tracking-wide text-center align-middle text-white">
                  LIFE, SEAMLESSLY LIT
                </h1>
                <p className=" font-bold text-[32px] lg:text-[72px] leading-[120%] tracking-wide text-center align-middle text-white">
                  Experience Smart Automation in Action Effortlessly
                </p>
                <p className=" font-medium text-[22px] lg:text-[36px] leading-[120%] tracking-wide text-center align-middle text-[#F5F5F5]">
                  See the magic of seamless automation.
                </p>
              </motion.div>
            )}
          </motion.div>
          <div className="relative flex justify-center items-end gap-[30px] pb-[100px] h-[30vh] w-full">
            {activeStep === 1 && (
              <motion.div
                className="lg:absolute lg:inset-[5vh] lg:left-[50%] w-[300px] flex flex-col justify-center items-center gap-2 lg:gap-4 text-center  rounded-[32px] backdrop-blur-sm"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
                viewport={{ once: true }}
              >
                <p className="font-semibold text-[24px] lg:text-[32px] leading-[120%] tracking-[0.41%] align-middle text-white">
                  Lights Control
                </p>
                <p className="font-light italic text-[18px] lg:text-[24px] leading-[120%] tracking-[0%] align-middle text-white">
                  Lights on—because bumping into furniture isn’t a personality
                  trait.
                </p>
              </motion.div>
            )}
            {activeStep === 2 && (
              <motion.div
                className="lg:absolute lg:inset-[5vh] lg:left-[25%] w-[300px] flex flex-col justify-center items-center gap-2 lg:gap-4 text-center  rounded-[32px] backdrop-blur-sm"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
                viewport={{ once: true }}
              >
                <p className="font-semibold text-[24px] lg:text-[32px] leading-[120%] tracking-[0.41%] align-middle text-white">
                  Fan Control
                </p>
                <p className="font-light italic text-[18px] lg:text-[24px] leading-[120%] tracking-[0%] align-middle text-white">
                  Fan on—keeping you cool and comfortable, effortlessly.
                </p>
              </motion.div>
            )}
            {activeStep === 3 && (
              <motion.div
                className="lg:absolute lg:inset-[5vh] lg:left-[70%] w-[300px] flex flex-col justify-center items-center gap-2 lg:gap-4 text-center  rounded-[32px] backdrop-blur-sm"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
                viewport={{ once: true }}
              >
                <p className="font-semibold text-[24px] lg:text-[32px] leading-[120%] tracking-[0.41%] align-middle text-white">
                  Curtain Control
                </p>
                <p className="font-light italic text-[18px] lg:text-[24px] leading-[120%] tracking-[0%] align-middle text-white">
                  Curtains open—let the sunshine (or your main character moment)
                  in.
                </p>
              </motion.div>
            )}

            {activeStep === 4 && (
              <motion.div
                className="lg:absolute lg:inset-[5vh] lg:left-[5%] w-[300px] flex flex-col justify-center items-center gap-2 lg:gap-4 text-center  rounded-[32px] backdrop-blur-sm"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
                viewport={{ once: true }}
              >
                <p className="font-semibold text-[24px] lg:text-[32px] leading-[120%] tracking-[0.41%] align-middle text-white">
                  Weather Control
                </p>
                <p className="font-light italic text-[18px] lg:text-[24px] leading-[120%] tracking-[0%] align-middle text-white">
                  Temperature set—because guessing the weather is so last
                  season.
                </p>
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SmartAutomation;

import React from "react";
import { motion } from "framer-motion";
import { TimelessArray } from "./Constants";

function Timeless() {
  return (
    <div className="p-[24px] lg:px-[165px] lg:py-[150px] flex flex-col justify-center items-center gap-[24px] lg:gap-[48px]">
      <motion.div
        className=" w-full lg:w-[1000px] flex flex-col justify-center items-center gap-[12px] lg:gap-[24px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <h1 className="font-semibold text-[22px] lg:text-[64px] leading-[100%] tracking-[0%] align-middle text-center">
          {TimelessArray?.label}
        </h1>
        <p className="font-semibold text-[16px] lg:text-[24px] leading-[140%] tracking-[1%] text-center align-middle text-[#1E1E1ECC] text-center">
          {TimelessArray?.text}
        </p>
      </motion.div>
      <div className="grid grid-cols-1 gap-[8px] text-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[8px]">
          <div className="flex  flex-col lg:flex-col-reverse gap-[8px]">
            <div className="bg-custom-bg-black rounded-[32px] h-[250px] lg:h-[350px] p-[20px] lg:p-[40px]">
              <motion.div
                className="flex flex-col gap-[12px]"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                viewport={{ once: true }}
              >
                <h2 className="text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#ffffff]">
                  {TimelessArray?.secondCard?.label}
                </h2>
                <p className="text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#CCCCCC]">
                  {TimelessArray?.secondCard?.text}
                </p>
              </motion.div>
            </div>

            <div className="bg-custom-bg-black h-[500px] lg:h-[700px] rounded-[32px] flex items-center justify-start">
              <motion.img
                src={TimelessArray?.firstCard?.img}
                alt="Sensor Design"
                className="w-[270px] h-[300px] lg:w-[430px] lg:h-[480px]"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                viewport={{ once: true }}
              />
            </div>
          </div>

          <div className="grid gap-[8px]">
            <div className="bg-custom-bg-black rounded-[32px] h-[250px] lg:h-[350px]  p-[20px] lg:p-[40px] ">
              <motion.div
                className="flex flex-col gap-[12px]"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                viewport={{ once: true }}
              >
                <h2 className=" text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#ffffff]">
                  {TimelessArray?.thirdCard?.label}
                </h2>
                <p className=" text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#CCCCCC]">
                  {TimelessArray?.thirdCard?.text}
                </p>
              </motion.div>
            </div>

            <div className="bg-custom-bg-black h-[500px] lg:h-[700px] rounded-[32px] flex items-center justify-center ">
              <motion.img
                src={TimelessArray?.fourthCard?.img}
                alt="360 Detection"
                className="rounded-[32px] w-[400px] h-[400px] lg:w-[550px] lg:h-[550px]"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                viewport={{ once: true }}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[8px] bg-custom-bg-black rounded-[32px] h-[600px] lg:h-[450px]">
          <div className="  flex items-center justify-start overflow-hidden">
            <motion.img
              src={TimelessArray?.fiveCard?.img}
              alt="Lux Settings"
              className="rounded-[32px] w-[350px] h-[350px] lg:w-[450px] lg:h-[450px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              viewport={{ once: true }}
            />
          </div>

          <div className=" flex flex-col justify-center items-start rounded-[32px]">
            <motion.div
              className="flex flex-col gap-[12px] p-[20px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              viewport={{ once: true }}
            >
              <h2 className="text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#ffffff]">
                {TimelessArray?.fiveCard?.label}
              </h2>
              <p className="text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#CCCCCC]">
                {TimelessArray?.fiveCard?.text}
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeless;

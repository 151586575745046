import React from "react";

function PrevIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4415 16.916C11.8555 17.5 11.8535 18.446 12.4355 19.034L19.3925 26.056C19.6855 26.352 20.0715 26.5 20.4575 26.5C20.8395 26.5 21.2215 26.355 21.5135 26.065C22.1025 25.482 22.1055 24.533 21.5225 23.944L15.6184 17.984L21.5594 12.062C22.1454 11.478 22.1474 10.5279 21.5624 9.941C20.9784 9.354 20.0264 9.353 19.4414 9.938L12.4415 16.916Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default PrevIcon;

import React, { useEffect, useRef } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import {
  LocationAirIcon,
  ReadingBookIcon,
  WalkingIcon,
  WatchIcon,
} from "../../../assets/svg";

function Benefits({ setBgColor }) {
  const containerRef = useRef(null);

  // const isMobile = window?.innerWidth <= 768;

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 20 });
  };
  const useSpringTransformSmall = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 10 });
  };

  const x1 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["5%", "15%", "15%", "15%", "15%"]
  );
  const y1 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["20%", "-100%", "-200%", "-300%", "-400%"]
  );

  const x2 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["15%", "5%", "15%", "15%", "15%"]
  );
  const y2 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["20%", "-100%", "-200%", "-300%", "-400%"]
  );

  const x3 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["15%", "15%", "5%", "15%", "15%"]
  );
  const y3 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["20%", "-100%", "-200%", "-300%", "-400%"]
  );

  const x4 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["15%", "15%", "15%", "5%", "15%"]
  );
  const y4 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["20%", "-100%", "-200%", "-300%", "-400%"]
  );

  // Smooth transitions
  const mobileY1 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, -25, -50, -75, -100]
  );
  const mobileY2 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, -25, -50, -75]
  );
  const mobileY3 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 0, -25, -50]
  );
  const mobileY4 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [0, 0, 0, 0, -25]
  );

  const mobileOpacity1 = useSpringTransform(scrollYProgress, [0, 0.1], [1, 1]);
  const mobileOpacity2 = useSpringTransform(
    scrollYProgress,
    [0.25, 0.3],
    [0, 1]
  );
  const mobileOpacity3 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.55],
    [0, 1]
  );
  const mobileOpacity4 = useSpringTransform(
    scrollYProgress,
    [0.75, 0.8],
    [0, 1]
  );

  const mobileScale1 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [1, 0.9, 0.8, 0.7, 0.6]
  );
  const mobileScale2 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [1, 1, 0.9, 0.8, 0.7]
  );
  const mobileScale3 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [1, 1, 1, 0.9, 0.8]
  );
  const mobileScale4 = useSpringTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [1, 1, 1, 1, 0.9]
  );

  const color1 = useTransform(
    scrollYProgress,
    [0, 0.2, 0.25, 1],
    ["#f87171", "#fca5a5", "#ffffff", "#ffffff"]
  );
  const color2 = useTransform(
    scrollYProgress,
    [0.2, 0.25, 0.45, 0.5, 1],
    ["#ffffff", "#facc15", "#fde047", "#ffffff", "#ffffff"]
  );
  const color3 = useTransform(
    scrollYProgress,
    [0.45, 0.5, 0.7, 0.75, 1],
    ["#ffffff", "#86efac", "#4ade80", "#ffffff", "#ffffff"]
  );
  const color4 = useTransform(
    scrollYProgress,
    [0.7, 0.75, 1],
    ["#ffffff", "#60a5fa", "#93c5fd"]
  );

  // Move up & fade in on scroll
  const text1y = useSpringTransformSmall(scrollYProgress, [0, 0.25], [50, 0]);
  const text1Opacity = useSpringTransformSmall(
    scrollYProgress,
    [0, 0.05, 0.2, 0.22],
    [1, 1, 1, 0]
  );
  const text2y = useSpringTransformSmall(scrollYProgress, [0.25, 0.5], [50, 0]);
  const text2Opacity = useSpringTransformSmall(
    scrollYProgress,
    [0.28, 0.3, 0.45, 0.47],
    [0, 1, 1, 0]
  );
  const text3y = useSpringTransformSmall(scrollYProgress, [0.5, 0.75], [50, 0]);
  const text3Opacity = useSpringTransformSmall(
    scrollYProgress,
    [0.53, 0.55, 0.6, 0.72],
    [0, 1, 1, 0]
  );
  const text4y = useSpringTransformSmall(scrollYProgress, [0.75, 1], [50, 0]);
  const text4Opacity = useSpringTransformSmall(
    scrollYProgress,
    [0.78, 0.8, 0.95, 1],
    [0, 1, 1, 1]
  );

  const bgColor = useTransform(
    scrollYProgress,
    [0.95, 0.99],
    ["#FFFFFF", "#000000"]
  );

  useEffect(() => {
    const unsubscribe = bgColor.on("change", (latest) => {
      setBgColor(latest);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [bgColor, setBgColor]);

  return (
    <>
      <div ref={containerRef} className="relative h-[500vh] ">
        {/* Sticky Container */}
        <div className="sticky top-0 w-full h-screen flex flex-col lg:flex-row">
          {/* Left Section - ordered second on mobile */}
          <div className="w-full lg:w-1/2 h-[50vh] lg:h-screen  flex items-center justify-center order-2 lg:order-1">
            <div className="relative w-full h-full px-[48px] lg:px-[100px]">
              <div className=" w-full flex justify-start items-center gap-[24px] pt-[5vh] lg:pt-[25vh]">
                <div className=" w-full flex justify-start items-center gap-[8px] lg:gap-[24px]">
                  <motion.div
                    className="flex items-center justify-center w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full"
                    style={{ backgroundColor: color1 }}
                  >
                    <ReadingBookIcon />
                  </motion.div>

                  <motion.div
                    className="flex items-center justify-center w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full"
                    style={{ backgroundColor: color2 }}
                  >
                    <LocationAirIcon />
                  </motion.div>

                  <motion.div
                    className="flex items-center justify-center w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full"
                    style={{ backgroundColor: color3 }}
                  >
                    <WalkingIcon />
                  </motion.div>

                  <motion.div
                    className="flex items-center justify-center w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full"
                    style={{ backgroundColor: color4 }}
                  >
                    <WatchIcon />
                  </motion.div>
                </div>
              </div>

              <div className="relative w-full pt-[20px]">
                <motion.div
                  className="absolute flex flex-col justify-center items-start gap-[24px] lg:w-[550px]"
                  style={{ y: text1y, opacity: text1Opacity }}
                >
                  <h1 className=" font-inter font-semibold text-[24px] lg:text-[46px] leading-[140%] tracking-[-0.41%] align-middle">
                    Set the Scene for Reading
                  </h1>
                  <p className="font-inter font-medium text-[16px] lg:text-[24px] leading-[140%] tracking-[0%] align-middle text-[#808080]">
                    Dim the lights, mute notifications, and create a cozy vibe
                    instantly—perfect for unwinding with a book on a quiet
                    evening.
                  </p>
                </motion.div>
                <motion.div
                  className="absolute flex flex-col justify-center items-start gap-[24px] lg:w-[550px]"
                  style={{ y: text2y, opacity: text2Opacity }}
                >
                  <h1 className=" font-inter font-semibold text-[24px] lg:text-[46px] leading-[140%] tracking-[-0.41%] align-middle">
                    Home Knows You’re Near
                  </h1>
                  <p className="font-inter font-medium text-[16px] lg:text-[24px] leading-[140%] tracking-[0%] align-middle text-[#808080]">
                    As you approach, doors unlock, lights turn on, and the heat
                    kicks in—welcoming you home without lifting a finger.
                  </p>
                </motion.div>
                <motion.div
                  className="absolute flex flex-col justify-center items-start gap-[24px] lg:w-[550px]"
                  style={{ y: text3y, opacity: text3Opacity }}
                >
                  <h1 className=" font-inter font-semibold text-[24px] lg:text-[46px] leading-[140%] tracking-[-0.41%] align-middle">
                    Your Path, Always Lit
                  </h1>
                  <p className="font-inter font-medium text-[16px] lg:text-[24px] leading-[140%] tracking-[0%] align-middle text-[#808080]">
                    Walk in, lights brighten; leave the room, they fade—smart
                    sensors make your home responsive and energy-efficient all
                    day long
                  </p>
                </motion.div>
                <motion.div
                  className="absolute flex flex-col justify-center items-start gap-[24px] lg:w-[550px]"
                  style={{ y: text4y, opacity: text4Opacity }}
                >
                  <h1 className=" font-inter font-semibold text-[24px] lg:text-[46px] leading-[140%] tracking-[-0.41%] align-middle">
                    Lights That Follow You
                  </h1>
                  <p className="font-inter font-medium text-[16px] lg:text-[24px] leading-[140%] tracking-[0%] align-middle text-[#808080]">
                    As you walk room to room, lights brighten ahead and fade
                    behind—keeping every step lit, energy-efficient, and
                    effortless.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>

          {/* Right Section - ordered first on mobile */}
          <div className="w-full lg:w-1/2 h-[50vh] lg:h-screen flex items-center justify-center order-1 lg:order-2">
            <div className="w-full h-full hidden lg:flex flex-col items-center justify-center">
              <motion.div className="w-full h-full overflow-hidden">
                <motion.div
                  style={{ x: x1, y: y1 }}
                  className="w-[40vw] h-[60vh] rounded-[90px] flex justify-center items-center my-[80px] overflow-hidden"
                >
                  <video
                    src="/images/home/readingmood.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover"
                  />
                </motion.div>
                <motion.div
                  style={{ x: x2, y: y2 }}
                  className="w-[40vw] h-[60vh] rounded-[90px] flex justify-center items-center my-[80px] overflow-hidden"
                >
                  <video
                    src="/images/home/location.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover"
                  />
                </motion.div>

                <motion.div
                  style={{ x: x3, y: y3 }}
                  className="w-[40vw] h-[60vh] rounded-[90px] flex justify-center items-center my-[80px] overflow-hidden"
                >
                  <video
                    src="/images/home/Schedule.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover"
                  />
                </motion.div>

                <motion.div
                  style={{ x: x4, y: y4 }}
                  className="w-[40vw] h-[60vh]  rounded-[90px] flex justify-center items-center my-[80px] overflow-hidden"
                >
                  <video
                    src="/images/home/motion.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover"
                  />
                </motion.div>
              </motion.div>
            </div>

            <div className="w-full h-full flex lg:hidden  flex-col items-center justify-center">
              <motion.div className="relative w-full h-full overflow-hidden">
                <motion.div
                  style={{
                    y: mobileY1,
                    opacity: mobileOpacity1,
                    scale: mobileScale1,
                  }}
                  className="absolute bottom-0 w-full h-full rounded-[16px] flex justify-center items-center overflow-hidden px-[60px] pt-[160px]"
                >
                  <video
                    src="/images/home/readingmood.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover rounded-[16px]"
                  />
                </motion.div>

                <motion.div
                  style={{
                    y: mobileY2,
                    opacity: mobileOpacity2,
                    scale: mobileScale2,
                  }}
                  className="absolute bottom-0 w-full h-full rounded-[16px] flex justify-center items-center overflow-hidden px-[60px] pt-[160px]"
                >
                  <video
                    src="/images/home/location.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover rounded-[16px]"
                  />
                </motion.div>

                <motion.div
                  style={{
                    y: mobileY3,
                    opacity: mobileOpacity3,
                    scale: mobileScale3,
                  }}
                  className="absolute bottom-0 w-full h-full rounded-[16px] flex justify-center items-center overflow-hidden px-[60px] pt-[160px]"
                >
                  <video
                    src="/images/home/Schedule.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover rounded-[16px]"
                  />
                </motion.div>

                <motion.div
                  style={{
                    y: mobileY4,
                    opacity: mobileOpacity4,
                    scale: mobileScale4,
                  }}
                  className="absolute bottom-0 w-full h-full rounded-[16px] flex justify-center items-center overflow-hidden px-[60px] pt-[160px]"
                >
                  <video
                    src="/images/home/motion.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-cover rounded-[16px]"
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[50vh]"></div>
    </>
  );
}

export default Benefits;

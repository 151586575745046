import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import {
  ConatctMain,
  Benefit,
  WhyChooseUs,
  Featured,
  PoshSeries,
  QuartzSeries,
  SmartControl,
  Warranty,
  Welcome,
  ContactUSNavbar,
  ContactUSFooter,
  SuccessStories,
  Testimonials,
  Frequently,
  FooterButton,
} from "../../components/contactUs";
import { ContactModalContext } from "../../config/contexts";

function Index() {
  const { openModal } = useContext(ContactModalContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollTop / docHeight) * 100;

      if (scrollPercent >= 50) {
        openModal();
        // Optional: remove listener after modal opens
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div className="">
        <Helmet>
          {/* Example: Google Tag Manager Script */}

          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WLJT625F');`}
          </script>
          <noscript>
            {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLJT625F"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
          </noscript>
        </Helmet>

        <ContactUSNavbar />
        {/* Welcome */}
        <Welcome />
        {/* Benefit section */}
        <Benefit />
        {/* Success Stories */}
        <SuccessStories />
        {/* why choose whitelion */}
        <WhyChooseUs />
        {/* Featured  */}
        <Featured />
        {/* Posh Series */}
        <PoshSeries />
        {/* Quartz Series */}
        <QuartzSeries />
        {/* Smart Control */}
        <SmartControl isContact={true} />
        {/* Warranty */}
        <Warranty />
        {/* Testimonials */}
        <Testimonials />
        {/* Frequently Asked Questions */}
        <Frequently />
        {/* Conatct form */}
        <ConatctMain />
        {/* ContactUSFooter */}
        <ContactUSFooter />
        {/* FooterButton */}
        <FooterButton />
      </div>
    </>
  );
}

export default Index;

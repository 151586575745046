import React from "react";

function CallIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.68 20.5067C25.04 20.5067 23.4533 20.24 21.9733 19.76C21.7414 19.6819 21.4922 19.6705 21.2541 19.727C21.0161 19.7836 20.7986 19.9059 20.6267 20.08L18.5333 22.7067C14.76 20.9067 11.2267 17.5067 9.34667 13.6L11.9467 11.3867C12.3067 11.0133 12.4133 10.4933 12.2667 10.0267C11.7733 8.54667 11.52 6.96 11.52 5.32C11.52 4.6 10.92 4 10.2 4H5.58667C4.86667 4 4 4.32 4 5.32C4 17.7067 14.3067 28 26.68 28C27.6267 28 28 27.16 28 26.4267V21.8267C28 21.1067 27.4 20.5067 26.68 20.5067Z"
        fill="white"
      />
    </svg>
  );
}

export default CallIcon;

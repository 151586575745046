import React from "react";
// import { motion } from "framer-motion";
import { ElegantTechArray } from "./Constants";

function ElegantTech() {
  return (
    <div className=" grid grid-cols-1 gap-[24px] lg:gap-[64px] px-[24px] py-[60px] lg:px-[48px] lg:py-[150px] bg-custom-bg-white">
      <div className="">
        <h1 className="font-sfpro font-semibold text-[40px] lg:text-[64px] leading-[110%] tracking-[0%] text-center align-middle">
          {ElegantTechArray?.label}
        </h1>
        <p className="font-inter font-semibold text-[14px] lg:text-[20px] leading-[150%] tracking-[0%] text-center align-middle opacity-80">
          {ElegantTechArray?.text}
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[16px] lg:gap-[24px] ">
        {/* first card */}

        <div className=" rounded-[8px] flex flex-col justify-center items-center pt-[12px] lg:pt-[48px] gap-[12px] lg:gap-[24px] bg-white ">
          <div className="flex flex-col justify-center items-center  lg:gap-[12px] px-[24px]">
            <h1 className="font-bold text-[26px] lg:text-[38px]   text-center align-middle">
              {ElegantTechArray?.first?.cardLabel}
            </h1>
            <p className="font-sfpro font-normal text-[14px] lg:text-[21px]  text-center align-middle">
              {ElegantTechArray?.first?.cardText}
            </p>
            <div className="pt-[6px]">
              <button className="bg-black text-white text-[12px] lg:text-[20px] px-[12px] py-[4px] lg:px-[20px] lg:py-[8px] rounded-[8px]">
                Know more
              </button>
            </div>
          </div>
          <div className="overflow-hidden ">
            <img
              src={ElegantTechArray?.first?.img}
              alt="app"
              loading="lazy"
              className=" h-[200px] lg:h-[340px]"
            />
          </div>
        </div>

        {/* second card */}
        <div className=" rounded-[8px] flex flex-col justify-end items-center pt-[12px] lg:pt-[48px]  gap-[12px] lg:gap-[24px] bg-black">
          <div className="flex flex-col justify-center items-center gap-[12px] text-white px-[24px]">
            <h1 className="font-bold text-[26px] lg:text-[38px]  text-center align-middle ">
              {ElegantTechArray?.second?.cardLabel}
            </h1>
            <p className="font-sfpro font-normal text-[14px] lg:text-[21px]  text-center align-middle">
              {ElegantTechArray?.second?.cardText}
            </p>
            <div className="pt-[6px]">
              <button className="bg-white text-black text-[12px] lg:text-[20px] px-[12px] py-[4px] lg:px-[20px] lg:py-[8px] rounded-[8px]">
                Know more
              </button>
            </div>
          </div>
          <div className=" overflow-hidden">
            <img
              src={ElegantTechArray?.second?.img}
              alt="app"
              loading="lazy"
              className=" h-[200px] lg:h-[340px]  "
            />
          </div>
        </div>

        {/* thirdCard */}
        <div className=" rounded-[8px] flex flex-col justify-center items-center pt-[12px] lg:pt-[48px]  gap-[12px] lg:gap-[24px] bg-black">
          <div className="flex flex-col justify-center items-center gap-[12px] text-white px-[24px]">
            <h1 className="font-bold text-[26px] lg:text-[38px] leading-[44px] tracking-[1%] text-center align-middle">
              {ElegantTechArray?.third?.cardLabel}
            </h1>
            <p className="font-sfpro font-normal text-[14px] lg:text-[21px] leading-[26px] tracking-[0.34px] text-center align-middle">
              {ElegantTechArray?.third?.cardText}
            </p>
            <div className="pt-[6px]">
              <button className="bg-white text-black text-[12px] lg:text-[20px] px-[12px] py-[4px] lg:px-[20px] lg:py-[8px] rounded-[8px]">
                Know more
              </button>
            </div>
          </div>
          <div className="w-full overflow-hidden rounded-[8px] flex justify-center items-center">
            <img
              src={ElegantTechArray?.third?.img}
              alt="app"
              loading="lazy"
              className="h-[200px] lg:h-[340px]"
            />
          </div>
        </div>

        {/* fourth card */}
        <div className=" rounded-[8px] flex flex-col justify-center items-center pt-[12px] lg:pt-[48px] gap-[12px] lg:gap-[24px] bg-white">
          <div className="flex flex-col justify-center items-center gap-[12px] px-[24px]">
            <h1 className="font-bold text-[26px] lg:text-[38px] leading-[44px] tracking-[1%] text-center align-middle">
              {ElegantTechArray?.fourth?.cardLabel}
            </h1>
            <p className="font-sfpro font-normal text-[14px] lg:text-[21px] leading-[26px] tracking-[0.34px] text-center align-middle">
              {ElegantTechArray?.fourth?.cardText}
            </p>
            <div className="pt-[6px]">
              <button className="bg-black text-white text-[12px] lg:text-[20px] px-[12px] py-[4px] lg:px-[20px] lg:py-[8px] rounded-[8px]">
                Know more
              </button>
            </div>
          </div>
          <div className="overflow-hidden">
            <img
              src={ElegantTechArray?.fourth?.img}
              alt="app"
              loading="lazy"
              className=" h-[200px] lg:h-[340px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElegantTech;

import React, { useContext } from "react";
import PoshFilledDark from "./posh-filled-dark.svg";
import PoshFilledLight from "./posh-filled-light.svg";
import PoshOutlineDark from "./posh-outline-dark.svg";
import PoshOutlineLight from "./posh-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function PoshIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? PoshFilledLight
      : PoshOutlineLight
    : isDefaultHeader
    ? PoshFilledDark
    : PoshOutlineDark;

  return <img src={iconSrc} alt="posh Icon" />;
}

export default PoshIcon;

import React from "react";
import { motion } from "framer-motion";

function Featured() {
  const FeaturedArray = [
    { img: "/images/clients/mckinsey.png", text: "mckinsey" },
    { img: "/images/clients/vtp.png", text: "vtp" },
    { img: "/images/clients/sangini.png", text: "sangini" },
    { img: "/images/clients/arvind.png", text: "arvind" },
    { img: "/images/clients/kia.png", text: "kia" },
  ];
  return (
    <div className="bg-[#1E1E1E]">
      <div className="py-8 lg:py-16 mx-auto px-4 border overflow-hidden">
        <h2 className="mb-8 lg:mb-16 text-[#6E6E73] text-center font-semibold leading-[28.8px] tracking-[-0.41%] text-[24px] md:text-[32px] md:leading-[38.4px]">
          Our Esteemed Clients
        </h2>
        <div className="flex MyGradient">
          {[...Array(2)].map((_, i) => (
            <motion.div
              key={i}
              initial={{ x: "0%" }}
              animate={{ x: "-100%" }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              className="flex flex-shrink-0"
            >
              {FeaturedArray.map((item, index) => (
                <img
                  className="pr-20"
                  src={item.img}
                  key={index}
                  alt={item.text}
                />
              ))}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Featured;

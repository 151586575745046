import React from "react";
import Welcome from "./Welcome";
import Timeless from "./Timeless";
import MotionSensor from "./MotionSensor";
import Precision from "./Precision";

function index() {
  return (
    <div className=" w-full">
      <Welcome />
      <Timeless />
      <MotionSensor />
      <Precision />
    </div>
  );
}

export default index;

import React from "react";
import { motion, useSpring, useTransform } from "framer-motion";
import {
  AccessControlIcon,
  ActiveSessionsIcon,
  AreasIcon,
  DeviceManagerIcon,
  NotificationsIcon,
  SecurityControlIcon,
  ThemeIcon,
} from "../../../assets/svg";

function ControlRight({ scrollYProgress }) {
  const isMobile = window?.innerWidth <= 768;

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 30 });
  };

  const yTransform1 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [50, 150, 250, 0, 0, 0, -120, -220]
  );
  const yTransform2 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-30, 70, 170, 0, 0, 0, -120, -220]
  );
  const yTransform3 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-115, -15, 85, 0, 0, 0, -120, -220]
  );
  const yTransform4 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-200, -100, 0, 0, 0, 0, -120, -220]
  );
  const yTransform5 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-285, -185, -85, 0, 0, 0, -120, -220]
  );
  const yTransform6 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-370, -270, -170, 0, 0, 0, -120, -220]
  );
  const yTransform7 = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6],
    [-450, -350, -250, 0, 0, 0, -120, -220]
  );

  const xTransform = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.25, 0.3],
    [0, -50, 0, 0, 0]
  );
  const divOpacity = useSpringTransform(scrollYProgress, [0, 0.1], [0, 1]);
  const rotateValue = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2],
    [50, 25, 0]
  );
  const scaleValue = useSpringTransform(
    scrollYProgress,
    [0, 0.1, 0.2],
    [0.4, 0.7, 1]
  );
  const bgColorValue = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3],
    ["#000000", "#161618"]
  );
  const xMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [0, 50]);
  const yMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [0, -100]);
  const opacityMove = useSpringTransform(scrollYProgress, [0.7, 0.8], [1, 0]);

  return (
    <motion.div className="relative w-full  h-[450px] lg:h-[700px] flex items-center justify-center px-[24px] lg:px-[100px] pt-[100px] lg:py-[80px] overflow-hidden">
      <motion.div
        className="w-full h-full   bg-[#161618] overflow-hidden text-black rounded-[8px] lg:rounded-[16px]"
        style={{
          backgroundColor: bgColorValue,
          x: !isMobile ? xMove : undefined,
          y: !isMobile ? yMove : undefined,
          opacity: opacityMove,
        }}
      >
        <motion.div
          className="w-[320px]  lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform1,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <AccessControlIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Access Control
          </p>
        </motion.div>

        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform2,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <AreasIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Areas
          </p>
        </motion.div>
        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform3,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <DeviceManagerIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Device Manager
          </p>
        </motion.div>
        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform4,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <SecurityControlIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Security
          </p>
        </motion.div>
        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform5,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <NotificationsIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Notification
          </p>
        </motion.div>
        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform6,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <ThemeIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Select Theme
          </p>
        </motion.div>
        <motion.div
          className="w-[320px] lg:w-[400px] h-[71px] lg:h-[100px] mx-auto my-[8px] bg-[#FFFFFF] rounded-[8px] lg:rounded-[16px]  flex items-center justify-start gap-4 px-4 lg:px-8"
          style={{
            y: yTransform7,
            x: xTransform,
            opacity: divOpacity,
            rotate: rotateValue,
            scale: scaleValue,
          }}
        >
          <ActiveSessionsIcon />
          <p className="font-medium text-[24px] md:text-[32px] leading-[140%] tracking-[-0.41%] font-inter">
            Active Sessions
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ControlRight;

import React from "react";

function WhitelionWhite() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.9773 40.9039L41.2589 44.9781V49.5919H41.3129L48.0313 45.5447V40.9039H47.9773Z"
        fill="white"
      />
      <path
        d="M32.8137 40.9039L39.5321 44.9781V49.5919H39.4781L32.7598 45.5447V40.9039H32.8137Z"
        fill="white"
      />
      <path
        d="M40.382 38.6375L36.3483 41.0388L40.382 43.4941L44.4157 41.0388L40.382 38.6375Z"
        fill="white"
      />
      <path
        d="M53.8862 30.0573L49.7581 28.1147V44.4924L53.8862 42.0371V30.0573Z"
        fill="white"
      />
      <path
        d="M26.8778 30.0573L31.0061 28.1147V44.4924L26.8778 42.0371V30.0573Z"
        fill="white"
      />
      <path
        d="M48.0313 27.8988L44.1189 28.8432V33.3761L41.2589 35.0489V37.1804L46.1156 40.0135L48.0313 38.8803V27.8988Z"
        fill="white"
      />
      <path
        d="M32.7598 27.8988L36.6721 28.8432V33.3761L39.5321 35.0489V37.1804L34.6754 40.0135L32.7598 38.8803V27.8988Z"
        fill="white"
      />
      <path
        d="M40.382 23.6627L34.3787 26.5228L38.3989 27.4941V32.4047L40.382 33.5649L42.3922 32.4047V27.4671L46.3854 26.5228L40.382 23.6627Z"
        fill="white"
      />
      <path
        d="M26.8778 28.1686V24.4722C31.3568 23.1879 36.4067 22.7319 38.372 22.6644C36.2674 23.6681 29.8324 26.7521 26.8778 28.1686Z"
        fill="white"
      />
      <path
        d="M53.8862 28.1686V24.4722C49.4073 23.1879 44.3571 22.7319 42.3921 22.6644C44.4966 23.6681 50.9317 26.7521 53.8862 28.1686Z"
        fill="white"
      />
      <path
        d="M53.8863 15.5143V22.6239C42.8245 19.5512 31.2589 21.3387 26.8778 22.62V15.5683L32.7733 20.6948L40.3821 15.5413L48.0988 20.6678L53.8863 15.5143Z"
        fill="white"
      />
      <path
        d="M19.4387 57.5433H20.3483C20.1791 57.7338 20.024 58.0158 19.883 58.3895L17.5667 64.4077H16.6782L15.1763 60.5685L13.7062 64.4077H12.8177L10.4803 58.3895C10.4168 58.2203 10.3428 58.0652 10.2581 57.9243C10.1735 57.7832 10.1065 57.6845 10.0572 57.6281L9.98315 57.5433H11.8552C11.7636 57.8326 11.7706 58.1145 11.8764 58.3895L13.8331 63.4242L15.0494 60.2404L14.8908 59.8386L14.859 59.7434L14.3408 58.3895C14.2773 58.2203 14.2033 58.0652 14.1187 57.9243C14.0411 57.7832 13.9741 57.6845 13.9177 57.6281L13.8437 57.5433H15.7157C15.617 57.8185 15.6241 58.1005 15.7369 58.3895L17.6936 63.4242L19.6186 58.3895C19.6609 58.2486 19.6679 58.1145 19.6397 57.9877C19.6115 57.8606 19.5727 57.7549 19.5234 57.6704L19.4387 57.5433Z"
        fill="white"
      />
      <path
        d="M27.731 63.4981C27.731 63.6109 27.7555 63.738 27.8049 63.8789C27.8543 64.02 27.9036 64.1327 27.953 64.2175L28.0269 64.3443H26.0703C26.2677 64.034 26.3664 63.752 26.3664 63.4981V61.1184H23.204V63.4981C23.204 63.6109 23.2287 63.738 23.278 63.8789C23.3274 64.02 23.3768 64.1327 23.4261 64.2175L23.5002 64.3443H21.5435C21.7409 64.034 21.8396 63.752 21.8396 63.4981V58.3895C21.8396 58.2767 21.8149 58.1499 21.7656 58.0088C21.7162 57.8679 21.6668 57.7549 21.6175 57.6704L21.5435 57.5433H23.5002C23.3027 57.8536 23.204 58.1359 23.204 58.3895V60.875H26.3664V58.3895C26.3664 58.2767 26.3418 58.1499 26.2924 58.0088C26.2431 57.8679 26.1937 57.7549 26.1443 57.6704L26.0703 57.5433H28.0269C27.8294 57.8536 27.731 58.1359 27.731 58.3895V63.4981Z"
        fill="white"
      />
      <path
        d="M30.0546 58.3895C30.0546 58.2767 30.0265 58.1499 29.9701 58.0088C29.9208 57.8679 29.8714 57.7549 29.822 57.6704L29.7478 57.5433H31.7153C31.5178 57.8536 31.419 58.1359 31.419 58.3895V63.4981C31.419 63.6109 31.4439 63.738 31.493 63.8789C31.5423 64.02 31.5917 64.1327 31.6411 64.2175L31.7153 64.3443H29.7478C29.9523 64.034 30.0546 63.752 30.0546 63.4981V58.3895Z"
        fill="white"
      />
      <path
        d="M38.8296 58.4003C38.8083 58.3579 38.7732 58.3015 38.7238 58.2308C38.6814 58.1604 38.5792 58.0687 38.417 57.9559C38.2619 57.8431 38.0927 57.7867 37.9092 57.7867H36.64V63.4981C36.64 63.6109 36.6649 63.738 36.7142 63.8788C36.7636 64.02 36.8127 64.1327 36.8621 64.2175L36.9363 64.3443H34.9796C35.1771 64.034 35.2756 63.752 35.2756 63.4981V57.7867H34.0064C33.8232 57.7867 33.654 57.8396 33.4989 57.9453C33.3437 58.0441 33.2344 58.1464 33.171 58.2521L33.0863 58.4003L33.1602 57.4799C33.4001 57.5223 33.6821 57.5433 34.0064 57.5433H37.9092C38.0504 57.5433 38.1915 57.5398 38.3323 57.5328C38.4734 57.5188 38.5792 57.5047 38.6496 57.4904L38.7554 57.4799L38.8296 58.4003Z"
        fill="white"
      />
      <path
        d="M40.5429 58.3895C40.5429 58.2767 40.5183 58.1499 40.4689 58.0088C40.4196 57.8679 40.3667 57.7549 40.3103 57.6704L40.2361 57.5433H44.0332C44.1743 57.5433 44.3154 57.5398 44.4563 57.5328C44.5974 57.5188 44.7031 57.5047 44.7736 57.4904L44.8793 57.4799L44.9535 58.4003C44.9322 58.3579 44.8971 58.3015 44.8478 58.2308C44.8054 58.1604 44.7031 58.0687 44.541 57.9559C44.3858 57.8431 44.2167 57.7867 44.0332 57.7867H41.8968V60.177H43.3562C43.4973 60.177 43.6384 60.1524 43.7793 60.1031C43.9204 60.0467 44.0262 59.9938 44.0966 59.9444L44.2024 59.8597V60.7587C43.9628 60.5331 43.6808 60.4204 43.3562 60.4204H41.8968V63.4981C41.8968 63.6533 41.9284 63.7944 41.992 63.9212C42.0625 64.041 42.1647 64.1009 42.2985 64.1009H44.0016C44.1918 64.1009 44.3683 64.0763 44.5305 64.027C44.6996 63.9706 44.8299 63.9072 44.9217 63.8365C45.0204 63.7661 45.1014 63.6956 45.1651 63.6249C45.2355 63.5475 45.2813 63.4841 45.3024 63.4347L45.3448 63.35L45.2495 64.3443H40.2361C40.4406 64.034 40.5429 63.752 40.5429 63.4981V58.3895Z"
        fill="white"
      />
      <path
        d="M46.956 58.3895C46.956 58.2767 46.9312 58.1499 46.8821 58.0088C46.8327 57.8679 46.7834 57.7549 46.734 57.6704L46.6598 57.5433H48.6165C48.4193 57.8536 48.3205 58.1359 48.3205 58.3895V63.4981C48.3205 63.6533 48.3521 63.7944 48.4157 63.9212C48.4862 64.041 48.5849 64.1009 48.7117 64.1009H49.9704C50.1609 64.1009 50.3371 64.0764 50.4993 64.027C50.6684 63.9706 50.799 63.9072 50.8905 63.8365C50.9892 63.7661 51.0704 63.6956 51.1339 63.625C51.2043 63.5475 51.2537 63.4841 51.282 63.4347L51.3136 63.35L51.2186 64.3443H46.6598C46.8573 64.034 46.956 63.752 46.956 63.4981V58.3895Z"
        fill="white"
      />
      <path
        d="M53.3119 58.3895C53.3119 58.2767 53.2838 58.1499 53.2274 58.0088C53.1781 57.8679 53.1287 57.7549 53.0793 57.6704L53.0054 57.5433H54.9726C54.7751 57.8536 54.6763 58.1359 54.6763 58.3895V63.4981C54.6763 63.6109 54.7012 63.738 54.7505 63.8789C54.7999 64.02 54.849 64.1327 54.8984 64.2175L54.9726 64.3443H53.0054C53.2096 64.034 53.3119 63.752 53.3119 63.4981V58.3895Z"
        fill="white"
      />
      <path
        d="M57.454 58.4424C58.1239 57.7584 58.9666 57.4165 59.9819 57.4165C60.9972 57.4165 61.8363 57.7584 62.4992 58.4424C63.1689 59.1263 63.5038 59.962 63.5038 60.9492C63.5038 61.9362 63.1689 62.7718 62.4992 63.4558C61.8363 64.1328 60.9972 64.4711 59.9819 64.4711C58.9666 64.4711 58.1239 64.1328 57.454 63.4558C56.7913 62.7718 56.4597 61.9362 56.4597 60.9492C56.4597 59.962 56.7913 59.1263 57.454 58.4424ZM60.3097 64.1433C61.0007 64.1433 61.4872 63.8543 61.7694 63.2761C62.0584 62.6909 62.1571 61.9151 62.0654 60.9492C61.9739 59.9833 61.727 59.2111 61.325 58.6329C60.9233 58.0476 60.3696 57.7549 59.6646 57.7549C58.9666 57.7549 58.4728 58.0476 58.1838 58.6329C57.9019 59.2111 57.8066 59.9833 57.8981 60.9492C57.9828 61.8941 58.2367 62.6625 58.6598 63.2548C59.0899 63.8473 59.6397 64.1433 60.3097 64.1433Z"
        fill="white"
      />
      <path
        d="M69.9872 57.5433H70.7805C70.5973 57.8536 70.5056 58.1359 70.5056 58.3895V64.3443H69.7652L65.3864 58.2627C65.3793 58.291 65.3758 58.3331 65.3758 58.3895V63.4981C65.3758 63.6109 65.3972 63.738 65.4392 63.8789C65.4886 64.02 65.538 64.1327 65.5874 64.2175L65.6508 64.3443H64.8575C65.041 64.034 65.1327 63.752 65.1327 63.4981V58.3895C65.1327 58.2767 65.1044 58.1499 65.048 58.0088C64.9986 57.8679 64.9457 57.7549 64.8894 57.6704L64.8154 57.5433H66.2854L70.2624 63.0751V58.3895C70.2624 58.2767 70.2376 58.1499 70.1882 58.0088C70.1459 57.8679 70.1003 57.7549 70.0509 57.6704L69.9872 57.5433Z"
        fill="white"
      />
    </svg>
  );
}

export default WhitelionWhite;

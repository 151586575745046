import React from "react";

function ProtectionIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4L2 42H46M24 12L39 38H9M28 28H25L28 22H20V30H22V36L28 28Z"
        fill="url(#paint0_linear_1208_839)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1208_839"
          x1="-2.06009"
          y1="12.999"
          x2="26.4578"
          y2="29.6184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProtectionIcon;

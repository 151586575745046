import React, { useContext } from "react";
import {
  SeamlessControl,
  EnergyEfficiency,
  SmartIntegration,
  CustomizableSolutions,
  EnhancedSecurity,
} from "../../assets/svg";
import { ContactModalContext } from "../../config/contexts";

const BenefitCard = ({ icon, title, description }) => {
  return (
    <div className="bg-[#ffffff]  w-full  md:w-[362px] h-[212px] p-[32px] px-[24px] gap-[16px] rounded-[8px] flex flex-col items-center text-center">
      <div className="text-4xl">{icon}</div>
      <p className="font-inter font-semibold text-[20px] leading-[28px] text-center">
        {title}
        <span className="text-[#1E1E1E80] font-medium"> {description}</span>
      </p>
    </div>
  );
};

function Benefit() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="bg-custom-bg-white  p-[48px] ">
      <div className="text-center">
        <h2 className="font-inter font-semibold text-[32px] leading-[48px] tracking-[-3%] md:text-[48px] md:leading-[57.6px] text-center mb-[24px]">
          The Benefits of Smarter Living with Whitelion
        </h2>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px] mt-10">
            <BenefitCard
              icon={<SeamlessControl />}
              title="Seamless Control"
              description="Lets you effortlessly manage your home from anywhere."
            />
            <BenefitCard
              icon={<EnergyEfficiency />}
              title="Energy Efficiency"
              description="Helps you optimize power usage and reduce costs."
            />
            <BenefitCard
              icon={<SmartIntegration />}
              title="Smart Integration"
              description="Connects your devices and voice assistants seamlessly."
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[24px] mt-[24px] place-items-center">
            <BenefitCard
              icon={<EnhancedSecurity />}
              title="Enhanced Security"
              description="Ensures advanced protection and peace of mind."
            />
            <BenefitCard
              icon={<CustomizableSolutions />}
              title="Customizable Solutions"
              description="Adapt automation to fit your unique lifestyle."
            />
          </div>
        </div>

        <button
          className="mt-8 w-full md:w-[306px]  h-[56px] px-[32px] py-[14px] rounded-[8px] bg-[#1E1E1E] text-white font-medium"
          onClick={() => openModal()}
        >
          Schedule a Demo
        </button>
      </div>
    </div>
  );
}

export default Benefit;

import React from 'react'

function YouTube() {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.802734" width="40" height="40" rx="20" fill="#1B1819" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.1045 15.2725C28.6439 15.8334 28.82 17.107 28.82 17.107C28.82 17.107 29 18.603 29 20.0984V21.5008C29 22.9967 28.82 24.4921 28.82 24.4921C28.82 24.4921 28.6439 25.7657 28.1045 26.3266C27.4878 26.9901 26.8039 27.0586 26.4174 27.0974C26.3749 27.1016 26.336 27.1055 26.3011 27.1098C23.7822 27.297 20 27.3027 20 27.3027C20 27.3027 15.32 27.2588 13.88 27.1167C13.8118 27.1037 13.7291 27.0935 13.6354 27.0819C13.1793 27.0258 12.4633 26.9376 11.8949 26.3266C11.3555 25.7657 11.18 24.4921 11.18 24.4921C11.18 24.4921 11 22.9967 11 21.5008V20.0984C11 18.603 11.18 17.107 11.18 17.107C11.18 17.107 11.3555 15.8334 11.8949 15.2725C12.513 14.6081 13.1975 14.5403 13.5841 14.502C13.626 14.4979 13.6644 14.4941 13.6989 14.4899C16.2177 14.3027 19.9961 14.3027 19.9961 14.3027H20.0039C20.0039 14.3027 23.7822 14.3027 26.3011 14.4899C26.3356 14.4941 26.374 14.4979 26.4159 14.502C26.8022 14.5403 27.487 14.6082 28.1045 15.2725ZM18.1409 18.0069L18.1415 23.2001L23.0043 20.6124L18.1409 18.0069Z" fill="white" />
        </svg>
    )
}

export default YouTube
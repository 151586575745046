import React, { useEffect, useState } from "react";
import { clockNumbers } from "./Constants";
import { motion } from "framer-motion";

function Innovation() {
  const [time, setTime] = useState(new Date(2025, 0, 1, 12, 0, 0)); // Fixed start at 12:00

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => new Date(prevTime.getTime() + 90 * 60 * 1000)); // Add exactly 90 min (1.5 hours)
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Extract hours and minutes (seconds are always 0)
  const hours = time.getHours() % 12 || 12;
  const minutes = time.getMinutes();
  //   const seconds = 0; // Always static 0

  // Calculate degrees for each hand
  const hourDeg = hours * 30 + minutes * 0.5;
  const minuteDeg = minutes * 6;
  //   const secondDeg = seconds * 6; // Always 0 (pointing to 12)

  return (
    <div className="">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 px-[24px] lg:px-[60px] py-[60px] lg:pt-[165px] bg-[#0E0E0E] h-screen">
        <div className="col-span-12 lg:col-span-7  px-[24px] flex justify-center items-center">
          <img
            src="/images/home/safe.png"
            alt="home"
            // className="h-full w-full lg:h-[720px] lg:w-[780px]"
          />
        </div>

        <div className="col-span-12 lg:col-span-5 flex flex-col  items-center">
          <div className="pt-[60px] lg:pt-[0px]">
            <div className="flex justify-center items-center ">
              <div className="relative w-[180px] h-[180px]  lg:w-[300px] lg:h-[300px] rounded-full bg-[#e0e5ec]  flex justify-center items-center bg-[#ffffff]">
                {/* Clock Face */}
                <div className="relative w-[90%] h-[90%] rounded-full bg-[#e0e5ec] shadow-inset-[10px_10px_20px_#a3b1c6,inset_-10px_-10px_20px_#ffffff]">
                  {/* Numbers */}
                  {/* Render all numbers dynamically */}
                  {clockNumbers?.map(
                    ({ num, top, left, right, bottom, translate }, index) => (
                      <div
                        key={index}
                        className={`absolute text-[18px] font-bold text-[#333] ${translate}`}
                        style={{ top, left, right, bottom }}
                      >
                        {num}
                      </div>
                    )
                  )}

                  {/* Clock Hands */}
                  <div
                    className="absolute w-[4px] h-[36px] lg:w-[6px] lg:h-[60px] bg-[#333] rounded-[5px] top-[28%] left-[50%] origin-bottom"
                    style={{
                      transform: `translateX(-50%) rotate(${hourDeg}deg)`,
                    }}
                  />
                  <div
                    className="absolute w-[4px] h-[54px] lg:h-[90px] bg-[#333] rounded-[5px] top-[17%] left-[50%] origin-bottom"
                    style={{
                      transform: `translateX(-50%) rotate(${minuteDeg}deg)`,
                    }}
                  />
                  {/* <div
                    className="absolute w-[2px] h-[60px] lg:h-[100px] bg-[#ff6f61] rounded-[5px] top-[13%] left-[50%] origin-bottom"
                    style={{
                      transform: `translateX(-50%) rotate(${secondDeg}deg)`,
                    }}
                  /> */}

                  {/* Center Dot */}
                  <div className="absolute w-[13px] h-[13px] bg-[#333] rounded-full top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 shadow-md"></div>
                </div>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 50 }} // Start invisible & lower
            whileInView={{ opacity: 1, y: 0 }} // Animate to visible & normal position
            transition={{ duration: 1, ease: "easeOut" }} // Smooth effect
            viewport={{ once: true, amount: 0.2 }} // Trigger when 20% visible
            className=" flex flex-col justify-center items-start lg:items-end text-right gap-2 lg:gap-4 pt-[60px] lg:pt-[60px]"
          >
            <div>
              {" "}
              <h1 className="text-white  font-bold text-[24px] lg:text-[48px] leading-[32px] lg:leading-[52px] tracking-[0px] text-left lg:text-right">
                Constant Innovation,
              </h1>
              <h1 className="font-bold text-[24px] bg-custom-gradient-text bg-clip-text text-transparent lg:text-[48px] leading-[32px] lg:leading-[52px] tracking-[0px] text-left lg:text-right">
                Every Hour and a Half.
              </h1>
            </div>

            <p className="text-white  font-medium text-[14px] lg:text-[20px] leading-[150%] tracking-[0.23%] text-left lg:text-right">
              At Whitelion, we're crafting home automation solutions every 90
              minutes. Experience our dedication to innovation, ensuring your
              home stays at the forefront of technology.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Innovation;

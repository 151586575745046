import React from "react";

function SecurityIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 39.8C27.2333 38.8 29.9333 36.8253 32.1 33.876C34.2667 30.9266 35.5333 27.6346 35.9 24H24V8.28798L12.808 12.442C12.5507 12.5446 12.352 12.6986 12.212 12.904C12.0707 13.1093 12 13.34 12 13.596V23.062C12 23.3206 12.0333 23.6333 12.1 24H24V39.8ZM24 41.684C23.8187 41.684 23.6273 41.6673 23.426 41.634C23.2247 41.6006 23.038 41.5506 22.866 41.484C18.9287 39.984 15.8 37.44 13.48 33.852C11.16 30.264 10 26.38 10 22.2V13.634C10 12.954 10.1967 12.3386 10.59 11.788C10.9833 11.2373 11.4867 10.8373 12.1 10.588L22.87 6.58798C23.2593 6.44664 23.636 6.37598 24 6.37598C24.364 6.37598 24.7413 6.44664 25.132 6.58798L35.9 10.588C36.5133 10.8373 37.0167 11.2373 37.41 11.788C37.8033 12.3386 38 12.954 38 13.634V22.2C38 26.38 36.84 30.264 34.52 33.852C32.2 37.44 29.0713 39.984 25.134 41.484C24.9607 41.5506 24.774 41.6006 24.574 41.634C24.374 41.6673 24.1827 41.684 24 41.684Z"
        fill="url(#paint0_linear_1208_807)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1208_807"
          x1="7.41631"
          y1="14.7375"
          x2="28.3867"
          y2="23.1074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SecurityIcon;

import React from "react";
import SwiperTestimonials from "./SwiperTestimonials";

function Testimonials() {
  return (
    <>
      <div id="Testimonials">
        <div
          className="testimonials-section h-[1017px] lg:h-[820px]  items-center px-[20px] py-[40px]  lg:py-[60px] "
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, #2E2E2E 0%, #0E0E0E 100%)",
          }}
        >
          <div className="grid grid-cols-1 gap-[30px] lg:gap-[0px]">
            <div className=" grid grid-cols-1 h-[122px] text-center">
              <h1 className="font-inter font-semibold text-[#F3F5F0] text-[32px] leading-[32px] lg:text-[64px] lg:leading-[70.4px] tracking-[-0.0041em]">
                Here from our clients
              </h1>
            </div>
            <div className=" grid  grid-cols-1 h-[800px] lg:h-[600px]">
              <SwiperTestimonials />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;

import React from "react";

function Facebook() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2623_2484)">
        <path
          d="M40.0514 20.0081C40.0514 8.94046 31.069 -0.0419922 20.0013 -0.0419922C8.93363 -0.0419922 -0.0488281 8.94046 -0.0488281 20.0081C-0.0488281 29.7124 6.84841 37.7926 15.9913 39.6573V26.0232H11.9812V20.0081H15.9913V14.9956C15.9913 11.1259 19.1391 7.97806 23.0088 7.97806H28.0213V13.9931H24.0113C22.9086 13.9931 22.0063 14.8954 22.0063 15.9981V20.0081H28.0213V26.0232H22.0063V39.958C32.1316 38.9555 40.0514 30.4141 40.0514 20.0081Z"
          fill="#1B1819"
        />
      </g>
      <defs>
        <clipPath id="clip0_2623_2484">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;

import React from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";

function SectionAirGlass({ section, secondCard, thirdCard, fourthCard }) {
  return (
    <div className="grid grid-cols-1 px-[24px] py-[60px] lg:px-[164px] lg:py-[150px] bg-custom-bg-black gap-[40px] lg:gap-[126px]">
      <motion.div
        className="border-b border-white border-opacity-20 text-white flex flex-row justify-between item-center gap-[22px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <h1 className="font-semibold text-[30px] lg:text-[64px] leading-[100%] tracking-[0%] align-middle lg:w-[400px] ">
          {section?.label}
        </h1>
        <div className="">
          <img
            src={section?.img}
            alt={section?.img}
            loading="lazy"
            className="w-[225px] h-[80px] lg:w-[366px] lg:h-[150px]"
          />
        </div>
      </motion.div>
      <div className="">
        <motion.p
          className="font-inter font-semibold  text-[14px] lg:text-[20px] leading-[150%] tracking-[0%] text-[#ffffff] opacity-80"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          {section?.text}
        </motion.p>
      </div>
      {/* second card */}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-5 text-white flex flex-col items-center justify-center text-center h-full">
          <motion.img
            src={secondCard?.img}
            alt={secondCard?.img}
            loading="lazy"
            className="w-[300px] h-[300px] lg:w-[445px] lg:h-[445px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          />
        </div>

        <div className="col-span-12 md:col-span-7  text-white text-center py-[24px] lg:pl-[100px] flex ">
          <div className="flex flex-col gap-[12px] lg:gap-[24px]  justify-center items-center">
            <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
              <motion.div
                className="flex flex-row items-center gap-1"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
                  {secondCard?.first?.label}
                </p>
                <img
                  src={secondCard?.first?.logo}
                  alt="star"
                  className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
                  loading="lazy"
                ></img>
              </motion.div>
              <motion.p
                className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                {secondCard?.first?.text}
              </motion.p>
            </div>
            <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
              <motion.div
                className="flex flex-row items-center gap-1"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
                  {secondCard?.second?.label}
                </p>
                <img
                  src={secondCard?.second?.logo}
                  alt="star"
                  className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
                  loading="lazy"
                ></img>
              </motion.div>
              <motion.p
                className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                {secondCard?.second?.text}
              </motion.p>
            </div>
          </div>
        </div>
      </div>

      {/* third card */}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7 order-2 md:order-1  text-white text-center py-[24px] lg:pr-[100px]">
          <div className="flex flex-col gap-[12px] lg:gap-[24px]">
            <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
              <motion.div
                className="flex flex-row items-center gap-1"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
                  {thirdCard?.first?.label}
                </p>
                <img
                  src={thirdCard?.first?.logo}
                  alt="star"
                  className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
                  loading="lazy"
                ></img>
              </motion.div>
              <motion.p
                className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                {thirdCard?.first?.text}
              </motion.p>
            </div>
            <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
              <motion.div
                className="flex flex-row items-center gap-1"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
                  {thirdCard?.second?.label}
                </p>
                <img
                  src={thirdCard?.second?.logo}
                  alt="star"
                  className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
                  loading="lazy"
                ></img>
              </motion.div>
              <motion.p
                className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                {thirdCard?.second?.text}
              </motion.p>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-5 order-1 md:order-2 flex flex-col items-center justify-center text-center h-full">
          {/* <motion.img
            src={thirdCard?.img}
            alt={thirdCard?.img}
            loading="lazy"
            className="w-[225px] h-[80px] lg:w-[366px] lg:h-[150px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          /> */}
          <div className="">
            <Lottie animationData={thirdCard?.img} loop={true} />
          </div>
        </div>
      </div>
      {/* fourthCard */}
      <div className="flex flex-col items-center justify-center ">
        <motion.img
          src={fourthCard?.img}
          alt={fourthCard?.img}
          loading="lazy"
          className="lg:w-[650px] lg:h-[350px]"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 text-white gap-[24px] lg:gap-[48px]">
        <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
          <motion.div
            className="flex flex-row items-center gap-1"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
              {fourthCard?.first?.label}
            </p>
            <img
              src={fourthCard?.first?.logo}
              alt="star"
              className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
              loading="lazy"
            ></img>
          </motion.div>
          <motion.p
            className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
          >
            {fourthCard?.first?.text}
          </motion.p>
        </div>
        <div className="border-t border-white border-opacity-20 text-left flex flex-col gap-[12px] lg:gap-[24px] py-[24px]">
          <motion.div
            className="flex flex-row items-center gap-1"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          >
            <p className="font-semibold text-[16px] lg:text-[24px] leading-[100%] tracking-[1%] align-middle">
              {fourthCard?.second?.label}
            </p>
            <img
              src={fourthCard?.second?.logo}
              alt="star"
              className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]"
              loading="lazy"
            ></img>
          </motion.div>
          <motion.p
            className="font-medium text-[14px] lg:text-[20px] leading-[130%] tracking-[0%] opacity-80"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
          >
            {fourthCard?.second?.text}
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default SectionAirGlass;

import React from "react";

function DownloadIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#232323"
        />
        <path
          d="M20 23.577L16.461 20.039L17.169 19.319L19.5 21.65V13H20.5V21.65L22.83 19.32L23.539 20.039L20 23.577ZM14.616 27C14.1553 27 13.771 26.846 13.463 26.538C13.155 26.23 13.0007 25.8453 13 25.384V22.961H14V25.384C14 25.538 14.064 25.6793 14.192 25.808C14.32 25.9367 14.461 26.0007 14.615 26H25.385C25.5383 26 25.6793 25.936 25.808 25.808C25.9367 25.68 26.0007 25.5387 26 25.384V22.961H27V25.384C27 25.8447 26.846 26.229 26.538 26.537C26.23 26.845 25.8453 26.9993 25.384 27H14.616Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
}

export default DownloadIcon;

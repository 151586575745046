import React from "react";
import { LivingRoom } from "../home";

function index() {
  return (
    <div>
      <LivingRoom />
    </div>
  );
}

export default index;

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { sliderImages } from "./Constants";
import { NextIcon, PrevIcon } from "../../assets/svg";
import { Link } from "react-router-dom";

function Categories() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        setActiveIndex(swiperRef.current.realIndex);
      });
    }
  }, []);

  const prevIndex = activeIndex > 0 ? activeIndex - 1 : sliderImages.length - 1;
  const nextIndex = activeIndex < sliderImages.length - 1 ? activeIndex + 1 : 0;
  return (
    <div className="py-[32px] lg:py-[132px] bg-black">
      <div className="px-[32px] lg:px-[132px] flex flex-col gap-[20px]">
        <div className="grid grid-cols-[1fr_auto_1fr] items-center">
          <div className="border border-white border-opacity-40"></div>

          <div className="px-4">
            <h1 className="font-semibold text-[24px] lg:text-[48px] leading-[120%] tracking-[-0.41%] text-center text-white">
              Explore Our Categories
            </h1>
          </div>

          <div className="border border-white border-opacity-40"></div>
        </div>

        <div className="flex justify-between items-center px-[20px] lg:px-[100px] border-b-2 border-white border-opacity-40 pb-[10px]">
          {/* Previous Button */}
          <motion.button
            className="custom-prev-product flex h-[30px] lg:h-[50px] px-[8px] items-center justify-center 
                 bg-white transition-all duration-500 opacity-100 cursor-pointer rounded-[8px]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="scale-[0.7] lg:scale-[1]">
              <PrevIcon />
            </div>
            <span className="pr-[8px] text-[14px] lg:text-[22px] font-semibold leading-[100%] tracking-[0.23px]">
              {sliderImages[prevIndex].name}
            </span>
          </motion.button>
          {/* Next Button */}
          <motion.button
            className="custom-next-product h-[30px] px-[8px] lg:h-[50px] flex justify-center items-center 
                    transition-all duration-300 flex opacity-100 cursor-pointer bg-white rounded-[8px]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="font-semibold text-[14px] lg:text-[22px] leading-[100%] tracking-[0.23px]  pl-[8px]">
              {sliderImages[nextIndex].name}
            </span>
            <div className="scale-[0.7] lg:scale-[1]">
              <NextIcon />
            </div>
          </motion.button>
        </div>
      </div>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.8}
        spaceBetween={30} // Reduce space to make slides overlap slightly
        loop={true}
        navigation={{
          nextEl: ".custom-next-product",
          prevEl: ".custom-prev-product",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        coverflowEffect={{
          rotate: -10, // No rotation for a clean look
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false, // Disable shadows for clarity
        }}
        allowTouchMove={true}
        simulateTouch={true}
        // pagination={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper h-[250px] lg:h-[600px] "
      >
        {sliderImages?.map((item, index) => (
          <SwiperSlide
            key={index}
            className="bg-black"
            style={{
              opacity: activeIndex === index ? "1" : "0.5", // Other slides are slightly faded
            }}
          >
            <Link to={activeIndex === index ? item?.route : ""}>
              <div
                className={`h-full w-full flex items-center
                ${
                  index === activeIndex
                    ? "justify-center"
                    : index ===
                      (activeIndex - 1 + sliderImages.length) %
                        sliderImages.length
                    ? "justify-end"
                    : "justify-start"
                }`}
              >
                <img
                  src={item?.img}
                  alt={item?.img}
                  style={{
                    width: activeIndex === index ? "100%" : "50%",
                  }}
                  className="transition-all duration-1000 scale-75 swiper-slide-active:scale-100"
                />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Custom Pagination */}
      <div className="flex justify-center ">
        <div className="flex justify-center items-center gap-[16px] h-[30px] lg:h-[56px] px-[20px] rounded-full bg-[#FFFFFF1A] ">
          {sliderImages?.map((_, index) => (
            <button
              key={index}
              onClick={() => swiperRef.current?.slideTo(index)}
              className={` h-[8px] rounded-full bg-white transition-all duration-1000 ${
                activeIndex === index ? " w-[48px]" : " w-[8px] opacity-50"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default Categories;

import React from "react";
import {
  ChildLockIcon,
  ProtectionIcon,
  SecurityIcon,
  ShockIcon,
  WaterIcon,
} from "../../../assets/svg";
import { motion } from "framer-motion";

const BenefitCard = ({ icon, title, description }) => {
  return (
    <div className="bg-[#ffffff]  w-full  md:w-[362px] h-[212px] p-[32px] px-[24px] gap-[16px] rounded-[8px] flex flex-col items-center text-center overflow-hidden">
      <motion.div
        className=""
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        {icon}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        <p className="font-inter text-[#0E0E0E] font-semibold text-[20px] leading-[28px] text-center">
          {title}
        </p>
        <p className="font-medium text-[#6E6E73] text-[20px] leading-[22px] tracking-[0.01em] text-center">
          {description}
        </p>
      </motion.div>
    </div>
  );
};

function AdvantagePoshQuartz() {
  return (
    <div className=" bg-[#F5F5F7] grid grid-cols-1 py-[24px] lg:py-[60px] gap-[48px]">
      <div className="text-center px-[24px] lg:px-[165px] ">
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="font-semibold text-[40px] leading-[48px] lg:text-[64px] lg:leading-[64px] tracking-[0] text-[#1E1E1E] text-center"
        >
          Safe, Secure, and Reliable
        </motion.p>

        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[24px] mt-10">
            <BenefitCard
              icon={<ProtectionIcon />}
              title="SurgeGuard Protection"
              description="Shields your home from electrical surges."
            />
            <BenefitCard
              icon={<ShockIcon />}
              title="ShockShield Technology"
              description="Safeguards against electric shocks and accidents."
            />
            <BenefitCard
              icon={<WaterIcon />}
              title="WaterGuard Display"
              description="Ensures durability against moisture and spills."
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[24px] mt-[24px] place-items-center">
            <BenefitCard
              icon={<ChildLockIcon />}
              title="ChildSafe Lock"
              description="Prevents unauthorized access by young children."
            />
            <BenefitCard
              icon={<SecurityIcon />}
              title="Robust Security"
              description="Enjoy peace of mind with Robust Security features."
            />
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center items-center px-[24px] lg:px-[165px]">
        <img src="/images/posh/safe.png" alt="safe home" loading="lazy" />
      </div> */}
    </div>
  );
}

export default AdvantagePoshQuartz;

import React, { useContext } from "react";
import QuartzFilledDark from "./quartz-filled-dark.svg";
import QuartzFilledLight from "./quartz-filled-light.svg";
import QuartzOutlineDark from "./quartz-outline-dark.svg";
import QuartzOutlineLight from "./quartz-outline-light.svg";
import { NavContext } from "../../../config/contexts";

function QuartzIcon({ position }) {
  const { isWhiteColor } = useContext(NavContext);

  const isDefaultHeader = position;

  const iconSrc = isWhiteColor
    ? isDefaultHeader
      ? QuartzFilledLight
      : QuartzOutlineLight
    : isDefaultHeader
    ? QuartzFilledDark
    : QuartzOutlineDark;

  return <img src={iconSrc} alt="Quartz Icon" />;
}

export default QuartzIcon;

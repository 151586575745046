import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const paragraphs = [
  {
    head: "Switches",
    text: "Take control of your lights effortlessly. Whether you’re at home or away, turn them on or off with just a tap in the AirHome app.",
  },
  {
    head: "Remote",
    text: "Manage your appliances with ease. Adjust your AC, TV, and more—all from your phone, no extra remotes needed.",
  },
  {
    head: "Scenes",
    text: "Create the perfect ambiance in seconds. Activate multiple devices at once with a single tap for a truly smart home experience.",
  },
  {
    head: "Schedule",
    text: "Let your home run on autopilot. Set schedules for lights and appliances, so everything works exactly when you need it.",
  },
];

const imagesScreen = [
  "/images/airglass/switchesscreen.png",
  "/images/airglass/remotescreen.png",
  "/images/airglass/scenesscreen.png",
  "/images/airglass/schedulescreen.png",
];

function EffortlessTouchGlass() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"], // Start animating when container enters
  });
  const isMobile = window?.innerWidth <= 1024;

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 30 });
  };

  const viewportHeight = window.innerHeight;
  // web scroll progress to Y translation
  const y1 = useSpringTransform(
    scrollYProgress,
    [0, 0.4, 0.5],
    [-(viewportHeight * 1), viewportHeight * 1, viewportHeight * 2]
  );
  const y2 = useSpringTransform(
    scrollYProgress,
    [0.3, 0.5, 0.7],
    [-(viewportHeight * 1), viewportHeight * 1, viewportHeight * 2]
  );
  const y3 = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.8],
    [-(viewportHeight * 1), viewportHeight * 1, viewportHeight * 2]
  );
  const y4 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.7, 0.9],
    [
      -(viewportHeight * 1),
      (viewportHeight * 1) / 12,
      (viewportHeight * 1) / 12,
    ]
  );

  const textY1 = useSpringTransform(
    scrollYProgress,
    [0, 0.4, 0.5],
    [viewportHeight * 1, -(viewportHeight * 1), -(viewportHeight * 2)]
  );
  const textY2 = useSpringTransform(
    scrollYProgress,
    [0.3, 0.5, 0.7],
    [viewportHeight * 1, -(viewportHeight * 1), -(viewportHeight * 2)]
  );
  const textY3 = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.8],
    [viewportHeight * 1, -(viewportHeight * 1), -(viewportHeight * 2)]
  );
  const textY4 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.7, 0.9],
    [
      viewportHeight * 1,
      -((viewportHeight * 1) / 12),
      -((viewportHeight * 1) / 12),
    ]
  );
  const viewportWidth = window.innerWidth;

  // Mobile scroll progress to X translation
  const x1 = useSpringTransform(
    scrollYProgress,
    [0, 0.4, 0.5],
    [-(viewportWidth * 1), viewportWidth * 1, viewportWidth * 2]
  );

  const x2 = useSpringTransform(
    scrollYProgress,
    [0.3, 0.5, 0.7],
    [-(viewportWidth * 1), viewportWidth * 1, viewportWidth * 2]
  );

  const x3 = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.8],
    [-(viewportWidth * 1), viewportWidth * 1, viewportWidth * 2]
  );

  const x4 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.7, 0.9],
    [-(viewportWidth * 1), (viewportWidth * 1) / 40, (viewportWidth * 1) / 40]
  );
  const textX1 = useSpringTransform(
    scrollYProgress,
    [0, 0.4, 0.5],
    [viewportWidth * 1, -(viewportWidth * 1), -(viewportWidth * 2)]
  );

  const textX2 = useSpringTransform(
    scrollYProgress,
    [0.3, 0.5, 0.7],
    [viewportWidth * 1, -(viewportWidth * 1), -(viewportWidth * 2)]
  );

  const textX3 = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.8],
    [viewportWidth * 1, -(viewportWidth * 1), -(viewportWidth * 2)]
  );

  const textX4 = useSpringTransform(
    scrollYProgress,
    [0.5, 0.7, 0.9],
    [
      viewportWidth * 1,
      -((viewportWidth * 1) / 40),
      -((viewportWidth * 1) / 40),
    ]
  );
  return (
    <div className=" px-[24px] py-[60px] lg:px-[165px] lg:py-[150px] bg-custom-bg-white flex justify-center items-center">
      <div className="grid grid-cols-1 gap-[40px] lg:gap-[64px]">
        <div className="">
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
            className="font-sfpro font-semibold text-[40px] leading-[110%]  lg:text-[64px] lg:leading-[130%] tracking-[0] align-middle"
          >
            Effortless Control, Total Compatibility
          </motion.h1>
        </div>
        <div className="grid grid-cols-1 gap-[10px] lg:gap-[30px]">
          <div className="relative h-[400vh]" ref={containerRef}>
            <div className="sticky top-0 h-screen w-full flex items-center justify-center py-[5vh] ">
              <div className="flex flex-col lg:flex-row justify-center items-center w-full h-full rounded-[16px] bg-white">
                <div className="lg:w-1/2 w-full h-full flex items-center justify-center text-white overflow-hidden">
                  <div className="lg:w-1/2 w-full h-full flex items-center justify-center relative ">
                    <div className="relative h-[280px] w-[160px] lg:h-[550px] lg:w-[270px]  ">
                      <motion.img
                        style={isMobile ? { x: x1 } : { y: y1 }}
                        src={imagesScreen[0]}
                        alt="screen-1"
                        className="absolute top-0 left-0 w-full h-full"
                      />
                      <motion.img
                        style={isMobile ? { x: x2 } : { y: y2 }}
                        src={imagesScreen[1]}
                        alt="screen-2"
                        className="absolute top-0 left-0 w-full h-full"
                      />
                      <motion.img
                        style={isMobile ? { x: x3 } : { y: y3 }}
                        src={imagesScreen[2]}
                        alt="screen-3"
                        className="absolute top-0 left-0 w-full h-full"
                      />
                      <motion.img
                        style={isMobile ? { x: x4 } : { y: y4 }}
                        src={imagesScreen[3]}
                        alt="screen-4"
                        className="absolute top-0 left-0 w-full h-full"
                      />
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full h-full flex items-center justify-center text-white overflow-hidden ">
                  <div className="lg:w-full w-full h-full flex items-center justify-center relative ">
                    <div className="relative h-[280px] w-full lg:h-[550px] lg:w-full ">
                      <motion.div
                        style={isMobile ? { x: textX1 } : { y: textY1 }}
                        className="absolute top-0 left-0 w-full h-full flex flex-col justify-center gap-[12px] p-[24px]"
                      >
                        <h1 className="font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[0]?.head}
                        </h1>
                        <p className="font-semibold text-[20px] lg:text-[28px] leading-[140%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[0]?.text}
                        </p>
                      </motion.div>
                      <motion.div
                        style={isMobile ? { x: textX2 } : { y: textY2 }}
                        className="absolute top-0 left-0 w-full h-full flex flex-col justify-center gap-[12px] p-[24px]"
                      >
                        <h1 className="font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[1]?.head}
                        </h1>
                        <p className="font-semibold text-[20px] lg:text-[28px] leading-[140%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[1]?.text}
                        </p>
                      </motion.div>
                      <motion.div
                        style={isMobile ? { x: textX3 } : { y: textY3 }}
                        className="absolute top-0 left-0 w-full h-full flex flex-col justify-center gap-[12px] p-[24px]"
                      >
                        <h1 className="font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[2]?.head}
                        </h1>
                        <p className="font-semibold text-[20px] lg:text-[28px] leading-[140%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[2]?.text}
                        </p>
                      </motion.div>
                      <motion.div
                        style={isMobile ? { x: textX4 } : { y: textY4 }}
                        className="absolute top-0 left-0 w-full h-full flex flex-col justify-center gap-[12px] p-[24px]"
                      >
                        <h1 className="font-semibold text-[24px] lg:text-[36px] leading-[126%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[3]?.head}
                        </h1>
                        <p className="font-semibold text-[20px] lg:text-[28px] leading-[140%] tracking-[-0.03em] align-middle text-black">
                          {paragraphs[3]?.text}
                        </p>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-[10px] lg:gap-[30px] lg:w-[1110px]">
            <div className=" rounded-[16px] lg:rounded-[16px] flex flex-col justify-between items-center bg-cover bg-center bg-[#3492EA]  ">
              <motion.div
                className="px-[16px] pt-[10px] md:pt-[24px] lg:px-[50px] lg:pt-[60px] text-white"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <p className=" font-semibold text-[10px] md:text-[16px] lg:text-[36px] leading-[120%] tracking-[0px] align-middle">
                  Geo Location 🌍
                </p>
                <p className=" font-semibold text-[10px] md:text-[16px] lg:text-[36px] leading-[120%] tracking-[0px] align-middle">
                  Pinpoint control for a truly connected life.
                </p>
              </motion.div>
              <img
                src="/images/airglass/airmap.png"
                alt="mobile air glass"
                loading="lazy"
                className="w-[229px] h-[160px] lg:w-full lg:h-[450px] rounded-[16px]"
              />
            </div>
            <div className=" rounded-[16px] lg:rounded-[16px] flex flex-col justify-center items-center bg-white">
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
                className="px-[16px] pt-[10px] md:pt-[24px] lg:px-[50px] lg:pt-[60px] font-semibold text-[10px] md:text-[16px] lg:text-[36px] leading-[120%] tracking-[0px] align-middle"
              >
                Automate devices based on your location for an extra touch of
                convenience.
              </motion.p>

              <img
                src="/images/airglass/twomobile.png"
                alt="mobile air card"
                loading="lazy"
                className="w-[177px] h-[160px] lg:w-full lg:h-[500px] rounded-[16px] "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EffortlessTouchGlass;

import React from "react";
import {
  AdvantagePoshQuartz,
  CompatibilityPoshQuartz,
  ElevatePoshQuartz,
  // InnovationsPoshQuartz,
  SectionPoshQuartz,
  SwitchPoshQuartz,
  WelcomePoshQuartz,
} from "../common";
import {
  // Insights,
  SmartControl,
  Warranty,
  ResourceHub,
  EsteemedClients,
} from "../../common";
import {
  head,
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  swipperList,
  elevateList,
  // innovations,
  switchData,
  masterButton,
  twoWay,
} from "./Constants";

function index() {
  return (
    <div className="w-full">
      <WelcomePoshQuartz
        img={"/images/posh/poshhome.png"}
        alt={"Posh Home"}
        head={"POSH"}
        text={"The Evolution of Class"}
      />

      <SectionPoshQuartz
        head={head}
        firstCard={firstCard}
        secondCard={secondCard}
        thirdCard={thirdCard}
        fourthCard={fourthCard}
        swipperList={swipperList}
        masterButton={masterButton}
        twoWay={twoWay}
      />
      {/* <InnovationsPoshQuartz innovations={innovations} /> */}
      <AdvantagePoshQuartz />
      <SwitchPoshQuartz switchData={switchData} />
      <Warranty />
      <SmartControl series="posh" />
      <CompatibilityPoshQuartz mode={true} />
      <ResourceHub />
      <EsteemedClients />
      <ElevatePoshQuartz elevateList={elevateList} />
      {/* <Insights /> */}
    </div>
  );
}

export default index;

import React from "react";

function ShockIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3797 4.42011L8.65969 22.9801C7.37969 24.1401 8.09969 26.2801 9.81969 26.4401L25.9997 28.0001L16.2997 41.5201C15.8597 42.1401 15.9197 43.0001 16.4597 43.5401C17.0597 44.1401 17.9997 44.1601 18.6197 43.5801L39.3397 25.0201C40.6197 23.8601 39.8997 21.7201 38.1797 21.5601L21.9997 20.0001L31.6997 6.48011C32.1397 5.86011 32.0797 5.00011 31.5397 4.46011C31.2569 4.17134 30.872 4.00531 30.4679 3.99782C30.0638 3.99034 29.673 4.14201 29.3797 4.42011Z"
        fill="url(#paint0_linear_1208_844)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1208_844"
          x1="5.05652"
          y1="13.4715"
          x2="28.9568"
          y2="23.0885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ShockIcon;

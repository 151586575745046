import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { navLinks, productsLink, lpLink } from "../config/constant/NavLink";

import {
  Home,
  About,
  Career,
  Partner,
  Posh,
  Solutions,
  ContactUs,
  ThankYou,
  AirTouch,
  AirGlass,
  Quartz,
  Mocha,
  AirSensor,
  AirBlaster,
  AirHome,
  Accessories,
  NotFound,
  ScenePanel,
  LpPagesList,
  NewOffers,
} from "../pages";
import ScrollToTopButton from "../components/footer/ScrollToTopButton";
import { ContactModel } from "../components/common";

function AppRoutes() {
  const location = useLocation();
  const contactRoutes = ["/lp", "/lp/get-in-touch"];
  const isContactPage = contactRoutes.includes(
    location.pathname.replace(/\/+$/, "")
  );

  return (
    <div className="w-full">
      {!isContactPage && <Navbar />}
      <ContactModel />
      <Routes>
        <Route path={navLinks.home.route} element={<Home />} />
        <Route path={navLinks.about.route} element={<About />} />
        <Route path={navLinks.career.route} element={<Career />} />
        <Route path={navLinks.partner.route} element={<Partner />} />
        {/* <Route path={navLinks.products.route} element={<Posh />} /> */}
        <Route path={navLinks.solution.route} element={<Solutions />} />
        <Route path={navLinks.thankYou.route} element={<ThankYou />} />
        <Route path={navLinks.scenePanel.route} element={<ScenePanel />} />

        {/* products list */}
        <Route path={productsLink.posh.route} element={<Posh />} />
        <Route path={productsLink.quartz.route} element={<Quartz />} />
        {/* <Route path={productsLink.mocha.route} element={<Mocha />} /> */}
        <Route path={productsLink.airtouch.route} element={<AirTouch />} />
        <Route path={productsLink.airglass.route} element={<AirGlass />} />
        <Route path={productsLink.airsensor.route} element={<AirSensor />} />
        {/* <Route
              path={productsLink.airblaster.route}
              element={<AirBlaster />}
            /> */}
        <Route path={productsLink.airhome.route} element={<AirHome />} />
        <Route
          path={productsLink.accessories.route}
          element={<Accessories />}
        />

        {/* Landing pages */}
        <Route path="/lp/pages" element={<LpPagesList />} />
        <Route path={navLinks.contact.route} element={<ContactUs />} />
        <Route path={lpLink.getInTouch.route} element={<ContactUs />} />
        <Route path={lpLink.offers.route} element={<NewOffers />} />

        {/* route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTopButton />
      {!isContactPage && <Footer />}
    </div>
  );
}

export default AppRoutes;

import { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import StoryCard from "./StoryCard";

gsap.registerPlugin(ScrollTrigger);

const OurStory = () => {
  const containerRef = useRef(null);
  const animatedLineRef = useRef(null);
  const textRefs = useRef([]); // Store multiple h1 elements
  const headRefs = useRef([]); // Store multiple h1 elements
  const roundRefs = useRef([]); // Store multiple h1 elements

  useLayoutEffect(() => {
    const container = containerRef.current;
    const animatedLine = animatedLineRef.current;
    const texts = textRefs.current;
    const heads = headRefs.current;
    const rounds = roundRefs.current;

    if (container && animatedLine) {
      // Line animation
      gsap.fromTo(
        animatedLine,
        { height: "0%" },
        {
          height: "100%",
          ease: "none",
          scrollTrigger: {
            trigger: container,
            start: "top 40%",
            end: "bottom center",
            scrub: true,
            //  markers: true, // Remove in production
          },
        }
      );

      // Text color animation
      texts.forEach((text) => {
        gsap.fromTo(
          text,
          { opacity: 0.3 },
          {
            opacity: 1, // Change color opacity
            ease: "none",
            scrollTrigger: {
              trigger: text,
              start: "top 40%", // Color starts changing when text is near center
              end: "bottom center", // Fully blue before it leaves screen
              scrub: true,
            },
          }
        );
      });
      // header color animation
      heads.forEach((text) => {
        gsap.fromTo(
          text,
          { opacity: 0.3 },
          {
            opacity: 1, // Change color opacity
            ease: "none",
            scrollTrigger: {
              trigger: text,
              start: "top 40%", // Color starts changing when text is near center
              end: "bottom center", // Fully blue before it leaves screen
              scrub: true,
            },
          }
        );
      });
      rounds.forEach((text) => {
        gsap.fromTo(
          text,
          { opacity: 0.3 },
          {
            opacity: 1, // Change color opacity
            ease: "none",
            scrollTrigger: {
              trigger: text,
              start: "top 40%", // Color starts changing when text is near center
              end: "bottom center", // Fully blue before it leaves screen
              scrub: true,
            },
          }
        );
      });
    }
  }, []);

  const OurStoryArray = [
    {
      year: "2014",
      heading: "The Beginning",
      text: "Whitelion was founded in 2014 by Vishal Kukadiya, Sajag Sheta, Nirav Dholiya, and Jenish Kumbhani to transform home automation in India. Starting with three employees, it introduced smart switch panels and intelligent solutions for modern living.",
    },
    {
      year: "2019",
      heading: "Trailblazer Award & Growth",
      text: `Whitelion earned "The Most Promising Brand of the Year" from the Real Estate Congress and expanded from smart switch panels to motion and TV sensors for a better smart home experience.`,
    },
    {
      year: "2024",
      heading: "Expanding Horizons with the Air Series",
      text: "Continuing its mission to push the boundaries of automation, Whitelion introduced the Air Series—a groundbreaking addition to its portfolio, further elevating the smart home experience.",
    },
    {
      year: "Beyond 2024",
      heading: "Shaping the Future of Smart Homes",
      text: "With a team that has grown to over 55 experts, Whitelion aspires to become India’s leading Home Automation brand in smart home technology. Committed to continuous innovation, the company is dedicated to shaping the future of modern homes with advanced, intelligent solutions and making human life efficient.",
    },
  ];
  const circleArray = [
    "top-[4%] md:top-[10%]",
    "top-[29%] md:top-[36%]",
    "top-[54%] md:top-[59%]",
    "top-[79%] md:top-[82%]",
  ];

  return (
    <div className="grid grid-cols-1 bg-[#1E1E1E] gap-[24px] lg:gap-[48px] px-[24px] py-[24px] lg:py-[90px] lg:px-[164px]">
      <div className="flex items-center justify-center gap-[20px] lg:gap-[60px] ">
        <h1 className="font-inter text-[#F4F4F4] lg:font-semibold font-medium text-[32px] leading-[38.19px] lg:text-[72px] lg:leading-[85.92px] tracking-[-0.41px]">
          OUR STORY
        </h1>
      </div>
      <div className="">
        <p className="text-[#F4F4F4CC] font-inter font-medium text-[14px] leading-[21px] lg:text-[20px] lg:leading-[30px] tracking-[0] text-center">
          Founded in 2014 by Vishal Kukadiya, Sajag Sheta, Nirav Dholiya, and
          Jenish Kumbhani, Whitelion manufactures cutting-edge Home Automation
          technology in India. Starting with 3 employees, the company now boasts
          a team of over 55 and was recognized as The Most Promising Brand Of
          The Year by the Real Estate Congress in 2019.
        </p>
      </div>

      <div
        ref={containerRef}
        className="relative  flex flex-col  text-white px-[10px] py-[30px] lg:p-[80px] gap-[54px] lg:gap-[110px]"
      >
        {/* Static Line */}
        <div className="absolute top-0 left-1/3 w-[3px] h-full bg-[#F4F4F4CC] opacity-50"></div>
        {/* Animated Line */}
        <div
          ref={animatedLineRef}
          className="absolute top-0 left-1/3 w-[3px] z-10 "
          style={{
            background:
              "linear-gradient(145.42deg, #00F0FF -19.1%, #0066FF 105.1%)",
          }}
        ></div>

        {/* circle */}
        <div className="absolute top-0 left-1/3 w-[3px] h-full ">
          {circleArray.map((position, index) => (
            <div
              key={index}
              ref={(el) => (roundRefs.current[index] = el)}
              className={`z-10 absolute ${position} left-[-9px] w-[20px] h-[20px] bg-black rounded-full flex items-center justify-center`}
            >
              <div className="w-[6px] h-[6px] lg:w-[10px] lg:h-[10px] bg-[#FFFFFF] rounded-full"></div>
            </div>
          ))}
        </div>

        {/* Content Blocks */}
        {OurStoryArray?.map((item, index) => (
          <div
            key={index}
            className="relative z-10  grid grid-cols-[20%_60%] gap-[20%] "
          >
            <h1
              ref={(el) => (textRefs.current[index] = el)} // Store refs dynamically
              style={{
                background:
                  "linear-gradient(145.42deg, #00F0FF -19.1%, #0066FF 105.1%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className="font-inter font-semibold text-[20px] leading-[25.2px] lg:text-[32px] lg:leading-[40.32px] tracking-[0px] text-right transition-all duration-300"
            >
              {item?.year}
            </h1>

            <div
              ref={(el) => (headRefs.current[index] = el)} // Store refs dynamically
              className="grid grid-cols-1 gap-[6px] text-[#FFFFFF] text-left"
            >
              <h1 className="font-inter font-medium text-[16px] leading-[20.8px] lg:text-[24px] lg:leading-[33.6px] tracking-[0] transition-all duration-300">
                {item?.heading}
              </h1>
              <p className="font-inter font-normal text-[14px] leading-[18.2px] lg:text-[16px] lg:leading-[24px] tracking-[0] transition-all duration-300">
                {item?.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <p className="font-inter text-[#F4F4F4] font-normal text-[32px] leading-[41.6px] lg:text-[56px] lg:leading-[72.8px] tracking-[0]">
          Our culture defines who we are, setting us apart with a commitment to
          excellence. United by a common mission, we uphold these four core
          principles as the foundation of our work.
        </p>
      </div>
      <div>
        <StoryCard />
      </div>
    </div>
  );
};

export default OurStory;

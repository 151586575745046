import React, { useEffect, useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import {
  CalendarIcon,
  LocationAirIcon,
  ShiningStarIcon,
  SunStarIcon,
  WatchIcon,
} from "../../../assets/svg";

function Feature({ setBgColor }) {
  const containerRef = useRef(null);

  const isMobile = window?.innerWidth <= 768;

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
    smooth: 20,
  });

  const useSpringTransform = (
    scrollYProgress,
    progressValues,
    transformValues
  ) => {
    const transform = useTransform(
      scrollYProgress,
      progressValues,
      transformValues
    );
    return useSpring(transform, { stiffness: 100, damping: 20 });
  };

  const leftPosition = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3, 0.4, 0.5, 0.6, 0.7],
    ["3%", "70%", "70%", "10%", "10%", "65%"]
  );
  const scalePosition = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3, 0.4, 0.5, 0.6, 0.7],
    [1, 0.9, 0.9, 0.8, 0.8, 0.7]
  );

  const text1Opacity = useSpringTransform(scrollYProgress, [0.25, 0.3], [1, 0]);
  const text1RotateX = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3],
    ["0deg", "90deg"]
  );
  const text1TranslateY = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3],
    ["0px", "14px"]
  );
  const mobileText1TranslateY = useSpringTransform(
    scrollYProgress,
    [0.1, 0.3],
    ["0px", "8px"]
  );

  const text2Opacity = useSpringTransform(scrollYProgress, [0.45, 0.5], [1, 0]);
  const text2RotateX = useSpringTransform(
    scrollYProgress,
    [0.4, 0.5],
    ["0deg", "90deg"]
  );
  const text2TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5],
    ["0px", "0px", "-90px", "-76px"]
  );
  const mobileText2TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5],
    ["0px", "0px", "-50px", "-40px"]
  );

  const text3Opacity = useSpringTransform(scrollYProgress, [0.65, 0.7], [1, 0]);
  const text3RotateX = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7],
    ["0deg", "90deg"]
  );
  const text3TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
    ["0px", "0px", "-90px", "-90px", "-190px", "-166px"]
  );
  const mobileText3TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
    ["0px", "0px", "-50px", "-50px", "-110px", "-100px"]
  );

  const text4TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
    ["0px", "0px", "-90px", "-90px", "-190px", "-190px", "-270px"]
  );
  const mobileText4TranslateY = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
    ["0px", "0px", "-50px", "-50px", "-110px", "-110px", "-150px"]
  );

  const span2Color = useTransform(
    scrollYProgress,
    [0.2, 0.3],
    ["#ffffff", "rgba(255, 255, 255, 0)"]
  );
  const span3Color = useTransform(
    scrollYProgress,
    [0.1, 0.4, 0.5],
    ["#ffffff", "#ffffff", "rgba(255, 255, 255, 0)"]
  );
  const span4Color = useTransform(
    scrollYProgress,
    [0.1, 0.6, 0.7],
    ["#ffffff", "#ffffff", "rgba(255, 255, 255, 0)"]
  );

  const icon1Y = useSpringTransform(
    scrollYProgress,
    [0, 0.2, 0.3],
    [0, 0, -100]
  );
  const icon2Y = useSpringTransform(
    scrollYProgress,
    [0.2, 0.4, 0.5],
    [0, 0, -100]
  );
  const icon3Y = useSpringTransform(
    scrollYProgress,
    [0.5, 0.6, 0.7],
    [0, 0, -100]
  );
  const icon4Y = useSpringTransform(
    scrollYProgress,
    [0.6, 0.8, 0.9],
    [0, 0, -0]
  );

  const icon1Opacity = useSpringTransform(
    scrollYProgress,
    [0, 0.2, 0.3],
    [1, 1, 0]
  );
  const icon2Opacity = useSpringTransform(
    scrollYProgress,
    [0.2, 0.4, 0.5],
    [0, 1, 0]
  );
  const icon3Opacity = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.7],
    [0, 1, 0]
  );
  const icon4Opacity = useSpringTransform(
    scrollYProgress,
    [0.6, 0.8, 0.9],
    [0, 1, 1]
  );

  const button1Y = useSpringTransform(
    scrollYProgress,
    [0, 0.2, 0.3],
    [0, 0, -100]
  );
  const button2Y = useSpringTransform(
    scrollYProgress,
    [0.2, 0.4, 0.5],
    [0, 0, -100]
  );
  const button3Y = useSpringTransform(
    scrollYProgress,
    [0.5, 0.6, 0.7],
    [0, 0, -100]
  );
  const button4Y = useSpringTransform(
    scrollYProgress,
    [0.6, 0.8, 0.9],
    [0, 0, -0]
  );

  const button1Opacity = useSpringTransform(
    scrollYProgress,
    [0, 0.2, 0.3],
    [1, 1, 0]
  );
  const button2Opacity = useSpringTransform(
    scrollYProgress,
    [0.2, 0.4, 0.5],
    [0, 1, 0]
  );
  const button3Opacity = useSpringTransform(
    scrollYProgress,
    [0.4, 0.6, 0.7],
    [0, 1, 0]
  );
  const button4Opacity = useSpringTransform(
    scrollYProgress,
    [0.6, 0.8, 0.9],
    [0, 1, 1]
  );

  const firstRotateX = useSpringTransform(scrollYProgress, [0.2, 0.3], [0, 90]);
  const firstRotateY = useSpringTransform(scrollYProgress, [0.2, 0.3], [0, 45]);
  const firstOpacity = useSpringTransform(scrollYProgress, [0.2, 0.3], [1, 0]);

  const secondRotateX = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5],
    [90, 0, 0, -90]
  );
  const secondOpacity = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5],
    [0, 1, 1, 0]
  );
  const secondScale = useSpringTransform(
    scrollYProgress,
    [0.2, 0.3, 0.4, 0.5],
    [0.8, 1, 1, 0.8]
  );

  const thirdRotateX = useSpringTransform(
    scrollYProgress,
    [0.4, 0.5, 0.6, 0.7],
    [90, 0, 0, -90]
  );
  const thirdOpacity = useSpringTransform(
    scrollYProgress,
    [0.4, 0.5, 0.6, 0.7],
    [0, 1, 1, 0]
  );
  const thirdScale = useSpringTransform(
    scrollYProgress,
    [0.4, 0.5, 0.6, 0.7],
    [0.8, 1, 1, 0.8]
  );

  const fourthRotateX = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7, 0.8, 0.9],
    [90, 0, 0, 0]
  );
  const fourthOpacity = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7, 0.8, 0.9],
    [0, 1, 1, 1]
  );
  const fourthScale = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7, 0.8, 0.9],
    [0.8, 1, 1, 1]
  );
  const fourthRotateY = useSpringTransform(
    scrollYProgress,
    [0.6, 0.7, 0.8, 0.9],
    [0, 45, 0, 0]
  );

  const bgColor = useTransform(
    scrollYProgress,
    [0.95, 0.99],
    ["#000000", "#FFFFFF"]
  );
  const textColor = useTransform(
    scrollYProgress,
    [0.95, 0.99],
    ["#FFFFFF", "#000000"]
  );

  // Update background color in parent component
  useEffect(() => {
    const unsubscribe = bgColor.on("change", (latest) => {
      setBgColor(latest);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [bgColor, setBgColor]);

  return (
    <motion.div
      ref={containerRef}
      className="relative h-[500vh]"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <div className="sticky top-0 w-full h-screen  pt-[20vh] top-1/2 -translate-y-1/2 text-center  flex justify-center items-start overflow-hidden">
        <div className="flex flex-col justify-center items-center gap-[96px] lg:gap-[48px] ">
          <div className="relative overflow-hidden flex justify-center items-center  h-[200px] w-[350px] lg:w-[400px]">
            <div
              className="w-[254px] h-[63px] relative rounded-[32px] border border-[#0066FF] 
                   px-[32px] py-[16px] gap-[10px] text-white font-semibold flex justify-center items-center overflow-hidden"
              style={{
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 70.03%, #0066FF 180%)",
              }}
            >
              <motion.p
                className="absolute"
                style={{ y: button1Y, opacity: button1Opacity }}
              >
                Scheduled Actions
              </motion.p>
              <motion.p
                className="absolute"
                style={{ y: button2Y, opacity: button2Opacity }}
              >
                Custom Scenes
              </motion.p>
              <motion.p
                className="absolute"
                style={{ y: button3Y, opacity: button3Opacity }}
              >
                Geo-Automation
              </motion.p>
              <motion.p
                className="absolute"
                style={{ y: button4Y, opacity: button4Opacity }}
              >
                Recurring Routines
              </motion.p>
            </div>
            <motion.div
              className="absolute left-[30%] top-[16%]"
              style={{
                opacity: firstOpacity,
                rotateX: firstRotateX,
                rotateY: firstRotateY,
                transformPerspective: 1000,
              }}
            >
              <ShiningStarIcon />
            </motion.div>

            <motion.div
              className="absolute right-[10%] top-[25%] w-[100px] h-[32px] bg-[#005CF5] rounded-[32px] flex justify-center items-center "
              style={{
                opacity: secondOpacity,
                rotateX: secondRotateX,
                scale: secondScale,
                transformPerspective: 1000,
              }}
            >
              <p className="text-white font-semibold text-[12px] leading-[140%] tracking-[0%] align-middle">
                Turn off Light
              </p>
            </motion.div>
            <motion.div
              className="absolute left-[10%] top-[60%] w-[80px] h-[24px] bg-white rounded-[32px] flex justify-center items-center "
              style={{
                opacity: secondOpacity,
                rotateX: secondRotateX,
                scale: secondScale,
                transformPerspective: 1000,
              }}
            >
              <p className="text-black font-semibold text-[10px] leading-[140%] tracking-[0%] align-middle">
                Set AC to 25°
              </p>
            </motion.div>

            <motion.div
              className="absolute right-[10%] top-[10%]  "
              style={{
                opacity: thirdOpacity,
                rotateX: thirdRotateX,
                scale: thirdScale,
                transformPerspective: 1000,
              }}
            >
              <img
                src="/images/airhome/car.png"
                alt="car icon"
                className="w-[90px] h-[90px] lg:w-[100px] lg:h-[100px]"
              />
            </motion.div>

            <motion.div
              className="absolute left-[30%] top-[68%]"
              style={{
                opacity: fourthOpacity,
                rotateX: fourthRotateX,
                rotateY: fourthRotateY,
                transformPerspective: 1000,
              }}
            >
              <ShiningStarIcon />
            </motion.div>

            <motion.div
              className="absolute left-[10%] top-[20%]"
              style={{
                opacity: fourthOpacity,
                rotateX: fourthRotateX,
                scale: fourthScale,
                transformPerspective: 1000,
              }}
            >
              <img
                src="/images/airhome/router.png"
                alt="router icon"
                className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]"
              />
            </motion.div>

            <motion.div
              className="absolute left-0 top-1/2 w-[90px] h-[90px] lg:w-[100px] lg:h-[100px] rounded-[123.75px] bg-white flex flex-col justify-center items-center overflow-hidden"
              style={{ left: leftPosition, scale: scalePosition }}
            >
              <motion.div
                className="absolute"
                style={{
                  y: icon1Y,
                  opacity: icon1Opacity,
                }}
              >
                <WatchIcon />
              </motion.div>

              <motion.div
                className="absolute"
                style={{
                  y: icon2Y,
                  opacity: icon2Opacity,
                }}
              >
                <SunStarIcon />
              </motion.div>

              <motion.div
                className="absolute"
                style={{
                  y: icon3Y,
                  opacity: icon3Opacity,
                }}
              >
                <LocationAirIcon />
              </motion.div>

              <motion.div
                className="absolute"
                style={{
                  y: icon4Y,
                  opacity: icon4Opacity,
                }}
              >
                <CalendarIcon />
              </motion.div>
            </motion.div>
          </div>

          <div className=" flex flex-col justify-center items-center gap-[36px]">
            <motion.p
              className="font-semibold text-[20px] md:text-[36px] lg:text-[56px] leading-[110%] tracking-[0%] text-center align-middle"
              style={{
                transformPerspective: 1000,
                rotateX: text1RotateX,
                y: isMobile ? mobileText1TranslateY : text1TranslateY,
                opacity: text1Opacity,
              }}
            >
              <span className="bg-custom-gradient-text bg-clip-text text-transparent">
                Automation{" "}
              </span>
              on Your Time
            </motion.p>
            <motion.p
              className="font-semibold text-[20px] md:text-[36px] lg:text-[56px] leading-[110%] tracking-[0%] text-center align-middle"
              style={{
                transformPerspective: 1000,
                rotateX: text2RotateX,
                y: isMobile ? mobileText2TranslateY : text2TranslateY,
                opacity: text2Opacity,
              }}
            >
              <motion.span
                className="bg-custom-gradient-text bg-clip-text text-transparent"
                style={{
                  color: span2Color,
                }}
              >
                One Tap
              </motion.span>
              , Perfect Ambience
            </motion.p>
            <motion.p
              className="font-semibold text-[20px] md:text-[36px] lg:text-[56px] leading-[110%] tracking-[0%] text-center align-middle"
              style={{
                transformPerspective: 1000,
                rotateX: text3RotateX,
                y: isMobile ? mobileText3TranslateY : text3TranslateY,
                opacity: text3Opacity,
              }}
            >
              <motion.span
                className="bg-custom-gradient-text bg-clip-text text-transparent"
                style={{
                  color: span3Color,
                }}
              >
                Smart Control
              </motion.span>
              , Wherever You Go
            </motion.p>
            <motion.p
              className="font-semibold text-[20px] md:text-[36px] lg:text-[56px] leading-[110%] tracking-[0%] text-center align-middle"
              style={{
                y: isMobile ? mobileText4TranslateY : text4TranslateY,
              }}
            >
              <motion.span
                className="bg-custom-gradient-text bg-clip-text text-transparent"
                style={{
                  color: span4Color,
                }}
              >
                Effortless{" "}
              </motion.span>
              Everyday Automation
            </motion.p>
          </div>
        </div>
      </div>
      <div className="hidden md:flex absolute bottom-0 w-full justify-start items-start pb-[100px] pl-[100px] lg:pl-[140px]">
        <div className="flex flex-col justify-start items-start">
          <p className=" font-medium text-[24px] lg:text-[32px] leading-[140%] tracking-[0%] align-middle text-[#808080]">
            From quick controls to advanced automation
          </p>
          <div className="relative  w-[540px] lg:w-[800px] h-[100px] lg:h-[160px]">
            <h1 className=" font-bold text-[46px] lg:text-[72px] leading-[140%] tracking-[0%] align-middle">
              Your Smart Home
            </h1>

            <div className=" -rotate-[3deg] absolute right-[4%] w-[115px] lg:h-[50px] lg:w-[145px] lg:h-[75px] bg-[#59EDF8] rounded-[12px] flex flex-col justify-center items-center">
              <h1 className="  font-semibold text-[30px] lg:text-[48px] leading-[140%] tracking-[0%] align-middle">
                Way
              </h1>
            </div>
            <div className="-rotate-[9deg] absolute bottom-[15%] right-[14%] w-[115px] lg:h-[50px] lg:w-[145px] lg:h-[75px] bg-[#66A3FF] rounded-[12px] flex flex-col justify-center items-center rotate-9">
              <h1 className=" font-semibold text-[30px] lg:text-[48px] leading-[140%] tracking-[0%] align-middle">
                Your
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex absolute bottom-0 w-full justify-start items-start pb-[100px] ">
        <div className="flex flex-col justify-center items-center gap-[24px] w-full">
          <div className="relative w-full h-[60px]">
            {/* "Way" Block */}
            <div className="-rotate-[3deg] absolute left-1/2 top-1/2 transform -translate-x-[10%] -translate-y-1/2 w-[115px] lg:w-[145px] h-[50px] lg:h-[75px] bg-[#59EDF8] rounded-[12px] flex flex-col justify-center items-center">
              <h1 className="font-semibold text-[30px] leading-[140%] tracking-[0%] text-center">
                Way
              </h1>
            </div>

            {/* "Your" Block */}
            <div className="-rotate-[9deg] absolute left-1/2 top-1/4 transform -translate-x-[80%] -translate-y-1/2 w-[115px] lg:w-[145px] h-[50px] lg:h-[75px] bg-[#66A3FF] rounded-[12px] flex flex-col justify-center items-center">
              <h1 className="font-semibold text-[30px] leading-[140%] tracking-[0%] text-center">
                Your
              </h1>
            </div>
          </div>

          <p className=" font-medium text-[14px]  leading-[140%] tracking-[0%] align-middle text-[#808080] text-center ">
            From quick controls to advanced automation
          </p>

          <h1 className=" font-bold text-[30px]  leading-[140%] tracking-[0%] align-middle text-center">
            Your Smart Home
          </h1>
        </div>
      </div>
    </motion.div>
  );
}

export default Feature;
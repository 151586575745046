import React from "react";
import Career from "../../components/career";
function index() {
  return (
    <div>
      <Career />
    </div>
  );
}

export default index;

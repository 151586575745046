import React from "react";

function VisionIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37695 30.7003C9.76695 22.8253 10.9332 17.9128 14.4282 14.4253C17.9157 10.9303 22.8282 9.76408 30.7032 9.37408M80.627 30.7003C80.237 22.8253 79.0707 17.9128 75.5757 14.4253C72.0882 10.9303 67.1757 9.76408 59.3007 9.37408M59.3007 80.6241C67.1757 80.2341 72.0882 79.0678 75.5757 75.5728C79.0707 72.0853 80.237 67.1728 80.627 59.2978M30.7032 80.6241C22.8282 80.2341 17.9157 79.0678 14.4282 75.5728C10.9332 72.0853 9.76695 67.1728 9.37695 59.2978"
        stroke="white"
        strokeWidth="3.46154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.3857 44.8839C52.3857 42.8947 51.5956 40.9871 50.189 39.5806C48.7825 38.174 46.8749 37.3839 44.8857 37.3839C42.8966 37.3839 40.989 38.174 39.5824 39.5806C38.1759 40.9871 37.3857 42.8947 37.3857 44.8839C37.3857 46.873 38.1759 48.7806 39.5824 50.1871C40.989 51.5937 42.8966 52.3839 44.8857 52.3839C46.8749 52.3839 48.7825 51.5937 50.189 50.1871C51.5956 48.7806 52.3857 46.873 52.3857 44.8839Z"
        stroke="white"
        strokeWidth="3.11538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6952 44.9992C72.6952 44.2203 72.2729 43.6907 71.4317 42.6384C67.6482 37.903 57.9871 27.6915 45.0029 27.6915C32.0186 27.6915 22.3575 37.903 18.574 42.6384C17.7329 43.6907 17.3105 44.2203 17.3105 44.9992C17.3105 45.778 17.7329 46.3076 18.574 47.3599C22.3575 52.0953 32.0186 62.3069 45.0029 62.3069C57.9871 62.3069 67.6482 52.0953 71.4317 47.3599C72.2729 46.3076 72.6952 45.778 72.6952 44.9992Z"
        stroke="white"
        strokeWidth="3.11538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VisionIcon;

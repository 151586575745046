import {
  BagIconWhite,
  CallIconWhite,
  CartIconWhite,
  ChatIconWhite,
  HandShakeIconWhite,
  MoneyIconWhite,
  TrendIconWhite,
  TvIconWhite,
  VideoIconWhite,
} from "../../../assets/svg";
import airGlassMaster from "../../../assets/js/airglassmaster.json";
export const head = {
  label: "The AirGlass Series",
  text: "Luxuriously sleek. Effortlessly smart.",
  button: "Explore More",
  imgWeb: "/images/airglass/airgalsshome.png",
  imgMobile: "/images/airglass/airgalsshome.png",
};

export const section = {
  label: "Glass Meets Innovation",
  text: "The AirGlass Series combines stylish design with smart technology, giving you control that’s as smooth as glass. Whether you're dimming the lights or turning up the comfort, it's a touch of luxury without the usual hassles. Now, even your touch panel can be smarter than your friends.",
  img: "/images/airglass/headglass.png",
};
export const secondCard = {
  first: {
    label: "3KV Surge Protection ",
    logo: "/images/airglass/gemstone.png",
    text: "Protects your devices from unexpected voltage spikes, offering peace of mind and lasting reliability for your smart home setup.",
  },
  second: {
    label: "2mm Toughened Glass ",
    logo: "/images/airglass/lightningbolt.png",
    text: "A slim yet robust glass surface designed to resist scratches and impacts, ensuring a flawless look while enduring the rigors of everyday use.",
  },

  img: "/images/airglass/secondairglass.png",
};
export const thirdCard = {
  first: {
    label: "Master Button",
    logo: "/images/airglass/fingerup.png",
    text: "It can be tedious to turn off all the touch panel when you are moving out. One touch of the master button can turn off all the touch panel at once. It's hassle free and convenient.",
  },
  second: {
    label: "Two Way use",
    logo: "/images/airglass/arrows.png",
    text: "Our smart touch panel enable two-way usage. You can easily turn them on at one end and turn them off at the other - or vice versa.",
  },

  img: airGlassMaster,
};

export const fourthCard = {
  first: {
    label: "Master Button",
    logo: "/images/airglass/signalbars.png",
    text: "It can be tedious to turn off all the touch panel when you are moving out. One touch of the master button can turn off all the touch panel at once. It's hassle free and convenient.",
  },
  second: {
    label: "Two Way use",
    logo: "/images/airglass/lightbulb.png",
    text: "Our smart touch panel enable two-way usage. You can easily turn them on at one end and turn them off at the other - or vice versa.",
  },

  img: "/images/airglass/airglasspanel.png",
};

export const smartControlList = {
  label: "Smart Control, Wherever You Are",
  text: "Seamless integration with your existing smart home setup.",
  first: {
    cardLabel: "AirHome App",
    cardText: "Controls IR devices via app with sleek precision.",
    img: "/images/airhomeapp.png",
  },
  second: {
    cardLabel: "AirRemote",
    cardText: "Click, relax, repeat—because remotes never go out of style.",
    img: "/images/airremote.png",
  },
  third: {
    cardLabel: "Voice Control",
    cardText: "Say it, and it's done. Who needs buttons?",
    img: "/images/airvoicecontrol.png",
  },
  fourth: {
    cardLabel: "Scene Panel",
    cardText: "One touch panel, endless possibilities. Your home, your rules.",
    img: "/images/scenepanel.png",
  },
};

export const innovations = {
  head: "Advanced Technology for Seamless Living",
  text: "Cutting-edge technology that enhances your home experience",
  img: "/images/airglass/airglassinnovation.png",
  card: [
    {
      label: "CapSense",
      text: "Precise touch control for smooth and responsive interactions.",
    },
    {
      label: "IR Functionality",
      text: "Integrate traditional control with Infrared Intelligence for smarter automation.",
    },
  ],
};
export const swiperData = [
  {
    label: "ShockProof Technology",
    text: ["Oops-proof! Built to survive ", "your accidental zaps"],
    img: "/images/airglass/lightningbolt.png",
    bgImage: "/images/airglass/spark.png",
  },
  {
    label: "WaterGuard Display",
    text: ["Splish-splash? No problem!", "It's spill-resistant."],
    img: "/images/posh/droplet.png",
    bgImage: "/images/airglass/geminiwater.png",
  },
  {
    label: "ChildSafe Lock",
    text: ["Kid-tested, tantrum-approved.", "Safety made simple."],
    img: "/images/airglass/face.png",
    bgImage: "/images/airglass/gemini.png",
  },
  {
    label: "Safety",
    text: ["Peace of Mind.", "Safety Reinvented."],
    img: "/images/quartz/shield.png",
    bgImage: "/images/airglass/handslamp.png",
  },
];

export const switchData = {
  head: "Smart colors crafted for ultra-modern interiors",
  text: "Seamlessly fits in homes with glass, marble, or minimal setups.",
  img: {
    after: "/images/airglass/whiteairglass.png",
    before: "/images/airglass/blackairglass.png",
  },
  isPosh: true,
};

export const elevateList = {
  head: "Ready to Elevate Your Home?",
  text: "Simple steps to purchase the Posh Series.",
  card: [
    {
      headIcon: ["08", "09", "10", "11", "12"],
      cardIcon: <CallIconWhite />,
      steps: {
        head: "Step 1: Schedule a Call or Fill Form",
        text: "Let’s get started by scheduling a consultation. Either book a call or fill out our quick form, and one of our experts will reach out to guide you through the process.",
      },
    },
    {
      headIcon: [<VideoIconWhite />, <HandShakeIconWhite />, <ChatIconWhite />],
      cardIcon: <TvIconWhite />,
      steps: {
        head: "Step 2: Personalized Demo",
        text: "Experience the Posh Series in action. Our team will provide a personalized demo, answering all your questions and helping you find the perfect fit for your smart home.",
      },
    },
    {
      headIcon: [<MoneyIconWhite />, <BagIconWhite />, <TrendIconWhite />],
      cardIcon: <CartIconWhite />,
      steps: {
        head: "Step 3: Purchase & Upgrade",
        text: "Once you're satisfied, proceed with the purchase and get ready to elevate your home with elegance and innovation.",
      },
    },
  ],
};
export const productComparisonArray = {
  label: "Crafted for Every Style",
  text: "Choose between two uniquely designed series, each crafted for distinct tastes.",
  productA: "AirGlass",
  productB: "Quartz",
  imgProductA: "/images/airglass/airglasssmall.png",
  imgProductAClass: "w-[120px] lg:w-[250px]",
  imgProductB: "/images/quartz/quartzproduct.png",
  imgProductBClass: "w-full",
  productList: [
    {
      category: "Surface Aesthetic",
      productA: {
        title: "Graphic Glass",
        description:
          "Luxurious design with detailed graphic finish for an elevated look.",
      },
      productB: {
        title: "Frameless Glass",
        description:
          "Sleek and modern, for those who appreciate elegance in simplicity.",
      },
    },
    {
      category: "Durability",
      productA: {
        title: "Surge Protection",
        description:
          "Extra protection with surge resistance for enhanced longevity.",
      },
      productB: {
        title: "Toughened Glass",
        description:
          "Built to withstand daily wear while maintaining its pristine look.",
      },
    },
    {
      category: "Connectivity",
      productA: {
        title: "Advanced Technology",
        description:
          "Faster, more reliable with BLE 5 & Wi-Fi 6 connections for a truly connected home.",
      },
      productB: {
        title: "Smart Technology",
        description:
          "Seamless integration for intuitive control of smart devices.",
      },
    },
    {
      category: "Installation",
      productA: {
        title: "Quick & Easy Fitting",
        description:
          "Fast installation designed for modern, hassle-free setup.",
      },
      productB: {
        title: "Concealed Box",
        description: "Clean and unobtrusive installation for a sleek interior.",
      },
    },
    {
      category: "Lighting Control",
      productA: {
        title: "Backlit Control",
        description:
          "Elegant backlit design in customizable colors for a premium experience.",
      },
      productB: {
        title: "Minimalist Elegance",
        description:
          "Subtle design that enhances your living space’s ambiance.",
      },
    },
  ],
};

import React, { useContext } from "react";
import { motion } from "framer-motion";
import { MotionSensorArray } from "./Constants";
import { CallIconBlack } from "../../../assets/svg";
import { Link } from "react-router-dom";
import { ContactModalContext } from "../../../config/contexts";

function MotionSensor() {
  const { openModal } = useContext(ContactModalContext);

  return (
    <div className="p-[24px] lg:px-[165px] lg:py-[150px] flex flex-col justify-center items-center gap-[24px] lg:gap-[64px] bg-custom-bg-black ">
      <motion.div
        className=" w-full lg:w-[700px] flex flex-col justify-center items-center gap-[12px] lg:gap-[24px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <h1 className="font-semibold text-[22px] lg:text-[64px] leading-[100%] tracking-[0%] align-middle text-center text-[#ffffff]">
          {MotionSensorArray?.label}
        </h1>
        <p className="font-semibold text-[16px] lg:text-[24px] leading-[140%] tracking-[1%] text-center align-middle text-[#F4F4F4CC] text-center">
          {MotionSensorArray?.text}
        </p>
      </motion.div>
      <div className="flex flex-col gap-[24px] lg:gap-[32px] w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 bg-white rounded-[32px] items-center h-[450px]">
          <div className="flex justify-center items-center">
            <motion.img
              src={MotionSensorArray?.firstCard?.img}
              alt="Sensor Design"
              className="h-[200px] lg:h-[350px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              viewport={{ once: true }}
            />
          </div>
          <motion.div
            className="flex flex-col justify-center items-start gap-[12px] h-full p-[24px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#000000]">
              {MotionSensorArray?.firstCard?.label}
            </h2>
            <p className="text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#808080]">
              {MotionSensorArray?.firstCard?.text}
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2  bg-white rounded-[32px] items-center h-[450px]">
          <motion.div
            className="flex flex-col justify-center items-start gap-[12px] h-full p-[24px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#000000]">
              {MotionSensorArray?.secondCard?.label}
            </h2>
            <p className="text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#808080]">
              {MotionSensorArray?.secondCard?.text}
            </p>
          </motion.div>
          <div className="flex justify-center items-center">
            <motion.img
              src={MotionSensorArray?.secondCard?.img}
              alt="Sensor Design"
              className="h-[200px] lg:h-[350px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              viewport={{ once: true }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 bg-white rounded-[32px] items-center h-[450px]">
          <div className="flex justify-center items-center">
            <motion.img
              src={MotionSensorArray?.thirdCard?.img}
              alt="Sensor Design"
              className="h-[200px] lg:h-[350px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              viewport={{ once: true }}
            />
          </div>
          <motion.div
            className="flex flex-col justify-center items-start gap-[12px] h-full p-[24px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
            viewport={{ once: true }}
          >
            <h2 className="text-[24px] lg:text-[48px] font-semibold leading-[130%] tracking-normal align-middle text-[#000000]">
              {MotionSensorArray?.thirdCard?.label}
            </h2>
            <p className="text-[18px] lg:text-[20px] font-medium leading-[130%] tracking-normal align-middle text-[#808080]">
              {MotionSensorArray?.thirdCard?.text}
            </p>
          </motion.div>
        </div>
        <div className="flex justify-center items-center">
          <Link
            onClick={() => {
              openModal();
            }}
            className="w-[218px] h-[54px] gap-[8px] px-[28px] py-[12px]  font-semibold rounded-[8px] bg-[#ffffff] flex justify-center items-center"
          >
            <CallIconBlack />
            Schedule a call
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MotionSensor;

import React from "react";

function Instagram() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#181818" />
      <path
        d="M7.5 20C7.5 14.1079 7.5 11.1605 9.33026 9.33026C11.1605 7.5 14.1066 7.5 20 7.5C25.8921 7.5 28.8395 7.5 30.6697 9.33026C32.5 11.1605 32.5 14.1066 32.5 20C32.5 25.8921 32.5 28.8395 30.6697 30.6697C28.8395 32.5 25.8934 32.5 20 32.5C14.1079 32.5 11.1605 32.5 9.33026 30.6697C7.5 28.8395 7.5 25.8934 7.5 20Z"
        stroke="white"
        strokeWidth="1.97368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2485 12.7632H27.2353M25.9222 20C25.9222 21.5704 25.2984 23.0764 24.1879 24.1868C23.0775 25.2973 21.5715 25.9211 20.0011 25.9211C18.4308 25.9211 16.9247 25.2973 15.8143 24.1868C14.7039 23.0764 14.0801 21.5704 14.0801 20C14.0801 18.4297 14.7039 16.9236 15.8143 15.8132C16.9247 14.7028 18.4308 14.079 20.0011 14.079C21.5715 14.079 23.0775 14.7028 24.1879 15.8132C25.2984 16.9236 25.9222 18.4297 25.9222 20Z"
        stroke="white"
        strokeWidth="1.97368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Instagram;

import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import WarrantySection from "./WarrantySection";

const Smarter = () => {
  const ref = useRef(null);

  // Track scroll progress relative to the component
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"], // Animation starts when the section enters the viewport
  });

  // Transform scroll progress into opacity values
  const window1Opacity = useTransform(scrollYProgress, [0.1, 0.2], [0, 1]);
  const window2Opacity = useTransform(scrollYProgress, [0.2, 0.4], [0, 1]);
  const window3Opacity = useTransform(scrollYProgress, [0.4, 0.6], [0, 1]);
  const window4Opacity = useTransform(scrollYProgress, [0.5, 0.7], [0, 1]);

  return (
    <div className="flex flex-col items-center justify-center w-full bg-[#191919]">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="flex flex-col items-center justify-center p-[24px] gap-[16px] lg:py-[100px] lg:gap-[24px]"
      >
        <motion.h1
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          className="font-semibold text-[24px] lg:text-[56px] leading-[120%] tracking-normal text-center text-white lg:w-[940px]"
        >
          We make your space smarter{" "}
          <span className="bg-custom-gradient-text bg-clip-text text-transparent">
            Before you even arrive
          </span>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
          className="font-normal text-[14px] lg:text-[22px] leading-[150%] tracking-normal text-center text-[#98A2B3] lg:w-[600px]"
        >
          Imagine a home that knows you best. With our advanced tech, we
          anticipate your needs pre-arrival. Adjusting temperature or lighting,
          we tailor your space.
        </motion.p>
      </motion.div>
      <div
        ref={ref}
        className="relative  flex items-center justify-center w-full"
      >
        <div className="relative w-full">
          <img
            src="/images/home/hotel.png"
            alt="Building"
            className=" w-full"
          />

          {/* Windows appearing one by one */}
          <motion.img
            src="/images/home/hotel-window-1.png"
            alt="Window 1"
            className="absolute top-0 left-0 w-full"
            style={{ opacity: window1Opacity }}
          />
          <motion.img
            src="/images/home/hotel-window-2.png"
            alt="Window 2"
            className="absolute top-0 left-0 w-full"
            style={{ opacity: window2Opacity }}
          />
          <motion.img
            src="/images/home/hotel-window-3.png"
            alt="Window 3"
            className="absolute top-0 left-0 w-full"
            style={{ opacity: window3Opacity }}
          />
          <motion.img
            src="/images/home/hotel-window-4.png"
            alt="Window 4"
            className="absolute top-0 left-0 w-full"
            style={{ opacity: window4Opacity }}
          />

          {/* Windows card appearing one by one */}
          <motion.img
            src="/images/home/hotel-card-1.png"
            alt="Window 1"
            className="hidden lg:block absolute top-[13%] left-[18%] w-[10vw] h-[30vh]"
            style={{ opacity: window1Opacity }}
          />
          <motion.img
            src="/images/home/hotel-card-2.png"
            alt="Window 2"
            className="hidden lg:block absolute top-[25%] right-[18%] w-[10vw] h-[30vh]"
            style={{ opacity: window2Opacity }}
          />
          <motion.img
            src="/images/home/hotel-card-3.png"
            alt="Window 3"
            className="hidden lg:block absolute top-[45%] left-[18%] w-[10vw] h-[30vh]"
            style={{ opacity: window3Opacity }}
          />
          <motion.img
            src="/images/home/hotel-card-4.png"
            alt="Window 4"
            className="hidden lg:block absolute top-[55%] right-[18%] w-[10vw] h-[30vh]"
            style={{ opacity: window4Opacity }}
          />
        </div>

        <div className="absolute bottom-0 hidden lg:flex ">
          <WarrantySection />
        </div>
        <div className="absolute bottom-0 lg:hidden w-full p-[24px]">
          <div className="flex flex-col gap-2 justify-center items-center ">
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
              className="font-inter font-normal text-[14px] leading-[19.36px] lg:text-[18px] lg:leading-[21.78px] tracking-[0] px-[5px] text-[#98A2B3] text-center"
            >
              Made in India with Global Innovation
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
              className="font-inter font-normal text-[14px] leading-[19.36px] lg:text-[18px] lg:leading-[21.78px] tracking-[0] px-[5px] text-[#98A2B3] text-center"
            >
              Secure, Energy-Smart Ecosystems
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
              className="font-inter font-normal text-[14px] leading-[19.36px] lg:text-[18px] lg:leading-[21.78px] tracking-[0] px-[5px] text-[#98A2B3] text-center"
            >
              User Centric Convenience
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
              className="font-inter font-normal text-[14px] leading-[19.36px] lg:text-[18px] lg:leading-[21.78px] tracking-[0] px-[5px] text-[#98A2B3] text-center"
            >
              Sleek Design, Lasting Performance
            </motion.p>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden">
        <WarrantySection />
      </div>
    </div>
  );
};

export default Smarter;

import React, { lazy } from "react";
// Remove these direct imports since we're using lazy loading
// import {
//   Categories,
//   CustomersReview,
//   Innovation,
//   LivingRoom,
//   Routines,
//   Smarter,
//   SmartAutomation,
//   Welcome,
// } from "../../components/home";
// import { EsteemedClients } from "../../components/common";

// Lazy load all components
const Welcome = lazy(() => import("../../components/home/Welcome"));
const SmartAutomation = lazy(() => import("../../components/home/SmartAutomation"));
const Smarter = lazy(() => import("../../components/home/Smarter"));
const Innovation = lazy(() => import("../../components/home/Innovation"));
const Categories = lazy(() => import("../../components/home/Categories"));
const Routines = lazy(() => import("../../components/home/Routines"));
const LivingRoom = lazy(() => import("../../components/home/LivingRoom"));
const CustomersReview = lazy(() => import("../../components/home/CustomersReview"));
const EsteemedClients = lazy(() => import("../../components/common/EsteemedClients"));

function HomePage() {
  return (
    <div className="w-full">
      <Welcome />
      
      {/* Smart Automation */}
      <SmartAutomation />
      
      {/* Smarter */}
      <Smarter />
      
      {/* Innovation */}
      <Innovation />
      
      {/* Categories */}
      <Categories />
      
      {/* Routines */}
      <Routines />
      
      {/* LivingRoom */}
      <LivingRoom />
      
      {/* EsteemedClients */}
      <EsteemedClients color={"#ffffff"} />
      
      {/* CustomersReview */}
      <CustomersReview />
    </div>
  );
}

export default HomePage;

import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { NextIcon, PrevIcon, StarRating, ThumbIcon } from "../../assets/svg";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

const TestimonialsCard = ({
  index,
  isVisible,
  customMargin,
  designation,
  img,
  star,
  text,
  name,
  onOpenModal,
  videoId,
}) => {
  return (
    <div
      className={`grid grid-cols-12  h-[192px] w-[350px] mb-0 md:w-[392px] lg:w-[392px]  cursor-pointer rounded-[4px] ${
        isVisible ? customMargin : "pt-0"
      }`}
      style={{
        border: "1px solid #FFFFFF1A",
        boxFhadow: "0px 19.82px 42.18px 0px #0000003D",

        backdropfilter: "blur(4px)",
      }}
      onClick={() => {
        onOpenModal(videoId);
      }}
    >
      <div className="bg-[#F3F5F0] col-span-2 flex justify-center items-center h-[192px] rounded-bl-[4px] rounded-tl-[4px]">
        <ThumbIcon />
        <p className="font-inter font-medium text-[10px] leading-[16.44px] tracking-[1%]">
          {designation}
        </p>
      </div>
      <div className=" col-span-3 relative -top-[14px] h-[220px]">
        <img
          src={img}
          alt="Description"
          className="w-full h-full  rounded-[4px]"
          loading="lazy"
        />
      </div>
      <div className=" col-span-7 grid grid-cols-1 gap-2 pl-[8px] h-[192px]">
        <div className="flex items-center pl-[10px] pt-[10px] ">
          {Array.from({ length: star }).map((_, index) => (
            <StarRating key={index} />
          ))}
        </div>
        <div className=" flex items-center justify-center">
          <p className="font-inter font-normal italic  text-[9px] leading-[18px]  tracking-[0%] text-white text-left">
            {text}
          </p>
        </div>

        <div className="">
          <p className=" font-inter font-extrabold text-[9px] leading-[12px] text-[#FFFFFF] tracking-[0%] text-left">
            {" "}
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};
const testimonialsArray = [
  {
    designation: "Client",
    text: ` “Whitelion transformed my home! The convenience is unmatched – lights on entry, remote control, and sleek designs. Safety features like water resistance are reassuring. Highly recommend for a smart, hassle-free home”`,
    img: "/images/rohitpurohit.png",
    star: 5,
    name: "Rohit Purohit | Mumbai",
    videoId: "XSsOupuwxCM",
  },
  {
    designation: "Architect",
    text: ` “Whitelion's smart touch panel: easy install, modern, energy-saving. Perfect for existing wiring, recommended for designers seeking simplicity and efficiency.”`,
    img: "/images/kamiliniapte.png",
    star: 5,
    name: "Kamilini Apte | Pune",
    videoId: "nHAzDtPgCRI",
  },
  {
    designation: "Channel Partner",
    text: ` “After 30 years in the industry, Whitelion stands out. Their innovations, support, and growth are exceptional. We're proud to partner with them.”`,
    img: "/images/pratikkothari.png",
    star: 5,
    name: "Pratik Kothari | Surat",
    videoId: "3SlYBopn6tQ",
  },
  {
    designation: "Client",
    text: ` “Whitelion transformed my home! The convenience is unmatched – lights on entry, remote control, and sleek designs. Safety features like water resistance are reassuring. Highly recommend for a smart, hassle-free home”`,
    img: "/images/rohitpurohit.png",
    star: 5,
    name: "Rohit Purohit | Mumbai",
    videoId: "XSsOupuwxCM",
  },
  {
    designation: "Architect",
    text: ` “Whitelion's smart touch panel: easy install, modern, energy-saving. Perfect for existing wiring, recommended for designers seeking simplicity and efficiency.”`,
    img: "/images/kamiliniapte.png",
    star: 5,
    name: "Kamilini Apte | Pune",
    videoId: "nHAzDtPgCRI",
  },
  {
    designation: "Channel Partner",
    text: ` “After 30 years in the industry, Whitelion stands out. Their innovations, support, and growth are exceptional. We're proud to partner with them.”`,
    img: "/images/pratikkothari.png",
    star: 5,
    name: "Pratik Kothari | Surat",
    videoId: "3SlYBopn6tQ",
  },
];
function SwiperTestimonials() {
  const [visibleSlides, setVisibleSlides] = useState([0, 1, 2]);
  const [isMobile, setIsMobile] = useState(false);
  const swiperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [YouTubeUrl, setYouTubeUrl] = useState(null);

  const onOpenModal = (videoId) => {
    if (videoId) {
      setYouTubeUrl(videoId);
      setOpen(true);
    }
  };

  const onCloseModal = () => {
    setOpen(false);
    setYouTubeUrl(null); // Reset URL when closing modal
  };

  // Detect screen size and update isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Mobile: width < 768px
    };

    handleResize(); // Run on mount
    window.addEventListener("resize", handleResize); // Update on resize

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // Dynamic paddings for the 3 visible slides
  const cardCssValues = [
    " lg:mt-[240px] lg:ml-[100px]",
    " lg:mt-[20px]",
    " lg:mt-[150px]",
  ];

  // Function to update visible slides
  const updateVisibleSlides = (swiper) => {
    const newVisibleSlides = [
      swiper.realIndex,
      (swiper.realIndex + 1) % swiper.slides.length,
      (swiper.realIndex + 2) % swiper.slides.length,
    ];
    setVisibleSlides(newVisibleSlides);
  };

  // Move to next slide manually
  const nextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      updateVisibleSlides(swiperRef.current);
    }
  };

  // Move to previous slide manually
  const prevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      updateVisibleSlides(swiperRef.current);
    }
  };
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: " lg:h-full lg:w-full flex items-center justify-center ",
            overlay: "bg-black bg-opacity-80",
            closeButton: "hidden",
          }}
        >
          <div className="video-container relative w-full h-full flex items-center justify-center">
            {YouTubeUrl && (
              <iframe
                className="pt-[30px] md:pt-[40px] w-full h-full"
                src={`https://www.youtube.com/embed/${YouTubeUrl}?autoplay=1`}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </Modal>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => updateVisibleSlides(swiper)}
        navigation={{
          prevEl: ".custom-prev",
          nextEl: ".custom-next",
        }}
        modules={[Pagination, Navigation]}
        allowTouchMove={false} // Prevent touch-based slide changing
        simulateTouch={false} // Disable mouse drag to prevent unwanted slide changes
        direction={isMobile ? "vertical" : "horizontal"}
        style={{ width: "100%", touchAction: "auto" }}
        className="mySwiper "
      >
        {testimonialsArray?.map((item, index) => (
          <SwiperSlide
            key={index}
            className={isMobile ? "!flex !justify-center !items-center" : ""}
          >
            <TestimonialsCard
              index={index}
              isVisible={visibleSlides.includes(index)}
              customMargin={
                visibleSlides.includes(index)
                  ? cardCssValues[visibleSlides.indexOf(index)]
                  : "pt-0"
              }
              designation={item.designation}
              img={item.img}
              star={item.star}
              text={item.text}
              name={item.name}
              onOpenModal={onOpenModal}
              videoId={item.videoId}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className=" grid grid-cols-1 pt-[30px] lg:pt-[0px]">
        <div className="flex justify-center items-center gap-4">
          <button
            className="custom-prev h-[36px] w-[36px] rounded-full bg-[#FFFFFF]"
            onClick={prevSlide}
          >
            <PrevIcon />
          </button>
          <button
            className="custom-next h-[36px] w-[36px] rounded-full bg-[#FFFFFF]"
            onClick={nextSlide}
          >
            <NextIcon />
          </button>
        </div>
      </div>
    </>
  );
}

export default SwiperTestimonials;

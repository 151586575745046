import React from "react";

function StarRating() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.57964 2.17219C6.72884 1.71302 7.37844 1.71302 7.52764 2.17219L8.50828 5.19031C8.575 5.39566 8.76636 5.53469 8.98228 5.53469H12.1557C12.6385 5.53469 12.8393 6.1525 12.4487 6.43629L9.8813 8.30159C9.70662 8.4285 9.63353 8.65345 9.70025 8.8588L10.6809 11.8769C10.8301 12.3361 10.3045 12.7179 9.91395 12.4341L7.34659 10.5688C7.17191 10.4419 6.93537 10.4419 6.76069 10.5688L4.19333 12.4341C3.80273 12.7179 3.27719 12.3361 3.42638 11.8769L4.40703 8.8588C4.47375 8.65346 4.40066 8.4285 4.22598 8.30159L1.65861 6.43629C1.26802 6.1525 1.46876 5.53469 1.95156 5.53469H5.125C5.34091 5.53469 5.53227 5.39566 5.599 5.19031L6.57964 2.17219Z"
        fill="white"
      />
    </svg>
  );
}

export default StarRating;

import React from "react";

function DownArrow({ size }) {
  return (
    <>
      {size === "desktop" && (
        <svg
          width="80"
          height="79"
          viewBox="0 0 80 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2500_3947)">
            <circle
              cx="40"
              cy="35"
              r="24"
              transform="rotate(90 40 35)"
              fill="#1E1E1E"
            />
          </g>
          <path
            d="M32.1777 32.0537L40.0005 39.8412L47.8233 32.0537"
            stroke="white"
            strokeWidth="1.89492"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_2500_3947"
              x="0.840624"
              y="0.577929"
              width="78.3188"
              height="78.3188"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.73731" />
              <feGaussianBlur stdDeviation="7.57969" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2500_3947"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2500_3947"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
      {size === "mobile" && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2502_4150)">
            <circle
              cx="20"
              cy="18"
              r="12"
              transform="rotate(90 20 18)"
              fill="#1E1E1E"
            />
          </g>
          <path
            d="M16.0879 16.5264L19.9993 20.4201L23.9107 16.5264"
            stroke="white"
            strokeWidth="0.947461"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_2502_4150"
              x="0.420312"
              y="0.788965"
              width="39.1594"
              height="39.1594"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.36865" />
              <feGaussianBlur stdDeviation="3.78984" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2502_4150"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2502_4150"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </>
  );
}

export default DownArrow;

import React from "react";
import { head } from "./Constants";
import { motion } from "framer-motion";

function Welcome() {
  return (
    <motion.div className="bg-custom-bg-white  pt-[160px] lg:pt-[100px] h-screen flex flex-col h-[100vh]  lg:justify-center items-center gap-[100px] lg:gap-[40px]" >
      <motion.div
        className="flex  justify-center items-center px-[24px]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <motion.img
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          src={head?.imgWeb}
          alt="air sensor home"
          className="lg:h-[40vh] w-full"
        />
      </motion.div>
      <motion.div
        className="grid grid-cols-1 gap-[12px] px-[24px] lg:w-[1000px]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        <p className="font-bold text-[24px] lg:text-[36px] leading-[36px]  lg:text-[64px] lg:leading-[64px] tracking-[0.68px] text-center">
          {head?.label}
        </p>
        <p className="font-semibold text-[14px] lg:text-[24px] leading-[130%] tracking-[0.22px] text-center align-middle text-[#86868B]">
          {head?.text}
        </p>
      </motion.div>
    </motion.div>
  );
}

export default Welcome;

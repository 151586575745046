import React, { useState } from "react";
import { motion } from "framer-motion";
import { AppleAppLink, GoogleAppLink } from "../../../config/constant";

function CompatibilityPoshQuartz({ mode }) {
  const [isOnBlack, setIsOnBlack] = useState(mode);
  return (
    <>
      {/* Toggle Section */}
      <motion.div
        className="grid grid-cols-1 gap-[24px] lg:gap-[54px]"
        animate={{ backgroundColor: isOnBlack ? "#000000" : "#ffffff" }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div className="flex justify-center items-center pt-[24px] lg:pt-[54px] ">
          <div className="grid grid-cols-1 gap-[24px] w-full lg:w-[800px] px-[24px]">
            <motion.p
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              className="bg-custom-gradient-text bg-clip-text text-transparent text-center font-semibold text-[32px] leading-[35.2px] lg:text-[64px] lg:leading-[70.4px] tracking-[-0.41%]"
            >
              AirHome Compatibility
            </motion.p>

            <motion.p
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              style={{ color: isOnBlack ? "#CCCCCC" : "#1E1E1E" }}
              className=" opacity-80 text-center font-semibold text-[12px] leading-[16.8px] lg:text-[24px] lg:leading-[33.6px] tracking-[1%]"
            >
              Elevate Your Smart Home Automation Experience. Enjoy Universal
              Automation, Geofencing, effortless Scheduling, and tailored User
              Functions for intelligent control and convenience.
            </motion.p>
            <motion.div
              className="flex justify-center items-center gap-[24px]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
            >
              <a href={GoogleAppLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/images/googleplay.png"
                  alt="google play"
                  className="h-[24px] w-[84px] lg:h-[40px] lg:w-[130px]"
                  loading="lazy"
                />
              </a>
              <a href={AppleAppLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/images/appstore.png"
                  alt="app store"
                  className="h-[24px] w-[84px] lg:h-[40px] lg:w-[130px]"
                  loading="lazy"
                />
              </a>
            </motion.div>
            <div className="flex flex-col  items-center justify-center gap-[12px]">
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                style={{ color: isOnBlack ? "#CCCCCC" : "#1E1E1E" }}
                className=" opacity-80 text-center font-semibold text-[12px] leading-[16.8px] lg:text-[24px] lg:leading-[33.6px] tracking-[1%]"
              >
                Experience in Dark & Light Mode
              </motion.p>
              <motion.div
                className={`w-16 h-8 flex items-center rounded-full cursor-pointer p-1 transition-colors duration-300 hidden lg:block`}
                animate={{
                  backgroundColor: isOnBlack ? "#CCCCCC" : "#1E1E1E",
                }}
                onClick={() => setIsOnBlack(!isOnBlack)}
              >
                <motion.div
                  className="w-6 h-6 bg-white rounded-full shadow-md"
                  animate={{
                    x: isOnBlack ? 32 : 0,
                    backgroundColor: isOnBlack ? "#1E1E1E" : "#CCCCCC",
                  }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
              </motion.div>
              <motion.div
                className={`w-12 h-6 flex items-center rounded-full cursor-pointer p-1 transition-colors duration-300 block lg:hidden`}
                animate={{
                  backgroundColor: isOnBlack ? "#CCCCCC" : "#1E1E1E",
                }}
                onClick={() => setIsOnBlack(!isOnBlack)}
              >
                <motion.div
                  className="w-4 h-4 bg-white rounded-full shadow-md"
                  animate={{
                    x: isOnBlack ? 25 : 0,
                    backgroundColor: isOnBlack ? "#1E1E1E" : "#CCCCCC",
                  }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
              </motion.div>
            </div>
          </div>
        </div>

        {/* Image Section - BELOW the toggle, no absolute */}
        <div className="flex justify-center items-center w-full relative h-[40vh] lg:h-[76vh]  overflow-hidden">
          {/* Desktop Light */}
          <motion.img
            src="/images/quartz/compatibilityframeblack.png"
            alt="compatibility frame light desktop"
            className="hidden lg:block w-full absolute top-0 left-0"
            initial={false}
            animate={{ opacity: !isOnBlack ? 1 : 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />

          {/* Desktop Dark */}
          <motion.img
            src="/images/posh/compatibilityframe.png"
            alt="compatibility frame dark desktop"
            className="hidden lg:block w-full absolute top-0 left-0"
            initial={false}
            animate={{ opacity: isOnBlack ? 1 : 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />

          {/* Mobile Light */}
          <motion.img
            src="/images/quartz/compatibilityframeblackmobile.png"
            alt="compatibility frame light mobile"
            className="block lg:hidden w-full absolute top-0 left-0"
            initial={false}
            animate={{ opacity: !isOnBlack ? 1 : 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />

          {/* Mobile Dark */}
          <motion.img
            src="/images/posh/compatibilityframemobile.png"
            alt="compatibility frame dark mobile"
            className="block lg:hidden w-full absolute top-0 left-0"
            initial={false}
            animate={{ opacity: isOnBlack ? 1 : 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
        </div>
      </motion.div>
    </>
  );
}

export default CompatibilityPoshQuartz;

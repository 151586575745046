import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { TopIcon } from "../../assets/svg";

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      onClick={scrollToTop}
      className="fixed bottom-6 h-12 w-12 left-6 p-3 rounded-full shadow-lg backdrop-blur-md flex items-center justify-center overflow-hidden"
      style={{
        background: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(20px)",
        opacity: isVisible ? 1 : 0,
      }}
      // initial={{ y: -700, opacity: 0 }}
      // animate={isVisible ? { y: 0, opacity: 1 } : { y: -700, opacity: 0 }}
      // transition={{ duration: 1, ease: "easeInOut" }}
    >
      <TopIcon />
    </motion.button>
  );
}

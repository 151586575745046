import React from "react";

function MoringIcon({ active }) {
  return active ? (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.998 1.99951V5.73436"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M18.0977 1.99951V5.73436"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M25.209 1.99951V5.73436"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M34.1951 40.1626H2"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96842 12.3652H29.8397C31.9572 12.3652 34.2442 14.2913 33.6887 16.468L32.0396 22.9307C30.3717 29.4621 26.8424 35.2396 20.4926 35.2396H15.3155C8.9664 35.2396 5.43637 29.4634 3.76918 22.9307L2.11943 16.468C1.56459 14.2907 3.85228 12.3652 5.96842 12.3652Z"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M33.4766 19.4321H37.8194C39.7265 19.4321 40.2331 20.6147 39.9081 21.7069L38.4554 26.5801C38.2034 27.4268 37.7075 28.194 36.8063 28.194H30.1777"
        stroke="#36E061"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.998 1.99951V5.73436"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M18.0977 1.99951V5.73436"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M25.209 1.99951V5.73436"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M34.1951 40.1626H2"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96842 12.3652H29.8397C31.9572 12.3652 34.2442 14.2913 33.6887 16.468L32.0396 22.9307C30.3717 29.4621 26.8424 35.2396 20.4926 35.2396H15.3155C8.9664 35.2396 5.43637 29.4634 3.76918 22.9307L2.11943 16.468C1.56459 14.2907 3.85228 12.3652 5.96842 12.3652Z"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
      <path
        d="M33.4766 19.4321H37.8194C39.7265 19.4321 40.2331 20.6147 39.9081 21.7069L38.4554 26.5801C38.2034 27.4268 37.7075 28.194 36.8063 28.194H30.1777"
        stroke="#FEFEFE"
        strokeWidth="2.30744"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default MoringIcon;

import React from "react";

function WaterIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.55 38C24.95 37.9667 25.292 37.808 25.576 37.524C25.86 37.24 26.0013 36.8987 26 36.5C26 36.0333 25.85 35.6587 25.55 35.376C25.25 35.0933 24.8667 34.968 24.4 35C23.0333 35.1 21.5833 34.7253 20.05 33.876C18.5167 33.0267 17.55 31.4847 17.15 29.25C17.0833 28.8833 16.9087 28.5833 16.626 28.35C16.3433 28.1167 16.018 28 15.65 28C15.1833 28 14.8 28.1753 14.5 28.526C14.2 28.8767 14.1 29.2847 14.2 29.75C14.7667 32.7833 16.1 34.95 18.2 36.25C20.3 37.55 22.4167 38.1333 24.55 38ZM24 44C19.4333 44 15.6253 42.4333 12.576 39.3C9.52667 36.1667 8.00133 32.2667 8 27.6C8 24.2667 9.32533 20.642 11.976 16.726C14.6267 12.81 18.6347 8.568 24 4C29.3667 8.56667 33.3753 12.8087 36.026 16.726C38.6767 20.6433 40.0013 24.268 40 27.6C40 32.2667 38.4753 36.1667 35.426 39.3C32.3767 42.4333 28.568 44 24 44Z"
        fill="url(#paint0_linear_1208_852)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1208_852"
          x1="5.04721"
          y1="13.4727"
          x2="28.9553"
          y2="23.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default WaterIcon;

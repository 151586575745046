import React from "react";
import { head } from "./Constants";
import { motion } from "framer-motion";

function Welcome() {
  return (
    <motion.div className="bg-custom-bg-white flex flex-col justify-around lg:justify-around items-center h-screen overflow-hidden pt-[100px] overflow-hidden">
      <motion.div
        className="grid grid-cols-1 gap-[12px] px-[24px]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        <p className="font-bold text-[36px] leading-[36px]  lg:text-[64px] lg:leading-[64px] tracking-[0.68px] text-center">
          {head?.label}
        </p>
        <p className="font-bold text-[20px] leading-[20px] opacity-80 lg:text-[32px] lg:leading-[32px]  tracking-[0.68px] text-center">
          {head?.text}
        </p>
      </motion.div>
      <motion.div
        className="flex  justify-center items-center lg:px-[200px] py-[100px] lg:py-[0px]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
      >
        <motion.img
          src={head?.imgWeb}
          alt="air sensor home"
          className="lg:w-[50vw]"
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      </motion.div>
    </motion.div>
  );
}

export default Welcome;

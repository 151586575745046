import React from "react";

function VideoIconWhite() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83301 6.54004C4.90475 6.54004 4.01451 6.90879 3.35813 7.56517C2.70176 8.22154 2.33301 9.11178 2.33301 10.04V19.3734C2.33301 20.3016 2.70176 21.1919 3.35813 21.8482C4.01451 22.5046 4.90475 22.8734 5.83301 22.8734H17.4997C18.4279 22.8734 19.3182 22.5046 19.9746 21.8482C20.6309 21.1919 20.9997 20.3016 20.9997 19.3734V17.523L23.6748 20.1982C23.838 20.3613 24.0459 20.4724 24.2721 20.5174C24.4984 20.5624 24.733 20.5393 24.9461 20.451C25.1593 20.3627 25.3414 20.2132 25.4696 20.0214C25.5978 19.8296 25.6663 19.6041 25.6663 19.3734V10.04C25.6663 9.80933 25.5978 9.58382 25.4696 9.392C25.3414 9.20019 25.1593 9.0507 24.9461 8.96241C24.733 8.87413 24.4984 8.85103 24.2721 8.89602C24.0459 8.94102 23.838 9.05209 23.6748 9.21521L20.9997 11.8904V10.04C20.9997 9.11178 20.6309 8.22154 19.9746 7.56517C19.3182 6.90879 18.4279 6.54004 17.4997 6.54004H5.83301Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
}

export default VideoIconWhite;

import React from "react";
import { Security, RoundClock, Guarantee } from "../../assets/svg";
import { motion } from "framer-motion";

const warrantyArray = [
  {
    svg: <Security />,
    text: "7 Years Warranty – Enjoy long term protection and worry-free service.",
  },
  {
    svg: <RoundClock />,
    text: "Seamless Automation – Experience effortless control for your entire home.",
  },
  {
    svg: <Guarantee />,
    text: "Future-Ready Technology – Stay ahead with smart, secure, and efficient solutions.",
  },
];

const WarrantyCard = ({ svg, text }) => {
  return (
    <div className=" h-[126px] lg:h-[176px] lg:w-[240px]  flex flex-col items-center justify-center gap-[16px] ">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
      >
        {svg}
      </motion.div>
      <motion.p
        className="font-inter font-normal text-[14px] leading-[19.36px] lg:text-[18px] lg:leading-[21.78px] tracking-[0] px-[5px] text-white text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      >
        {text}
      </motion.p>
    </div>
  );
};

function Warranty() {
  return (
    <div
      className="h-[732px] lg:h-[600px]  px-[24px] py-[50px] lg:px-[108px] lg:py-[100px] bg-gradient-to-r from-[#56595E] via-[#404348] to-[#0A0E11]"
      style={{
        background: "linear-gradient(99.1deg, #56595E 9.79%, #0A0E11 56.73%)",
      }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-[20px]  place-items-center">
        <div className=" flex justify-center items-center lg:w-[362px]">
          <motion.img
            className="w-[181px] h-[200px] lg:w-[362px] lg:h-[400px] "
            src={"/images/warranty.png"}
            alt={"Warranty"}
            loading="lazy"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          />
        </div>
        {warrantyArray?.map((item, index) => (
          <WarrantyCard key={index} svg={item.svg} text={item.text} />
        ))}
      </div>
    </div>
  );
}

export default Warranty;

import React from "react";
import { TeamIcon, TrustIcon, ExceedIcon } from "../../assets/svg";

const SuccessCardTemplate = ({ item }) => {
  return (
    <div
      className="border border-[#FFFFFF33] rounded-[8px] p-[16px] lg:p-[28px]"
      style={{
        background:
          "linear-gradient(141.52deg, rgba(255, 255, 255, 0) -6.72%, rgba(255, 255, 255, 0.17) 112.79%)",
      }}
    >
      {/* <div className=" border">{item}</div> */}
      <div className="flex flex-col gap-y-[80px] lg:gap-y-[100px]">
        <div className="flex flex-col gap-y-[16px] lg:gap-y-[49px]">
          <div className="flex gap-[6px]">
            <p className=" font-inter font-medium text-[#F4F4F4] text-[16px] leading-[19.09px] tracking-[0%] text-center border border-[#F4F4F4] px-[14px] py-[7px] rounded-full">
              {item?.value}
            </p>

            <p className="font-inter font-medium text-[#F4F4F4] text-[16px] leading-[19.09px] tracking-[0%] text-center border border-[#F4F4F4] px-[10px] py-[7px] rounded-[9px]">
              {item?.count}
            </p>
          </div>
          <div className=" text-left">
            <p className="font-medium h-[40px] lg:h-[60px] text-[#F4F4F4] text-[20px] leading-[24px]  lg:text-[32px] lg:leading-[38.4px] tracking-normal">
              {item?.head}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center h-[64px] w-[64px] lg:h-[120px] lg:w-[120px] border border-[#FFFFFF33] rounded-[8px]">
          {item?.icon}
        </div>
      </div>
    </div>
  );
};

const cardArray = [
  { value: "value", count: "1", head: "Win as a team", icon: <TeamIcon /> },
  {
    value: "value",
    count: "2",
    head: "Be worthy of people’s trust",
    icon: <TrustIcon />,
  },
  {
    value: "value",
    count: "3",
    head: "Exceed expectations",
    icon: <ExceedIcon />,
  },
  {
    value: "value",
    count: "4",
    head: "Be worthy of people’s trust",
    icon: <TrustIcon />,
  },
];

function StoryCard() {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-[12px] gap-[12px] lg:gap-[32px] ">
      {cardArray?.map((item, index) => {
        return <SuccessCardTemplate key={index} item={item} />;
      })}
    </div>
  );
}

export default StoryCard;
